import { RootState } from '@/store';
import { setBasicFilter } from '@/store/reportSlice';
import { ApolloError } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ITsuTableItem } from '../../common/components/tsu-table/interfaces';
import { IOrderShipping, ReportHeader, ReportProps } from '../../common/interfaces';
import { useReportAdvanced } from './hooks/useReportAdvanced';
import { useReportBasic } from './hooks/useReportBasic';

export function useReport() {
  const dispatch = useDispatch();
  const basicFilter = useSelector((store: RootState) => store.report.basicFilter);

  // ====================================
  // Table Options
  // ====================================

  const initialOpts = useMemo(
    () => ({
      page: 0,
      itemsPerPage: 20,
      searchValue: '',
      freezeHeaders: [] as ReportHeader[],
      hideHeaders: [] as ReportHeader[],
      groupByHeaders: [] as ReportHeader[],
    }),
    []
  );
  const [opts, setOpts] = useState(initialOpts);

  // ====================================
  // Basic, Advanced, Line Order Reports
  // ====================================

  const pagination = {
    skip: opts.page * opts.itemsPerPage,
    take: opts.itemsPerPage,
    sort: opts.groupByHeaders.map((h) => ({ column: h.value, order: 'ASC' })),
  } as ReportProps;
  // Report data
  const reportBasic = useReportBasic(pagination);
  const reportAdvanced = useReportAdvanced(pagination);

  // ====================================
  // Selected Report Type state
  // ====================================

  // Caller can switch to another report (Basic, Advanced, etc.)
  const [selectedReport, setSelectedReport] = useState('basic');
  const reportChoices = useMemo(
    () => [
      { text: 'Reports - Basic', value: 'basic' },
      { text: 'Reports - Advanced', value: 'advanced' },
      { text: 'Reports - Line Order', value: 'lineOrder' },
    ],
    []
  );

  // ====================================
  // Table data,
  // changes based on selected report
  // ====================================

  // Report (table) data - can be any of the Reports - Basic, Advanced, ... etc.
  let rtu: any; // rtu - Report To Use
  if (selectedReport === 'basic') rtu = reportBasic;
  else if (selectedReport === 'advanced') rtu = reportAdvanced;
  else if (selectedReport === 'lineOrder') rtu = reportBasic;
  // Once the report to use is determined, recompute table data
  // to pass back to caller
  const tableData = {
    items: rtu.data as any[],
    headers: rtu.headers as ReportHeader[],
    totalCount: rtu.totalCount as number,
    loading: rtu.loading as boolean,
    error: rtu.error as ApolloError,
  };

  // ====================================
  // Custom Table Item Render function
  // ====================================

  const customTableItemRender = useCallback((item: ITsuTableItem, value: string) => {
    const _item = item as any;
    if (value === 'despatchDate' || value === 'created') {
      let date = _item[value];
      // Some dates can be null
      if (date === null || date === undefined) return <span>-</span>;
      // Some dates are returned as string (not sure why, but let's handle it)
      if (typeof date === 'string') date = parseISO(date);
      return <span>{format(date, 'dd/MM/yyyy')}</span>;
    }
    const column = _item[value as keyof IOrderShipping];
    // Handle falsey / null values and return -
    if (!column) return <span>-</span>;
    return <span>{column}</span>;
  }, []);

  // ====================================
  // Effects
  // ====================================

  // If selected report changes, we should reset table options
  // (because table data may be different)

  useEffect(() => {
    if (selectedReport === null) return;
    setOpts(initialOpts);
    // Trigger refresh on whatever report we selected
    rtu.refresh();
    // Ignore exhaustive deps here, because we only want to fire this effect
    // when selected report changes, and do not care about other variables / functions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport]);

  // ====================================
  // Functions to update table options
  // ====================================

  const setPage = (page: number) => setOpts({ ...opts, page });

  const setItemsPerPage = (ipp: number) => setOpts({ ...opts, itemsPerPage: ipp });

  const setFreezeHeaders = (freezeHeaders: ReportHeader[]) => setOpts({ ...opts, freezeHeaders });

  const setHideHeaders = (hideHeaders: ReportHeader[]) => setOpts({ ...opts, hideHeaders });

  const setGroupByHeaders = (groupByHeaders: ReportHeader[]) =>
    setOpts({ ...opts, groupByHeaders });

  const setSearchValue = (searchValue: string) => setOpts({ ...opts, searchValue });

  const onFilterDelete = (key: string, i: number, type: string) => {
    if (type === 'basic') {
      dispatch(setBasicFilter({ ...basicFilter, [key]: null }));
    }
  };

  // ====================================
  // Functions to update state
  // ====================================

  const selectReport = (r: string) => setSelectedReport(r);

  return {
    // Table data
    items: tableData.items,
    loading: tableData.loading,
    error: tableData.error,
    headers: tableData.headers,
    totalCount: tableData.totalCount,
    // Loading & error states
    // Table pagination & options
    opts,
    setPage,
    setItemsPerPage,
    setFreezeHeaders,
    setHideHeaders,
    setGroupByHeaders,
    setSearchValue,
    onFilterDelete,
    // Table Custom Item Render function
    customTableItemRender,
    // Choose type of report
    selectedReport,
    selectReport,
    reportChoices,
  };
}
