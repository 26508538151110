import QuantityAdjustment from '@/common/components/QuantityAdjustment';
import QuantityDropdown from '@/common/components/QuantityDropdown';
import { FavoriteButton } from '@/features/favorites/components/FavoriteButton';
import { ProductContext } from '@/features/product/product.context';
import { ChildProductsQuery, ChildProductsDocument, ProductType } from '@/graphql';
import { useApolloClient } from '@apollo/client';
import { Box, Button, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';

type QuantChangeEvent = SelectChangeEvent<string>;
type MouseEvent = React.MouseEvent;

interface ChildProductProps {
    productId: number;
    name: string;
    code: string;
    stockAvailable: number;
    parentId?: number | null | undefined;
}

interface Props {
  onAddToCart?: (id: number, amount: number) => void;
  onClick?: () => void;
}

export function ShopItemActions(props: Props) {
  const client = useApolloClient();
  const [childProducts, setChildProducts] = useState<ChildProductProps[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const product = useContext(ProductContext);
  // This will only be used if the product uses Price Breaks
  const quantityChoices = product.prices ?? [];

  const [amount, setAmount] = useState(
    // In case there is a computation issue with defaultQuantity,
    // use minOrderQuantity if it is lower than defaultQuantity
    Math.max(product.defaultQuantity, product.minOrderQuantity ?? 1)
  );
  const getChildProds = async () => {
    try {
      const res = await client
          .query<ChildProductsQuery>({
            query: ChildProductsDocument,
            variables: { productId: product.productId }
          });
      const child_list_var = res.data.childProducts;
      if(child_list_var) {
        setChildProducts(child_list_var);
      }
    }
    catch(error) {
      console.error('Promise failed: ', error);
    }
  };
  

  // const handleAddToFavorites = () =>
  //   enqueueSnackbar('This feature is not yet implemented', { variant: 'info' });

  const handleQuantityDropdownChange = (v: string, e: QuantChangeEvent) => {
    e.stopPropagation();
    setAmount(parseInt(v));
  };

  const handleDecrementClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setAmount(amount - 1 > 0 ? amount - 1 : 1);
  };

  const handleIncrementClick = (e: MouseEvent) => {
    e.stopPropagation();
    setAmount(amount < product.stockAvailable ? amount + 1 : amount);
  };

  const addToCart = (e: MouseEvent) => {
    e.stopPropagation();
    props.onAddToCart?.(product.productId, amount);
  };

  useEffect(() => {
    getChildProds();
  }, [product.productId]);

  if ((product.isNormalProduct && product.hasStock && (product.stockAvailable >= (product.minOrderQuantity ?? 1))) || product.isContractProduct) {
    return (
      <Grid container alignItems="center">
        <Grid item xs={6}>
          {product.hasPriceBreaks ? (
            <QuantityDropdown
              value={amount.toString()}
              onChange={handleQuantityDropdownChange}
              quantityChoices={quantityChoices}
            />
          ) : (
            <QuantityAdjustment
              min={product.minOrderQuantity ?? 1}
              max={product.stockAvailable!}
              quantity={amount}
              onChange={setAmount}
              onIncrementClick={handleIncrementClick}
              onDecrementClick={handleDecrementClick}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          <Button
            onClick={addToCart}
            variant="contained"
            sx={{ width: '100%', whiteSpace: 'nowrap'}}
          >
            Add to Cart
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (product.isArchiveProduct) {
    return (
      <Box flexGrow={1}>
        <Button
          onClick={addToCart}
          variant="contained"
          sx={{ width: '100%', whiteSpace: 'nowrap', wordBreak: 'keep-all' }}
        >
          Add to Cart
        </Button>
      </Box>
    );
  }

  if (product.isNormalProduct && !product.hasStock && (childProducts.length == 0 || childProducts.every((childProd) => childProd.stockAvailable == 0))) {
    return <Typography>No stock available</Typography>;
  }
  if (product.isNormalProduct && product.stockAvailable == 0 && childProducts.length != 0 && childProducts.some((childProd) => childProd.stockAvailable > 0)) {
    return <Button variant='outlined' onClick={props.onClick} sx={{width: '100%', whiteSpace: 'nowrap'}}>Click for more options</Button>;
  }

  // last resourt, display a Favourite button
  return <FavoriteButton productId={product.productId}/>
}
