/**
 * This component intends to be the "container" for a step in the checkout process.
 * In this card / container, we display the following components:
 *  1. The STEP NUMBER alongside the TITLE of the step - at the top of the card.
 *  2. The "FORM" or component that handles this step.
 *
 * We provide the descendant components with a custom React Context that provides
 * the following details:
 *  1. The index of the current step
 *  2. The title of the current step
 *  3. The title that belongs to the "submit button" of the step
 */

import { TsuStepNumber } from '@/common/components';
import { Box, Button, Card, CardContent, Stack, Tooltip, Typography } from '@mui/material';
import React, { PropsWithChildren, useContext } from 'react';
import { useCheckoutStep } from '../checkout-store';
import { CHECKOUT_STEPS } from '../constants/checkout-steps';

type StepContext = {
  stepIndex: number;
  stepTitle?: string;
  submitTitle?: string;
  nextStep: () => void;
};

const StepContext = React.createContext<StepContext>({} as StepContext);

interface StepCardProps {
  title: string;
  submitTitle?: string;
  step: number;
  isActive: boolean;
  showEditButton: boolean;
  hideContent: boolean;
  onEditClick?: () => void;
  onFinish?: () => void;
  onNextStep?: () => void;
}

export function StepCard(props: PropsWithChildren<StepCardProps>) {
  // We want to wrap nextStep with our own nextStep function
  // so that we can signal the (parent) Checkout screen when it is already the final step.
  const { currentStep, nextStep, isStepEncountered } = useCheckoutStep();

  // These values will be passed to our descendants.
  // These are all optional and the descendants have control over whether to use them or not.
  const stepContext: StepContext = {
    stepIndex: props.step,
    stepTitle: props.title,
    submitTitle: props.submitTitle,
    nextStep() {
      // Check if we're at the final step
      if (currentStep + 1 >= CHECKOUT_STEPS.length) {
        // If so, emit event for Checkout screen to know.
        props.onFinish?.();
      } else {
        // Otherwise, emit "next step" event
        props.onNextStep?.();
      }
      // Call our Checkout Store's (Zustand) next step function (state update)
      nextStep();
    },
  };

  const hideContent = props.hideContent && !isStepEncountered(props.step);

  return (
    <Card>
      <CardContent>
        {/* Step's header section */}
        <Stack direction="row" justifyContent="space-between" sx={{ mb: props.isActive ? 0 : 2 }}>
          {/* This is the STEP NUMBER with the TITLE of the step. */}
          <Stack direction="row" spacing={1} alignItems="center">
            <TsuStepNumber step={props.step + 1} />
            <Typography variant="h6" sx={{ color: 'primary.main' }}>
              {props.title}
            </Typography>
          </Stack>
          {/* This button intends to allow the user to BACKTRACK to this step and edit its details. */}
          <Box hidden={!props.showEditButton}>
            <Button onClick={props.onEditClick}>Edit Details</Button>
          </Box>
        </Stack>

        {/* Here we actually render the content / form of this step. */}
        <StepContext.Provider value={stepContext}>
          <Box hidden={hideContent} onDoubleClick={props.onEditClick}>
            {props.children}
          </Box>
        </StepContext.Provider>
      </CardContent>
    </Card>
  );
}

// Allow descendant components to access our React Context
// and optionally use any of the values we provided.
export function useStepCardContext() {
  return useContext(StepContext);
}
