import ContentLoader from 'react-content-loader';

export function ShopItemLoader() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={460}
      viewBox="0 0 300 460"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="6" y="6" rx="2" ry="2" width="300" height="150" />
      <rect x="8" y="174" rx="0" ry="0" width="294" height="19" />
      <rect x="9" y="202" rx="0" ry="0" width="293" height="19" />
      <rect x="8" y="234" rx="0" ry="0" width="173" height="18" />
      <rect x="10" y="267" rx="0" ry="0" width="178" height="22" />
      <rect x="11" y="305" rx="0" ry="0" width="139" height="21" />
      <rect x="11" y="371" rx="0" ry="0" width="141" height="37" />
      <rect x="162" y="372" rx="0" ry="0" width="141" height="37" />
    </ContentLoader>
  );
}
