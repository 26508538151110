// This enum is based off Tsunami Backend's order type enum
export enum OrderTypeEnum {
  Normal = 1,
  Archive = 2,
  Contract = 3,
  Etemplate = 4,
  OnDemand = 5,
  Mixed = 8,
  Destruction = 9,
  Replenishment = 10,
  Retrieve = 11,
  Return = 12,
  Stationary = 25,
  Procurement = 26,
  Marketing = 27,
  ThirdParty = 28,
  GoodIssue = 29,
  DigitalDownload = 30,
  ZeeScapeJob = 31,
  Consult = 32,
  Invoice = 33,
  WBSSalesJob = 34,
  WBSPurchaseQuote = 35,
  WBSSalesQuote = 36,
  WBSPurchaseOrder = 37,
  WBSSalesOrder = 38,
  WBSDeliveryNote = 39,
  WBSARInvoice = 40,
  WBSAPInvoice = 41,
  WBSARCreditMemo = 42,
  WBSAPCreditMemo = 43,
  WBSARDownPayment = 44,
  WBSAPDownPayment = 45,
  WBSSalesCreditNote = 46,
}
