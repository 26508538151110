import { TsuSelect } from '@/common/components';
import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { useGetCategoriesQuery, useOrderTypesQuery } from '../../../graphql';

export interface Filter {
  orderTypeId: number | null;
  categoryId: number | null;
}

export const makeBasicFilter = () => ({
  orderTypeId: null,
  categoryId: null,
});

interface BasicFilterProps {
  onApplyFilters?: (draftBasicFilter: Filter) => void;
}

export function BasicFilter({ onApplyFilters }: BasicFilterProps) {
  const [filter, setFilter] = useState<Filter>(makeBasicFilter);
  const { data: orderTypesQuery, loading: isOrderTypesLoading } = useOrderTypesQuery();
  const { data: categoriesQuery, loading: isCategoriesLoading } = useGetCategoriesQuery();

  const orderTypes = orderTypesQuery?.orderTypes ?? [];

  const handleApplyFilters = () => onApplyFilters?.(filter);
  const handleClearAll = () => setFilter(makeBasicFilter());

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <TsuSelect
          value={filter.orderTypeId?.toString() ?? ''}
          items={orderTypes}
          loading={isOrderTypesLoading}
          onChange={(v) =>
            setFilter({
              ...filter,
              orderTypeId: parseInt(v),
            })
          }
          itemText="name"
          itemValue="orderTypeId"
          label="Order Type"
        />
        <TsuSelect
          value={filter.categoryId?.toString() ?? ''}
          items={categoriesQuery?.childCategories ?? []}
          onChange={(v) => setFilter({ ...filter, categoryId: parseInt(v) })}
          loading={isCategoriesLoading}
          itemText="name"
          itemValue="categoryId"
          label="Category"
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button onClick={handleApplyFilters} variant="contained">
          Apply Filters
        </Button>
        <Button onClick={handleClearAll} variant="text">
          Clear All
        </Button>
      </Stack>
    </Stack>
  );
}
