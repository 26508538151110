import MaintenanceImage from '@/assets/images/Maintenance.png';
import { DASHBOARD_ROUTE } from '@/features/dashboard/dashboard.routes';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

export function UnderConstructionBanner() {
  const theme = useTheme();
  return (
    <Stack
      direction={{ xs: 'column-reverse', lg: 'row' }}
      alignItems="center"
      spacing={{ xs: 3, lg: 0 }}
    >
      <Stack flex={1} alignItems="stretch" spacing={4}>
        <Stack sx={{ textAlign: 'center' }} spacing={2}>
          <Typography variant="h1" fontWeight="bold">
            Under Construction
          </Typography>
          <Typography variant="h6">
            This page is under construction.{' '}
            <span style={{ color: theme.palette.primary.main }}>Contact Us</span>
          </Typography>
        </Stack>
        <Button
          component={Link}
          to={DASHBOARD_ROUTE}
          variant="contained"
          sx={{ alignSelf: 'stretch', py: 2 }}
        >
          <Typography variant="h6">Go back to Home</Typography>
        </Button>
      </Stack>
      <Box flex={1} width={{ xs: '100%', lg: 'auto' }} height={{ xs: 'auto', lg: 500 }}>
        <img
          src={MaintenanceImage}
          alt="Page Not Found"
          style={{
            flex: 1,
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      </Box>
    </Stack>
  );
}
