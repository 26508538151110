import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reportReducer from './reportSlice';
import searchReducer from './searchSlice';
import userReducer from './userSlice';

// Configuration taken from https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

// Combine reducers into rootReducer so we can pass it to persistReducer
// Source: https://saadbashar.com/2021/09/19/integrating-redux-toolkit-redux-persist-and-react-native-debugger
const rootReducer = combineReducers({
  user: userReducer,
  search: searchReducer,
  report: reportReducer,
});

// Source: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux-Persist + Redux-Toolkit reference: https://stackoverflow.com/a/68456094
export const store = configureStore({
  reducer: persistedReducer,
  // Avoid "non-serializable value was detected" error with redux-persist by passing
  // this confusing middleware configuration copied from Redux-Toolkit tutorial
  // Reference: https://stackoverflow.com/a/63244831
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// This will be passed to PersistGate in App.tsx
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
