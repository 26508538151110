import { TsunamiTheme } from '@/graphql';
import { responsiveFontSizes, ThemeOptions, TypeBackground } from '@mui/material';
import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import DEFAULT_TSUNAMI_THEME from './assets/default-tsunami-theme.json';

export function createFromTsunamiTheme(theme?: Omit<TsunamiTheme, 'customerId' | 'themeId'>) {
  const tsunamiTheme = theme ?? DEFAULT_TSUNAMI_THEME;
  // if (!tsunamiTheme) tsunamiTheme =
  // Transform / map "TsunamiTheme" into a "MUI Theme"
  // @ts-ignore
  const muiTheme = makeThemeObject(tsunamiTheme);
  // Pass MUI Theme into MUI's createTheme function
  return responsiveFontSizes(createTheme(muiTheme));
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    main: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    neutral: true;
  }
}

export const blankColor = '#FFFFFF';

export function makeThemeObject(
  tsunamiTheme: Omit<TsunamiTheme, 'customerId' | 'themeId'>
): ThemeOptions {
  const primary = tsunamiTheme.primary;
  const secondary = tsunamiTheme.secondary;
  const background: Partial<TypeBackground> = {
    default: tsunamiTheme.background.main,
    paper: blankColor,
  };
  return {
    palette: {
      primary,
      secondary,
      background,
      // text: defaultTheme.palette.text,
    },
    typography: {
      h1: {
        fontFamily: tsunamiTheme.fonts.navigationAndHeading,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '42px',
        lineHeight: '1.2',
      },
      h2: {
        fontFamily: tsunamiTheme.fonts.navigationAndHeading,
        fontWeight: 700,
        fontStyle: 'normal',
        fontSize: '32px',
        lineHeight: '1.1',
      },
      h3: {
        fontFamily: tsunamiTheme.fonts.navigationAndHeading,
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '28px',
        lineHeight: '1.2',
      },
      h4: {
        fontFamily: tsunamiTheme.fonts.navigationAndHeading,
        fontWeight: 500,
        fontStyle: 'normal',
        fontSize: '24px',
        lineHeight: '1.1',
      },
      h5: {
        fontFamily: tsunamiTheme.fonts.navigationAndHeading,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '20px',
        lineHeight: '1.2',
      },
      h6: {
        fontFamily: tsunamiTheme.fonts.navigationAndHeading,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '18px',
        lineHeight: '1.2',
      },
      body1: {
        fontFamily: tsunamiTheme.fonts.body,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '1.7',
      },
      body2: {
        fontFamily: tsunamiTheme.fonts.body,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '1.7',
      },
      caption: {
        fontFamily: tsunamiTheme.fonts.body,
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '1.5',
      },
    },
    components: {
      MuiTypography: {
        variants: [
          {
            props: { variant: 'main' },
            style: {
              color: primary.main,
            },
          },
        ],
      },
      MuiButton: {
        // Custom Outlined Button (Source: https://mui.com/customization/theme-components/#adding-new-component-variants)
        variants: [
          {
            props: { variant: 'contained' },
            style: {
              textTransform: 'none',
              fontWeight: 500,
              fontSize: '1rem',
              borderRadius: 8,
              '&:hover': {
                // backgroundColor: opts.secondaryMain,
                color: primary.contrastText,
              },
            },
          },
          {
            props: { variant: 'outlined' },
            style: {
              border: `solid 1px ${primary.main}`,
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: '1rem',
              borderRadius: 8,
              '&:hover': {
                backgroundColor: primary.main,
                color: tsunamiTheme.primary.contrastText,
              },
            },
          },
          {
            props: { variant: 'text' },
            style: {
              textTransform: 'none',
              color: primary.main,
              fontSize: '1rem',
              borderRadius: 8,
            },
          },
          {
            props: { variant: 'neutral' },
            style: {
              textTransform: 'none',
              fontWeight: 500,
              fontSize: '1rem',
              borderRadius: 8,
              backgroundColor: grey[500],
              color: blankColor,
              '&:hover': {
                backgroundColor: grey[700],
              },
            },
          },
        ],
      },
      MuiTextField: {
        variants: [
          {
            props: { variant: 'outlined' },
            style: {
              borderColor: primary.main,
              '& .MuiInputBase-formControl': {
                backgroundColor: 'background.default',
                borderRadius: 2.5,
              },
              width: '100%',
              textTransform: 'none',
            },
          },
        ],
      },
      MuiAlert: {
        // Reduce padding in some components inside Alert dialog
        // to match closer to the Figma design
        styleOverrides: {
          // Message content has paddings on top & bottom
          message: {},
          // Close button icon has paddings on left & top
          action: {
            paddingTop: 0,
            paddingLeft: 0,
            // Align the icon vertically center (parent container is flexbox)
            alignSelf: 'center',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundColor: blankColor,
            borderRadius: '10px',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: primary.main,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: blankColor,
          },
        },
      },
    },
  };
}
