import { createContext } from 'react';
import { Role } from '../interfaces';

export interface ISelectedRoleContext {
  selectedRole: Role | null;
}

export const SelectedRoleContext = createContext<ISelectedRoleContext>({
  selectedRole: null,
});
