import { TsuPills } from '@/common/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCheckoutStep, useCheckoutStore } from '../checkout-store';
import { CHECKOUT_REQUIRED_FIELD_MESSAGE } from '../constants/initial-data';
import { PaymentMethodForm, PaymentMethods } from '../interfaces';
import { useStepCardContext } from './StepCard';
import { useHasFeature } from '../../../common/auth/hooks/use-has-feature';
import { CustomerFeatureEnum } from '../../auth/auth.constants';
import { useGetSharedPaymentUrlQuery, useSubmitOrderWithoutEmailMutation, useUpdateOrderShippingStatusMutation } from '@/graphql';
import { useGetUserCartQuery } from '@/graphql';
import { client } from '@/core/apollo-client';
import { CartItemInterface, setCartItemBuffer } from '@/features/cart/components/CartInformationBuffer';
import { useShoppingCart } from '@/features/cart/shopping-cart.context';
import { useNavigate } from 'react-router';
import { Underline } from 'docx';

const message = CHECKOUT_REQUIRED_FIELD_MESSAGE;
const schema: yup.SchemaOf<PaymentMethodForm> = yup.object({
  paymentMethod: yup.mixed().oneOf(Object.values(PaymentMethods)).default('invoice'),
});

export function PaymentMethod() {
  const { contactInfo, deliveryAddress } = useCheckoutStore((s) => ({
    contactInfo: s.contactInfo,
    deliveryAddress: s.deliveryAddress,
  }));
  const navigate = useNavigate();
  const [orderIdVar, setOrderIdVar] = useState<number>(0);
  const [orderShipIdVar, setOrderShipIdVar] = useState<number>(0);
  // These values are provided by our parent Checkout Step Card (React Context).
  const isUpdatingCart = useCheckoutStore((s) => s.isUpdatingCart);
  // These values are provided by our parent Checkout Step Card (React Context).
  const { stepIndex, submitTitle, nextStep } = useStepCardContext();
  // These values are provided by the Checkout Store (Zustand).
  const { currentStep } = useCheckoutStep();
  const hasFeature = useHasFeature();
  const { data: userCartQuery, refetch } = useGetUserCartQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  });
  const [mutate, { error }] = useSubmitOrderWithoutEmailMutation();
  const [updateOSstatusMutation, {error: updateOSstatusMutationError}] = useUpdateOrderShippingStatusMutation();
  const [finishButtonDisabled, setFinishButtonDisabled] = useState(false);
  const [eWayButtonDisabled, setEWayButtonDisabled] = useState(false);
  const [alternativeButtonDisabled, setAlternativeButtonDisabled] = useState(false);
  const shoppingCart = useShoppingCart();  
  // Derived state
  const cart = userCartQuery?.userCart;
  useEffect(() => {
    if(!cart && !isUpdatingCart) {
      refetch();
    }  
  }, [isUpdatingCart]);

  const grandTotalDecimal: number | undefined = cart?.orderCosts?.grandTotal ?? 0;
  const grandTotalInt: number = Math.round(grandTotalDecimal * 100); // Rounded integer

  const methodChoices: { text: string; value: PaymentMethods, visible: boolean }[] = [
    // { text: 'Credit/Debit Card', value: PaymentMethods.PAYMENT_CARD },
    { text: 'Invoice', value: PaymentMethods.INVOICE, visible: hasFeature(CustomerFeatureEnum.CheckoutPaymentMethodInvoice) },
    { text: 'Credit/Debit Card', value: PaymentMethods.EWAY, visible: hasFeature(CustomerFeatureEnum.CheckoutPaymentMethodEWay) },
    { text: 'Alternative Payment method', value: PaymentMethods.ALTERNATIVE, visible: hasFeature(CustomerFeatureEnum.CheckoutVACCAlternativePaymentMethod) },
  ];
  const [currentMethod, setCurrentMethod] = useState<PaymentMethods>(PaymentMethods.PAYMENT_CARD);

  var currentMethodChoices = methodChoices.filter(m => m.visible);

  const handleChange = (v) => {
    setValue('paymentMethod', v);
    setCurrentMethod(v);
  }
  useEffect(() => {
    if (currentMethod == PaymentMethods.EWAY) {
      console.log("Eway selected");
    }
  }, [currentMethod]);

  useEffect(() => {
    if(currentMethodChoices.length == 1 && currentMethod !== currentMethodChoices[0].value) {
      setCurrentMethod(currentMethodChoices[0].value);
    }
  }, [currentMethodChoices]);

  const {data: sharedPaymentUrlData, loading: sharedPaymentUrlLoading, error: sharePaymentUrlError, refetch: sharedPaymentUrlRefetch} = useGetSharedPaymentUrlQuery({
    variables:{
      input: {
        orderId: orderIdVar,
        orderShipId: orderShipIdVar,
        firstName: cart?.cartInformation.firstName ?? "",
        lastName: cart?.cartInformation.lastName ?? "",
        email: contactInfo.email ?? "",
        company: contactInfo.companyName ?? "",
        street1: deliveryAddress.streetAddress ?? "",
        street2: cart?.cartInformation.billingAddress?.street2,
        state: deliveryAddress.state ?? "",
        suburb: deliveryAddress.suburb ?? "",
        postcode: deliveryAddress.postcode ?? "",
        country: cart?.cartInformation.billingAddress?.country ?? "",
        phone: contactInfo.phoneNo ?? contactInfo.mobileNo,
        mobile: contactInfo.phoneNo ?? contactInfo.mobileNo,
        totalAmount: grandTotalInt
      }
    },
    skip: !orderIdVar, // Skip the query if orderIdVar is not available
  });

  async function handleEWayClick() 
  {
    try 
    {
      setEWayButtonDisabled(true);
      // Do not proceed without user cart
      if (!userCartQuery?.userCart?.id){
        console.log("No user cart ID");
        return;
      }
      // Trigger order mutation
      const res = await mutate({ variables: { cartId: userCartQuery?.userCart?.id } });
      // Copy Cart Information to the buffer
      let cartItems: CartItemInterface[] = [];
      for (var item of shoppingCart.cartItems){
        cartItems.push(
          {
            name: item.product?.name.toString(),
            quantity: item.quantity,
            price: item.product?.prices.find((p) => p.quantity === 1)?.price
          }
        );
      }
      setCartItemBuffer(cartItems);
      // Check for problems before proceeding
      if (!res && error) return;
      // Refresh the user's cart (should be NULL as it is cleared during submitOrder mutation)
      client.refetchQueries({ include: ['userCart'] });
       // Compute orderId for route query
      const newOrderIdVar = res.data?.submitOrderWithoutEmail.orderReference?.split('~').shift() ?? '';
      const newOSId = res.data?.submitOrderWithoutEmail.orderReference?.split('~').pop() ?? '';
      // Put the new OS in Placing status
      if (newOSId) {
        const update_res = await updateOSstatusMutation({variables: {
          input: {
            orderShippingId: Number(newOSId),
            statusId: 26
          }
        }});
        setOrderShipIdVar(Number(newOSId));
      }
      if (newOrderIdVar)
      {
        setOrderIdVar(Number(newOrderIdVar)); 
      }
    } 
    catch (err)
    {
      console.log('error:', err);
      enqueueSnackbar((err as any).message, { variant: 'error' });
    }
  }

  async function handleAlternativeClick() 
  {
    try 
    {
      setAlternativeButtonDisabled(true);
      // Do not proceed without user cart
      if (!userCartQuery?.userCart?.id){
        console.log("No user cart ID");
        return;
      }
      // Trigger order mutation
      const res = await mutate({ variables: { cartId: userCartQuery?.userCart?.id } });
      // Copy Cart Information to the buffer
      let cartItems: CartItemInterface[] = [];
      for (var item of shoppingCart.cartItems){
        cartItems.push(
          {
            name: item.product?.name.toString(),
            quantity: item.quantity,
            price: item.product?.prices.find((p) => p.quantity === 1)?.price
          }
        );
      }
      setCartItemBuffer(cartItems);
      // Check for problems before proceeding
      if (!res && error) return;
      // Clear the cart items saved in store
      shoppingCart.removeAllItems();
      // Refresh the user's cart (should be NULL as it is cleared during submitOrder mutation)
      client.refetchQueries({ include: ['userCart'] });
       // Compute orderId for route query
      const orderId = res.data?.submitOrderWithoutEmail.orderReference?.split('~').shift() ?? '';
      const newOSId = res.data?.submitOrderWithoutEmail.orderReference?.split('~').pop() ?? '';
      // Put the new OS in Placing status
      if (newOSId) {
        const update_res = await updateOSstatusMutation({variables: {
          input: {
            orderShippingId: Number(newOSId),
            statusId: 7
          }
        }});
        setOrderShipIdVar(Number(newOSId));
      }
      if (orderId)
      {
        setOrderIdVar(Number(orderId)); 
      }
      navigate({
        pathname: '/transactionFinished',
        search: new URLSearchParams({ orderId }).toString(),
      });
    } 
    catch (err)
    {
      console.log('error:', err);
      enqueueSnackbar((err as any).message, { variant: 'error' });
    }
  }

  useEffect(() => {
    if(!sharedPaymentUrlData && !sharedPaymentUrlLoading) {
      sharedPaymentUrlRefetch();
    }
    if(sharedPaymentUrlData){
      const paymentUrl = sharedPaymentUrlData?.sharedPaymentUrl;
      window.location.href = paymentUrl as string;
    }
  }, [orderIdVar, sharedPaymentUrlData, sharedPaymentUrlLoading]);

  // Define our form state using react-hook-form & yup.
  const { handleSubmit, control, setValue } = useForm<PaymentMethodForm>({
    defaultValues: { paymentMethod: methodChoices.slice().shift()!.value },
    resolver: yupResolver(schema),
  });
  const submitPaymentMethod = useCheckoutStore((s) => s.submitPaymentMethod);
  // Derived state
  const isReadOnly = currentStep != stepIndex;
  const onSubmit = handleSubmit((data) => {
    setFinishButtonDisabled(true);
    submitPaymentMethod(data);
    nextStep();
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2} mt={4}>
        <Controller
          name="paymentMethod"
          control={control}
          render={({ field, formState: { errors } }) => (
            <Box sx ={{display: (methodChoices.filter(m => m.visible).length == 1) ? 'none' : 'block'}}>
              <TsuPills
                value={field.value}
                onChange={(v) => handleChange(v)}
                selected = {methodChoices.filter(m => m.visible)[0]}
                items={methodChoices.filter(m => m.visible)}
                readOnly={isReadOnly}
              />
              {errors.paymentMethod ? (
                <FormHelperText error>{errors.paymentMethod.message}</FormHelperText>
              ) : null}
            </Box>
          )}
        />
        <Box hidden={ currentMethod !== PaymentMethods.EWAY }>
          <Typography>
            <b>Please Note:</b> We do not keep your credit card details in any form, they are only used for this single transaction. All transactions are in Australian Dollars (AUD) unless otherwise specified.
            <br/><br/>
            <b>You will be redirected to a Secure Hosted Page to finish payment.</b>
            <br/>
          </Typography>
          <Button variant="contained" onClick={handleEWayClick} disabled={eWayButtonDisabled} sx={{marginTop: '15px'}}>
            Proceed with Credit/Debit Card (eWay)
          </Button>
        </Box>
        <Box hidden={ currentMethod !== PaymentMethods.INVOICE }>
          <Grid container>
            <Grid xs={6} item>
              <Button type="submit" variant="contained" sx={{ alignSelf: 'start', width: '100%' }} disabled={currentMethod === PaymentMethods.EWAY || finishButtonDisabled}>
                {submitTitle}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box hidden={ currentMethod !== PaymentMethods.ALTERNATIVE }>
          <Typography sx={{textDecoration: 'underline', marginTop: '10px', marginBottom: '10px'}}>
            <b>PAYMENTS BY CHEQUE</b>
          </Typography>
          <Typography>
            If paying by CHEQUE, please note that due to normal clearance requirements, delivery of goods may take up to 10 business days.
            <br/><br/>
            Cheques should be posted to:
            <br/>
            Ms Yvonne Chan
            <br/>
            VACC House
            <br/>
            650 Victoria Street
            <br/>
            North Melbourne VIC 3051
          </Typography>
          <Typography sx={{textDecoration: 'underline', marginTop: '10px', marginBottom: '10px'}}>
            <b>PAYMENTS BY EFT</b>
          </Typography>
          <Typography>
            If paying by EFT, please include the reference ST##orderID##
            <br/><br/>
            Please note that due to funds clearance requirements, delivery of goods may take up to 5 business days.
            <br/><br/>
            Bank details for EFT payment:
            <br/>
            Bank Westpac
            <br/>
            Account Name VACC
            <br/>
            BSB No 033-079
            <br/>
            Account No 00-0273
          </Typography>
          <Button variant="contained" onClick={handleAlternativeClick} disabled={alternativeButtonDisabled} sx={{marginTop: '15px'}}>
            Invoice Checkout
          </Button>
        </Box>
      </Stack>
    </form>
  );
}
function enqueueSnackbar(message: any, arg1: { variant: string; }) {
  throw new Error('Function not implemented.');
}

