import { useEffect, useState } from 'react';
import { MediaTabProps } from './MediaTab';

export type FileWithPreview = File & {
  preview: string;
};

export function useMediaTab({ productId }: MediaTabProps) {
  // const { productImage } = useProductInfo();
  const [imageFile, setImageFile] = useState<FileWithPreview | null>(null);
  const isSaving = false;

  useEffect(() => {
    if (imageFile !== null) URL.revokeObjectURL(imageFile.preview);
  }, [imageFile]);

  function save() {
    
  }

  return {
    // State
    imageFile,
    setImageFile,
    // Functions
    save,
    // Flags
    isSaving,
  };
}
