import { TsuTable } from '@/common/components';
import { useTopOrderedProductsQuery } from '@/graphql';
import { Stack, Typography } from '@mui/material';
import { useState } from 'react';

const headers = [
  { text: 'Product Code', value: 'productCode' },
  { text: 'Product Name', value: 'productName' },
  { text: 'Product Sold', value: 'productsSold' },
  { text: 'Total Quantity', value: 'totalQuantity' },
];

function TopOrderedProductsTable() {
  const [opts, setOpts] = useState({ page: 0, itemsPerPage: 20 });
  const { data, error, loading } = useTopOrderedProductsQuery({
    variables: {
      skip: opts.page * opts.itemsPerPage,
      take: opts.itemsPerPage,
    },
  });
  const items = data?.topOrderedProducts?.items ?? [];
  const totalCount = data?.topOrderedProducts?.totalCount;

  return (
    <Stack spacing={2} alignItems="stretch">
      <Typography variant="h2" fontWeight="bold" sx={{ alignSelf: 'center' }}>
        Top Ordered Products this Month
      </Typography>

      <TsuTable
        headers={headers}
        items={items}
        totalCount={totalCount}
        loading={loading}
        error={error}
        stretchColumns={true}
        page={opts.page}
        itemsPerPage={opts.itemsPerPage}
        onPageChange={(page) => setOpts({ ...opts, page })}
        onItemsPerPageChange={(ipp) => setOpts({ ...opts, itemsPerPage: ipp })}
      />
    </Stack>
  );
}

export default TopOrderedProductsTable;
