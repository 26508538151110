import { useQuery } from '@apollo/client';
import { Checkbox, List, ListItemButton, ListItemText, Stack } from '@mui/material';
import { gql } from 'apollo-boost';
import { useContext, useEffect, useState } from 'react';
import { SelectedRoleContext } from '../context/selected-role.context';
import { CustomFreightSetting } from '../interfaces/freight-setting.interfaces';

function useFreightSettings(roleId?: number) {
  const [selectedFreightSettings, setSelectedFreightSettings] = useState<CustomFreightSetting[]>(
    []
  );

  // fs = Freight Settings
  const { data: fsQuery } = useQuery<{ roleFreightSettings: CustomFreightSetting[] }>(
    gql`
      query ($roleId: Int!) {
        roleFreightSettings(roleId: $roleId) {
          freightSettingId
          name
          isActive
        }
      }
    `,
    { variables: { roleId } }
  );

  useEffect(() => {
    if (fsQuery)
      setSelectedFreightSettings(fsQuery.roleFreightSettings.filter((fs) => fs.isActive));
  }, [fsQuery]);

  return {
    freightSettings: fsQuery?.roleFreightSettings ?? [],
    selectedFreightSettings,
    setSelectedFreightSettings,
  };
}

interface Props {
  onChange?: (selectedFreightSettings: CustomFreightSetting[]) => void;
}

export function RoleFreightSettingsList(props: Props) {
  const { selectedRole } = useContext(SelectedRoleContext);
  const { freightSettings, selectedFreightSettings, setSelectedFreightSettings } =
    useFreightSettings(selectedRole?.roleId);

  useEffect(() => {
    props.onChange?.(selectedFreightSettings);
  }, [selectedFreightSettings, props.onChange]);

  // This is meant for checking Freight Setting items
  function handleFreightSettingClick(idx: number) {
    const freightSetting = freightSettings[idx];
    const isSelected = selectedFreightSettings.some(
      (sfs) => sfs.freightSettingId === freightSetting.freightSettingId
    );
    if (!isSelected) {
      // Add to list of selected freight settings
      setSelectedFreightSettings([...selectedFreightSettings, freightSetting]);
    } else {
      // Remove from list of selected freight settings
      setSelectedFreightSettings(
        selectedFreightSettings.filter(
          (sfs) => sfs.freightSettingId !== freightSetting.freightSettingId
        )
      );
    }
  }

  return (
    <Stack>
      <List>
        {freightSettings.map((fs, i) => (
          <ListItemButton key={i} sx={{ pl: 4 }} onClick={() => handleFreightSettingClick(i)}>
            <Checkbox
              sx={{ mr: 1 }}
              checked={selectedFreightSettings.some(
                (sfs) => sfs.freightSettingId === fs.freightSettingId
              )}
            />
            <ListItemText primaryTypographyProps={{ fontSize: '12px' }} primary={fs.name} />
          </ListItemButton>
        ))}
      </List>
    </Stack>
  );
}
