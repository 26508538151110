import { useMutation } from '@apollo/client';
import { gql } from 'apollo-boost';
import { CreateProductUomPayload } from '../../../common/interfaces';

export function useCreateUom() {
  const [mutate, { error, loading }] = useMutation<
    { message: string },
    { productId: number; input: CreateProductUomPayload }
  >(gql`
    mutation ($productId: Int!, $input: CreateProductUomPayloadInput!) {
      createUom(productId: $productId, input: $input) {
        message
      }
    }
  `);

  const createUom = (productId: number, input: CreateProductUomPayload) =>
    mutate({ variables: { productId, input } });

  return { createUom, error, loading };
}
