import { EyeIcon, EyeSlashIcon } from '@/common/components';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  TextFieldProps,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { setPasswordValue } from './passwordValue';
import './style.css';

const CheckBox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  '& .MuiCheckbox-root': {},
}));

function StyledCheckBox(props: CheckboxProps) {
  const theme = useTheme();
  return <CheckBox sx={{ color: theme.palette.primary.main }} {...props} />;
}

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    background: theme.palette.background.default,
    color: 'black',
    borderStyle: 'none',
    borderRadius: 10,
    borderWidth: '2px',
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus fieldset': {
      background: 'white',
    },
    paddingTop: 2,
    paddingBottom: 2,
  },
  '& .MuiInputAdornment-root': {
    padding: theme.spacing(1),
  },
}));

export function PasswordField({ onPasswordValidation }) {
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [state, setState] = useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  };
  const [validate, setValidate] = useState({
    hasSpecialChar: false,
    hasUpperChar: false,
    hasNumber: false,
    has8CharsMin: false,
  });

  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);

  const strength = Object.values(validate).reduce(
    (a, item) => a + (item ? 1 : 0),
    0
  );
  const feedback = {
    1: 'Weak! Try again',
    2: 'Still weak! Try harder please',
    3: "You're almost there!",
    4: 'Strong, you are good to go!',
  }[strength];

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
    setPasswordValue(e.target.value);
  };

  const validatePassword = (password: string) => {
    if (password.match(/\d+/g)) {
      setValidate((o) => ({ ...o, hasNumber: true }));
      setIsChecked3(true);
    } else {
      setValidate((o) => ({ ...o, hasNumber: false }));
      setIsChecked3(false);
    }

    if (password.match(/[A-Z]+/g)) {
      setValidate((o) => ({ ...o, hasUpperChar: true }));
      setIsChecked2(true);
    } else {
      setValidate((o) => ({ ...o, hasUpperChar: false }));
      setIsChecked2(false);
    }

    if (password.match(/[!@#$%^&*)(+=._-]+/g)) {
      setValidate((o) => ({ ...o, hasSpecialChar: true }));
      setIsChecked4(true);
    } else {
      setValidate((o) => ({ ...o, hasSpecialChar: false }));
      setIsChecked4(false);
    }

    if (password.length > 7) {
      setValidate((o) => ({ ...o, has8CharsMin: true }));
      setIsChecked1(true);
    } else {
      setValidate((o) => ({ ...o, has8CharsMin: false }));
      setIsChecked1(false);
    }
    const isValid =
    isChecked1 && isChecked2 && isChecked3 && isChecked4;
    setIsPasswordValid(isValid);
    onPasswordValidation(isValid);
  };
  useEffect(() => {
    // Call the callback function in the parent whenever the validation state changes
    onPasswordValidation(isChecked1 && isChecked2 && isChecked3 && isChecked4);
  }, [isChecked1, isChecked2, isChecked3, isChecked4, onPasswordValidation]);

  return (
    <div>
      <StyledTextField
        required
        name="password"
        type={state.showPassword ? 'text' : 'password'}
        value={password}
        onChange={handleChangePassword}
        fullWidth
        placeholder="Input password"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                color="primary"
                name="showPassword"
                onClick={handleClickShowPassword}
              >
                {state.showPassword ? <EyeSlashIcon /> : <EyeIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <br />
      <progress
        className={`password strength-${strength}`}
        value={strength}
        max="4"
      />
      <br />
      <div className="feedback">
        Password strength:&nbsp;<strong>{feedback}</strong>
      </div>
      <FormGroup>
        <FormControlLabel
          control={<StyledCheckBox id="8CharsMin" checked={isChecked1} />}
          label="8 Characters Minimum"
        />
        <FormControlLabel
          control={<StyledCheckBox id="UpperChar" checked={isChecked2} />}
          label="Uppercase Letter"
        />
        <FormControlLabel
          control={<StyledCheckBox id="Number" checked={isChecked3} />}
          label="Number"
        />
        <FormControlLabel
          control={<StyledCheckBox id="SpecialChar" checked={isChecked4} />}
          label="Special Character"
        />
      </FormGroup>
    </div>
  );
}
