// import TransactionFinishedImage from '@/assets/images/TransactionFinished.png';
import { AppLayout } from '@/layouts/AppLayout';
import { TsuLoading } from '@/common/components';
import { OrderDetailDisplay } from '@/common/interfaces';
import PageNotFound from '@/common/screens/PageNotFound';
import { Box, Button, Card, CardContent, Container, Stack, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Order, useGetOrderDetailQuery, useUpdateOrderSavedMutation } from '../../../graphql';
import { ViewOrderDetailsDialog } from '../components/ViewOrderDetailsDialog';
import { getCartInformationBuffer } from '@/features/cart/components/CartInformationBuffer';
import LocalMallTwoToneIcon from '@mui/icons-material/LocalMallTwoTone';
import { useCustomerTheme } from '@/common/theme';
import { useSnackbar } from 'notistack';

function useRouteQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function makeOrderDetailDisplay(od?: Partial<Order>) {
  const os = od?.orderShippings?.slice().shift() ?? null;
  const orderReference = (od?.orderId ?? '') + '~' + (os?.orderShipId ?? '');

  return {
    orderNo: orderReference,
    shippingMethod: os?.deliveryMethod ?? '',
    orderedBy: os?.orderedByUser
      ? os.orderedByUser.firstName + ' ' + os?.orderedByUser.lastName
      : '',
    orderDate: os?.created ? format(parseISO(os.created), 'dd/MM/yyyy') : '',
    deliveryFullName: (os?.deliveryUserFirstName ?? '') + ' ' + (os?.deliveryUserLastName ?? ''),
    email: os?.deliveryEmail ?? '',
    mobile: os?.deliveryPhone ?? '',
    phone: os?.deliveryMobile ?? '',
    companyName: os?.deliveryCompanyName ?? '',
    grandTotal: os?.feeTotal ?? 0,
  } as OrderDetailDisplay & {
    grandTotal: number;
  };
}

export function TransactionFinished() {
  const [updateOrderSave, updateOrderSaveMut] = useUpdateOrderSavedMutation();
  const { enqueueSnackbar } = useSnackbar();
  const customerTheme = useCustomerTheme();
  const email = getCartInformationBuffer().Email;
  const query = useRouteQuery();
  const orderId = query.get('orderId');
  const orderIdNum : number = +(orderId ?? 0)

  const {
    data: orderDetailQuery,
    loading,
    error,
  } = useGetOrderDetailQuery({
    variables: { orderId: orderId ? parseInt(orderId) : 0 },
  });
  const orderDetail = orderDetailQuery?.orderDetail;
  const [openViewOrderDetailsDialog, setOpenViewOrderDetailsDialog] = useState(false);
  // TODO: fix this nested types issue
  // solution is to change the arguments here
  // to pass the specific nested properties that are needed in the function
  // @ts-ignore
  const orderDetailDisplay = makeOrderDetailDisplay(orderDetail);

  if (orderId === null || orderId === '') return <PageNotFound />;

  async function SaveOrderClickHandle() {
    if (orderIdNum == 0) return;
    // Call the hook when the button is clicked
      await updateOrderSave({
        variables: {
          input: {
            orderId:  orderIdNum,
            saved: true
          }
        }
      });
      enqueueSnackbar("Order has been saved for future repeat successfully", { variant: 'success' });
  }

  return (
    <AppLayout>
      <Box display="flex" justifyContent="center">
        <Container sx={{ flexGrow: 1 }} maxWidth="sm">
          <Card raised>
            <CardContent>
              <TsuLoading loading={loading} error={error}>
                <Stack alignItems="center" spacing={4}>
                  {/* <img src={TransactionFinishedImage} alt="Transaction Finished" height="200" /> */}
                  <LocalMallTwoToneIcon sx={{width: '100%', height: 200, color: customerTheme?.primary.main ?? 'text.primary'}}/>
                  <Typography variant="h2" fontWeight="bold">
                    Thank you for your transaction!
                  </Typography>
                  <Stack alignItems="center">
                    <Typography variant="h5">Order Number</Typography>
                    <Typography variant="h5" fontWeight="bold">
                      {orderDetailDisplay.orderNo}
                    </Typography>
                  </Stack>
                  <Stack alignItems="center">
                    <Typography variant="h5">Total Amount</Typography>
                    <Typography variant="h5" fontWeight="bold">
                      {orderDetailDisplay.grandTotal.toFixed(2)}
                      {/* {orderDetailDisplay.grandTotal.toFixed(2)} */}
                    </Typography>
                  </Stack>
                  <Stack alignItems="center">
                    <Typography variant="h5">
                      We've sent an email of the order receipt to
                    </Typography>
                    <Typography variant="h5" fontWeight="bold">
                      {email}
                    </Typography>
                  </Stack>
                  <Stack alignSelf="stretch" alignItems="stretch" spacing={2}>
                    <Button
                      variant="outlined"
                      sx={{ textTransform: 'none', py: 1.5, borderRadius: 2 }}
                      onClick={() => setOpenViewOrderDetailsDialog(true)}
                    >
                      <Typography variant="h6">View Order</Typography>
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ textTransform: 'none', py: 1.5, borderRadius: 2 }}
                      onClick={SaveOrderClickHandle}
                    >
                      <Typography variant="h6">Save Order for future repeat</Typography>
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ textTransform: 'none', py: 1.5, borderRadius: 2 }}
                      href="/"
                    >
                      <Typography variant="h6">Shop More</Typography>
                    </Button>
                  </Stack>
                </Stack>
              </TsuLoading>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <ViewOrderDetailsDialog
        open={openViewOrderDetailsDialog}
        orderDetailDisplay={orderDetailDisplay}
        onClose={() => setOpenViewOrderDetailsDialog(false)}
      />
    </AppLayout>
  );
}
