import Link from '@mui/material/Link';
import Typography, { TypographyProps } from '@mui/material/Typography';

export function LoginHeader(props: TypographyProps) {
  return (
    <Typography
      align="center"
      variant="caption"
      padding={3}
      pt={1}
      {...props}
      sx={{ color: '#222F3E' }}
    >
      Interested in WaiveNet?
      <Link
        href="#"
        underline="none"
        color="primary"
        paddingLeft="4px"
        fontWeight="bold"
        sx={{ color: '#e2066f'}}
      >
        Book a Demo
      </Link>
    </Typography>
  );
}
