import { ProductType } from '@/graphql';

interface Props {
  priceType: string;
  prices: ProductType['prices'];
  quantity?: number;
}

export function ProductPrice(props: Props) {
  const { priceType, prices, quantity } = props;

  // If product has no prices, just exit with $0.00
  if (prices.length <= 0) return <span>$0.00</span>;

  // Helper function for logic below to exit with first valid price
  const firstPrice = () => <span>${prices.slice().shift()!.price.toFixed(2)}</span>;

  if (priceType === 'NORMAL') return firstPrice();

  if (!quantity) return firstPrice();

  if (priceType === 'PRICEBREAKS_TEXTBOX') {
    const priceBreak = prices.find((p) => p.quantity >= quantity);
    if (!priceBreak) return firstPrice();
    return <span>${priceBreak.price.toFixed(2)}</span>;
  }
  if (priceType === 'PRICEBREAKS_DROPDOWN') {
    const priceBreak = prices.find((p) => p.quantity >= quantity);
    if (!priceBreak) return firstPrice();
    return <span>${priceBreak.price.toFixed(2)}</span>;
  }

  // Last resort - display $0.00
  return <span>$0.00</span>;
}
