// import ForgotPasswordLogo from '@/assets/images/ForgotPasswordLogo.png';
import { Copyright, LoginHeader } from '@/common/components';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'; 
import { useCustomerTheme } from '@/common/theme';

export default function CheckYourEmail() {
  const customerTheme = useCustomerTheme();
  const theme = useTheme();
  return (
    <Container maxWidth={false} sx={{ height: `calc(100vh)` }}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
        sx={{ height: '100%' }}
      >
        <LoginHeader />
        <Card elevation={20} sx={{ padding: theme.spacing(3), minWidth: '300px' }}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={{ height: '100%' }}
          >
            <Box m={10} maxWidth="60%" margin="10px">
              {/* <img src={ForgotPasswordLogo} alt="Logo" width="100%" height="100%" /> */}
              <LockOpenTwoToneIcon sx ={{width: '100%', height: '100%', color: customerTheme?.primary.main ?? 'text.primary'}}/>
            </Box>
            <CardHeader
              sx={{ marginTop: theme.spacing(1) }}
              title="Password Reset Email Sent !"
              titleTypographyProps={{ variant: 'h2', align: 'left' }}
            />
            <CardContent sx={{ paddingTop: theme.spacing(0) }}>
              <Box textAlign="left" component="form" autoComplete="off">
                <FormGroup>
                  <Typography variant="caption" fontWeight="normal" mb={1}>
                    Check your email for the password reset link, you can close this tab now !
                  </Typography>
                </FormGroup>
              </Box>
            </CardContent>
          </Stack>
        </Card>
        <Copyright />
      </Stack>
    </Container>
  );
}
