/*
  This component renders a small form containing two radio buttons
  which allow you to select either of the following Product Statuses:
    1. "At Client" - this means the product is in CLIENT's LOCATION and needs to be COLLECTED by us.
    2. "In Warehouse" - this means the product is in OUR WAREHOUSE and needs to be DELIVERED to client's location.
*/
import {
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ProductStatusEnum } from '../../../common/enums/productStatusEnum';

interface Props {
  onOptionChange?: (value: ProductStatusEnum) => void;
}

export function ArchiveOrderOptions(props: Props) {
  const [archiveOption, setArchiveOption] = useState(ProductStatusEnum.AtClient);

  useEffect(() => {
    console.log('[ArchiveOrderOptions] archiveOption changed:', archiveOption);
    props?.onOptionChange?.(archiveOption);
  }, [archiveOption]);

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Archive Order
        </Typography>
        <RadioGroup
          value={archiveOption}
          onChange={(e) => setArchiveOption(parseInt(e.currentTarget.value))}
        >
          <FormControlLabel
            value={ProductStatusEnum.AtClient}
            control={<Radio />}
            label="Book collection of box(es)"
          />
          <FormControlLabel
            value={ProductStatusEnum.InWarehouse}
            control={<Radio />}
            label="Have box(es) delivered"
          />
        </RadioGroup>
      </CardContent>
    </Card>
  );
}
