import productPlaceholderImage from '@/assets/images/ProductPlaceholder.jpg';
import { useImageFile } from '@/common/hooks/use-image-file';
import { Button, useTheme } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  logoSrc?: string | File;
  width?: number;
  height?: number;
  id?: string;
  alt?: string;
  onChange?: (v: File) => void;
  noBorder?: boolean;
}

export function LogoField(props: Props) {
  const theme = useTheme();
  const id = props.id ?? 'logoUpload';
  const { imageDataUrl } = useImageFile({ file: props.logoSrc });

  return (
    <Box border={props.noBorder ? undefined : 1} borderRadius={4}>
      <Button variant="text" sx={{ px: theme.spacing(4) }}>
        <label htmlFor={id}>
          <img
            src={Boolean(imageDataUrl) ? imageDataUrl : productPlaceholderImage}
            alt={props.alt ?? 'Customer Theme Logo'}
            style={{ width: props.width ?? 200, height: props.height ?? 200, objectFit: Boolean(imageDataUrl) ? 'contain' : 'cover' }}
          />
        </label>
        <input
          name={id}
          id={id}
          hidden
          accept="image/*"
          type="file"
          onChange={(e) => e.target.files && props.onChange?.(e.target.files[0])}
        />
      </Button>
    </Box>
  );
}
