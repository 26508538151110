import { TsuCarousel } from '@/common/components';
import { useCustomerTheme } from '@/common/theme';
import { SERVER_STATIC_FILES_URL } from '@/globals';
import { AppLayout } from '@/layouts/AppLayout';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  Alert,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomerFeatureEnum } from '../../auth/auth.constants';
import { FeatureGuard } from '../../auth/components/FeatureGuard';
import OrdersForApprovalTable from '../get-started/orders-for-approval-table/OrdersForApprovalTable';
import SavedOrdersTable from '../get-started/saved-orders-table/SavedOrdersTable';
import OrdersInProgressTable from '../get-started/orders-in-progress-table/OrdersInProgressTable';
import TopOrderedProductsTable from '../get-started/TopOrderedProductsTable';
import { useGetCurrentCustomerQuery } from '@/graphql';

function Dashboard() {
  const theme = useTheme();
  const customerTheme = useCustomerTheme();
  const navigate = useNavigate();
  const {
    data,
    loading: isCustomerLoading,
    refetch,
  } = useGetCurrentCustomerQuery();
  const [snackbar, setSnackbar] = useState({
    open: false,
    text: '',
  });
  const [custId, setCustId] = useState(data?.currentCustomer?.customerId);
  useEffect(() => {
    if (data?.currentCustomer?.customerId != null && custId !== data.currentCustomer.customerId) {
      refetch();
      setCustId(data.currentCustomer.customerId);
    }
  }, [data]);

  const getStartedItems = useMemo(() => {
    const iconStyle = { color: theme.palette.primary.main };
    var items = [
      {
        text: 'User Guide',
        icon: <StarBorderIcon fontSize="large" sx={iconStyle} />,
        to: `https://www.waivenet.com.au/CustomerContent/${custId}/UploadedImages/WelcomeBlock/WaiveNet_User_Guide.pdf`,
      },
      // {
      //   text: 'How It Works',
      //   icon: <StarBorderIcon fontSize="large" sx={iconStyle} />,
      //   to: "https://www.waivenet.com.au/CustomerContent/545/UploadedImages/WelcomeBlock2/Nutrition_APAC_WaiveNet_User_Guide.pdf",
      // },
      {
        text: 'FAQs',
        icon: <StarBorderIcon fontSize="large" sx={iconStyle} />,
        to: '/faqs',
      },
      // {
      //   text: 'Request Info',
      //   icon: <StarBorderIcon fontSize="large" sx={iconStyle} />,
      //   to: "https://www.waivenet.com.au/CustomerContent/502/UploadedImages/WelcomeBlock2/lx_man_level2_rep_02.pdf",
      // }
    ];

    // Adding Form and Size document for EuropCar
    if (custId == 426) {
      items.push(
        {
          text: 'Return Form',
          icon: <StarBorderIcon fontSize="large" sx={iconStyle} />,
          to: 'https://waivenet.com.au/CustomerContent/426/UploadedImages/WelcomeBlock/europcar-uniform-returns.pdf'
        },
        {
          text: 'Size Chart',
          icon: <StarBorderIcon fontSize="large" sx={iconStyle} />,
          to: 'https://waivenet.com.au/CustomerContent/426/UploadedImages/WelcomeBlock/europcar-sizing-info.pdf'
        }
      );
      items = items.slice(1);
    }

    return items;
  }, [theme, custId]);

  const handleGetStartedItemClick = (to: string | null) => {
    if (to === null) setSnackbar({ text: 'This feature is not yet implemented', open: true });
    else window.open(to);
  };

  return (
    <AppLayout title="Dashboard" noContentSpacing>
      <Stack spacing={3} alignItems="center">
        <FeatureGuard
          feature={CustomerFeatureEnum.DashboardBannerImages}
          otherwise={<Box height={5} />}
        >
          <TsuCarousel
            images={(customerTheme?.banners ?? []).map((b) => ({ fileName: SERVER_STATIC_FILES_URL + '/' + b.fileName, href: b.href }))}
          />
        </FeatureGuard>

        {/* <script
          src="https://secure.ewaypayments.com/scripts/eCrypt.min.js"
          className="eway-paynow-button"
          data-publicapikey="epk-DAC06CB1-8A86-4EFB-B35C-0BCBEDA7FDB8"
          data-amount="1000"
          data-currency="AUD"
        ></script> */}
        <br/>
        <FeatureGuard feature={CustomerFeatureEnum.DashboardGetStarted}>
          <Typography variant="h2" fontWeight="bold">
            Get Started
          </Typography>
          <Container maxWidth="lg">
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              {getStartedItems.map((item, i) => (
                <Card sx={{ m: 2, width: 250 }} elevation={2} key={i}>
                  <CardActionArea onClick={() => handleGetStartedItemClick(item.to)}>
                    <CardContent>
                      <Stack spacing={2} alignItems="center">
                        {item.icon}
                        <Typography variant="h6" fontWeight="bold">
                          {item.text}
                        </Typography>
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Box>
          </Container>
        </FeatureGuard>
        <Container>
          <Stack spacing={10} sx={{ width: '100%' }}>
            <FeatureGuard feature={CustomerFeatureEnum.DashboardOrdersForApproval}>
              <OrdersForApprovalTable />
            </FeatureGuard>
            <FeatureGuard feature={CustomerFeatureEnum.DashboardSavedOrders}>
              <SavedOrdersTable />
            </FeatureGuard>
            <FeatureGuard feature={CustomerFeatureEnum.DashboardOrdersInProgress}>
              <OrdersInProgressTable />
            </FeatureGuard>
            <FeatureGuard feature={CustomerFeatureEnum.DashboardTopOrderedProducts}>
              <TopOrderedProductsTable />
            </FeatureGuard>
          </Stack>
        </Container>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        autoHideDuration={2000}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity="warning"
          variant="outlined"
          sx={{
            width: '100%',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography>{snackbar.text}</Typography>
        </Alert>
      </Snackbar>
    </AppLayout>
  );
}

export default Dashboard;
