import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { TsuLoading } from '@/common/components';
import { useMediaTab } from './MediaTab.hook';

export interface MediaTabProps {
  productId: number | null;
}

export function MediaTab(props: MediaTabProps) {
  const mediaTab = useMediaTab(props);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles.pop();
      // Inject preview property to allow displaying & cleaning up object URL
      // Source: https://react-dropzone.js.org/#section-previews
      if (file)
        mediaTab.setImageFile({ ...file, preview: URL.createObjectURL(file) });
    },
    [mediaTab]
  );
  const drop = useDropzone({ onDrop });

  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader title="Media" />
        <CardContent>
          {mediaTab.imageFile && (
            <img
              src={mediaTab.imageFile.preview}
              alt="Product preview"
              style={{ width: '250px', height: 'auto' }}
            />
          )}
          <Box
            {...drop.getRootProps()}
            sx={{
              bgcolor: grey[400],
              border: drop.isDragActive ? 'dashed 5px black' : undefined,
            }}
            p={4}
          >
            <input {...drop.getInputProps()} />
            {drop.isDragActive ? (
              <Typography>Drop the files here ...</Typography>
            ) : (
              <Typography>
                Drag 'n' drop some files here, or click to select files
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
      <TsuLoading loading={mediaTab.isSaving}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          sx={{ width: '100%' }}
        >
          <Button variant="contained" onClick={mediaTab.save}>
            Save
          </Button>
        </Stack>
      </TsuLoading>
    </Stack>
  );
}
