import { useGetCustomerBusinessLevelsQuery } from '@/graphql';
import { useMemo, useState } from 'react';


export function useBusinessLevelsApi() {
  const [opts, setOpts] = useState({ page: 0, itemsPerPage: 20, searchValue: '' });
  const headers = useMemo(
    () => [
      { text: 'Business Level Name', value: 'name' },
      { text: 'Is Active', value: 'active' },
      { text: 'BL ID', value: 'businessLevelId' },
      { text: '', value: 'edit' },
    ],
    []
  );
  const { loading, error, data, refetch } = useGetCustomerBusinessLevelsQuery();
  return {
    loading,
    error,
    data,
    refetch,
    headers,
    opts,
    setPage: (page: number) => setOpts({ ...opts, page }),
    setItemsPerPage: (ipp: number) => setOpts({ ...opts, itemsPerPage: ipp }),
    setSearchValue: (v: string) => setOpts({ ...opts, searchValue: v }),
  };
}
