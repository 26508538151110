import { FeatureRoute } from '@/common/interfaces/feature-route';
import { ProductManagementPunchout } from './screens/ProductManagementPunchout';

export const prodManagementPunchoutRoutes: FeatureRoute[] = [
  {
    path: '/productmanagementpunchout',
    component: ProductManagementPunchout,
    options: {
      useCustomerTheme: true,
    },
  },
];
