import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';

interface Props {
  tip: string;
}

export function InfoTooltip({ tip }: Props) {
  return (
    <Tooltip
      title={
        <div
          style={{
            whiteSpace: 'pre-wrap',
            fontSize: '1.5em',
            lineHeight: '1.75em',
          }}
        >
          {tip}
        </div>
      }
    >
      <InfoIcon />
    </Tooltip>
  );
}
