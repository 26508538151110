import { useQuery } from '@apollo/client';
import { gql } from 'apollo-boost';
import { GetUomDescriptorsRes } from '../../../common/interfaces';

export function useGetUomDescriptors() {
  const { data, error, loading } = useQuery<GetUomDescriptorsRes>(gql`
    query {
      uomDescriptors {
        descriptorId
        name
      }
    }
  `);

  return { uomDescriptors: data?.uomDescriptors ?? [], error, loading };
}
