import { TsuLoading } from '@/common/components';
import { CONTACT_US_EMAIL } from '@/globals';
import { useFaqsQuery } from '@/graphql';
import { AppLayout } from '@/layouts/AppLayout';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import FaqCard from '../components/FaqCard';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import { useCustomerTheme } from '@/common/theme';

export default function FAQ() {
  const { data, loading, error } = useFaqsQuery();
  const faqs = data?.faqs ?? [];
  const customerTheme = useCustomerTheme();

  const handleContactUs = () => (window.location.href = `mailto:${CONTACT_US_EMAIL}`);

  return (
    <AppLayout title="FAQs">
      <Container sx={{ flexGrow: 1, my: 4, alignSelf: 'center' }} maxWidth="sm">
        <Stack alignItems="stretch" spacing={2}>
          {/* <img
            src={FAQImage}
            alt="FAQ"
            style={{ width: '100%', height: '200px', objectFit: 'contain' }}
          /> */}
          <ContactSupportTwoToneIcon sx={{width: '100%', height: '200px', color: customerTheme?.primary.main ?? 'text.primary'}}/>
          <Typography variant="h3" fontWeight="bold" sx={{ alignSelf: 'center' }}>
            How can we help you?
          </Typography>
          <TsuLoading loading={loading} error={error}>
            <Stack spacing={2} sx={{ mb: 4 }}>
              {faqs.map((faq, i) => (
                <FaqCard key={i} question={faq.fQuestion} answer={faq.fAnswer} />
              ))}
            </Stack>
          </TsuLoading>
          <Container
            maxWidth="xs"
            sx={{
              alignSelf: 'center',
              '&.MuiContainer-root': {
                my: 8,
              },
            }}
          >
            <Stack spacing={1} alignItems="center">
              <Typography variant="h5">Still have a question?</Typography>
              <Box height={16} />
              <Button
                variant="contained"
                onClick={handleContactUs}
                sx={{ alignSelf: 'stretch', py: 1.5 }}
              >
                Contact Us
              </Button>
            </Stack>
          </Container>
        </Stack>
      </Container>
    </AppLayout>
  );
}
