import VACCForgotPassword from '@/features/customization/VACC/VACCForgotPassword';
import NormalForgotPassword from './NormalForgotPassword';


export default function ForgotPassword() {
  // Check if the URL contains "ourautoistore"
  if (window.location.hostname.includes('ourautoistore')) {
    return <VACCForgotPassword/>;
  }
  // Render the default SignIn component
  return (
    <NormalForgotPassword/>
  );
}