import { ApolloError } from '@apollo/client';
import {
  Alert,
  Box,
  CircularProgress,
  CircularProgressProps,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

interface Props {
  title?: string;
  loading?: boolean;
  error?: ApolloError;
  children?: React.ReactNode;
  loader?: React.ReactNode;
  justifyContent?: string;
  // This is really only meant for the "Loading theme" scenario,
  // because we need our container to fill the entire container (Box),
  // then position the loading indicator at the center of it.
  fullSize?: boolean;
  sx?: SxProps<Theme>;
  defaultLoaderProps?: CircularProgressProps;
}

export function TsuLoading(props: Props) {
  const { title, loading, error, children, loader, justifyContent, fullSize } = props;
  const loaderComponent = loader || <CircularProgress {...props.defaultLoaderProps} />;
  const hasGraphQLErrors = error && error.graphQLErrors.length;

  return (
    <>
      <Box
        hidden={!loading}
        display={loading || loading ? 'flex' : 'none'}
        justifyContent={justifyContent || 'center'}
        alignItems="center"
        sx={{
          width: fullSize ? '100%' : undefined,
          height: fullSize ? '100%' : undefined,
          ...props.sx,
        }}
      >
        {loaderComponent}
        <Box mx={1} />
        {title && <Typography>{title}</Typography>}
      </Box>

      <Box hidden={!error}>
        {hasGraphQLErrors ? (
          error.graphQLErrors.map((e, i) => (
            <Alert key={i} severity="error" sx={{ alignItems: 'center' }}>
              {e.message}
            </Alert>
          ))
        ) : (
          <Alert severity="error" sx={{ alignItems: 'center' }}>
            {error?.message}
          </Alert>
        )}
      </Box>

      {!loading && children}
    </>
  );
}
