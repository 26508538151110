import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { ITsuTableItem } from '@/common/components/tsu-table/interfaces';
import { useCallback } from 'react';
import { IBusinessLevel } from '../interfaces/business-level.interface';

type IBL = IBusinessLevel;
interface Args {
  onEdit?: (os: IBL) => void;
}

function useBusinessLevelsRender({
  onEdit,
}: Args) {
  const customItemRender = useCallback(
    (_i: ITsuTableItem, value: string) => {
      const i = _i as IBL;
      switch (value) {
        case 'edit':
          return (
            <Button
              onClick={() => onEdit?.(i)} 
              variant="contained" 
              color='success'
            >
              <EditIcon sx={{ color: '#E35E5D', mr: 1 }} />
              Edit
            </Button>
          );
        case 'active':
          return i[value] ? <span>True</span> : <span>False</span>;
        default:
          return i[value as keyof IBL];
      }
    },
    [onEdit]
  );

  return { customItemRender };
}

export default useBusinessLevelsRender;
