import { Button } from '@mui/base/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Box, Stack, styled, Typography } from '@mui/material';
import usePagination from '@mui/material/usePagination';

interface Props {
  page?: number;
  count: number;
  onChange?: (p: number) => void;
}

const PageButton = styled(Button)({
  backgroundColor: 'white',
  border: 'none',
  cursor: 'pointer',
  transition: 'background-color 0.25s',
  minWidth: 32,
  '&:hover': {
    backgroundColor: '#F1F2F6',
  },
});

export function Pagination({ page, count, onChange }: Props) {
  // Source: https://mui.com/components/pagination/#usepagination
  const { items } = usePagination({
    count,
    showFirstButton: true,
    showLastButton: true,
    onChange: (_, p) => {onChange?.(p - 1);},
    page: page !== undefined ? page + 1 : 1,
  });

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {items.map(({ page, type, selected, ...item }, index) => {
        // page in this context is NOT zero-based (so we must adjust accordingly)
        let children: React.ReactNode | null = null;

        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          children = '…';
        } else if (type === 'page') {
          children = (
            <PageButton>
              <Typography fontWeight={selected ? 'bold' : undefined} {...item}>
                {page}
              </Typography>
            </PageButton>
          );
        } else {
          let icon: JSX.Element;
          switch (type) {
            case 'next':
              icon = <KeyboardArrowRightIcon />;
              break;
            case 'previous':
              icon = <KeyboardArrowLeftIcon />;
              break;
            case 'first':
              icon = <KeyboardDoubleArrowLeftIcon />;
              break;
            case 'last':
              icon = <KeyboardDoubleArrowRightIcon />;
              break;
          }
          children = <PageButton {...item}>{icon}</PageButton>;
        }

        return <Box key={index}>{children}</Box>;
      })}
    </Stack>
  );
}
