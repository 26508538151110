import { Button, SxProps, useTheme } from '@mui/material';

interface TsuButtonProps {
  children: React.ReactNode;
  outlined?: boolean;
  text?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  bold?: boolean;
  dark?: boolean;
  white?: boolean;
  rounded?: boolean;
  secondary?: boolean;
  flat?: boolean;
  disabled?: boolean;
  // Allow passing other props like MUI props (Source: https://stackoverflow.com/a/58201122)
  [x: string]: any;
}

type MuiButtonVariant = 'text' | 'contained' | 'outlined';

export function TsuButton({
  children,
  outlined,
  text,
  onClick,
  bold,
  white,
  dark,
  rounded,
  secondary,
  flat,
  disabled,
  ...props
}: TsuButtonProps) {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const secondaryColor = '#E2E2E2';
  const darkColor = 'white';
  // Default button uses the "contained" variant
  let variant: MuiButtonVariant = 'contained';
  if (outlined) variant = 'outlined';
  if (text) variant = 'text';

  let buttonStyles: SxProps = {
    textTransform: 'none',
    alignSelf: 'center',
    py: 1,
    borderRadius: rounded ? 16 : 3,
    '& .MuiSvgIcon-root': {
      color: outlined || (text && !white) ? primaryColor : darkColor,
    },
    '& .MuiTypography-root': {
      fontWeight: bold ? 'bold' : 'normal',
    },
  };

  if (white) {
    buttonStyles = { ...buttonStyles, color: darkColor };
  } else if (outlined || text) {
    buttonStyles = { ...buttonStyles, color: primaryColor };
  } else if (dark) {
    buttonStyles = { ...buttonStyles, color: '#3A5069' };
  }
  
  if (secondary) {
    buttonStyles = { ...buttonStyles, backgroundColor: secondaryColor };
  }

  if (flat) {
    buttonStyles = { ...buttonStyles, boxShadow: 'none' };
  }

  const { sx, ...otherProps } = props;
  if (Object.prototype.hasOwnProperty.call(props, 'sx')) {
    buttonStyles = { ...buttonStyles, ...sx };
  }

  return (
    <Button
      variant={variant}
      sx={buttonStyles}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </Button>
  );
}
