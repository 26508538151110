/*
This component provides an abstraction to the following functionality:
1. dialog state
2. handling loading & error states
3. handling close & submit events

And hides away redundant MUI component & style code
*/

import { TsuLoading } from '@/common/components';
import { ApolloError } from '@apollo/client';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Breakpoint,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React, { createContext, useContext } from 'react';

// ==========================================================
// Main Dialog component
// ==========================================================

type TsuDialogProps = {
  open: boolean;
  title?: string;
  submitText?: string;
  maxWidth?: false | Breakpoint;
  loading?: boolean;
  error?: ApolloError;
  onClose?: () => void;
  onSubmit?: () => void;
  children: React.ReactNode;
  hasCloseButton?: boolean;
  noFooter?: boolean;
  footerActions?: React.ReactNode;
} & DialogProps;

interface TsuDialogContext {
  submitText?: string;
  loading?: boolean;
  error?: ApolloError;
  onClose?: () => void;
  onSubmit?: () => void;
  hasCloseButton?: boolean;
  noFooter?: boolean;
  footerActions?: React.ReactNode;
}

const DialogContext = createContext<TsuDialogContext>({
  submitText: 'Submit',
  loading: false,
  error: undefined,
  onClose: undefined,
  onSubmit: undefined,
  hasCloseButton: undefined,
  footerActions: undefined,
});

export function TsuDialog(props: TsuDialogProps) {
  const {
    submitText,
    loading,
    error,
    onClose,
    onSubmit,
    hasCloseButton,
    noFooter,
    footerActions,
    ...dialogProps
  } = props;

  return (
    <Dialog
      {...dialogProps}
      open={props.open}
      onClose={onClose}
      maxWidth={
        dialogProps.maxWidth !== undefined ? dialogProps.maxWidth : 'lg'
      }
    >
      <DialogContext.Provider
        value={{
          submitText,
          loading,
          error,
          onClose,
          onSubmit,
          hasCloseButton,
          noFooter,
          footerActions,
        }}
      >
        <DialogTitle sx={{ py: 3 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" fontWeight="bolder">
              {props.title}
            </Typography>
            <Button variant="text" onClick={onClose} sx={{ px: 2 }}>
              <CancelOutlinedIcon sx={{ mr: 1 }} />
              <Typography>Close</Typography>
            </Button>
          </Stack>
        </DialogTitle>
        {props.children}
      </DialogContext.Provider>
    </Dialog>
  );
}

// ==========================================================
// Dialog content
// ==========================================================

interface TsuDialogContentProps {
  children: React.ReactNode;
}

export function TsuDialogContent({ children }: TsuDialogContentProps) {
  const context = useContext(DialogContext);
  return (
    <DialogContent>
      <Stack
        flex={1}
        spacing={2}
        maxWidth={{ xs: 'none' }}
        alignItems="stretch"
      >
        {children}
        {!context.noFooter && <TsuDialogFooter />}
      </Stack>
    </DialogContent>
  );
}

// ==========================================================
// Dialog footer / actions
// ==========================================================

export function TsuDialogFooter() {
  const context = useContext(DialogContext);
  return (
    <TsuLoading loading={context.loading} error={context.error}>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        {context.footerActions}
        {context.hasCloseButton && (
          <Button variant="outlined" onClick={context.onClose}>
            Close
          </Button>
        )}
        {/* <Button variant="contained" onClick={context.onSubmit}>
          {context.submitText}
        </Button> */}
      </Stack>
    </TsuLoading>
  );
}
