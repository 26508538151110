import { TsuField, TsuLoading } from '@/common/components';
import { AppLayout } from '@/layouts/AppLayout';
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useGetCurrentUserQuery, useUpdateUserDetailsMutation } from '../../../graphql';
import { useNavigate } from 'react-router-dom';
import { useSendResetPasswordEmailMutation } from '@/graphql';

function MyAccount() {
  const navigate = useNavigate();
  const {
    data: currentUserQuery,
    loading: isLoadingCurrentUser,
    error: getCurrentUserError,
  } = useGetCurrentUserQuery();
  const [sendEmail] = useSendResetPasswordEmailMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [updateUserDetails, { loading: isUpdatingUser, error: updateUserError }] =
    useUpdateUserDetailsMutation();

  const u = currentUserQuery?.currentUser;
  const userDetails = {
    firstName: u?.firstName ?? '',
    lastName: u?.lastName ?? '',
    email: u?.email ?? '',
    mobile: u?.mobile ?? '',
  };
  const [accountDetails, setAccountDetails] = useState(() => ({
    ...userDetails,
  }));

  // Wait for user's User Details to load, then update the form fields
  useEffect(() => {
    if (currentUserQuery && currentUserQuery.currentUser) {
      const u = currentUserQuery.currentUser;
      setAccountDetails({
        firstName: u.firstName,
        lastName: u.lastName,
        email: u.email ?? '',
        mobile: u.mobile ?? '',
      });
    }
  }, [currentUserQuery]);

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>, propertyName: string) => {
    setAccountDetails({ ...accountDetails, [propertyName]: event.target.value });
  };
  const email = currentUserQuery?.currentUser?.email ?? "";
  const handleSavePassword = async (event: any) => {
    await sendEmail({
      variables: {
        email,
      },
    });
    navigate('/checkYourEmail');
  };

  const handleSaveClick = async () => {
    const res = await updateUserDetails({
      variables: {
        contactNumber: accountDetails.mobile || '',
        email: accountDetails.email,
        firstName: accountDetails.firstName,
        lastName: accountDetails.lastName,
      },
    });
    if (Boolean(res.data?.updateDetails?.success)) {
      enqueueSnackbar('User Details updated', { variant: 'success' });
    }
  };

  return (
    <AppLayout title="My Account">
      <Stack alignItems="start" sx={{ p: 4 }}>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <Stack alignItems="stretch" spacing={2}>
              <Typography variant="h4" fontWeight="bold">
                My Account
              </Typography>
              <Card raised>
                <CardContent>
                  <TsuLoading
                    loading={isLoadingCurrentUser || isUpdatingUser}
                    error={getCurrentUserError || updateUserError}
                  >
                    <Stack spacing={2} alignItems="stretch" sx={{ width: '100%' }}>
                      <Typography variant="h5" fontWeight="bold">
                        Personal Details
                      </Typography>
                      <TsuField
                        label="First Name"
                        value={accountDetails.firstName}
                        onChange={(v) => handleFieldChange(v, 'firstName')}
                      />
                      <TsuField
                        label="Last Name"
                        value={accountDetails.lastName}
                        onChange={(v) => handleFieldChange(v, 'lastName')}
                      />
                      <TsuField
                        label="Email Address"
                        value={accountDetails.email}
                        onChange={(v) => handleFieldChange(v, 'email')}
                      />
                      <TsuField
                        label="Mobile Number"
                        value={accountDetails.mobile ?? ''}
                        onChange={(v) => handleFieldChange(v, 'mobile')}
                      />
                      <Typography variant="h5" fontWeight="bold">
                        Security
                      </Typography>
                      <Typography variant="body1">Update your password.</Typography>
                      <Button variant="outlined" sx={{ alignSelf: 'start' }} onClick ={handleSavePassword}>
                        Change Password
                      </Button>
                      <Stack sx={{ width: '100%' }} direction="row" justifyContent="end">
                        <Button variant="contained" onClick={handleSaveClick}>
                          Save
                        </Button>
                      </Stack>
                    </Stack>
                  </TsuLoading>
                </CardContent>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </AppLayout>
  );
}

export default MyAccount;
