import { useGetCurrentUserQuery, useSavedAddressQuery } from '@/graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Grid, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useCheckoutStep, useCheckoutStore } from '../checkout-store';
import { deliveryDetailsSchema } from '../constants/checkout-validation';
import { AddressForm } from '../forms/AddressForm';
import { ContactInfoForm } from '../forms/ContactInfoForm';
import { DeliveryDetailsForm } from '../interfaces';
import { addressToFormValues, getInitialAddress, getInitialContactInfo } from '../utils';
import { useStepCardContext } from './StepCard';
import { setCartEmailBuffer } from '@/features/cart/components/CartInformationBuffer';
import { useState } from 'react';

export function DeliveryDetails() {
  // These values are provided by our parent Checkout Step Card (React Context).
  const { stepIndex, submitTitle, nextStep } = useStepCardContext();
  // These values are provided by the Checkout Store (Zustand).
  const { currentStep } = useCheckoutStep();
  // Setter function for saving Delivery Details in our Checkout Store (Zustand).
  const submitDeliveryDetails = useCheckoutStore((s) => s.submitDeliveryDetails);
  // Define our form state using react-hook-form & yup.
  const methods = useForm<DeliveryDetailsForm>({
    defaultValues: {
      ...getInitialContactInfo(),
      ...getInitialAddress(),
    },
    resolver: yupResolver(deliveryDetailsSchema),
  });
  const [loading, setLoading] = useState(true); // Add loading state
  useGetCurrentUserQuery({
    fetchPolicy: 'no-cache',
    onCompleted(query) {
      let data = query.currentUser;
      if (!data) return;
      methods.reset({
        ...methods.getValues(),
        fullName: (data.firstName ?? '') + (data.lastName ? ' ' + data.lastName : ''),
        email: data.email ?? '',
        mobileNo: data.mobile ?? '',
        phoneNo: data.phone ?? '',
        companyName: data.customer?.name ?? '',
      });
      setLoading(false);
    },
  });
  useSavedAddressQuery({
    fetchPolicy: 'no-cache',
    onCompleted(query) {
      const data = query.savedAddress?.deliveryAddress;
      if (!data) return;
      methods.reset({ ...methods.getValues(), ...addressToFormValues(data) });
    },
  });
  // Derived state
  const isReadOnly = currentStep != stepIndex;

  const onSubmit = methods.handleSubmit((data) => {
    // console.log('Delivery details:', data);
    setCartEmailBuffer(data.email);
    submitDeliveryDetails(data);
    nextStep();
  });

  if(loading) {
    return(
      <div>Loading...</div> // Render loading state
    );
  }
  else {
    return (
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Stack spacing={2}>
            <Box />
  
            <ContactInfoForm readOnly={isReadOnly} />
  
            <Divider
              sx={{
                '&.MuiDivider-root': { mt: 3 },
                display: !isReadOnly ? 'block' : 'none',
              }}
            />
            <AddressForm title="Delivery Address" readOnly={isReadOnly} />
  
            <Box hidden={isReadOnly} mt={2}>
              <Grid container>
                <Grid xs={6} item>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ alignSelf: 'start', width: '100%' }}
                  >
                    {submitTitle}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </form>
      </FormProvider>
    );
  }
}
