import { ItemsPerPageDropdown, Pagination } from '@/common/components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Stack,
  Table,
  TextField,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { TsuLoading } from '../TsuLoading';
import { useTableCellWidth } from './hooks';
import { ITsuTableHeader, ITsuTableItem, Props } from './interfaces';
import { StyledTable } from './styled';

export function TsuTable(props: Props) {
  const {
    headers,
    items,
    totalCount,
    colMinWidth,
    error,
    // Pagination options
    page,
    itemsPerPage,
    // Override column render
    customItemRender,
    // Events
    onPageChange,
    onItemsPerPageChange,
    // Header special actions
    hideHeaders,
    freezeHeaders,
    groupByHeaders,
    // Flags
    loading,
    stretchColumns,
  } = props;
  const [jtp, setJtp] = useState(page + 1);
  const { tdWidth, tbodyRef } = useTableCellWidth(items);
  // Compute styles for Table based on Table Cell Width & optional parameters (e.g. stretchColumns)
  const Table = StyledTable(colMinWidth, stretchColumns);
  const isUsingGroupBy = groupByHeaders && groupByHeaders.length > 0;

  // Exclude columns from being displayed
  // based on hideHeaders (Show / Hide columns feature)
  const filteredHeaders = headers.filter(
    (h) =>
      !hideHeaders ||
      (hideHeaders && hideHeaders.every((h2) => h.value !== h2.value))
  );

  const sticky = (h: ITsuTableHeader) => () =>
    computeStickyStyle(h, tdWidth, headers, freezeHeaders);

  const handleJumpToPage = (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.key === 'Enter' && onPageChange?.(jtp - 1);

  return (
    <Box>
      <Paper elevation={0} sx={{ overflowX: 'auto', width: '100%', mb: 3 }}>
        <Table>
          <thead>
            <tr>
              {filteredHeaders.map((h, i) => (
                <th key={i} style={sticky(h)()}>
                  {h.text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody ref={tbodyRef}>
            {!Boolean(loading) && !isUsingGroupBy && (
              <NormalTableRows
                items={items}
                headers={filteredHeaders}
                customItemRender={customItemRender}
                stickyStyle={(h) => sticky(h)()}
              />
            )}
            {!Boolean(loading) && isUsingGroupBy && (
              <GroupedTableRows
                items={items}
                headers={filteredHeaders}
                groupByHeaders={groupByHeaders}
                customItemRender={customItemRender}
                stickyStyle={(h) => sticky(h)()}
              />
            )}
          </tbody>
        </Table>
      </Paper>
      <TsuLoading loading={loading} error={error} />
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems="center"
        spacing={3}
      >
        <Box hidden={!totalCount}>
          <Typography>
            Showing {Math.min(itemsPerPage, items.length)} of {totalCount}{' '}
            results
          </Typography>
        </Box>

        <Box flexGrow={1} sx={{ display: { xs: 'none', md: 'block' } }} />

        <ItemsPerPageDropdown
          itemsPerPage={itemsPerPage}
          onChange={onItemsPerPageChange}
        />

        {totalCount !== undefined && (
          <Pagination
            page={page}
            count={Math.ceil(totalCount / itemsPerPage)}
            onChange={onPageChange}
          />
        )}

        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>Jump to page</Typography>
          <TextField
            value={jtp}
            onChange={(e) => setJtp(parseInt(e.target.value))}
            onKeyPress={handleJumpToPage}
            size="small"
            type="number"
            inputProps={{
              max: totalCount ? Math.floor(totalCount / itemsPerPage) : -1,
            }}
            sx={{ backgroundColor: 'white', maxWidth: 75, flexShrink: 1 }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}

interface RenderRowsProps {
  items: ITsuTableItem[];
  headers: ITsuTableHeader[];
  customItemRender?: (i: ITsuTableItem, value: string) => React.ReactNode;
  stickyStyle?: (h: ITsuTableHeader) => React.CSSProperties;
}

function NormalTableRows({
  items,
  headers,
  customItemRender,
  stickyStyle,
}: RenderRowsProps) {
  return (
    <>
      {items.map((item, i) => (
        <tr key={i}>
          {headers.map((h, j) => {
            return (
              <td
                key={j}
                style={{ backgroundColor: 'white', ...stickyStyle?.(h) }}
              >
                {customItemRender?.(item, h.value) ?? item[h.value]}
              </td>
            );
          })}
        </tr>
      ))}
    </>
  );
}

interface GroupedTableRowsProps extends RenderRowsProps {
  groupByHeaders: ITsuTableHeader[];
}

function GroupedTableRows({
  items,
  headers,
  groupByHeaders,
  customItemRender,
  stickyStyle,
}: GroupedTableRowsProps) {
  const uniqueItems = useMemo(() => {
    let uniques = items.slice();
    for (const gh of groupByHeaders)
      uniques = [...new Map(uniques.map((i) => [i[gh.value], i])).values()];
    return uniques;
  }, [items, groupByHeaders]);

  return (
    <>
      {uniqueItems.map((uniqueItem, i) => (
        <tr key={i}>
          <td colSpan={headers.length}>
            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography>
                  {groupByHeaders
                    .map((gh) => gh.text + ': ' + (uniqueItem[gh.value] || '-'))
                    .join(', ')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table>
                  <tbody>
                    <NormalTableRows
                      items={items.filter((item) =>
                        groupByHeaders.every(
                          (gh) => item[gh.value] === uniqueItem[gh.value]
                        )
                      )}
                      headers={headers}
                      customItemRender={customItemRender}
                      stickyStyle={stickyStyle}
                    />
                  </tbody>
                </Table>
              </AccordionDetails>
            </Accordion>
          </td>
        </tr>
      ))}
    </>
  );
}

function computeStickyStyle(
  h: ITsuTableHeader,
  tdWidth: number,
  headers: ITsuTableHeader[],
  freezeHeaders: ITsuTableHeader[] = []
) {
  const style = {} as React.CSSProperties;

  const freezeIdx = freezeHeaders.findIndex((h1) => h1.value === h.value);
  if (freezeIdx === -1) return style;

  const freezePosition = freezeHeaders
    .map((h) => ({
      ...h,
      idx: headers.findIndex((fh) => fh.value === h.value),
    }))
    .sort((h1, h2) => h1.idx - h2.idx)
    .findIndex((fh) => fh.value === h.value);

  style.position = 'sticky';
  style.left = freezePosition * tdWidth;
  return style;
}
