import { gql, useMutation } from '@apollo/client';

export function useDeleteProduct() {
  const [mutate, { loading, error }] = useMutation<
    { deleteProduct: { message: string } },
    { productId: number }
  >(gql`
    mutation ($productId: Int!) {
      deleteProduct(productId: $productId) {
        message
      }
    }
  `);

  function deleteProduct(productId: number) {
    return mutate({ variables: { productId }});
  }

  return {
    deleteProduct,
    error,
    loading,
  };
}
