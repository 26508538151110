import { Stack, Typography } from '@mui/material';
import FilterImage from '../../../assets/images/Filter.png';
import FilterChips from '../components/FilterChips';

interface FiltersAppliedProps {
  filterTab: number;
  onFilterDelete?: (key: string) => void;
}

function FiltersApplied({
  filterTab,
  onFilterDelete,
}: FiltersAppliedProps) {
  return (
    <Stack>
      <Typography variant="h5" display="flex" alignItems="center">
        <img
          style={{ marginRight: 8 }}
          src={FilterImage}
          alt="Filter"
          width="25"
        />
        Filters Applied
      </Typography>
      <FilterChips onFilterDelete={onFilterDelete} />
    </Stack>
  );
}

export default FiltersApplied;
