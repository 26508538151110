import VACCSignup from '@/features/customization/VACC/VACCSignup';
import NormalSignup from './NormalSignup';

export default function Signup() {
  // Check if the URL contains "ourautoistore"
  if (window.location.hostname.includes('ourautoistore')) {
    return <VACCSignup/>;
  }
  // Render the default SignIn component
  return (
    <NormalSignup/>
  );
}