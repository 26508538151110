import { TsuTable, TsuTableFilter } from '@/common/components';
import { useChangeCustomerEffect } from '@/features/customer/hooks/use-change-customer';
import { AppLayout } from '@/layouts/AppLayout';
import AddIcon from '@mui/icons-material/Add';
import { Button, Container, Stack } from '@mui/material';
import { AddProductDialog } from '../components/AddProductDialog';
import { BasicFilter } from '../components/BasicFilter';
import { EditProductDialog } from '../components/EditProductDialog';
import { allHeaders } from './ProductManagement.constants';
import {
  ProductContext,
  ProductContextValues,
  useProductManagement,
} from './ProductManagement.hook';

export function ProductManagement() {
  const productMgt = useProductManagement();

  const productContext: ProductContextValues = {
    productId: productMgt.selectedProduct?.productId ?? null,
    productName: productMgt.selectedProduct?.name ?? '',
  };
  let headers = allHeaders.slice();

  useChangeCustomerEffect(() => {
    productMgt.refresh();
  });

  return (
    <AppLayout title="Product Management">
      <Container sx={{ flexGrow: 1 }} maxWidth={false}>
        <Stack spacing={2}>
          <TsuTableFilter
            headers={headers}
            onSearchEntered={(v) => {
              productMgt.setSearchValue(v);
              productMgt.setPage(0);
            }}
            basicFilterComponent={<BasicFilter onApplyFilters={productMgt.applyBasicFilters} />}
            leftActionsComponent={
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={productMgt.openAddProductDialog}
              >
                Add Product
              </Button>
            }
          />
          {/* <FilterChips label="Filters:" onFilterDelete={handleFilterDelete} /> */}
          <TsuTable
            headers={headers}
            items={productMgt.data}
            loading={productMgt.loading}
            error={productMgt.error}
            totalCount={productMgt.totalCount}
            page={productMgt.opts.page}
            itemsPerPage={productMgt.opts.itemsPerPage}
            onPageChange={productMgt.setPage}
            onItemsPerPageChange={productMgt.setItemsPerPage}
            customItemRender={productMgt.customItemRender}
          />
        </Stack>

        <AddProductDialog
          open={productMgt.addProductDialog}
          onClose={productMgt.closeAddProductDialog}
        />

        <ProductContext.Provider value={productContext}>
          <EditProductDialog
            productId={productMgt.selectedProduct?.productId ?? null}
            open={productMgt.editProductDialog}
            onClose={productMgt.closeEditProductDialog}
          />
        </ProductContext.Provider>
      </Container>
    </AppLayout>
  );
}

export default ProductManagement;
