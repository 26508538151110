import { FeatureRoute } from '@/common/interfaces/feature-route';
import { Artwork } from './screens/Artwork';
import { CustomArtworks } from './screens/CustomArtworks';

export const etemplatesRoutes: FeatureRoute[] = [
  {
    path: '/customArtworks',
    component: CustomArtworks,
    options: {
      useCustomerTheme: true,
    },
  },
  {
    path: '/customArtworks/:productCode',
    component: Artwork,
    options: {
      useCustomerTheme: true,
    },
  },
];
