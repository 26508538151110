import { useMutation } from '@apollo/client';
import { gql } from 'apollo-boost';
import { ProductUom } from '../../../graphql';

export function useRemoveUom() {
  const [mutate, { error, loading }] = useMutation<
    ProductUom,
    { uomId: number }
  >(gql`
    mutation ($uomId: Int!) {
      removeUom(uomId: $uomId) {
        uomid
        removed
      }
    }
  `);

  const removeUom = (uomId: number) => mutate({ variables: { uomId } });

  return { removeUom, error, loading };
}
