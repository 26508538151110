import { createContext, PropsWithChildren, useContext } from 'react';

type CategoryItemContext = {
  onCategoryItemClick?: (catId: number) => void;
};

const CategoryItemContext = createContext({} as CategoryItemContext);

export function useCategoryItemsContext(): CategoryItemContext | undefined {
  return useContext(CategoryItemContext);
}

export function CategoryItemContainer(props: PropsWithChildren<CategoryItemContext>) {
  const context = {
    onCategoryItemClick: props.onCategoryItemClick,
  };
  return (
    <CategoryItemContext.Provider value={context}>{props.children}</CategoryItemContext.Provider>
  );
}
