import { useOrderStatusesQuery, useOrderTypesQuery } from '@/graphql';
import { Box, Chip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { IBasicFilter } from '../../../common/interfaces';
import { RootState } from '../../../store';

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#FCD8EA',
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

interface FilterChipsProps {
  label?: string;
  onFilterDelete?: (key: string, i: number, type: 'basic' | 'advanced') => void;
}

function FilterChips({ label, onFilterDelete }: FilterChipsProps) {
  const basicFilter = useSelector((store: RootState) => store.report.basicFilter);
  const { data: orderStatusesQuery } = useOrderStatusesQuery();
  const { data: orderTypesQuery } = useOrderTypesQuery();

  const orderStatuses = orderStatusesQuery?.orderStatuses ?? [];
  const orderTypes = orderTypesQuery?.orderTypes ?? [];
  const handleDelete = (key: string, i: number) => onFilterDelete?.(key, i, 'basic');

  if (!basicFilter) return null;

  const keys = Object.keys(basicFilter);
  const filters = keys.filter((k) => basicFilter[k as keyof IBasicFilter] !== null);
  const hasAnyFilters = filters.length > 0;

  if (!hasAnyFilters) return null;

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center">
      {label && (
        <Typography variant="h6" sx={{ mr: 2, mt: 1 }}>
          {label}
        </Typography>
      )}
      {hasAnyFilters &&
        filters.map((key, i) => {
          // Source: https://stackoverflow.com/a/7225450
          let formattedKey = key.replace(/([A-Z])/g, ' $1');
          formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
          let value = basicFilter[key as keyof IBasicFilter];

          if (value && key === 'orderStatus' && orderStatuses.length > 0) {
            value = orderStatuses.find((os) => os.statusId === parseInt(value!))!.name!;
          }

          if (value && key === 'orderType' && orderTypes.length > 0) {
            value = orderTypes.find((ot) => ot.orderTypeId === parseInt(value!))!.name!;
          }

          return (
            <StyledChip
              key={i}
              label={`${formattedKey}: ${value}`}
              onDelete={() => handleDelete(key, i)}
            />
          );
        })}
    </Box>
  );
}

export default FilterChips;
