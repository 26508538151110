import { Order, useGetCurrentUserQuery, useGetSavedOrdersQuery, useLoadSavedOrderMutation, useUpdateOrderSavedMutation } from '@/graphql';
import { TsuTable } from '@/common/components';
import { Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import useSavedOrdersRender from './useSavedOrdersRender';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';


function SavedOrdersTable() {
  const [updateOrderSave, updateOrderSaveMut] = useUpdateOrderSavedMutation();
  const [loadSavedOrderMutate, {error: LoadSavedOrderError}] = useLoadSavedOrderMutation();
  const {
    data: currentUserData,
    loading: isUserLoading,
    refetch: currentUserRefetch,
  } = useGetCurrentUserQuery({ notifyOnNetworkStatusChange: true });
  const { enqueueSnackbar } = useSnackbar();
  const [opts, setOpts] = useState({
    page: 0,
    itemsPerPage: 20,
  });
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useGetSavedOrdersQuery({
    variables: {
      skip: opts.page * opts.itemsPerPage,
      take: opts.itemsPerPage,
    },
  });

  refetch();
  async function DeleteSavedOrderClickHandle(orderId: number) {
    console.log(orderId);
    if (orderId == 0) return;
    // Call the hook when the button is clicked
      await updateOrderSave({
        variables: {
          input: {
            orderId:  orderId,
            saved: false
          }
        }
      });
      refetch();
      enqueueSnackbar("Order has been un-saved.", { variant: 'success' });
  }

  async function LoadSavedOrderClickHandle(order: Order) {
    try {
      const response = await loadSavedOrderMutate({ variables: {
        input: {
          orderShipId: order.orderShippings[0].orderShipId,
          cartId: currentUserData?.currentUser?.shoppingCart?.id ?? ""
        } 
      }});
      // Handle the response from the GraphQL server
      currentUserRefetch();
      navigate('/cart');
      enqueueSnackbar(response.data?.loadSavedOrder, { variant: 'success' });
    } catch (LoadSavedOrderError) {
      // Handle errors
      console.error(LoadSavedOrderError);
    }
  }

  const headers = useMemo(
    () => [
      { text: 'Order No.', value: 'orderId' },
      { text: 'Order Date', value: 'created' },
      { text: '', value: 'order' },
      { text: '', value: 'delete' },
    ],
    []
  );

  const { customItemRender } = useSavedOrdersRender({
    onOrder: useCallback((o) => {
      LoadSavedOrderClickHandle(o)
    }, [LoadSavedOrderClickHandle]),
    onDelete: useCallback((o) => {
      DeleteSavedOrderClickHandle(o.orderId)
    }, [DeleteSavedOrderClickHandle]),
  });
  
  return (
    <Stack spacing={2} alignItems="stretch">
      <Typography variant="h2" fontWeight="bold" sx={{ alignSelf: 'center' }}>
        Saved Orders
      </Typography>
      <TsuTable
        headers={headers}
        items={data?.savedOrders?.items ?? []}
        totalCount={data?.savedOrders?.totalCount}
        customItemRender={customItemRender}
        loading={loading}
        error={error}
        stretchColumns={true}
        page={opts.page}
        itemsPerPage={opts.itemsPerPage}
        onPageChange={(page) => setOpts({ ...opts, page })}
        onItemsPerPageChange={(ipp) => setOpts({ ...opts, itemsPerPage: ipp })}
      />
    </Stack>
  );
}

export default SavedOrdersTable;
