import { TsuField, TsuSelect } from '@/common/components';
import { Button, Checkbox, FormControlLabel, Grid, Stack } from '@mui/material';
import { ProductUom } from '../../../graphql';
import { CreateProductUomPayload } from '../../../common/interfaces';
import { useUomForm } from './UomForm.hook';

export interface UomFormProps {
  initialUom?: ProductUom;
  onCancel?: () => void;
  onSave?: (uom: CreateProductUomPayload) => void;
}

// This component / form allows the user to create a UOM payload object
// which can be used to either create a new UOM or edit an existing UOM
export function UomForm(props: UomFormProps) {
  const uomForm = useUomForm(props);
  const { uom, setUom } = uomForm;

  return (
    <Stack spacing={2} alignItems="stretch">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TsuSelect
            value={uom.descriptorId?.toString() ?? ''}
            items={uomForm.uomDescriptors}
            onChange={(id) => setUom({ ...uom, descriptorId: id })}
            itemText="name"
            itemValue="descriptorId"
            label="- Select Type -"
            loading={uomForm.isUomDescriptorsLoading}
            error={uomForm.getUomDescriptorsError}
          />
          <TsuField
            label="Description"
            value={uom.description}
            onChange={(v) => setUom({ ...uom, description: v })}
            multiline
            rows={5}
          />
          <TsuField
            type="number"
            label="Price Per Unit"
            value={uom.pricePerUnit?.toString() ?? ''}
            onChange={(v) => setUom({ ...uom, pricePerUnit: parseFloat(v) })}
          />
          <TsuField
            type="number"
            label="Multiplier"
            value={uom.multiplier.toString()}
            onChange={(v) => setUom({ ...uom, multiplier: parseInt(v) })}
          />
          <TsuField
            type="number"
            label="Cost Per Unit"
            value={uom.costPerUnit?.toString() ?? ''}
            onChange={(v) => setUom({ ...uom, costPerUnit: parseFloat(v) })}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={uom.stockingUom}
                onChange={(e) =>
                  setUom({ ...uom, stockingUom: e.target.checked })
                }
              />
            }
            label="Stocking UOM"
          />

          <TsuField
            type="number"
            label="Dimension X (cm)"
            value={uom.dimensionX?.toString() ?? ''}
            onChange={(v) => setUom({ ...uom, dimensionX: parseFloat(v) })}
          />
          <TsuField
            type="number"
            label="Dimension Y (cm)"
            value={uom.dimensionY?.toString() ?? ''}
            onChange={(v) => setUom({ ...uom, dimensionY: parseFloat(v) })}
          />
          <TsuField
            type="number"
            label="Dimension Z (cm)"
            value={uom.dimensionZ?.toString() ?? ''}
            onChange={(v) => setUom({ ...uom, dimensionZ: parseFloat(v) })}
          />
          <TsuField
            type="number"
            label="Weight (kg)"
            value={uom.weightKg?.toString() ?? ''}
            onChange={(v) => setUom({ ...uom, weightKg: parseFloat(v) })}
          />
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="end" spacing={1}>
        <Button onClick={uomForm.cancel} variant="outlined">
          Cancel
        </Button>
        <Button onClick={uomForm.save} variant="contained">
          Save
        </Button>
      </Stack>
    </Stack>
  );
}
