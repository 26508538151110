import { TsuButton, TsuSelect, TsuSelectForProdVariants } from '@/common/components';
import QuantityAdjustment from '@/common/components/QuantityAdjustment';
import QuantityDropdown from '@/common/components/QuantityDropdown';
import { OrderTypeEnum } from '@/common/enums/orderTypeEnum';
import { FavoriteButton } from '@/features/favorites/components/FavoriteButton';
import { ProductContext } from '@/features/product/product.context';
import { useProductAttributesQuery } from '@/graphql';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { MouseEvent, useContext, useState } from 'react';
import { ProductImage } from '../../product/components/ProductImage';
import { StockIndicator } from '../../product/components/StockIndicator';

interface ShopItemDialogProps {
  open: boolean;
  onClose?: () => void;
  onAddToCart?: (id: number, amount: number) => void;
}

function ShopItemDialog(props: ShopItemDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const product = useContext(ProductContext);
  const theme = useTheme();
  const [addAmount, setAddAmount] = useState(product.defaultQuantity);
  const [selectedAttributeId, setSelectedAttributeId] = useState<number>();

  const [prevProductId, setPrevProductId] = useState(product.productId);
  if (product.productId !== prevProductId) {
    setAddAmount(product.defaultQuantity);
    setPrevProductId(product.productId);
  }

  const { data: productAttributesQuery } = useProductAttributesQuery({
    variables: { productId: product.productId },
  });
  const productAttributes = productAttributesQuery?.productAttributes;

  const minQuantity = product.minOrderQuantity ?? 1;

  

  // This will only be used if the product uses Price Breaks
  const quantityChoices = (product.prices ?? [])
    .slice()
    .filter((q) => q.quantity > 0)
    .sort((q1, q2) => q1.quantity - q2.quantity);

  const handleClose = () => props.onClose?.();

  const addToCart = (e: MouseEvent) => {
    e.stopPropagation();
    if (productAttributes && selectedAttributeId) {
      const product = productAttributes.attributes.find(
        (pa) => pa.productId === selectedAttributeId
      );
      if (product && product.stockAvailable <= 0) {
        return enqueueSnackbar('This product is out of stock', { variant: 'error' });
      }
      props.onAddToCart?.(selectedAttributeId, addAmount);
    } else {
      if (product.stockAvailable <= 0 && product.orderTypeId !== OrderTypeEnum.Contract) {
        return enqueueSnackbar('This product is out of stock', { variant: 'error' });
      }
      if (product.stockAvailable >= 0 && product.stockAvailable < minQuantity) {
        return enqueueSnackbar('Available stock is less than minimum order quantity. Please contact your Account Manager', { variant: 'error' });
      }
      props.onAddToCart?.(product.productId, addAmount);
    }  
    handleClose();
  };

  const handleQuantityDropdownChange = (v: string, e: SelectChangeEvent<string>) => {
    e.stopPropagation();
    setAddAmount(parseInt(v));
  };

  const handleDecrementClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setAddAmount(addAmount - 1 > 0 ? addAmount - 1 : 1);
  };

  const handleIncrementClick = (e: MouseEvent) => {
    e.stopPropagation();
    setAddAmount(addAmount + 1);
  };

  return (
    <Dialog maxWidth="md" fullWidth open={props.open} onClose={handleClose}>
      <DialogTitle sx={{ pt: 1, pb: 1 }}>
        <Stack direction="row" justifyContent="end">
          <TsuButton onClick={handleClose} text>
            <CancelOutlinedIcon sx={{ mr: 1 }} />
            <Typography>Close</Typography>
          </TsuButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box sx={{ maxWidth: '500px' }}>
              <ProductImage id={product.productId} alt={product.name} />
            </Box>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ flex: 1, maxWidth: { xs: 'none' } }}>

            <Typography variant="h5" color="black" sx={{ mb: 1.5 }}>
              {product.name}
            </Typography>
            <Typography variant="body2" sx={{ mb: 1.5 }}>
              {product.unitOfMeasurementAmount} • {product.stockAvailable}
            </Typography>

            <Typography fontWeight="bold" sx={{ color: theme.palette.primary.main }}>
              <span dangerouslySetInnerHTML={{ __html: product.priceSummary }}></span>
            </Typography>

            {/* {isDiscounted && (
              <Stack direction="row">
                <Typography sx={{ color: '##3A5069' }}>
                  <span style={{ textDecoration: 'line-through' }}>
                    {originalPrice}
                  </span>
                  &nbsp;
                  <span>({discountDetail})</span>
                </Typography>
              </Stack>
            )} */}

            {product.description && (
              <>
              <div style={{
                marginTop: '30px',
                marginBottom: '30px'
              }}>
                <div>
                  <Typography variant="body1" fontWeight="bold">
                    Description:
                  </Typography>
                </div>
                <div>
                  <Typography>
                    <span dangerouslySetInnerHTML={{ __html: product.description ?? '' }}></span>
                  </Typography>
                </div> 
              </div>
              </>
            )}

            {/* <Stack>
              <Typography>Size:</Typography>
              <Stack direction="row" spacing={1}>
                <TsuButton rounded flat>
                  Small
                </TsuButton>
                <TsuButton rounded secondary dark flat>
                  Medium
                </TsuButton>
                <TsuButton rounded secondary dark flat>
                  Large
                </TsuButton>
                <TsuButton rounded secondary dark flat>
                  Extra Large
                </TsuButton>
              </Stack>
            </Stack>

            <Stack>
              <Typography>Color:</Typography>
              <Stack direction="row" spacing={1}>
                <TsuButton rounded flat>
                  Blue
                </TsuButton>
                <TsuButton rounded secondary dark flat>
                  Red
                </TsuButton>
                <TsuButton rounded secondary dark flat>
                  Green
                </TsuButton>
              </Stack>
            </Stack> */}

            {selectedAttributeId ? (
              <Typography variant="body1" sx={{
                marginTop: '20px',
                marginBottom: '20px'
              }}>
                Stock Available:{' '}
                <span>
                  {
                    productAttributes?.attributes.find((pa) => pa.productId === selectedAttributeId)
                      ?.stockAvailable
                  }
                </span>
              </Typography>
            ) : (
              <StockIndicator stock={product.stockAvailable} orderTypeId={product.orderTypeId}/>
            )}

            <Stack sx={{
              marginTop: '20px',
              marginBottom: '20px',
              width: '45%'
            }}>
              <Typography variant="body1">Quantity</Typography>
              {product.hasPriceBreaks ? (
                <QuantityDropdown
                  value={addAmount.toString()}
                  onChange={handleQuantityDropdownChange}
                  quantityChoices={quantityChoices}
                />
              ) : (
                selectedAttributeId ? (
                  // Render a different component or apply different logic for special orders
                  <QuantityAdjustment
                    min={1}
                    max={productAttributes?.attributes.find((pa) => pa.productId === selectedAttributeId)
                      ?.stockAvailable}
                    quantity={addAmount}
                    onChange={setAddAmount}
                    onIncrementClick={handleIncrementClick}
                    onDecrementClick={handleDecrementClick}
                  />
                ) : (
                  <QuantityAdjustment
                    min={product.minOrderQuantity ?? 1}
                    max={product.stockAvailable}
                    quantity={addAmount}
                    onChange={setAddAmount}
                    onIncrementClick={handleIncrementClick}
                    onDecrementClick={handleDecrementClick}
                  />
                )
              )}
            </Stack>

            {productAttributes ? (
              <Stack sx={{
                marginTop: '20px',
                marginBottom: '20px',
                width: '45%'
              }}>
                <Typography variant="h6">{productAttributes?.name}</Typography>
                <TsuSelectForProdVariants
                  value={selectedAttributeId?.toString() ?? ''}
                  items={productAttributes?.attributes ?? []}
                  onChange={(v) => {
                    setSelectedAttributeId(v);
                  } }
                  itemText="attributeName"
                  itemValue="productId"
                  // label={`- Select ${productAttributes?.name} -`}
                  dense 
                  itemStock="stockAvailable"
                />
              </Stack>
            ) : null}

            <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1, mb: 2, marginTop: '10px',marginBottom: '10px'}}>
              <InfoOutlinedIcon sx={{ color: theme.palette.primary.main }} />
              <Typography sx={{ color: '#222F3E' }}>
                Minimum Order of {product.minOrderQuantity ?? 1}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2} sx={{marginTop: '20px',marginBottom: '20px', width: '90%'}}>
              <Button variant="contained" onClick={addToCart} sx={{ flexGrow: 1 }}>
                Add to Cart
              </Button>
              <Button variant='outlined' 
                sx={{
                  width: '30px',
                  alignItems: 'center',
                  alignContent:'center',
                  '&:hover': {
                    backgroundColor: 'white'
                  }
                }}
              >
                <FavoriteButton productId={product.productId} />
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ShopItemDialog;
