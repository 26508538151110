import { client } from '@/core/apollo-client';
import { AddUserDocument, AddUserMutation, AddUserMutationVariables } from '@/graphql';
import { CreateUserInput } from '../interfaces/CreateUserInput.interface';

class VACCAuthService {

  async addUser(input: CreateUserInput) {
    const res = await client.mutate<AddUserMutation, AddUserMutationVariables>({
      mutation: AddUserDocument,
      variables: { input },
    });

    if (!res.data) {
      console.error('[AuthService] Empty login data');
      throw new Error('Login failed');
    }

  }

}

export default new VACCAuthService();