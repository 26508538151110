import { TsuLoading } from '@/common/components';
import { Button, Card, CardContent, CardHeader, List, Stack } from '@mui/material';
import { CategoryItem } from '../../product-category/components/CategoryItem';
// import { CategoryContext, ICategoryContext } from '../../shop/screens/Shop';
import {
  CategoryContextProvider,
  useCategoriesContext
} from '../../product-category/category.context';
import { useCategoriesForm } from './CategoriesForm.hook';

interface Props {
  productId: number | null;
  onCancel?: () => void;
}

export function WrappedCategoriesForm(props: Props) {
  return (
    <CategoryContextProvider>
      <CategoriesForm {...props} />
    </CategoryContextProvider>
  );
}

export function CategoriesForm({ productId, onCancel }: Props) {
  const { categories, isLoading } = useCategoriesContext();
  const cf = useCategoriesForm({ productId });
  // const categoryContext: ICategoryContext = useMemo(
  //   () => ({
  //     category: null,
  //     hasCheckbox: true,
  //     checkedCategories: cf.checkedCategories,
  //     onCategoryClick: () => {},
  //     onCategoryCheck: (c) => cf.checkCategory(c),
  //   }),
  //   [cf]
  // );

  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader title="Product is visible under the selected categories" />
        <CardContent>
          <TsuLoading loading={isLoading || cf.isLoadingProductCategories}>
            <List>
              {categories.map((c, i) => (
                <CategoryItem key={i} {...c} />
              ))}
            </List>
          </TsuLoading>
        </CardContent>
      </Card>
      <TsuLoading loading={cf.isSaving}>
        <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ width: '100%' }}>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={cf.save}>
            Save
          </Button>
        </Stack>
      </TsuLoading>
    </Stack>
  );
}
