import { styled } from '@mui/material';
import { Button } from '@mui/base/Button';

export const StyledTable = (colMinWidth?: number, stretchColumns?: boolean) =>
  styled('table')({
    // Table horizontal overflow (Source: https://stackoverflow.com/a/5533648)
    // overflowX: 'auto',
    borderCollapse: 'collapse',
    // borderCollapse: 'separate',
    color: '#242F3E',
    width: '100%',
    '& tr': {
      borderBottom: 'solid 1px #D8D8D8',
    },
    '& th': {
      borderBottom: 'solid 1px #242F3E',
      backgroundColor: '#F1F2F6',
      fontWeight: 'normal',
      whiteSpace: 'nowrap',
    },
    '& td': {
      minWidth: colMinWidth ? `${colMinWidth}px` : '100px',
      // width: stretchColumns ? '100%' : 'auto',
    },
    '& th, & td': {
      textAlign: 'left',
      padding: '16px',
    },
  });

export const PageButton = styled(Button)({
  backgroundColor: 'white',
  border: 'none',
  cursor: 'pointer',
  transition: 'background-color 0.25s',
  minWidth: 32,
  '&:hover': {
    backgroundColor: '#F1F2F6',
  },
});
