// This interface will be used in
// completing the user's contact info in their UserCart.
export interface ContactInfo {
  fullName: string;
  email: string;
  mobileNo: string;
  phoneNo: string;
  companyName: string;
}

// This interface will be shared by the following checkout fields:
// 1. Delivery Address
// 2. Billing Address (usually set to "the same as Delivery Address")
export interface Address {
  businessName: string;
  streetAddress: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
  deliveryNotes: string;
}

export enum PaymentMethods {
  PAYMENT_CARD = 'paymentCard',
  INVOICE = 'invoice',
  POINTS = 'points',
  EWAY = 'eway',
  ALTERNATIVE = 'alternative'
}

// This type encapsulates multiple form states into ONE form state.
// It will primarily be used by DeliveryDetails
// to handle the form states of ContactInfoForm and AddressForm.
// Descendant components can also use this type to provide type hinting to their own forms.
export type DeliveryDetailsForm = ContactInfo & Address;

// This type is identical to DeliveryDetailsForm (above),
// but we want to define it separately for scalability purposes
// (e.g. if we want to redefine it in the future).
export type BillingDetailsForm = ContactInfo & Address;

// This type encapsulates Shipping related fields (Shipping Method in Tsunami).
export type ShippingMethodForm = {
  freightSettingId: number | null;
  isUrgentOrder: boolean;
  chargeProcessingFee: boolean;
  processingFeeNotes: string;
};

export type PaymentMethodForm = {
  paymentMethod: PaymentMethods | null;
};
