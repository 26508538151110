import { FeatureRoute } from '@/common/interfaces/feature-route';
import { Cart } from './screens/Cart';

export const cartRoutes: FeatureRoute[] = [
  {
    path: '/cart',
    component: Cart,
    options: {
      useCustomerTheme: true,
    },
  },
];
