import { TsuField } from '@/common/components';
import { Stack, Tooltip, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ContactInfo } from '../interfaces';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {
  readOnly?: boolean;
}

export function ContactInfoForm(props: Props) {
  const { readOnly } = props;
  const methods = useFormContext<ContactInfo>();
  const register = methods?.register ?? (() => ({}));
  const watch = methods?.watch ?? (() => '');
  const errors = methods?.formState?.errors;

  return (
    <Stack sx={{ marginY: (theme) => readOnly ? `${theme.typography.pxToRem(1.2)} !important` : `${theme.spacing(2)} !important` }}>
      <Typography sx={{ mb: 0 , fontSize: readOnly ? '1.2rem' : '1.5rem'}}>
        <b>Contact Info</b>
      </Typography>
      <TsuField
        {...register('fullName')}
        value={watch('fullName')}
        name="fullName"
        required
        label="Full Name"
        onlyValue={readOnly}
        errorMessage={errors.fullName?.message}
      />
      <TsuField
        {...register('email')}
        value={watch('email')}
        name="email"
        required
        label="Email"
        onlyValue={readOnly}
        errorMessage={errors.email?.message}
      />
      <Stack direction={!readOnly ? 'row' : 'column'} spacing={!readOnly ? 2 : "1.2"}>
        <TsuField
          {...register('mobileNo')}
          value={watch('mobileNo')}
          name="mobileNo"
          label="Mobile No."
          onlyValue={readOnly}
          errorMessage={errors.mobileNo?.message}
        />
        <TsuField
          {...register('phoneNo')}
          value={watch('phoneNo')!=null ? watch('phoneNo') : " "}
          name="phoneNo"
          label="Phone No."
          onlyValue={readOnly}
          errorMessage={errors.phoneNo?.message}
        />
          <Tooltip
            sx={{display: readOnly ? 'none' : 'block'}}
            title="One of phone or mobile number is required"
            placement="top"
          >
            <InfoOutlinedIcon />
          </Tooltip>
      </Stack>
      <TsuField
        {...register('companyName')}
        value={watch('companyName')}
        name="companyName"
        label="Company Name"
        onlyValue={readOnly}
        errorMessage={errors.companyName?.message}
      />
    </Stack>
  );
}
