import { FeatureRoute } from '@/common/interfaces/feature-route';
import { DocManager } from './screens/DocManager';

export const docManagerRoutes: FeatureRoute[] = [
  {
    path: '/documentManager',
    component: DocManager,
    options: {
      useCustomerTheme: true,
    },
  },
];
