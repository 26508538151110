import { CONTACT_US_EMAIL } from '@/globals';
import { useContactUsMutation } from '@/graphql';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
  Button,
  Grid,
  Input,
  Link,
  LinkProps,
  Stack,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { PropsWithChildren, useState } from 'react';
import { useCustomerTheme } from '@/common/theme';
import { TsuLoading } from '../../../common/components/TsuLoading';

interface ContactUsFormProps {
  dense?: boolean;
}

type ContactUsInput = {
  name: string;
  email: string;
  message: string;
};

const StyledLink = (props: LinkProps) => {
  const customerTheme = useCustomerTheme();
  return (
    <Link
      {...props}
      sx={{
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '16px',
        color: customerTheme?.footer.text ?? 'text.primary'
      }}
    />
  );
};

const StyledTypography = (props: PropsWithChildren<TypographyProps>) => {
  const customerTheme = useCustomerTheme();
  return (
    <Typography
      {...props}
      sx={{
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 16,
        color: customerTheme?.footer.text ?? 'text.primary',
      }}
    >
      {props.children}
    </Typography>
  );
};

export function ContactUsForm(props: ContactUsFormProps) {
  const matchesSm = useMediaQuery('(min-width: 600px)');
  const { enqueueSnackbar } = useSnackbar();
  const customerTheme = useCustomerTheme();
  const theme = useTheme();
  const [contactForm, setContactForm] = useState<ContactUsInput>({
    name: '',
    email: '',
    message: '',
  });

  const [contactUsMutate, { loading: isFormSubmitting }] = useContactUsMutation();

  async function handleContactFormSubmit() {
    const res = await contactUsMutate({
      variables: { name: contactForm.name, email: contactForm.email, message: contactForm.message },
    });
    enqueueSnackbar(res.data?.contactUs?.message, { variant: 'success' });
  }

  return (
    <Grid container wrap={matchesSm ? 'nowrap' : 'wrap'}>
      <Grid item xs={12} md={6}>
        <Stack direction="column" spacing={2}>
          <StyledTypography variant="h4" textAlign="left" sx={{color: customerTheme?.footer.text ?? 'text.primary'}}>
            Contact Us
          </StyledTypography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <StyledLink href="https://goo.gl/maps/5WjQxS1qH2bY9brb9" underline="none">
              <Typography variant="body2" sx={{color: customerTheme?.footer.text ?? 'text.primary'}}>
                113-115 Northcorp Boulevard <br />
                Broadmeadows Victoria 3047
              </Typography>
            </StyledLink>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <StyledLink href="tel:+61 3 9487 2100" underline="none">
              <Typography variant="body2" sx={{color: customerTheme?.footer.text ?? 'text.primary'}}>+61 3 9487 2100</Typography>
            </StyledLink>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <StyledLink href={`mailto:${CONTACT_US_EMAIL}`} underline="none">
              <Typography variant="body2" sx={{color: customerTheme?.footer.text ?? 'text.primary'}}>{CONTACT_US_EMAIL}</Typography>
            </StyledLink>
          </Stack>
          <Stack direction="row" spacing={1}>
            <StyledLink href="https://www.facebook.com/brandservicesau">
              <FacebookIcon />
            </StyledLink>
            <StyledLink href="https://instagram.com/brandservicesau">
              <InstagramIcon />
            </StyledLink>
            <StyledLink href="https://www.linkedin.com/company/brand-services-ptyltd">
              <LinkedInIcon />
            </StyledLink>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6} sx={{ mt: { xs: theme.spacing(2), sm: 0 } }}>
        <TsuLoading loading={isFormSubmitting}>
          <Stack direction="column" spacing={2}>
            <Input
              placeholder="Name"
              value={contactForm.name}
              onChange={(e) => setContactForm({ ...contactForm, name: e.target.value })}
              sx={{color: customerTheme?.footer.text ?? 'text.primary'}}
            />
            <Input
              placeholder="Email"
              value={contactForm.email}
              onChange={(e) => setContactForm({ ...contactForm, email: e.target.value })}
              sx={{color: customerTheme?.footer.text ?? 'text.primary'}}
            />
            <Input
              placeholder="Message"
              value={contactForm.message}
              onChange={(e) => setContactForm({ ...contactForm, message: e.target.value })}
              multiline
              rows={5}
              sx={{color: customerTheme?.footer.text ?? 'text.primary'}}
            />
            <Button
              variant="contained"
              onClick={handleContactFormSubmit}
              sx={{
                alignSelf: 'start',
                fontWeight: 'bold',
                color: theme.palette.secondary.contrastText,
                backgroundColor: customerTheme?.footer.button,
                // '&:hover': {
                //   backgroundColor: theme.palette.background,
                //   color: theme.palette.text.primary,
                // }
            }}
            >
              Submit
            </Button>
          </Stack>
        </TsuLoading>
      </Grid>
    </Grid>
  );
}
