import { gql } from '@apollo/client';

export interface CreateReportScheduleResponse {
  createReportSchedule: {
    cronExpression: string;
    reportScheduleId: number;
    reportScheduleStatusId: number;
  }
}

export const CREATE_REPORT_SCHEDULE_MUTATION = gql`
  mutation CreateReportSchedule($input: CreateReportScheduleInput!) {
    createReportSchedule(input: $input) {
      cronExpression
      reportScheduleId
      reportScheduleStatusId
    }
  }
`;

export const initialReportDetails = {
  // Set the report's name
  reportName: '',
  // Set the report's file type
  fileType: 'XLSX',
  sendReportType: 'send-report-others',
  // This is only used when "Send Report" is set to "Send report to other emails"
  // This is used by the Send Report to [email] form so the user can
  // add as many emails as they want
  emailToAdd: '',
  otherEmails: [] as string[],
};

export type IReportDetails = typeof initialReportDetails;
