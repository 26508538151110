import { FeatureRoute } from '@/common/interfaces/feature-route';
import { Report } from './Report';

export const reportRoutes: FeatureRoute[] = [
  {
    path: '/report',
    component: Report,
    options: {
      useCustomerTheme: true,
    },
  },
];
