import { TsuLoading } from '@/common/components';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button, Stack } from '@mui/material';
import { useUomTab } from '../hooks/useUomTab';
import { UomForm } from './UomForm';
import { UomTable } from './UomTable';

export interface UomTabProps {
  productId: number | null;
}

export function UomTab(props: UomTabProps) {
  const uomTab = useUomTab(props);
  return (
    <Stack spacing={2} alignItems="stretch">
      <Stack direction="row" spacing={1}>
        <Button onClick={uomTab.enableAddMode} variant="outlined">
          <AddIcon />
          Add New UOM
        </Button>
        <Button variant="outlined" onClick={uomTab.refresh}>
          <RefreshIcon />
          Refresh
        </Button>
      </Stack>

      {uomTab.selectedUom !== null && (
        <TsuLoading loading={uomTab.isUpdating}>
          <UomForm
            initialUom={uomTab.selectedUom}
            onCancel={uomTab.clearSelectedUom}
            onSave={uomTab.updateUom}
          />
        </TsuLoading>
      )}

      {uomTab.isAddMode && (
        <TsuLoading loading={uomTab.isCreating}>
          <UomForm onCancel={uomTab.disableAddMode} onSave={uomTab.createUom} />
        </TsuLoading>
      )}

      <TsuLoading
        loading={uomTab.loading || uomTab.isRemoving}
        error={uomTab.error}
      >
        <UomTable
          uoms={uomTab.uoms}
          headers={uomTab.headers}
          onEditClick={uomTab.selectUom}
          onRemoveClick={uomTab.removeUom}
        />
      </TsuLoading>
    </Stack>
  );
}
