import { BrowserRouter } from 'react-router-dom';
import { AppProviders } from './AppProviders';
import { AppRouter } from './core/react-router';
import { AuthExpirationListener } from './features/auth/components/AuthExpirationListener';
import { addFonts } from './core/fonts';

addFonts();

function App() {
  return (
    <BrowserRouter>
      <AppProviders>
        <AppRouter />
      </AppProviders>
      <AuthExpirationListener />
    </BrowserRouter>
  );
}

export default App;
