import { StyledTextField } from '@/common/components';
import { useCustomerLogo, useCustomerTheme } from '@/common/theme';
import { useShoppingCart } from '@/features/cart/shopping-cart.context';
import { useChangeCustomerEffect } from '@/features/customer/hooks/use-change-customer';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {
  Badge,
  Box,
  Button,
  CardMedia,
  IconButton,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { color } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useShopParams } from '../../features/shop/hooks/use-shop-params';

interface SearchBarProps {
  // This is an optional property.
  // If not passed, then we will show the SearchBar by default
  centerTitle?: string;
  withSearchBar?: boolean;
}

export function SearchBar({ centerTitle, withSearchBar }: SearchBarProps) {
  const theme = useTheme();
  const { cartItems } = useShoppingCart();
  const customerThemeLogo = useCustomerLogo();
  const { searchValue, setSearchValue } = useShopParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState(searchValue);
  const customerTheme = useCustomerTheme();
  const badgeRef = useRef<HTMLInputElement>(null);
  const [position, setPosition] = useState(0);

  useChangeCustomerEffect(() => setSearch(''));

  const handleCartClick = () => navigate('/cart');

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (location.pathname !== '/') navigate(`/?s=${search}`);
    else setSearchValue(search);
    window.location.reload();
  };

  useEffect(() => {
    setPosition(badgeRef.current? (badgeRef.current.getBoundingClientRect().width/2): 0);
  }, [badgeRef]);

  const badgeStyle = {
    "& .MuiBadge-badge": {
        color: customerTheme?.primary.contrastText,
        backgroundColor: customerTheme?.pageHeader.cart,
        right: `${position-10}px`,
        width: 20,
        height: 20,
        borderRadius: '50%'
    }
}

  return (
    <Box width="100%">
      <Grid
        style={{ marginTop: 0 }}
        container
        spacing={1}
        sx={{
          width: '100%',
          backgroundColor: customerTheme?.pageHeader.background ?? 'white',
          boxShadow: '0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06)',
          py: { xs: theme.spacing(4), md: 0 },
          px: { xs: theme.spacing(2), md: 0 },
          ml: 0,
          color: theme.palette.primary.contrastText
        }}
      >
        <Grid item xs={3} sx={{ display: { xs: 'none', md: 'block' }, minHeight: '90px' }}>
          {customerThemeLogo && (
            <CardMedia
              component="img"
              image={customerThemeLogo}
              title="Logo"
              alt="Logo"
              sx={{
                height: '70px',
                width: '100px',
                objectFit: 'contain',
                padding: '15px',
              }}
            />
          )}
        </Grid>
        <Grid item xs={10} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
          {centerTitle && (
            <Box mx={2} textAlign="center" width="100%">
              <Typography variant="h6" fontWeight="bold" sx={{color: customerTheme.pageHeader.cart}}>
                {centerTitle}
              </Typography>
            </Box>
          )}
          {withSearchBar && (
            <form onSubmit={handleSearchSubmit} style={{ width: '100%' }}>
              <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%', color: customerTheme.pageHeader.cart}}>
                <StyledTextField
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  name="search"
                  type="text"
                  placeholder="Search"
                  variant="outlined"
                  sx={{
                    width: {
                      xs: 'auto',
                      md: '85%',
                    },
                    minWidth: {
                      xs: 0,
                      md: '400px',
                    },
                    borderColor: customerTheme.pageHeader.cart
                  }}
                  size="small"
                />
                <Button variant="contained" size="large" type="submit" disableElevation sx ={{backgroundColor: customerTheme.pageHeader.cart}}>
                  <Typography variant="body1" fontWeight="bold" sx={{color: theme.palette.secondary.contrastText}}>
                    Search
                  </Typography>
                </Button>
              </Stack>
            </form>
          )}
        </Grid>
        <Grid item xs={2} md={3}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              height: '100%',
              cursor: 'pointer',
            }}
            onClick={handleCartClick}
          >
            <IconButton sx={{ p: 2 }}>
              <Badge badgeContent={cartItems?.length ?? 0} sx={badgeStyle} ref={badgeRef}>
                <ShoppingCartOutlinedIcon
                  sx={{ color: customerTheme?.pageHeader.cart ?? 'text.primary' }}
                />
              </Badge>
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
