import OrdersForApprovalTable from '@/features/dashboard/get-started/orders-for-approval-table/OrdersForApprovalTable';
import { AppLayout } from '@/layouts/AppLayout';
import { Container } from '@mui/material';

export default function OrderManagement() {
  return (
    <AppLayout>
      <Container sx={{ flexGrow: 1 }} maxWidth={false}>
        <OrdersForApprovalTable />
      </Container>
    </AppLayout>
  );
}
