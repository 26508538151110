import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { Card, CardContent, Stack, styled, Typography, useTheme } from '@mui/material';
import { useCheckoutStep } from '../checkout-store';
import { CHECKOUT_STEPS } from '../constants/checkout-steps';

function Circle({ color }: { color: string }) {
  const Icon = styled(CircleOutlinedIcon)({ color });
  return <Icon />;
}

export function StepsOverview() {
  const { currentStep } = useCheckoutStep();
  const theme = useTheme();
  const activeColor = theme.palette.primary.main;
  const inactiveColor = '#D8D8D8';

  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          {CHECKOUT_STEPS.map((step, i) => {
            const isCurrentStep = currentStep === i;
            return (
              <Stack direction="row" spacing={1} key={i}>
                <Circle color={isCurrentStep ? activeColor : inactiveColor} />
                <Typography variant="h6" sx={{ color: isCurrentStep ? activeColor : null }}>
                  {step.title}
                </Typography>
              </Stack>
            );
          })}
          {/* <Divider sx={{ my: 2 }} /> */}
        </Stack>
      </CardContent>
    </Card>
  );
}
