import { TsuLoading } from '@/common/components';
import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { IPunchoutWaivenet, IPunchoutWaivenetVars } from '../../PunchoutInterface';

const WAIVENET_RGALIST_PUNCHOUT_MUTATION = gql`
  mutation ($destination: String!, $returnURL: String!) {
    punchoutRgaListWaivenet(input: { destination: $destination, returnURL: $returnURL }) {
      success
      link
    }
  }
`;

export function RgaListWaivenet() {
  const [punchoutRgaListWaivenetMutation, { data, loading }] = useMutation<
    IPunchoutWaivenet,
    IPunchoutWaivenetVars
  >(WAIVENET_RGALIST_PUNCHOUT_MUTATION, {
    variables: { destination: 'rgalist', returnURL: 'https://test.com.au' },
  });

  useEffect(() => {
    punchoutRgaListWaivenetMutation();
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      console.log('[WaivenetReport] Got MessageEvent:', event);
      console.log('got here?');
      if (event.origin === 'https://staging.waivenet.com.au') {
        try {
          console.log('[WaivenetReport] Event data:', event.data);
        } catch (err) {
          console.error(`[WaivenetReport] Error parsing message: ${event.data.toString()}`);
        }
      }
    };
    window.addEventListener('message', handleMessage, false);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
      <TsuLoading loading={loading}>
        <iframe
          id="punchout"
          // @ts-ignore
          src={data?.punchoutRgaListWaivenet.link}
          style={{ width: '100%' }}
          height={1080}
        ></iframe>
      </TsuLoading>
  );
}