import { TsuButton, TsuSelect } from '@/common/components';
import { AdvancedFilterEntry } from '@/common/interfaces';
import { setAdvancedFilters } from '@/store/reportSlice';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { conditionChoices } from '../constants';

// Initialize the draft advanced filter with a pre-added row
// of { column, condition, value } so that the user has a hint of what they can do
const initialDraftAdvancedFilter = [{ column: null, condition: null, value: '' }];

interface AdvancedFilterProps {
  headers: any[];
  onApplyFilters?: (advancedFilter: AdvancedFilterEntry[]) => void;
}

function AdvancedFilter({ headers, onApplyFilters }: AdvancedFilterProps) {
  const dispatch = useDispatch();
  // Draft Advanced Filter allows the user to modify the fields without
  // causing any re-renders or change detections
  const [draftAdvancedFilter, setDraftAdvancedFilter] = useState<AdvancedFilterEntry[]>(
    initialDraftAdvancedFilter
  );

  // Main handler for changes in the fields
  const handleAdvancedFilterChange = (idx: number, v: string, property: string) => {
    // Update only the entry at position (idx)
    const newAdvancedFilter = draftAdvancedFilter.map((entry, j) => {
      if (j !== idx) return entry;
      if (property === 'column') {
        const header = headers.find((h) => h.value === v)!;
        if (header.type === 'number' && entry.condition === 'contains') {
          entry.condition = 'eq';
        }
      }
      return { ...entry, [property]: v };
    });
    setDraftAdvancedFilter(newAdvancedFilter);
  };

  const addAdvancedFilterEntry = () => {
    const newAdvancedFilter = [
      ...draftAdvancedFilter,
      { column: null, condition: null, value: '' },
    ];
    setDraftAdvancedFilter(newAdvancedFilter);
  };

  const removeAdvancedFilterEntry = (idx: number) => {
    const newAdvancedFilter = draftAdvancedFilter.filter((e, j) => j !== idx);
    setDraftAdvancedFilter(newAdvancedFilter);
  };

  const handleApplyFilters = () => {
    dispatch(setAdvancedFilters(draftAdvancedFilter));
    onApplyFilters?.(draftAdvancedFilter);
  };

  const handleClearFiltersClick = () => {
    setDraftAdvancedFilter([]);
  };

  return (
    <Stack spacing={2} alignItems="start" sx={{ overflowX: 'auto', pt: 2 }}>
      <Stack spacing={2} sx={{ width: '100%' }} alignItems="stretch">
        {draftAdvancedFilter.map((row, i) => (
          <Stack direction="row" spacing={2} key={i}>
            <Box flex={1}>
              <TsuSelect
                value={row.column || ''}
                items={headers}
                onChange={(v) => handleAdvancedFilterChange(i, v, 'column')}
                itemText="text"
                itemValue="value"
                label="- Select Column -"
              />
            </Box>
            <Box flex={1}>
              <TsuSelect
                value={row.condition || ''}
                items={conditionChoices.filter((c) => {
                  if (row.column === null) return true;
                  const header = headers.find((h) => h.value === row.column)!;
                  if (header.type === 'number' && c.value === 'contains') return false;
                  return true;
                })}
                onChange={(v) => handleAdvancedFilterChange(i, v, 'condition')}
                itemText="text"
                itemValue="value"
                label="- Select Condition -"
              />
            </Box>
            <TextField
              value={row.value}
              onChange={(e) => handleAdvancedFilterChange(i, e.target.value, 'value')}
              placeholder="Value"
              sx={{ minWidth: 200 }}
            />
            <TsuButton text onClick={() => removeAdvancedFilterEntry(i)}>
              <DeleteOutlineIcon />
              <Typography>Remove</Typography>
            </TsuButton>
          </Stack>
        ))}
      </Stack>
      <TsuButton text onClick={addAdvancedFilterEntry} sx={{ alignSelf: 'start' }}>
        <AddCircleOutlineIcon sx={{ mr: 1 }} />
        <Typography>Add Condition</Typography>
      </TsuButton>
      <Grid container>
        <Grid item xs={12} md={3}>
          <Stack direction="row" flex={1}>
            <TsuButton onClick={handleApplyFilters} sx={{ flex: 1 }}>
              <Typography>Apply Filters</Typography>
            </TsuButton>
            <TsuButton onClick={handleClearFiltersClick} text sx={{ flex: 1 }}>
              <Typography>Clear All</Typography>
            </TsuButton>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default AdvancedFilter;
