import { ProductDetails } from '../../../common/interfaces';

export const defaultProductDetails = {
  // ==========================================
  // Column 1
  // ==========================================
  productName: '',
  productDescription: '',
  statusId: null,
  itemGroupId: null,
  // ==========================================
  // Column 2
  // ==========================================
  productCode: '',
  externalProductId: '',
  // These are checkboxes (flags)
  isHidden: false,
  isWBSOwnedStock: false,
  isEmbroidery: false,
  isScreenPrintItem: false,
  isOverPrintItem: false,
  isWBSJobItem: false,
  // Product Type (e.g. Normal Order, etc.)
  orderTypeId: null,
  // This points to a user (User ID)
  approverId: null,
  // ==========================================
  // Column 3
  // ==========================================
  crmSupplier: '',
  // This is in days
  supplierLeadTime: 0,
  crmSpecification: '',

  // ==========================================
  // Replenishment Details
  // ==========================================
  // Column 1
  reOrderLevel: 0,
  maxStockLevel: 0,
  // Column 2
  enableSerialNumberTracking: false,
  serialNumberBookSize: 0,

  // ==========================================
  // Order Details
  // ==========================================
  // Column 1
  gst: 0,
  processDays: 0,
  minQuantityPerOrder: 0,
  maxOrderQuantityLimit: 0,
  maxOrderQuantityLimitDays: 0,
  isSpecialDelivery: false,
  // Column 2
  // e.g. Text Box, Drop Down, Check Box
  quantityTypeId: null,
  maxQuantityPerOrder: 0,
  // "Free Freight when the Quantity Ordered is at least X"
  zeroFreightOrderQuantity: 0,
  forceRGA: false,
  sortOrder: 0,
} as ProductDetails;
