import { TsuDialog, TsuDialogContent } from '@/common/components';
import { OrderShippingItem } from './components/OrderShippingLine';
import { ProductProvider } from '@/features/product/product.context';
import { useSnackbar } from 'notistack';
import { IOrderShippingsForApproval } from '../../../../common/interfaces';
import { useOrderShippingApproval } from './use-order-shipping-approval';
import { Button, DialogActions, Divider, Stack, Typography } from '@mui/material';
import { ApproveDialog } from './ApproveDialog';
import { CancelDialog } from './CancelDialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';

interface EditOrderDialogProps {
  open: boolean;
  orderShipping: IOrderShippingsForApproval | null;
  onClose?: () => void;
  onEdit?: () => void;
  onCancel?: () => void;
  onApprove?: () => void;
}

export function EditOrderDialog(props: EditOrderDialogProps) {
  const { onClose, onApprove, onCancel, onEdit, open, orderShipping } = props;
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  // Toggle on and off the save button when a change is made
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [approveDisabled, setApproveDisabled] = useState(false);
  const [orderLines, setOrderLines] = useState(orderShipping?.orderLines ?? []);
  const { enqueueSnackbar } = useSnackbar();
  const { approveOSMut, editOrderLine, updateOrderShippingFee } = useOrderShippingApproval();
  const handleClose = () => onClose?.();
  // Reset function for when closing the dialog
  const reset = () => {
    if (orderShipping) {
      setOrderLines(orderShipping.orderLines);
    }
    setSaveDisabled(true);
    setApproveDisabled(false);
    onClose?.()
  }
  // When Click Approve Button
  async function handleApprove() {
    if (orderShipping === null) return;
    setOpenApproveDialog(false);
    setSaveDisabled(true);
    setApproveDisabled(false);
    handleClose();
    onApprove?.();
    enqueueSnackbar('Order has been approved', { variant: 'success' });
  }
  // Update the fee total of the order shipping
  async function handleFeeChange() {
    const totalUnitSell = updatedOrderLines.filter(ol => ol.active).reduce((total, item) => {
      const unitSell = item.productId ? item.unitSell ?? 0 : 0;
      return total + (unitSell * item.quantity);
    }, 0); 
    await updateOrderShippingFee({
      variables: {
        input: {
          orderShippingId: orderShipping?.orderShipId ?? 0,
          feeTotal: totalUnitSell
        }
      }
    });
  }
  // When click Save, it runs multiple UpdateOrderLineQuantity calls
  async function handleEdit() {
    if (orderLines === null) return;
  
    const mutationPromises = orderLines.map((orderLine) => {
      return editOrderLine({
        variables: {
          input: {
            active: orderLine.active,
            orderLineId: orderLine.orderLineId,
            quantity: orderLine.quantity
          }
        }
      });
    });
  
    await Promise.all(mutationPromises);
  
    setSaveDisabled(true);
    setApproveDisabled(false);
    handleClose();
    handleFeeChange();
    onEdit?.();
    enqueueSnackbar('Order has been updated', { variant: 'success' });
  }
  // When click Cancel button
  async function handleCancel() {
    if (orderShipping === null) return;
    setOpenCancelDialog(false);
    setSaveDisabled(true);
    setApproveDisabled(false);
    handleClose();
    onCancel?.();
    enqueueSnackbar('Order has been cancelled', { variant: 'success' });
  }
  const openApprove = useCallback(
    () => {
      setOpenApproveDialog(true);
    },
    [setOpenApproveDialog]
  );
  const openCancel = useCallback(
    () => {
      setOpenCancelDialog(true);
    },
    [setOpenCancelDialog]
  );
  // When click + or - Icon 
  const adjustItemQuantity = (orderLineId: number, new_quan: number) => {
    // Find the index of the item with the matching orderLineId
    const itemIndexToUpdate = orderLines.findIndex(item => item.orderLineId === orderLineId);

    if (itemIndexToUpdate !== -1) {
      // If a matching item is found, create a new array with the updated quantity
      const updatedItems = [...orderLines];
      updatedItems[itemIndexToUpdate] = {
        ...updatedItems[itemIndexToUpdate],
        quantity: new_quan
      };

      // Update the state with the new array containing the updated item
      setOrderLines(updatedItems);
      setSaveDisabled(false);
      setApproveDisabled(true);
    } else {
      console.log("Item not found in orderLines array.");
    }
  }
  // When enter the number directly
  const setItemQuantity = (orderLineId: number, quantity: number) => {
  
    // Find the index of the item with the matching orderLineId
    const itemIndexToUpdate = orderLines.findIndex(item => item.orderLineId === orderLineId);

    if (itemIndexToUpdate !== -1) {
      // If a matching item is found, create a new array with the updated quantity
      const updatedItems = [...orderLines];
      updatedItems[itemIndexToUpdate] = {
        ...updatedItems[itemIndexToUpdate],
        quantity: quantity
      };

      // Update the state with the new array containing the updated item
      setOrderLines(updatedItems);
      setSaveDisabled(false);
      setApproveDisabled(true);
    } else {
      console.log("Item not found in orderLines array.");
    }
  }
  // WHen clicking the Bin Icon
  const removeItem = (orderLineId: number) => {
    // Find the index of the item with the matching orderLineId
    const itemIndexToUpdate = orderLines.findIndex(item => item.orderLineId === orderLineId);

    if (itemIndexToUpdate !== -1) {
      // If a matching item is found, create a new array with the updated quantity
      const updatedItems = [...orderLines];
      updatedItems[itemIndexToUpdate] = {
        ...updatedItems[itemIndexToUpdate],
        active: false
      };

      // Update the state with the new array containing the updated item
      setOrderLines(updatedItems);
      setSaveDisabled(false);
      setApproveDisabled(true);
    } else {
      console.log("Item not found in orderLines array.");
    }
  }
  
  // Wait for the ordershipping data to finish fetching then set OrderLines array so that it can render correctly
  // Hence, it replaces reset function.
  useEffect(() => {
    if (orderShipping) {
      setOrderLines(orderShipping.orderLines);
    }
  }, [orderShipping]);

  const updatedOrderLines = useMemo(() => {
    return orderLines.map(item => {
      return {
        ...item,
        quantity: item.productId ? item.quantity : 0 // Initialize quantity to 0 for items without a productId
      };
    });
  }, [orderLines]);


  return (
    <TsuDialog
      open={open}
      loading={approveOSMut.loading}
      error={approveOSMut.error}
      title="Order Shipping Details"
      onClose={reset}
    >
      <TsuDialogContent>
        <Typography variant="h4">
          Order details
        </Typography>
        <Stack direction={'row'} spacing={25}>
          <Stack>
            <Typography>
              Order No.<br/><b>{orderShipping?.order.orderId}</b>
            </Typography>
            <Typography>
              Order date<br/><b>{(moment(orderShipping?.created)).format('DD/MMM/YYYY')}</b>
            </Typography>
          </Stack>
          <Stack>
            <Typography>
              Order Placed By<br/><b>{orderShipping?.billingCompanyName}</b>
            </Typography>
            <Typography>
              Order status<br/><b>{orderShipping?.status.name}</b>
            </Typography>
            {/* <Typography>
              Order Reference<br/><b>{orderShipping?.billingCompanyName}</b>
            </Typography> */}
          </Stack>
        </Stack>
        <Divider />
        <Typography variant="h4">
          Delivery details
        </Typography>
        <Stack direction={'row'} spacing={20}>
          <Stack>
            <Typography>
              Address<br/><b>{orderShipping?.deliveryStreet1},<br/>{orderShipping?.deliverySuburb}, {orderShipping?.deliveryState} {orderShipping?.deliveryPostcode}</b>
            </Typography>
            <Typography>
              Delivery Type<br/><b>{orderShipping?.deliveryMethod}</b>
            </Typography>
            <Typography>
              Shipping number<br/><b>{orderShipping?.orderShipId}</b>
            </Typography>
            {/* <Typography>
              <b>Company name: </b>{orderShipping?.deliveryCompanyName}
            </Typography>
            <Typography>
              <b>Delivery status: </b>{orderShipping?.status.name}
            </Typography>
            <Typography>
              <b>Min. dispatch date: </b>{(moment(orderShipping?.minimumDespatchDate)).format('DD-MMM-YYYY')}
            </Typography>
            <Typography>
              <b>Cost Center: </b>Brand Services Admin
            </Typography>
            <Typography>
              Mobile: {orderShipping?.deliveryMobile}
            </Typography> */}
          </Stack>
          <Stack>
            <Typography>
              Delivery To<br/><b>{orderShipping?.deliveryCompanyName}</b>
            </Typography>
            <Typography>
              Email<br/><b>{orderShipping?.deliveryEmail}</b>
            </Typography>
            {/* <Typography>
              Dispatch date: {(moment(orderShipping?.despatchedDate)).format('DD-MMM-YYYY')}
            </Typography> */}
            <Typography>
              Phone: {orderShipping?.deliveryPhone}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Typography variant="h4">
          Product details
        </Typography>
        {updatedOrderLines
          .filter((item) => item.feeLine == false && item.active)
          .map((item, i) => (
            <ProductProvider
              key={i}
              product={item.product!}
              eTemplateId={item.etemplateId ?? undefined}
            >
              <OrderShippingItem
                quantity={item.quantity}
                onRemove={() => item.productId && removeItem(item.orderLineId)}
                onQuantityChange={(q) => item.productId && setItemQuantity(item.orderLineId, q)}
                onIncrementClick={() => {
                  item.productId &&
                  item.productCode &&
                  adjustItemQuantity(item.orderLineId, item.quantity + 1);
                }}
                onDecrementClick={() =>
                  item.product?.productId !== undefined && adjustItemQuantity(item.orderLineId, item.quantity - 1)
                }
              />
            </ProductProvider>
          ))}
      </TsuDialogContent>
      <DialogActions>
        <Stack direction={'row'} spacing={5}>
            <Button
                onClick={openCancel}
                variant="contained"
                sx={{ backgroundColor: 'black' }}
            >
              Cancel Order
            </Button>
            <Button
              onClick={handleEdit}
              variant="contained"
              sx={{ backgroundColor: '#42B988'}}
              disabled={saveDisabled}
            >
              Save
            </Button>
            <Button
              onClick={openApprove}
              variant="contained"
              sx={{ backgroundColor: '#42B988'}}
              disabled={approveDisabled}
            >
              Approve
            </Button>
          </Stack>
      </DialogActions>
       <ApproveDialog
        open={openApproveDialog}
        onClose={() => setOpenApproveDialog(false)}
        onApprove={handleApprove}
        orderShipping={orderShipping}
      />

      <CancelDialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onCancel={handleCancel}
        orderShipping={orderShipping}
      />
    </TsuDialog>
  );
}
