import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { ProductUom } from '../../../graphql';
import { CreateProductUomPayload } from '../../../common/interfaces';
import { UomTabProps } from '../components/UomTab';
import { useCreateUom } from './useCreateUom';
import { useGetUoms } from './useGetUoms';
import { useRemoveUom } from './useRemoveUom';
import { useUpdateUom } from '../hooks/useUpdateUom';

export function useUomTab(props: UomTabProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { uoms, error, loading, refetch } = useGetUoms({
    productId: props.productId,
  });
  const { updateUom: updateUomMutation, loading: isUpdating } = useUpdateUom();
  const { createUom: createUomMutation, loading: isCreating } = useCreateUom();
  const { removeUom: removeUomMutation, loading: isRemoving } = useRemoveUom();
  const [selectedUom, setSelectedUom] = useState<ProductUom | null>(null);
  // This boolean state allows us to determine whether or not
  // the user wants to add a new UOM or edit an existing UOM.
  const [isAddMode, setIsAddMode] = useState(false);

  const headers = useMemo(
    () => [
      { text: 'Type', value: 'type' },
      { text: 'Stocking UOM', value: 'sellUom' },
      { text: 'Description', value: 'name' },
      { text: 'Multiplier', value: 'multiplier' },
      { text: 'Price Per Unit', value: 'sellPrice' },
      { text: 'Cost Per Unit', value: 'costPrice' },
      { text: 'Weight (kg)', value: 'weightKg' },
      { text: 'Dimensions (cm)', value: 'dimensionXcm' },
      { text: 'Actions', value: 'actions' },
    ],
    []
  );

  function selectUom(uom: ProductUom) {
    // Disable "add mode" before selecting UOM (in case it is enabled)
    setIsAddMode(false);
    setTimeout(() => setSelectedUom(uom), 250);
  }
  const clearSelectedUom = () => setSelectedUom(null);
  const enableAddMode = () => setIsAddMode(true);
  const disableAddMode = () => setIsAddMode(false);

  async function updateUom(input: CreateProductUomPayload) {
    if (selectedUom === null) return;
    const res = await updateUomMutation(selectedUom.uomid, input);
    enqueueSnackbar(res.data?.message ?? 'Updated Product UOM');
    clearSelectedUom();
    refetch();
  }

  async function createUom(input: CreateProductUomPayload) {
    if (!props.productId) return console.error('[Create UOM] Product ID is null'); // This should never happen
    const res = await createUomMutation(props.productId, input);
    enqueueSnackbar(res.data?.message ?? 'Created Product UOM');
    disableAddMode();
    refetch();
  }

  async function removeUom(uom: ProductUom) {
    await removeUomMutation(uom.uomid);
    enqueueSnackbar('Removed Product UOM');
    refetch();
  }

  return {
    // UOM data
    uoms,
    error,

    // State
    selectedUom,

    // Table headers
    headers,

    // Functions
    refresh: () => refetch(),
    selectUom,
    clearSelectedUom,
    enableAddMode,
    disableAddMode,
    updateUom,
    createUom,
    removeUom,

    // Flags
    isAddMode,
    isUpdating,
    isCreating,
    isRemoving,
    loading,
  };
}
