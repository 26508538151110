import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';

const sanitizeHtml = require('sanitize-html-react');

interface FaqCardProps {
  question: string;
  answer: string;
}

function FaqCard({ question, answer }: FaqCardProps) {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const [expanded, setExpanded] = useState(false);

  const makeHtml = useCallback(
    (html: string) => ({ __html: sanitizeHtml(html) }),
    []
  );

  return (
    <Card
      sx={{
        border: expanded ? `solid 1px ${primaryColor}` : 'none',
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => setExpanded(!expanded)}
          sx={{ mb: expanded ? 2 : 0, cursor: 'pointer' }}
        >
          <Typography
            variant="h6"
            sx={{ color: expanded ? primaryColor : null }}
          >
            {question}
          </Typography>
          {expanded ? (
            <ArrowCircleUpIcon fontSize="large" sx={{ color: primaryColor }} />
          ) : (
            <ArrowCircleDownIcon fontSize="large" />
          )}
        </Stack>
        {expanded && (
          <Typography variant="body1">
            <span dangerouslySetInnerHTML={makeHtml(answer)}></span>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default FaqCard;
