import { FeatureRoute } from '@/common/interfaces/feature-route';
import { Checkout } from './screens/Checkout';
import { TransactionFinished } from './screens/TransactionFinished';
import { TransactionFinishedEWay } from './screens/TransactionFinishedEWay';
import { TransactionCancelledEWay } from './screens/TransactionCancelledEWay';

export const checkoutRoutes: FeatureRoute[] = [
  {
    path: '/checkout',
    component: Checkout,
    options: {
      useCustomerTheme: true,
    },
  },
  {
    path: '/transactionFinished',
    component: TransactionFinished,
    options: {
      useCustomerTheme: true,
    },
  },
  {
    path: '/transactionFinishedEWay',
    component: TransactionFinishedEWay,
    options: {
      useCustomerTheme: true,
    },
  },
  {
    path: '/transactionCancelledEWay',
    component: TransactionCancelledEWay,
    options: {
      useCustomerTheme: true,
    },
  },
];
