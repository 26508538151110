import { useCurrentUser } from '@/common/auth/hooks/use-current-user';
import { DASHBOARD_ROUTE } from '@/features/dashboard/dashboard.routes';
import { useMemo } from 'react';

export function useFooterItems() {
  const { isAdmin, isRouteVisible, sitemaps } = useCurrentUser();

  return useMemo(() => {
    const items = [
      {
        text: 'Dashboard',
        to: DASHBOARD_ROUTE,
        legacyPagePaths: ['/userWelcomeScreen.aspx'],
      },
      {
        text: 'Shop',
        to: '/',
        legacyPagePaths: ['/orderV2.aspx', '/order.aspx'],
      },
      {
        text: 'Product Management',
        to: '/productManagement',
        legacyPagePaths: ['/product.aspx'],
      },
      {
        text: 'Order Management',
        to: '/orderManagement',
        legacyPagePaths: ['/order/orderswaitingapproval.aspx'],
      },
      // {
      //   text: 'Report',
      //   to: '/report',
      //   legacyPagePaths: ['/reportViewer.aspx'],
      // },
      {
        text: 'FAQs',
        to: '/faqs',
        legacyPagePaths: ['/FAQ.aspx'],
      },
      {
        text: 'Reports',
        to: '/wnreports',
        legacyPagePaths: ['/reportViewer.aspx'],
      },
    ];
    return (
      items
        .filter((i) => i.legacyPagePaths.some((p) => isRouteVisible(p)))
        // .sort((a, b) => {
        //   const orderA =
        //     sitemaps.find((s) => s.pagePath && a.legacyPagePaths.includes(s.pagePath))?.seqNo ?? 0;
        //   const orderB =
        //     sitemaps.find((s) => s.pagePath && b.legacyPagePaths.includes(s.pagePath))?.seqNo ?? 0;
        //   return orderA - orderB;
        // })
        // Remove isVisible from items (we don't need it anymore)
        .map((i) => ({ text: i.text, to: i.to }))
    );
  }, [isAdmin, isRouteVisible]);
}
