// import PageNotFoundImage from '@/assets/images/PageNotFound.png';
import { DASHBOARD_ROUTE } from '@/features/dashboard/dashboard.routes';
import { AppLayout } from '@/layouts/AppLayout';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ReportTwoToneIcon from '@mui/icons-material/ReportTwoTone'; 
import { useCustomerTheme } from '@/common/theme';

export default function PageNotFound() {
  const customerTheme = useCustomerTheme();
  return (
    <AppLayout>
      <Box display="flex" justifyContent="center">
        <Container sx={{ flexGrow: 1 }} maxWidth="lg">
          <Stack alignItems="center" spacing={3}>
            <ReportTwoToneIcon sx={{width: '100%', height: 200, color: customerTheme?.primary.main ?? 'text.primary' }}/>
            <Stack flex={1} alignItems="stretch" spacing={4}>
              <Stack sx={{ textAlign: 'center' }} spacing={2}>
                <Typography variant="h1" fontWeight="bold">
                  Oops! Page not found
                </Typography>
                <Typography variant="h6">
                  The page you're looking for has been removed or that another problem has occurred.
                </Typography>
              </Stack>
              <Button
                component={Link}
                to={DASHBOARD_ROUTE}
                variant="contained"
                sx={{ alignSelf: 'stretch', py: 2 }}
              >
                <Typography variant="h6">Go back to Home</Typography>
              </Button>
            </Stack>
            {/* <img
              src={PageNotFoundImage}
              alt="Page Not Found"
              style={{ flex: 1, height: 500, objectFit: 'contain' }}
            /> */}
          </Stack>
        </Container>
      </Box>
    </AppLayout>
  );
}
