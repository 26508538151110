import { TsuButton } from '@/common/components';
import { useShoppingCart } from '@/features/cart/shopping-cart.context';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router';
import { Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import { CartItemInterface, setCartItemBuffer } from '@/features/cart/components/CartInformationBuffer';
import { useGetUserCartQuery, useSubmitOrderWithoutEmailMutation, useUpdateOrderSavedMutation, useUpdateOrderShippingStatusMutation } from '@/graphql';
import { useEffect, useState } from 'react';
import { useCheckoutStore } from '../checkout-store';

interface CancelOrSaveOrderProp {
  saveOrderDisabled: boolean;
}

export function CancelOrSaveOrder(props: CancelOrSaveOrderProp) {
  const [updateOrderSave, updateOrderSaveMut] = useUpdateOrderSavedMutation();
  const client = useApolloClient();
  const shoppingCart = useShoppingCart();
  const isUpdatingCart = useCheckoutStore((s) => s.isUpdatingCart);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { data: userCartQuery, refetch } = useGetUserCartQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  });
  const [submitOrderNoEmailMutate, { error }] = useSubmitOrderWithoutEmailMutation();
  const [updateOSstatusMutation, {error: updateOSstatusMutationError}] = useUpdateOrderShippingStatusMutation();
  const [orderIdVar, setOrderIdVar] = useState<number>(0);
  const [orderShipIdVar, setOrderShipIdVar] = useState<number>(0);

  // Derived state
  const cart = userCartQuery?.userCart;
  useEffect(() => {
    if(!cart && !isUpdatingCart) {
      refetch();
    }  
  }, [isUpdatingCart]);

  async function CancelOrderClick() {
    try {
      shoppingCart.removeAllItems();
      // Refresh the user's cart (should be NULL as it is cleared during submitOrder mutation)
      client.refetchQueries({ include: ['userCart'] });
      // Navigate to shop page
      navigate("/");
      enqueueSnackbar("Order has been cancelled successfully.", { variant: 'success' });
    } catch (err) {
      console.log('error:', err);
      enqueueSnackbar((err as any).message, { variant: 'error' });
    }
  }

  async function handleSaveOrderClick() 
  {
    try 
    {
      // Do not proceed without user cart
      if (!userCartQuery?.userCart?.id){
        console.log("No user cart ID");
        return;
      }
      // Trigger order mutation
      const res = await submitOrderNoEmailMutate({ variables: { cartId: userCartQuery?.userCart?.id } });
      // Copy Cart Information to the buffer
      let cartItems: CartItemInterface[] = [];
      for (var item of shoppingCart.cartItems){
        cartItems.push(
          {
            name: item.product?.name.toString(),
            quantity: item.quantity,
            price: item.product?.prices.find((p) => p.quantity === 1)?.price
          }
        );
      }
      setCartItemBuffer(cartItems);
      // Check for problems before proceeding
      if (!res && error) return;
      // Refresh the user's cart (should be NULL as it is cleared during submitOrder mutation)
      client.refetchQueries({ include: ['userCart'] });
       // Compute orderId for route query
      const newOrderIdVar = res.data?.submitOrderWithoutEmail.orderReference?.split('~').shift() ?? '';
      const newOSId = res.data?.submitOrderWithoutEmail.orderReference?.split('~').pop() ?? '';
      // Put the new OS in Placing status
      if (newOSId) {
        const update_res = await updateOSstatusMutation({variables: {
          input: {
            orderShippingId: Number(newOSId),
            statusId: 26
          }
        }});
        setOrderShipIdVar(Number(newOSId));
      }
      if (newOrderIdVar)
      {
        setOrderIdVar(Number(newOrderIdVar));
      }
      await updateOrderSave({
        variables: {
          input: {
            orderId: Number(newOrderIdVar),
            saved: true
          }
        }
      });
      shoppingCart.removeAllItems();
      // Refresh the user's cart (should be NULL as it is cleared during submitOrder mutation)
      client.refetchQueries({ include: ['userCart'] });
      navigate("/");
      enqueueSnackbar("Order has been saved for future load.", { variant: 'success' });
    } 
    catch (err)
    {
      console.log('error:', err);
      enqueueSnackbar((err as any).message, { variant: 'error' });
    }
  }

  return(
    <Stack direction="row" spacing={2}>
      <TsuButton onClick={CancelOrderClick} sx={{ height: '44px' }}>Cancel Order</TsuButton>
      <TsuButton disabled={props.saveOrderDisabled} onClick={handleSaveOrderClick} sx={{ height: '44px' }}>Save Order</TsuButton>
    </Stack>
  );
}

