import { Checkbox, CheckboxProps } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

const CheckBox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  '& .MuiCheckbox-root': {},
  '&.Mui-checked': {
    color: '#e2066f',
  },

}));

const VACCCheckBox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  '& .MuiCheckbox-root': {},
  '&.Mui-checked': {
    color: '#f5821f',
  },

}));

export function StyledCheckBox(props: CheckboxProps) {
  const theme = useTheme();
  return <CheckBox sx={{ color: '#e2066f' }} {...props} />;
}
export function VACCStyledCheckBox(props: CheckboxProps) {
  const theme = useTheme();
  return <VACCCheckBox sx={{ color: '#f5821f' }} {...props} />;
}
