// import SetNewPasswordLogo from '@/assets/images/SetNewPasswordLogo.png';
import { Copyright, getPasswordValue, LoginHeader, PasswordField } from '@/common/components';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  FormGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetPasswordMutation } from '../../../graphql';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone'; 
import { useCustomerTheme } from '@/common/theme';

export default function SetNewPassword() {
  const customerTheme = useCustomerTheme();
  const [disableSubmit, setDisableSumit] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const [resetPassword] = useResetPasswordMutation();
  const [loading, setLoading] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const password = getPasswordValue();
    const resetToken = urlParams.get('token');
    // console.log(resetToken);
    if (resetToken) {
      setLoading(true);
      try {
        await resetPassword({
          variables: {
            resetToken,
            password,
          },
        });
        navigate('/signin');
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };
  const handlePasswordValidation = (isValid) => {
    setDisableSumit(!isValid);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: '100vh',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Stack direction="column" alignItems="center" justifyContent="space-between" spacing={3}>
        <LoginHeader />
        <Card elevation={20} sx={{ padding: theme.spacing(3), minWidth: '300px' }}>
          <Stack direction="column" alignItems="center" justifyContent="space-between" spacing={1}>
            <Box m={10} maxWidth="60%" margin="10px">
              {/* <img src={SetNewPasswordLogo} alt="Logo" width="100%" height="100%" /> */}
              <LockTwoToneIcon sx={{width: '100%', height: '100%', color: customerTheme?.primary.main ?? 'text.primary'}}/>
            </Box>
            <CardHeader
              sx={{ marginTop: theme.spacing(1) }}
              title="New Password"
              titleTypographyProps={{ variant: 'h2', align: 'left' }}
            />
            <CardContent sx={{ paddingTop: theme.spacing(0) }}>
              <Box hidden={!loading}>
                <CircularProgress />
              </Box>
              <Box
                textAlign="left"
                component="form"
                autoComplete="off"
                onSubmit={handleSubmit}
                hidden={loading}
              >
                <FormGroup>
                  <Typography variant="caption" fontWeight="normal" mb={1}>
                    Enter new Password
                  </Typography>
                  <PasswordField onPasswordValidation={handlePasswordValidation}/>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large"
                    sx={{ mt: theme.spacing(4.5), textTransform: 'none' }}
                    disabled = {disableSubmit}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Change Password and Log In
                    </Typography>
                  </Button>
                </FormGroup>
              </Box>
            </CardContent>
          </Stack>
        </Card>
        <Copyright />
      </Stack>
    </Container>
  );
}
