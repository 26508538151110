import { TsuField, TsuPills, TsuStepNumber } from '@/common/components';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';

const frequencyChoices = [
  { text: 'Weekly', value: 'weekly' },
  { text: 'Monthly', value: 'monthly' },
  { text: 'Yearly', value: 'yearly' },
];

const dayChoices = [
  { text: 'Sun', value: 'sun', order: 1 },
  { text: 'Mon', value: 'mon', order: 2 },
  { text: 'Tue', value: 'tue', order: 3 },
  { text: 'Wed', value: 'wed', order: 4 },
  { text: 'Thu', value: 'thu', order: 5 },
  { text: 'Fri', value: 'fri', order: 6 },
  { text: 'Sat', value: 'sat', order: 7 },
];

export interface IFrequency {
  frequency: string;
  startDate: Date | null;
  selectedDays: string[];
  frequencyEndType: string;
  numOccurences: number;
  endDate: Date | null;
}

interface FrequencyProps {
  onChange?: (frequency: IFrequency) => void;
}

function Frequency({ onChange }: FrequencyProps) {
  const [frequency, setFrequency] = useState<IFrequency>(() => ({
    frequency: '',
    startDate: new Date(),
    selectedDays: ['mon', 'wed', 'fri'],
    frequencyEndType: 'never',
    // This variable will only be relevant if frequency end type is "after-x-occurences"
    numOccurences: 2,
    endDate: new Date(),
  }));

  useEffect(() => onChange?.(frequency), [onChange, frequency]);

  const handleDayClick = (v: string) => {
    if (frequency.selectedDays.includes(v)) {
      setFrequency({
        ...frequency,
        selectedDays: frequency.selectedDays.filter((d) => d !== v),
      });
    } else {
      setFrequency({
        ...frequency,
        selectedDays: [...frequency.selectedDays, v],
      });
    }
  };

  const selectedDaysStr = frequency.selectedDays
    .map((d) => dayChoices.find((c) => d === c.value)!)
    .sort((d1, d2) => d1.order - d2.order)
    .map((d) => d.text)
    .join(', ');

  return (
    <Stack spacing={2}>
      <Typography variant="h5" display="flex" alignItems="center">
        <TsuStepNumber step={3} sx={{ mr: 1 }} />
        Frequency
      </Typography>
      <TsuPills
        label="Frequency"
        value={frequency}
        items={frequencyChoices}
        onChange={setFrequency}
        stretchItems
      />

      <Stack spacing={1}>
        <Typography variant="body1">Start Date</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack direction="row" spacing={2}>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              value={frequency.startDate}
              onChange={(d) => setFrequency({ ...frequency, startDate: d })}
              renderInput={(params) => <TextField {...params} />}
            />
            <TimePicker
              value={frequency.startDate}
              onChange={(d) => setFrequency({ ...frequency, startDate: d })}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </Stack>

      <TsuPills
        label="Select Days"
        value={frequency.selectedDays}
        items={dayChoices}
        onChange={handleDayClick}
        stretchItems
      />

      <Stack spacing={1}>
        <Typography variant="h6">End</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            name="row-radio-buttons-group"
            value={frequency.frequencyEndType}
            onChange={(e) => setFrequency({ ...frequency, frequencyEndType: e.target.value })}
          >
            <FormControlLabel value="never" control={<Radio />} label="Never" />
            <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
              <FormControlLabel value="after-x-occurences" control={<Radio />} label="After" />
              <TsuField
                value={frequency.numOccurences.toString()}
                onChange={(v) => setFrequency({ ...frequency, numOccurences: parseInt(v) })}
              />
              <Typography>occurrence(s)</Typography>
            </Stack>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                <FormControlLabel value="on-date" control={<Radio />} label="On" />
                <DesktopDatePicker
                  inputFormat="dd/MM/yyyy"
                  value={frequency.endDate}
                  onChange={(d) => setFrequency({ ...frequency, endDate: d })}
                  renderInput={(params) => <TextField {...params} />}
                />
                <TimePicker
                  value={frequency.endDate}
                  onChange={(d) => setFrequency({ ...frequency, endDate: d })}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </RadioGroup>
        </FormControl>
        <Typography variant="body1">
          Send Report every {selectedDaysStr} starting 15/10/2021 until 30/10/2021
        </Typography>
      </Stack>
    </Stack>
  );
}

export default Frequency;
