import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import {
  CrossUpSaleItem,
  GetCrossUpSalesRes,
  GetProductsUnderCategoryRes,
} from '../../../common/interfaces';
// import { CategoryContext } from '../../shop/screens/Shop';

interface Props {
  productId: number | null;
}

export function useCrossUpSales({ productId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  // ================================================================
  // Cross-sale / Up-sale state
  // ================================================================

  const [items, setItems] = useState<CrossUpSaleItem[]>([]);
  const crossSaleItems = items.filter((i) => i.saleType === 'cross');
  const upSaleItems = items.filter((i) => i.saleType === 'up');

  const headers = [
    {
      text: 'Product',
      value: 'productTitle',
    },
    {
      text: 'Remove',
      value: 'remove',
    },
  ];

  // ================================================================
  // Get Existing Cross-sale / Up-sales query
  // ================================================================

  const [getCrossUpSales, gcups] = useLazyQuery<GetCrossUpSalesRes>(
    gql`
      query ($productId: Int!) {
        crossUpSales(productId: $productId) {
          productId
          productTitle
          saleType
        }
      }
    `
  );

  // Ensure productId is not null before triggering Get Cross/Up Sales Query
  useEffect(() => {
    productId !== null && getCrossUpSales({ variables: { productId } });
  }, [productId, getCrossUpSales]);

  // Wait for Get Cross/Up Sales Query to load,
  // then populate our items state
  useEffect(() => {
    gcups.data && setItems(gcups.data.crossUpSales);
  }, [gcups.data]);

  // ================================================================
  // Update Cross-sale / Up-sales mutation
  // ================================================================

  const [mutate, ucups] = useMutation<
    { updateCrossUpSales: { message: string } },
    { productId: number; items: CrossUpSaleItem[] }
  >(gql`
    mutation ($productId: Int!, $items: [CrossUpSaleItemInput!]!) {
      updateCrossUpSales(payload: { productId: $productId, items: $items }) {
        message
      }
    }
  `);

  async function updateCrossUpSales() {
    if (productId === null) return;
    try {
      // Update Cross/Up Sales
      const res = await mutate({ variables: { productId, items } });
      // Show feedback
      enqueueSnackbar(res.data?.updateCrossUpSales.message ?? 'Updated Cross/Up Sales', {
        variant: 'success',
      });
      // Invalidate / refresh Get Existing Cross/Up Sales Query
      gcups.refetch();
    } catch (err) {
      console.error(err);
      enqueueSnackbar((err as any).toString(), { variant: 'error' });
    }
  }

  // ================================================================
  // Category query & state
  // ================================================================

  // const { categories, loading: isLoadingCategories } = useCategories();
  // This state doesn't feel like it belongs to useCrossUpSales so let's put it here
  // const [catChildComponents, setCatChildComponents] = useState<{
  //   [categoryId: string]: React.ReactNode;
  // }>({});

  // ================================================================
  // CRUD Functions
  // ================================================================

  const addCrossSaleItem = (productId: number, productTitle: string) => {
    if (crossSaleItems.every((i) => i.productId !== productId)) {
      setItems([...items, { productId, productTitle, saleType: 'cross' }]);
    }
  };

  function removeCrossSaleItem(productId: number) {
    const indexToExclude = items.findIndex(
      (i) => i.productId === productId && i.saleType === 'cross'
    );
    if (indexToExclude !== undefined) setItems(items.filter((_, j) => indexToExclude !== j));
  }

  function addUpSaleItem(productId: number, productTitle: string) {
    if (upSaleItems.every((i) => i.productId !== productId)) {
      setItems([...items, { productId, productTitle, saleType: 'up' }]);
    }
  }

  function removeUpSaleItem(productId: number) {
    const indexToExclude = items.findIndex((i) => i.productId === productId && i.saleType === 'up');
    if (indexToExclude !== undefined) setItems(items.filter((_, j) => indexToExclude !== j));
  }

  async function loadProductsUnderCategory(categoryId: number) {
    try {
      const res = await client.query<GetProductsUnderCategoryRes>({
        query: gql`
          query ($categoryId: Int!) {
            productsUnderCategory(categoryId: $categoryId) {
              productId
              productCode
              name
            }
          }
        `,
        variables: { categoryId },
      });
      // setCatChildComponents({
      //   ...catChildComponents,
      //   [categoryId.toString()]: <CategoryProducts products={res.data.productsUnderCategory} />,
      // });
    } catch (err) {
      console.error(err);
      enqueueSnackbar({ message: (err as any).toString() });
    }
  }

  return {
    // State
    items,
    crossSaleItems,
    upSaleItems,
    // categories,
    // catChildComponents,

    // Other variables
    headers,

    // Functions
    addCrossSaleItem,
    removeCrossSaleItem,
    addUpSaleItem,
    removeUpSaleItem,
    loadProductsUnderCategory,
    updateCrossUpSales,

    // Flags
    isSaving: ucups.loading,
    // isLoadingCategories,
  };
}

interface CategoryProductsProps {
  products: {
    productId: number;
    productCode: string;
    name: string;
  }[];
}

/*
function CategoryProducts({ products }: CategoryProductsProps) {
  const context = useContext(CategoryContext);
  return (
    <>
      {products.map((p, i) => {
        const productTitle = p.productCode + ': ' + p.name;
        return (
          <Grid container key={i}>
            <Grid item xs={6}>
              <ListItemButton>
                <ListItemText primary={productTitle} />
              </ListItemButton>
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: '100%', height: '100%' }}
                spacing={1}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    context.onCategoryCustomEvent?.('cross-sale-click', {
                      product: p.productId,
                      productTitle,
                    })
                  }
                >
                  <Typography variant="caption">Cross Sale</Typography>
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    context.onCategoryCustomEvent?.('up-sale-click', {
                      product: p.productId,
                      productTitle,
                    })
                  }
                >
                  <Typography variant="caption">Up Sale</Typography>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}
*/
