import { gql, useMutation } from '@apollo/client';
import { Role } from '../interfaces';
import { UpdateRoleInput } from '../interfaces/update-role.interfaces';

interface UpdateRoleDetailsInput {
  roleId: number;
  role: UpdateRoleInput;
  selectedUserActionIds: number[];
  selectedOrderTypeIds: number[];
  selectedFreightSettingIds: number[];
}

export function useRoleApi() {
  const [mutate, updateRoleMutation] = useMutation<
    { role: Role },
    { input: UpdateRoleDetailsInput }
  >(
    gql`
      mutation ($input: UpdateRoleDetailsInput!) {
        updateRole(input: $input) {
          roleId
          name
        }
      }
    `
  );

  return {
    updateRole: (input: UpdateRoleDetailsInput) => mutate({ variables: { input } }),
    updateRoleError: updateRoleMutation.error,
    isUpdatingRole: updateRoleMutation.loading,
  };
}
