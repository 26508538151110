import { StoreEntry } from '../interfaces/store';

type TextEntry = {
  id: string;
  text: string;
  label: string;
  wnOptionName?: string;
  resolver?: (store?: StoreEntry) => string;
};

// Some E-Template products have WRONG image paths,
// so we need to override & correct some of them.
export const customerImageOverrides: Record<string, string[]> = {
  PSGRMENU: ['assets/images/grooming_page1_blank.png', 'assets/images/grooming_page1.png'],
  PSVETTMP009: ['assets/images/vet-vaccination.jpg'],
};

export const possibleWnOptions: TextEntry[] = [
  { id: 'store', text: '', label: 'Store Name', wnOptionName: 'storeOption', resolver: (store) => store?.ID ?? '' },
  // PETStock
  {
    id: 'address',
    text: '',
    label: 'Address',
    wnOptionName: 'addressOption',
    resolver: (store) => (store ? store.AddressLine1 + '\n' + store.AddressLine2 : ''),
  },
  // AFL
  { id: 'address', text: '', label: 'Address', wnOptionName: 'addressOptions', resolver: () => '' },
  {
    id: 'web',
    text: 'www.petstock.com.au',
    label: 'Web Address',
    wnOptionName: 'webAddressOption',
    resolver: (store) => store?.web ?? '',
  },
  { id: 'abn', text: 'ABN Not Available', label: "ABN", wnOptionName: 'abnOption', resolver: (store) => store?.ABN ?? '' },
  { id: 'phone', text: '', label: "Phone No.", wnOptionName: 'phoneOption', resolver: (store) => store?.Phone ?? '' },
  { id: 'mobile', text: '', label: "Mobile No", wnOptionName: 'mobileOption', resolver: (store) => store?.Phone ?? '' },
  { id: 'email', text: '', label: "Email", wnOptionName: 'emailOption', resolver: (store) => store?.Email ?? '' },
  // HostPlus
  { id: 'emailTxt', text: '', label: "Email", wnOptionName: 'emailTxtOption', resolver: (store) => store?.Email ?? ''},
  { id: 'slogan', text: '', label: "Slogan", wnOptionName: 'sloganOption', resolver: () => '' },
  { id: 'services', text: '', label: "Services", wnOptionName: 'servicesOptions', resolver: () => '' },
  // Jenny Craig
  { id: 'name', text: '', label: "Name", wnOptionName: 'nameOption', resolver: () => '' },
  { id: 'title', text: '', label: "Title", wnOptionName: 'titleOption', resolver: () => '' },
  // HostPlus
  { id: 'qualification', text: '', label: "Qualification", wnOptionName: 'qualificationOption', resolver: () => '' },
  { id: 'position', text: '', label: "Position", wnOptionName: 'positionOption', resolver: () => '' },
  { id: 'department', text: '', label: "Department", wnOptionName: 'departmentOption', resolver: () => '' },
  { id: 'arnumber', text: '', label: "AR Number", wnOptionName: 'arnumberOption', resolver: () => '' },
  { id: 'valid', text: '', label: "Valid Date", wnOptionName: 'validOption', resolver: () => '' },
  { id: 'validdate', text: '', label: "Valid Date", wnOptionName: 'validOption', resolver: () => '' },
  { id: 'offerText', text: '', label: "Offer Text", wnOptionName: 'offerText', resolver: () => '' },
  { id: 'web-address', text: '', label: "Wed Address", wnOptionName: 'webOption', resolver: () => '' },
  { id: 'companyName', text: '', label: "Company Name", wnOptionName: 'companyNameOption', resolver: () => '' },
  { id: 'startNumber', text: '', label: "Start Number", wnOptionName: 'startNumberOption', resolver: () => '' },
  { id: 'companyAddress', text: '', label: "Company Address", wnOptionName: 'companyAddressOption', resolver: () => '' },
  { id: 'licenceHolder', text: '', label: "Licence Holder", wnOptionName: 'licenceHolderOption', resolver: () => '' },
  { id: 'tradingNumber', text: '', label: "Trading Number", wnOptionName: 'tradingNumberOption', resolver: () => '' },
  { id: 'depotAddress', text: '', label: "Depot Address", wnOptionName: 'depotAddressOption', resolver: () => '' },
  { id: 'depotNumber', text: '', label: "Depot Number", wnOptionName: 'depotNumberOption', resolver: () => '' },
  { id: 'phone2', text: '', label: "Phone 2", wnOptionName: 'phone2Option', resolver: () => '' },
  { id: 'fax', text: '', label: "Fax", wnOptionName: 'faxOption', resolver: () => '' },
];
