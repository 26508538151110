import { FeatureRoute } from '@/common/interfaces/feature-route';
import Dashboard from './screens/Dashboard';

export const DASHBOARD_ROUTE = '/dashboard';

export const dashboardRoutes: FeatureRoute[] = [
  {
    path: DASHBOARD_ROUTE,
    component: Dashboard,
    options: {
      useCustomerTheme: true,
      requiresAdmin: true
    },
  },
];
