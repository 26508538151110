import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import { useEffect, useState } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';

type Props = {
  readOnly?: boolean;
  onChange?: (placeId: string) => void;
};

export function AddressAutocomplete(props: Props) {
  const {
    init,
    setValue,
    suggestions: { status, data, loading: isSuggestionsLoading },
  } = usePlacesAutocomplete({
    requestOptions: {
      // Source: https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#ComponentRestrictions
      componentRestrictions: { country: 'au' },
      cache: 24 * 60 * 60,
    },
    debounce: 500,
    initOnMount: false,
  });
  const [place, setPlace] = useState<any>(null);

  useEffect(() => {
    const googleMapsSrc =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDL72PTAwO3Rd7x6ps_Lt9FAtCI1VP5LMY&libraries=places&callback=initPlacesApi';
    const html = document.querySelector('html');
    if (!html) return;
    if (html.querySelector(`script[src='${googleMapsSrc}']`)) return;
    (window as any).initPlacesApi = init;
    const script = document.createElement('script');
    script.src = googleMapsSrc;
    script.async = true;
    script.defer = true;
    html.appendChild(script);
    return () => {
      html.removeChild(script);
      // (window as any).initPlacesApi = null;
    };
  }, []);

  function onPlaceSelected(place: any) {
    setPlace(place);
    props.onChange?.(place.place_id);
  }

  return (
    <Autocomplete
      id="addressAutocomplete"
      style={{ display: props.readOnly ? 'none' : 'block' }}
      value={place}
      autoComplete
      options={data}
      onChange={(_, place: any | null) => onPlaceSelected(place)}
      onInputChange={(_, v) => setValue(v)}
      loading={isSuggestionsLoading}
      renderInput={(params) => <TextField {...params} name="address" label="Address Look Up" fullWidth />}
      renderOption={(props, option) => {
        // Source: https://mui.com/material-ui/react-autocomplete/#google-maps-place
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props} className="address-autocomplete-option">
            <Grid container alignItems="center">
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
    />
  );
}
