import { TsuDialog, TsuDialogContent, TsuField, TsuSelect } from '@/common/components';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useProductStatusesQuery } from '../../../graphql';
import { useAddProduct } from './AddProductDialog.hook';

interface Props {
  open: boolean;
  onClose?: () => void;
}

type InputEvent = React.ChangeEvent<HTMLInputElement>;

export function AddProductDialog({ open, onClose }: Props) {
  const ap = useAddProduct();
  const { state: pd, dispatch } = ap;
  const { data: productStatusesQuery, loading: isProductStatusesLoading } =
    useProductStatusesQuery();
  const productStatusChoices = productStatusesQuery?.productStatuses ?? [];

  function handleClose() {
    // Before handling close, reset state & validation
    ap.resetValidator();
    onClose?.();
  }

  // Curried functions for state change events (makes template code below cleaner)
  const onTextChange = (key: string) => (value: string) => dispatch(key, value);
  const onIntChange = (key: string) => (value: string) => dispatch(key, parseInt(value));
  const onFloatChange = (key: string) => (value: string) => dispatch(key, parseFloat(value));
  const onBoolChange = (key: string) => (e: InputEvent) => dispatch(key, e.target.checked);
  const onInputIntChange = (key: string) => (e: InputEvent) =>
    dispatch(key, parseInt(e.target.value));

  return (
    <TsuDialog
      title="Add Product"
      submitText="Submit"
      open={open}
      loading={ap.isCreating}
      error={ap.createError}
      onClose={handleClose}
      onSubmit={ap.createProduct}
    >
      <TsuDialogContent>
        <Stack spacing={1}>
          <Card>
            <CardHeader title="Basic Details" />
            <CardContent>
              <Grid container spacing={4} sx={{ width: '100%' }}>
                <Grid item xs={6}>
                  <Stack spacing={2}>
                    <TsuField
                      label="Product Name"
                      value={pd.productName}
                      onChange={onTextChange('productName')}
                    />
                    <TsuField
                      label="Product Description"
                      value={pd.productDescription}
                      onChange={onTextChange('productDescription')}
                      multiline
                      rows={5}
                    />
                    <TsuSelect
                      value={pd.statusId?.toString() ?? ''}
                      items={productStatusChoices}
                      loading={isProductStatusesLoading}
                      onChange={onTextChange('statusId')}
                      itemText="name"
                      itemValue="statusId"
                      label="- Select -"
                      title="Product Status"
                    />
                    <TsuSelect
                      value={pd.itemGroupId?.toString() ?? ''}
                      items={ap.itemGroupChoices}
                      loading={ap.isItemGroupsLoading}
                      onChange={onTextChange('itemGroupId')}
                      itemText="name"
                      itemValue="productItemGroupId"
                      label="- Select -"
                      title="Item Group"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={2}>
                    <TsuField
                      label="Product ID"
                      value={pd.productCode}
                      onChange={onTextChange('productCode')}
                    />
                    <TsuField
                      label="External Product ID"
                      value={pd.externalProductId ?? ''}
                      onChange={onTextChange('externalProductId')}
                      multiline
                    />
                    <TsuSelect
                      value={pd.productTypeId?.toString() ?? ''}
                      items={ap.productTypeChoices}
                      loading={ap.isProductTypesLoading}
                      onChange={onTextChange('productTypeId')}
                      itemText="name"
                      itemValue="orderTypeId"
                      label="- Select -"
                      title="Product Type"
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Replenishment Details" />
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TsuField
                    label="Re-Stock Level"
                    value={pd.reOrderLevel?.toString() ?? ''}
                    onChange={onIntChange('reOrderLevel')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TsuField
                    label="Maximum Stock Level"
                    value={pd.maxStockLevel.toString()}
                    onChange={onIntChange('maxStockLevel')}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card>
            <CardHeader title="Order Details" />
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Stack spacing={2}>
                    <TsuField
                      label="GST (e.g. for 10% enter 0.10)"
                      value={pd.gst.toString()}
                      onChange={onFloatChange('gst')}
                    />
                    <TsuField
                      label="Process Days"
                      value={pd.processDays.toString()}
                      onChange={onIntChange('processDays')}
                    />
                    <TsuField
                      label="Minimum Quantity Per Order"
                      value={pd.minQuantityPerOrder?.toString() ?? ''}
                      onChange={onIntChange('minQuantityPerOrder')}
                    />
                    <Box>
                      Maximum Order Quantity Limit is{' '}
                      <input
                        value={pd.maxOrderQuantityLimit?.toString() ?? ''}
                        onChange={onInputIntChange('maxOrderQuantityLimit')}
                      />
                      &nbsp; per &nbsp;
                      <input
                        value={pd.maxOrderQuantityLimitDays?.toString() ?? ''}
                        onChange={onInputIntChange('maxOrderQuantityLimitDays')}
                      />
                      &nbsp; day(s)
                    </Box>
                    <TsuField
                      label="Product Unit of Measure"
                      value={pd.unitOfMeasure}
                      onChange={onTextChange('unitOfMeasure')}
                    />
                    <TsuField
                      label="Zero Freight Minimum Order Quantity"
                      value={pd.zeroFreightOrderQuantity?.toString() ?? ''}
                      onChange={onIntChange('zeroFreightOrderQuantity')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={pd.isSpecialDelivery}
                          onChange={onBoolChange('isSpecialDelivery')}
                        />
                      }
                      label="Is this product a special delivery product?"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={2}>
                    <TsuSelect
                      value={pd.quantityTypeId?.toString() ?? ''}
                      items={ap.quantityTypeChoices}
                      onChange={onIntChange('quantityTypeId')}
                      itemText="name"
                      itemValue="quantityTypeId"
                      label="- Select -"
                      title="Quantity Type"
                    />
                    <TsuField
                      label="Maximum Quantity Per Order"
                      value={pd.maxQuantityPerOrder?.toString() ?? ''}
                      onChange={onIntChange('maxQuantityPerOrder')}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {ap.validator.enabled &&
            ap.validator.messages.map((m, i) => (
              <Alert key={i} severity="warning" sx={{ alignItems: 'center' }}>
                <Typography>{m}</Typography>
              </Alert>
            ))}
        </Stack>
      </TsuDialogContent>
    </TsuDialog>
  );
}
