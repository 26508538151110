import { TsuLoading } from '@/common/components';
import ShopItem from '@/features/shop/components/ShopItem';
import { AppLayout } from '@/layouts/AppLayout';
import { Container, Grid } from '@mui/material';
import { PropsWithChildren } from 'react';
import { ProductType } from '../../../graphql';
import { ProductProvider } from '../../product/product.context';
import { FavoritesProvider, useFavorites } from '../favorites.context';

export function Favorites() {
  return (
    <FavoritesProvider>
      <_Favorites />
    </FavoritesProvider>
  );
}

export function _Favorites() {
  const { favorites, isLoading } = useFavorites();

  const handleShopItemClick = (item: ProductType) => {
    // setSelectedProduct(item);
    // setShopItemDialogOpen(true);
  };

  return (
    <AppLayout withSearchBar>
      <Container sx={{ flexGrow: 1 }} maxWidth={false}>
        <TsuLoading loading={isLoading}>
          <Grid container spacing={0}>
            {favorites.map((entry, i) => (
              <CustomGrid key={i}>
                {entry.product && (
                  <ProductProvider product={entry.product}>
                    <ShopItem />
                  </ProductProvider>
                )}
              </CustomGrid>
            ))}
          </Grid>
        </TsuLoading>
      </Container>
    </AppLayout>
  );
}

function CustomGrid({ children }: PropsWithChildren<{}>) {
  return (
    <Grid
      item
      sx={{
        marginLeft: {
          xs: 0,
          sm: 2,
        },
        marginRight: {
          xs: 0,
          sm: 2,
        },
        marginBottom: 4,
        marginTop: {
          xs: 2,
          sm: 0,
        },
      }}
      xs={12}
      sm={5}
      lg={3}
      xl={2.5}
    >
      {children}
    </Grid>
  );
}
