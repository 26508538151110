import { useCurrentUser } from '@/common/auth/hooks/use-current-user';
import { TsuLoading } from '@/common/components';
import { ShoppingCartContext } from '@/features/cart/shopping-cart.context';
import { useUpdateShoppingCartMutation } from '@/graphql';
import { AppLayout } from '@/layouts/AppLayout';
import { gql, useMutation } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { IPunchoutWaivenet, IPunchoutWaivenetVars } from '../../PunchoutInterface';

const WAIVENET_PUNCHOUT_ETEMPV2_MUTATION = gql`
  mutation ($destination: String!, $returnURL: String!) {
    punchoutWaivenet(input: { destination: $destination, returnURL: $returnURL }) {
      success
      link
    }
  }
`;

interface eventOrderLine {
  productId: number;
  productCode: string;
  quantity: number;
  etemplateId: number;
  ibos: boolean;
  isartworkonly: boolean;
  extraProductName: string;
  fileURL: string;
}

export function ETempV2() {
  const [punchoutWaivenetEtempV2Mutation, { data, loading }] = useMutation<
    IPunchoutWaivenet,
    IPunchoutWaivenetVars
  >(WAIVENET_PUNCHOUT_ETEMPV2_MUTATION, {
    variables: { destination: 'EtempV2', returnURL: 'https://test.com.au' },
  });

  const [updateShoppingCart ] = useUpdateShoppingCartMutation();
  const { shoppingCart } = useCurrentUser();
  const [cartItems, setCartItems] = useState<ShoppingCartContext['cartItems']>([]);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  // Update our local cartItems after shoppingCart loads, without useEffect
  // Source: https://beta.reactjs.org/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  const [prevShoppingCart, setPrevShoppingCart] = useState(shoppingCart);
  if (shoppingCart !== prevShoppingCart && shoppingCart) {
    setPrevShoppingCart(shoppingCart);
    setCartItems(
      shoppingCart.map((i) => ({
        productId: i.productId,
        productCode: i.productCode,
        quantity: i.quantity,
        product: i.product ?? undefined,
        isSelected: i.isSelected,
        eTemplateId: i.eTemplateId,
      }))
    );
  }

  useEffect(() => {
    punchoutWaivenetEtempV2Mutation();
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      const orderLine = JSON.parse(event.data);
      if (orderLine) {
        var updatedShoppingCart = cartItems;
        updatedShoppingCart.push({
          isSelected: true,
          quantity: orderLine.quantity,
          productCode: orderLine.productCode,
          eTemplateId: orderLine.etemplateId,
          productId: orderLine.productId
        })
        if (timeout.current !== null) clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
          updateShoppingCart({
            variables: {
              // Avoid passing our internal product property to ShoppingCartItemInput
              cartItems: updatedShoppingCart.map((i) => ({
                productId: i.productId,
                productCode: i.productCode,
                quantity: i.quantity,
                isSelected: i.isSelected,
                eTemplateId: i.eTemplateId,
              })),
            },
            onCompleted(data) {
              if (!data.updateShoppingCart?.cartItems) return;
              setCartItems(
                data.updateShoppingCart.cartItems.map((i) => ({
                  productId: i.productId,
                  productCode: i.productCode,
                  quantity: i.quantity,
                  product: i.product ?? undefined,
                  isSelected: i.isSelected,
                  eTemplateId: i.eTemplateId,
                }))
              );
            },
          });
        }, 300);
      }
    };
    window.addEventListener('message', handleMessage, false);
  }, []);

  return (
    <AppLayout title="Custom Artwork">
      <TsuLoading loading={loading}>
        <iframe
          id="punchout"
          // @ts-ignore
          src={data?.punchoutWaivenet.link}
          style={{ width: '100%' }}
          height={1080}
        ></iframe>
      </TsuLoading>
    </AppLayout>
  );
}
