export interface CartItemInterface {
  name?: string;
  quantity?: number;
  price?: number;
}

export interface CartInformationInterface {
  Email: string;
  CartItems: CartItemInterface[];
  DestructionMode: boolean;
}
let CartInformationBuffer = <CartInformationInterface> {
  Email: "",
  CartItems: [],
  DestructionMode: false
}
export const getCartInformationBuffer = () => {
  return CartInformationBuffer;
}
export const setCartItemBuffer = (cartItems: CartItemInterface[]) => {
  CartInformationBuffer.CartItems = cartItems;
}

export const setCartEmailBuffer = (email: string) => {
  CartInformationBuffer.Email = email;
}

let DestructionModeOn : boolean;

export const getDestructionModeOn = () => {
  return DestructionModeOn;
}

export const setDestructionModeOn = (status: boolean) => {
  DestructionModeOn = status;
}

export const setDestructionModeOnForCartBuffer = (status: boolean) => {
  CartInformationBuffer.DestructionMode = status;
}