import { FeatureRoute } from '@/common/interfaces/feature-route';
import { withShoppingCart } from '@/features/cart/shopping-cart.context';
import { featureRoutes } from '@/features/routes';
// import { createBrowserHistory } from 'history';
import { Route, Routes } from 'react-router-dom';

export const toRoute = (route: FeatureRoute, i: number) => {
  const { component: Component, children, path, options } = route;
  let element = <Component />;
  if (!options?.isPublic) element = withShoppingCart(element);
  return (
    <Route key={i} path={path} element={element}>
      {(children ?? []).map((route, j) => toRoute(route, j))}
    </Route>
  );
};

export function AppRouter() {
  return <Routes>{featureRoutes.map(toRoute)}</Routes>;
}

// TODO: deprecated, remove
// export const history = createBrowserHistory();
