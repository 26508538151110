import { AppLayout } from '@/layouts/AppLayout';
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { FeaturesForm } from '../components/FeaturesForm';
import { ThemeForm } from '../components/ThemeForm';

export function ThemeBuilder() {
  return (
    <AppLayout title="Theme Builder">
      <Stack alignItems="start" sx={{ p: 4 }}>
        <Grid container columnSpacing={2} rowSpacing={4}>
          <Grid item xs={12} lg={3}>
            <Stack alignItems="stretch" spacing={2}>
              <Typography variant="h4" fontWeight="bold">
                Features
              </Typography>
              <Card raised>
                <CardContent>
                  <FeaturesForm />
                </CardContent>
              </Card>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Stack alignItems="stretch" spacing={2}>
              <Typography variant="h4" fontWeight="bold">
                Theme Builder
              </Typography>
              <Card raised>
                <CardContent>
                  <ThemeForm />
                </CardContent>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </AppLayout>
  );
}
