import { useSnackbar } from 'notistack';
import { useState } from 'react';
import {
  useAttributeSetsQuery,
  useChildProductsLazyQuery,
  useCreateChildProductMutation,
  useUpdateProductParentMutation,
} from '../../../graphql';
import { useDeleteProduct } from '../hooks/useDeleteProduct';
import { ChildProducsTabProps } from './ChildProductsTab';

const headers = [
  { text: 'Product Code', value: 'productCode' },
  { text: 'Product Name', value: 'name' },
  { text: 'SOH', value: 'stockOnHand' },
  { text: 'Actions', value: 'actions' },
];

export function useChildProductsTab({ productId }: ChildProducsTabProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [prevProductId, setPrevProductId] = useState<number | null>(null);
  const { deleteProduct, loading: isDeletingProduct } = useDeleteProduct();
  const [selectedAttribSetId, setSelectedAttribSetId] = useState<number | null>(null);
  const [selectedAttribDataId, setSelectedAttribDataId] = useState<number | null>(null);
  const [selectedAttribId, setSelectedAttribId] = useState<number | null>(null);

  const {
    data: attributeSetsQuery,
    error: attributeSetsError,
    loading: isAttributeSetsLoading,
  } = useAttributeSetsQuery();
  const attributeSets = attributeSetsQuery?.attributeSets ?? [];
  const selectedAttribSet = selectedAttribSetId
    ? attributeSets.find((a) => a.attributeSetId === selectedAttribSetId)
    : null;

  const selectedAttribData =
    selectedAttribSetId && selectedAttribDataId && selectedAttribId
      ? selectedAttribSet!.attributes
          .find((a) => a.attributeId === selectedAttribId)
          ?.attributeData.find((ad) => ad.attribDataId === selectedAttribDataId)
      : null;

  const [getChildProducts, getChildProductsQuery] = useChildProductsLazyQuery();
  const [createChildProduct, createChildProductMutation] = useCreateChildProductMutation();
  const [updateProductParent, updateProductParentMutation] = useUpdateProductParentMutation();

  if (productId !== prevProductId && productId !== null) {
    getChildProducts({ variables: { productId } });
    setPrevProductId(productId);
  }

  function selectAttributeSet(id: number) {
    setSelectedAttribSetId(id);
  }

  function selectAttributeTreeItem(_: React.SyntheticEvent, treeItemId: string) {
    // The logic below cannot work properly without selected attribute set
    if (!selectedAttribSet)
      return console.error('[selectAttributeTreeItem] Selected Attribute Set is null');

    // Only proceed with this logic if Tree Item ID's split is 2
    // (which means this is an Attribute Data Item)
    const spl = treeItemId.split('-');
    if (spl.length !== 2) return;

    // Compute & find Attribute Data Item that was selected,
    // based on TreeItem's Node ID
    const [attribId, attribDataId] = spl;
    const attribute = selectedAttribSet.attributes
      .find((a) => a.attributeId === parseInt(attribId))
      ?.attributeData.find((ad) => ad.attribDataId === parseInt(attribDataId));
    if (!attribute) return;

    setSelectedAttribId(parseInt(attribId));
    setSelectedAttribDataId(attribute.attribDataId);
  }

  async function _createChildProduct() {
    if (!productId) return console.log('[createChildProduct] Product is null');
    if (!selectedAttribDataId)
      return console.log('[createChildProduct] Selected Attribute is null');
    await createChildProduct({
      variables: { productId, attribDataId: selectedAttribDataId },
    });
    enqueueSnackbar('Created Variant/Child Product');
    getChildProducts({ variables: { productId } });
  }

  async function deleteChildProduct(childProductId: number) {
    await deleteProduct(childProductId);
    enqueueSnackbar('Deleted Variant/Child Product');
    getChildProductsQuery.refetch();
  }

  async function _updateProductParent(childProductId: number, link = true) {
    if (!productId) return console.log('[updateProductParent] Product is null');
    await updateProductParent({
      variables: { productId, childProductId, link },
    });
    enqueueSnackbar('Updates Variant/Child Product');
    getChildProductsQuery.refetch();
  }

  return {
    // State
    items: [],
    headers,
    selectedAttribSet,
    selectedAttribData,
    // Get Attribute Sets Query
    attributeSets,
    attributeSetsError,
    // Get Child Products Sets Query
    childProducts: getChildProductsQuery.data?.childProducts ?? [],
    childProductsError: getChildProductsQuery.error,
    // Functions
    deleteChildProduct,
    selectAttributeSet,
    selectAttributeTreeItem,
    createChildProduct: _createChildProduct,
    updateProductParent: _updateProductParent,
    // Flags
    isAttributeSetsLoading,
    isCreatingChildProduct: createChildProductMutation.loading,
    isUpdatingProductParent: updateProductParentMutation.loading,
    isDeletingProduct,
  };
}
