import { IconButton, InputAdornment, Typography, useTheme } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { EyeIcon, EyeSlashIcon, StyledTextField, VACCStyledTextField } from '../../../common/components';

interface Props {
  name: string;
  value: string;
  showPassword?: boolean;
  error?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onShowPassword?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

export function PasswordField(props: Props) {
  const theme = useTheme();
  return (
    <>
      <Typography
        variant="caption"
        fontWeight="bold"
        mb={1}
        mt={2}
        sx={{ color: '#e2066f', textAlign: 'left' }}
      >
        Password
      </Typography>
      <StyledTextField
        required
        name="password"
        type={props.showPassword ? 'text' : 'password'}
        error={props.error}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        placeholder="Password"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                color="primary"
                name="showPassword"
                onClick={props.onShowPassword}
                onMouseDown={(e) => e.preventDefault()}
              >
                {props.showPassword ? <EyeSlashIcon /> : <EyeIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export function VACCPasswordField(props: Props) {
  const theme = useTheme();
  return (
    <>
      <VACCStyledTextField
        required
        name={props.name}
        type={props.showPassword ? 'text' : 'password'}
        error={props.error}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        placeholder="Password"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                name="showPassword"
                onClick={props.onShowPassword}
                onMouseDown={(e) => e.preventDefault()}
                sx={{ color: '#f5821f' }}
              >
                {props.showPassword ? <EyeSlashIcon /> : <EyeIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}
