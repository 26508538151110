import { TsuButton, TsuField, TsuPills, TsuStepNumber } from '@/common/components';
import { RootState } from '@/store';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { initialReportDetails } from './constants';

const fileTypeChoices = [
  { text: 'XLSX', value: 'XLSX' },
  { text: 'PDF', value: 'PDF' },
  { text: 'CSV', value: 'CSV' },
  { text: 'DOC', value: 'DOC' },
];

interface ReportDetailsProps {
  onChange?: (reportDetails: typeof initialReportDetails) => void;
}

function ReportDetails({ onChange }: ReportDetailsProps) {
  const theme = useTheme();
  // Used in label about emailing to yourself
  const email = useSelector((store: RootState) => store.user.email);
  // Report Details State
  const [reportDetails, setReportDetails] = useState(initialReportDetails);

  useEffect(() => onChange?.(reportDetails), [onChange, reportDetails]);

  const handleAddOtherEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { emailToAdd, otherEmails } = reportDetails;
    if (emailToAdd && !otherEmails.includes(emailToAdd)) {
      setReportDetails({
        ...reportDetails,
        otherEmails: [...otherEmails, emailToAdd],
      });
    }
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h5" display="flex" alignItems="center">
        <TsuStepNumber step={1} sx={{ mr: 1 }} />
        Report Details
      </Typography>
      <TsuField
        label="Report Name"
        value={reportDetails.reportName}
        onChange={(v) => setReportDetails({ ...reportDetails, reportName: v })}
      />
      <TsuPills
        label="File Type"
        value={reportDetails.fileType}
        items={fileTypeChoices}
        onChange={(v) => setReportDetails({ ...reportDetails, fileType: v })}
        stretchItems
      />
      <Typography variant="h6">Send Report</Typography>
      <FormControl component="fieldset">
        <RadioGroup
          name="row-radio-buttons-group"
          value={reportDetails.sendReportType}
          onChange={(e) =>
            setReportDetails({
              ...reportDetails,
              sendReportType: e.target.value,
            })
          }
        >
          <FormControlLabel
            value="send-report-me"
            control={<Radio />}
            label={`Send report to Me (${email})`}
          />
          <FormControlLabel
            value="send-report-others"
            control={<Radio />}
            label="Send report to other emails"
          />
        </RadioGroup>
      </FormControl>

      <Box hidden={reportDetails.sendReportType !== 'send-report-others'}>
        <Typography variant="body1">Send Report to</Typography>
        <form onSubmit={handleAddOtherEmail}>
          <Stack direction="row" style={{ marginTop: 4, marginBottom: 4 }}>
            <TsuField
              name="email-to-add"
              value={reportDetails.emailToAdd}
              onChange={(v) => setReportDetails({ ...reportDetails, emailToAdd: v })}
              sx={{ flexGrow: 1 }}
            />
            <TsuButton text type="submit">
              <Typography>Add Email</Typography>
            </TsuButton>
          </Stack>
        </form>
        <Stack spacing={1}>
          {reportDetails.otherEmails.map((email) => (
            <Stack direction="row">
              <AccountCircleOutlinedIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
              <Typography variant="body1">{email}</Typography>
            </Stack>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
}

export default ReportDetails;
