/*

This context allows us to provide a Product object to all descendant components.

*/

import { OrderTypeEnum, ProductStatusEnum } from '@/common';
import { useCurrentUser } from '@/common/auth/hooks/use-current-user';
import { ProductPartsFragment } from '@/graphql';
import { differenceInDays, parseISO } from 'date-fns';
import { createContext, PropsWithChildren, useContext } from 'react';

export type ProductContext = ProductPartsFragment & {
  eTemplateId?: number;
  isNormalProduct: boolean;
  isArchiveProduct: boolean;
  isContractProduct: boolean;
  isETemplate: boolean;
  isNew: boolean;
  isAtClient: boolean;
  isInWarehouse: boolean;
  hasStock: boolean;
  parentId?: number;
};


type Props = {
  product: ProductPartsFragment;
  eTemplateId?: number;
};


export const ProductContext = createContext({} as ProductContext);


export function useProductContext() {
  return useContext(ProductContext);
}


export function ProductProvider(props: PropsWithChildren<Props>) {
  const { currentUser } = useCurrentUser();
  const discount = currentUser?.role?.discount;
  const { children, product } = props;
  const discountedPrices = product.prices.map((p) => ({
    ...p,
    price: p.price - p.price * (discount?? 0)/100,
  }));
  const updatedProduct = {
    ...product,
    prices: discountedPrices,
  }
  return (
    <ProductContext.Provider
      value={{
        ...updatedProduct,
        eTemplateId: props.eTemplateId,
        isNormalProduct: updatedProduct.orderTypeId === OrderTypeEnum.Normal,
        isArchiveProduct: updatedProduct.orderTypeId === OrderTypeEnum.Archive,
        isContractProduct: updatedProduct.orderTypeId === OrderTypeEnum.Contract,
        isETemplate: updatedProduct.orderTypeId === OrderTypeEnum.Etemplate,
        // Product is considered new if the difference between today and product's creation is less than ~1 month (28 days)
        isNew:
          !!updatedProduct.dateCreated &&
          differenceInDays(new Date(), parseISO(updatedProduct.dateCreated)) <= 28,
        isAtClient: updatedProduct.statusId === ProductStatusEnum.AtClient,
        isInWarehouse: updatedProduct.statusId === ProductStatusEnum.InWarehouse,
        hasStock: !!updatedProduct.stockAvailable && updatedProduct.stockAvailable > 0,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}

