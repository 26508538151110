import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { GetRolesRes, Role } from '../interfaces';

export function useRoleAdministration() {
  const headers = [
    { text: 'Role Name', value: 'name' },
    { text: 'Default Role', value: 'defaultRole' },
    { text: 'Admin Role', value: 'adminRole' },
    { text: 'Status', value: 'active' },
    { text: 'Discount', value: 'discount' },
    { text: 'Actions', value: 'actions' },
  ];

  const {
    data,
    error: getRolesError,
    loading: isRolesLoading,
  } = useQuery<GetRolesRes>(gql`
    query {
      roles {
        baseRole {
          maxSpendByDays
          maxSpendDays
          minOrderTotal
          maxOrderQuantity
          defaultWarehouse
          zeroFreightOrderAmount
          discountIsPercentage
        }
        roleId
        name
        defaultRole
        adminRole
        active
        discount
      }
    }
  `);

  const [opts, setOpts] = useState({
    itemsPerPage: 20,
    page: 0,
  });

  const setPage = (p: number) => setOpts({ ...opts, page: p });
  const setItemsPerPage = (ipp: number) => setOpts({ ...opts, itemsPerPage: ipp });

  return {
    // State
    headers,
    roles: data?.roles ?? [],
    // Table state
    opts,
    setPage,
    setItemsPerPage,
    // API Status
    getRolesError,
    isRolesLoading,
  };
}
