import WaivenetLogo from '@/assets/images/waivenetLogo.png';
import { Copyright, LoginHeader } from '@/common/components';
import { AppBackground } from '@/layouts/AppBackground';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { ContactUsForm } from '../components/ContactUsForm';

export function ContactUs() {
  const navigate = useNavigate();

  return (
    <AppBackground>
      <Stack alignItems="stretch" spacing={3} sx={{ minHeight: '100vh' }}>
        <LoginHeader />
        <Box flexGrow={1} display="flex" alignItems="stretch">
          <Grid
            container
            direction="row"
            alignItems="center"
            textAlign="center"
            sx={{ px: 8, pl: { md: 0 } }}
          >
            <Grid item md={4} sm={12} xs={12}>
              <Box width="100%" height="100%">
                <img src={WaivenetLogo} alt="WaiveNet" style={{ objectFit: 'contain' }} />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={8}>
              <Stack alignItems="start" spacing={2}>
                <Button
                  onClick={() => navigate('/signin')}
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                >
                  Back to Sign In
                </Button>
                <ContactUsForm />
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Copyright />
      </Stack>
    </AppBackground>
  );
}
