import { OrderTypeEnum } from '@/common';
import { Typography } from '@mui/material';

type Props = {
  stock?: number;
  // Parent can optionally pass the product's "Order Type ID".
  // See the possible values of "orderTypeId" in "OrderTypeEnum"
  orderTypeId?: number;
};

/**
 * This component handles the display of the product's
 * current stock level, or "stock available".
 * Stock available is computed by: stock on hand - stock committed.
 */
export function StockIndicator(props: Props) {
  // Contract Orders should not show stock levels, as it does not require having stock to order.
  if (props.orderTypeId === OrderTypeEnum.Contract) return null;

  // For now, every other order type will show the stock levels.
  if(props.stock && props.stock > 0 ) {
    return (
      <Typography variant="body1" sx={{
        marginTop: '20px',
        marginBottom: '20px'
      }}>
        Stock Available: <span style={{ fontWeight: 'bold' }}>{props.stock}</span>
      </Typography>
    );
  }
  else {
    return null;
  }
}
