import ContentLoader from 'react-content-loader';

export function CartItemLoader() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox="0 0 500 100"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="8" y="0" rx="0" ry="0" width="133" height="113" />
      <rect x="158" y="2" rx="0" ry="0" width="64" height="16" />
      <rect x="158" y="26" rx="0" ry="0" width="30" height="14" />
      <rect x="158" y="50" rx="0" ry="0" width="44" height="14" />
      <rect x="395" y="55" rx="0" ry="0" width="95" height="17" />
      <rect x="422" y="79" rx="0" ry="0" width="68" height="17" />
    </ContentLoader>
  );
}
