import VaccLogo from '@/assets/images/VACC_logo.png';
import OurautoistoreLogo from '@/assets/images/ourautoistore.png';
import Banner1 from '@/assets/images/exedy-2401.jpg';
import Banner2 from '@/assets/images/denso-2108.gif';
import Banner3 from '@/assets/images/bendix-2311.gif';
import Banner4 from '@/assets/images/podium-banner-2306.gif';
import Banner5 from '@/assets/images/spirit-super-2211.png';
import Banner6 from '@/assets/images/Workshop-Software.gif';
import { VACCStyledCheckBox, TsuButton, UnstyledTsuCarousel } from '@/common/components';
import { VACCStyledTextField } from '@/common/components/StyledTextField';
import AuthService from '@/features/auth/services/auth.service';
import { SHOP_ROUTE } from '@/features/shop/shop.routes';
import { AppBackground } from '@/layouts/AppBackground';
import { useApolloClient } from '@apollo/client';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BannerItem, GetCurrentUserDocument, GetCurrentUserQuery } from '../../../graphql';
import { VACCPasswordField } from '../../auth/components/PasswordField';
import oldWnAuthService from '../../auth/services/old-wn-auth.service';

export default function VACCSignin() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();

  const state = location.state as { message: string | undefined };
  const banners: BannerItem[] = [
    {fileName: Banner1, href: "https://www.bendix.com.au/product-range/general-ct-disc-brake-rotor"}, 
    {fileName: Banner2, href: "https://exedy.com.au/"}, 
    {fileName: Banner3, href: "https://www.podium.com.au/"}, 
    {fileName: Banner4, href: "https://www.spiritsuper.com.au/"}, 
    {fileName: Banner5, href: "https://workshopsoftware.com/free-trial/?utm_source=VACC&utm_medium=website&utm_campaign=VACC-Banner"}, 
    {fileName: Banner6, href: "https://www.youtube.com/watch?v=eCyDO7uxrOY"}];

  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
    emailError: false,
    passwordError: false,
    error: '',
  });
  const [isQuickLogin, setIsQuickLogin] = useState(
    AuthService.isRememberEnabled() && AuthService.getRememberMeUsername() !== null
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValues({ ...values, [event.target.name]: value });
  };

  const [rememberMe, setRememberMe] = React.useState(true);

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked);
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    let error = '';
    let emailError = false;
    let passwordError = false;
    let formError = false;

    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(values.email)) {
      formError = true;
      emailError = true;
      error = 'Invalid Email.';
      return;
    }

    try {
      await AuthService.login(values.email, values.password, rememberMe);

      client.query<GetCurrentUserQuery>({ query: GetCurrentUserDocument }).then(async (res) => {
        if (!res.data.currentUser) return console.error('Could not log in to OLD WN');
        await oldWnAuthService.login(res.data.currentUser.username, values.password);
        // console.log('Successfully logged in to OLD WN');
      });

      return navigate(SHOP_ROUTE);
    } catch (err) {
      console.log('error:', JSON.stringify(err));
      console.error(err);
      if (err instanceof Error) setLoginError(err.message);
      setLoading(false);
    }

    if (formError) {
      emailError = true;
      passwordError = true;
      error = 'Invalid Credentials.';
    }

    setLoading(false);
    setValues({
      ...values,
      error: error,
      emailError: emailError,
      passwordError: passwordError,
    });
  };

  const genericLogin = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    try {
      await AuthService.login('guest@vacc.com.au', 'vacc123', false);

      client.query<GetCurrentUserQuery>({ query: GetCurrentUserDocument }).then(async (res) => {
        if (!res.data.currentUser) return console.error('Could not log in to OLD WN');
        await oldWnAuthService.login(res.data.currentUser.username, values.password);
        // console.log('Successfully logged in to OLD WN');
      });

      return navigate(SHOP_ROUTE);
    } catch (err) {
      console.log('error:', JSON.stringify(err));
      console.error(err);
      if (err instanceof Error) setLoginError(err.message);
      setLoading(false);
    }

    setLoading(false);
    setValues({
      ...values,
      error: '',
      emailError: false,
      passwordError: false,
    });
  };

  function handleDeleteRememberedAccount() {
    AuthService.removeRememberedAccount();
    setIsQuickLogin(false);
  }
  const redirectToSignUp = () => {
    navigate('/signup');
  }

  return (
    <AppBackground>
      <Stack alignItems="stretch" spacing={3} sx={{ minHeight: '100vh' }}>
        <Grid
          container
          direction="row"
          alignItems={{ xs: 'start', md: 'center' }}
          textAlign="center"
          sx={{ marginLeft: 'auto', marginRight: 'auto', width: '90%', display: 'flex', minWidth: '60vw'}}
        >
          <Grid item md={6} sm={12} xs={12} sx={{ flex: 1}}>
            <Box width="100%" height="100%" sx={{ textAlign: 'left'}}>
              <img
                src={OurautoistoreLogo}
                alt="VACC Logo"
                style={{ maxHeight: 93, objectFit: 'contain'}}
              />
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12} sx={{ flex: 1}}>
            <Box width="100%" height="100%" sx={{ textAlign: 'right'}}>
              <img
                src={VaccLogo}
                alt="VACC Logo"
                style={{ maxHeight: 93, objectFit: 'contain', width: 175}}
              />
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ bgcolor: '#f5821f', borderBottomWidth: 5}}/>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box width="70%" sx={{ textAlign: 'center' }}>
            <UnstyledTsuCarousel images={(banners ?? []).map((b) => b)} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid
            container
            direction="row"
            alignItems={{ xs: 'start', md: 'center' }}
            textAlign="center"
            sx={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Grid item xs={5.9} height='500px'>
              <Stack direction="row" alignItems="center" justifyContent="flex-end" height='100%'>
                <Card
                  elevation={20}
                  sx={{
                    minWidth: '300px',
                    width: '60%',
                    mr: { xs: 15, md: 0 },
                    ml: { xs: 15, md: 0 },
                    p: { xs: 1, md: theme.spacing(3) },
                    boxSizing: 'border-box',
                    height: '100%'
                  }}
                >
                  <CardHeader
                    sx={{ color: '#6d6e71', mt: theme.spacing(1), fontWeight: 'bold' }}
                    title="Don’t have an OurAuto iStore account?"
                    titleTypographyProps={{ variant: 'h6', align: 'left' }}
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <Box
                      display={!loading ? 'none' : 'flex'}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircularProgress />
                      <Typography ml={theme.spacing(1)}>Logging in...</Typography>
                    </Box>
                      <Box
                        textAlign="left"
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        hidden={loading}
                      >
                        <FormGroup>
                          <Button sx={{ backgroundColor: '#6d6e71', color: '#fff', '&:hover': {color: 'black', backgroundColor: '#6d6e71'}}}
                            onClick={redirectToSignUp}
                          >
                            CREATE AN ACCOUNT
                          </Button>
                          <Typography
                            variant="caption"
                            fontWeight="bold"
                            mb={1}
                            sx={{ color: '#6d6e71' }}
                            alignSelf='center'
                          >
                            OR
                          </Typography>
                          <Button sx={{ backgroundColor: '#6d6e71', color: '#fff', '&:hover': {color: 'black', backgroundColor: '#6d6e71'}}}
                            onClick={genericLogin}
                          >
                            BROWSE AS A GUEST
                          </Button>
                          <Typography sx={{color: '#f5821f'}}>
                            VACC members enjoy at least 15% off all products.
                          </Typography>
                          <Typography>
                            If you don’t know your membership number or would like to become a VACC member, call 1300 013 341
                          </Typography>
                        </FormGroup>
                      </Box>
                  </CardContent>
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={0.2} sx={{ display: 'flex', alignItems: 'center' }} height='500px'>
              <Divider orientation='vertical' sx={{ height: '100%', marginLeft: 'auto', marginRight: 'auto' }}/>
            </Grid>
            <Grid item xs={5.9} height='500px'>
              <Stack direction="row" alignItems="center" justifyContent="flex-start" height='100%'>
                <Card
                  elevation={20}
                  sx={{
                    minWidth: '300px',
                    width: '60%',
                    mr: { xs: 15, md: 0 },
                    ml: { xs: 15, md: 0 },
                    p: { xs: 1, md: theme.spacing(3) },
                    boxSizing: 'border-box',
                    height:'100%'
                  }}
                >
                  <CardHeader
                    sx={{ color: '#f5821f', mt: theme.spacing(1), fontWeight: 'bold' }}
                    title="OurAuto iStore Account Login"
                    titleTypographyProps={{ variant: 'h6', align: 'left' }}
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <Box
                      display={!loading ? 'none' : 'flex'}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircularProgress />
                      <Typography ml={theme.spacing(1)}>Logging in...</Typography>
                    </Box>
                    {!isQuickLogin ? (
                      <Box
                        textAlign="left"
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        hidden={loading}
                      >
                        <FormGroup>
                          <Typography
                            variant="caption"
                            fontWeight="bold"
                            mb={1}
                            sx={{ color: '#6d6e71' }}
                          >
                            Email
                          </Typography>
                          <VACCStyledTextField
                            required
                            autoFocus
                            name="email"
                            type="text"
                            error={values.emailError}
                            value={values.email}
                            onChange={handleChange}
                            placeholder="Email"
                            variant="outlined"
                            sx={{ color: '#f5821f' }}
                          />
                          <Typography
                            variant="caption"
                            fontWeight="bold"
                            mb={1}
                            mt={2}
                            sx={{ color: '#6d6e71', textAlign: 'left' }}
                          >
                            Password
                          </Typography>
                          <VACCPasswordField
                            name="password"
                            value={values.password}
                            showPassword={values.showPassword}
                            error={values.passwordError}
                            onChange={handleChange}
                            onShowPassword={handleClickShowPassword}
                          />
                          <FormHelperText error sx={{ mx: 0, mt: theme.spacing(1) }}>
                            {values.error}
                          </FormHelperText>
                          <Grid container justifyContent="space-between" pt={1}>
                            <Grid item>
                              <FormControlLabel
                                control={
                                  <VACCStyledCheckBox
                                    onChange={handleChangeCheckBox}
                                    disableRipple
                                    sx={{ color: '#f5821f' }}
                                  />
                                }
                                checked={rememberMe}
                                label={
                                  <Typography variant="caption" sx={{ color: '#6d6e71' }}>
                                    Remember Me?
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid item alignSelf="center">
                              <Typography align="right" variant="caption">
                                <Link
                                  href="/forgotPassword"
                                  underline="none"
                                  color="primary"
                                  paddingLeft="4px"
                                  fontWeight="bold"
                                  sx={{ color: '#f5821f' }}
                                >
                                  Forgot Password?
                                </Link>
                              </Typography>
                            </Grid>
                          </Grid>
                          {state?.message && (
                            <Alert severity="error" sx={{ alignItems: 'center' }}>
                              {state.message}
                            </Alert>
                          )}
                          {loginError && (
                            <Alert severity="error" sx={{ alignItems: 'center' }}>
                              {loginError}
                            </Alert>
                          )}
                          <Button
                            type="submit"
                            sx={{ backgroundColor: '#f5821f', color: '#fff', '&:hover': {color: 'black', backgroundColor: '#f5821f'}}}
                          >
                            <Typography variant="body1" fontWeight="bold">
                              Log In
                            </Typography>
                          </Button>
                          <Typography
                            pt={4.5}
                            variant="caption"
                            align="center"
                            sx={{ color: '#f5821f' }}
                          >
                            Having trouble logging in?
                            <Link
                              href="/contact-us"
                              underline="none"
                              paddingLeft="4px"
                              fontWeight="bold"
                              sx={{ color: '#f5821f' }}
                            >
                              Contact Us
                            </Link>
                          </Typography>
                        </FormGroup>
                      </Box>
                    ) : (
                      <Box
                        display={!loading ? 'flex' : 'none'}
                        flexDirection="column"
                        alignItems="start"
                      >
                        {!values.email ? (
                          <Stack direction="row" sx={{ width: '100%' }}>
                            <Button
                              onClick={() => {
                                setValues({
                                  ...values,
                                  email: AuthService.getRememberMeUsername()!,
                                });
                                setTimeout(() => {
                                  (
                                    document.querySelector("input[name='password']") as
                                      | HTMLInputElement
                                      | undefined
                                  )?.focus();
                                }, 150);
                              }}
                              variant="text"
                              sx={{ py: 2, px: { md: 4 }, wordBreak: 'break-all' }}
                            >
                              <AccountCircleIcon color="primary" sx={{ mr: 1, color: '#f5821f' }} />
                              <Typography
                                textAlign="left"
                                component="form"
                                autoComplete="off"
                                onSubmit={handleSubmit}
                                hidden={loading}
                                fontWeight="bold"
                                sx={{ color: '#f5821f' }}
                              >
                                {AuthService.getRememberMeUsername() ?? ''}
                              </Typography>
                            </Button>
                            <IconButton
                              onClick={handleDeleteRememberedAccount}
                              sx={{ alignSelf: 'center', color: '#f5821f' }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                        ) : (
                          <Box
                            onSubmit={handleSubmit}
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            alignItems="stretch"
                            hidden={loading}
                          >
                            <Typography textAlign="left">{values.email}</Typography>
                            <VACCPasswordField
                              name="password"
                              value={values.password}
                              error={values.passwordError}
                              showPassword={values.showPassword}
                              onChange={handleChange}
                              onShowPassword={handleClickShowPassword}
                              onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
                            />
                            <Button
                              type="submit"
                              variant="contained"
                              onClick={handleSubmit}
                              sx={{ alignSelf: 'stretch', mt: 3 }}
                            >
                              <Typography variant="body1" fontWeight="bold">
                                Log In
                              </Typography>
                            </Button>
                          </Box>
                        )}
                        <Button
                          variant="text"
                          onClick={() => setIsQuickLogin(false)}
                          sx={{ mt: 2 }}
                        >
                          <Typography fontSize="0.85em" sx={{ color: '#f5821f' }}>
                            Use a different account
                          </Typography>
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </AppBackground>
  );
}
