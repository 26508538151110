import { useShoppingCart } from '@/features/cart/shopping-cart.context';
import { useGetUserCartQuery, useSubmitOrderMutation } from '@/graphql';
import { AppLayout } from '@/layouts/AppLayout';
import { useApolloClient } from '@apollo/client';
import { Box, Container, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useCheckoutState, useCheckoutStep, useCheckoutStore } from '../checkout-store';
import { StepCard } from '../components/StepCard';
import { StepsOverview } from '../components/StepsOverview';
import { CHECKOUT_STEPS } from '../constants/checkout-steps';
import { useUpdateCart } from '../hooks/use-update-cart';
import { isEqual } from 'lodash';
import { CartItemInterface, setCartItemBuffer } from '@/features/cart/components/CartInformationBuffer';

export function Checkout() {
  const { currentStep, navigateStep } = useCheckoutStep();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const {data: getCartQuery, refetch} = useGetUserCartQuery({ notifyOnNetworkStatusChange: true });
  const shoppingCart = useShoppingCart();
  const [mutate, { error }] = useSubmitOrderMutation();

  // Variables needed to update UserCart
  const checkoutState = useCheckoutState();
  const prevCheckoutState = useRef<ReturnType<typeof useCheckoutState>>();
  const updateCart = useUpdateCart();
  const isUpdatingCart = useCheckoutStore((s) => s.isUpdatingCart);
  const [userCartId, setUserCartId] = useState(getCartQuery?.userCart?.id);
  // Derived state
  useEffect(() => {
    if (!isEqual(checkoutState, prevCheckoutState.current)) {
      prevCheckoutState.current = checkoutState;
      if (checkoutState.freightSettingId !== null) {
        // console.log('[Checkout] Updating UserCart:', checkoutState);
        updateCart();
      }
    }
  }, [checkoutState]);

  useEffect(() => {
    if(!userCartId && !isUpdatingCart) {
      refetch();
    }  
  }, [userCartId, isUpdatingCart]);;

  async function onFinish() {
    try {
      // console.log('user cart:', userCartId);
      // Do not proceed without user cart
      if (!getCartQuery?.userCart?.id){
        // console.log("No user cart ID");
        return;
      }

      // Trigger order mutation
      const res = await mutate({ variables: { cartId: getCartQuery?.userCart?.id } });
      // Copy Cart Information to the buffer
      let cartItems: CartItemInterface[] = [];
      for (var item of shoppingCart.cartItems){
        cartItems.push(
          {
            name: item.product?.name.toString(),
            quantity: item.quantity,
            price: item.product?.prices.find((p) => p.quantity === 1)?.price
          }
        );
      }
      setCartItemBuffer(cartItems);
      // Check for problems before proceeding
      if (!res && error) return;

      // Clear the cart items saved in store
      shoppingCart.removeAllItems();
      // Refresh the user's cart (should be NULL as it is cleared during submitOrder mutation)
      client.refetchQueries({ include: ['userCart'] });

      // Compute orderId for route query
      const orderId = res.data?.submitOrder.orderReference?.split('~').shift() ?? '';
      // Navigate to transaction finished page
      navigate({
        pathname: '/transactionFinished',
        search: new URLSearchParams({ orderId }).toString(),
      });
    } catch (err) {
      console.log('error:', err);
      enqueueSnackbar((err as any).message, { variant: 'error' });
    }
  }

  return (
    <AppLayout title="Checkout">
        <Box flexGrow={1} display="flex" justifyContent="center" width="100%">
          <Container maxWidth="xl">
            <Grid container direction={{ xs: 'column', lg: 'row' }} spacing={4}>
              {/* Checkout steps overview / outline */}
              <Grid item xs={3}>
                <StepsOverview />
              </Grid>

              <Grid item xs={9}>
                {/* Checkout steps */}
                <Stack spacing={2}>
                  {CHECKOUT_STEPS.map((step, i) => (
                    <StepCard
                      key={i}
                      title={step.title}
                      submitTitle={step.submitTitle}
                      step={i}
                      isActive={currentStep === i}
                      showEditButton={currentStep > i}
                      hideContent={currentStep < i}
                      onEditClick={() => navigateStep(i)}
                      onFinish={onFinish}
                    >
                      {step.component()}
                    </StepCard>
                  ))}
                </Stack>
                {/* Checkout errors */}
              </Grid>
            </Grid>
          </Container>
        </Box>
    </AppLayout>
  );
}
