import { TsuButton } from '@/common/components';
import { OrderDetailDisplay } from '@/common/interfaces';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { OrderSummary, TsuLoading } from '@/common/components';
import { useShoppingCart } from '@/features/cart/shopping-cart.context';
import { useGetUserCartQuery } from '@/graphql';
import { getCartInformationBuffer } from '@/features/cart/components/CartInformationBuffer';
interface Props {
  open: boolean;
  orderDetailDisplay: OrderDetailDisplay;
  onClose?: () => void;
}

const OrderDetailsInputStyle = {
  fontWeight: 600,
  paddingRight: 10,
};

const PrintOrderButtonStyle = {
  fontWeight: 600,
  flexGrow: 0,
  color: '#E2066F',
  border: 'none',
  backgroundColor: '#FFFFFF',
};

export function ViewOrderDetailsDialog({ onClose, open, orderDetailDisplay }: Props) {
  const handleClose = () => onClose?.();
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  var cartItems = getCartInformationBuffer().CartItems;
  const { isCartLoading: isShoppingCartLoading } = useShoppingCart();
  const { data: userCartQuery, loading: isUserCartLoading } = useGetUserCartQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  // Derived state
  const isLoading = isUserCartLoading || isShoppingCartLoading;
  const cart = userCartQuery?.userCart;

  return (
    <Dialog maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle sx={{ pt: 1, pb: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Order Details</Typography>
          <TsuButton onClick={handleClose} text>
            <CancelOutlinedIcon sx={{ mr: 1 }} />
            <Typography>Close</Typography>
          </TsuButton>
        </Stack>
      </DialogTitle>
      <Divider flexItem />
      <DialogContent ref={componentRef}>
        <Stack flex={1} spacing={2} maxWidth={{ xs: 'none', lg: '40vw' }} alignItems="stretch">
          <Stack spacing={2} divider={<Divider flexItem />}>
            <Stack spacing={2}>
              <Typography variant="h4">Order Details</Typography>
              <Grid container columns={16}>
                <Grid item xs={8}>
                  <Stack spacing={2}>
                    <Typography variant="body2">Order No.</Typography>
                    <Typography variant="body1" sx={OrderDetailsInputStyle}>
                      {orderDetailDisplay.orderNo}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={8}>
                  <Stack spacing={2}>
                    <Typography variant="body2">Delivery Type</Typography>
                    <Typography variant="body1" sx={OrderDetailsInputStyle}>
                      {orderDetailDisplay.shippingMethod}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container columns={16}>
                <Grid item xs={8}>
                  <Stack spacing={2}>
                    <Typography variant="body2">Ordered By</Typography>
                    <Typography variant="body1" sx={OrderDetailsInputStyle}>
                      {orderDetailDisplay.orderedBy}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={8}>
                  <Stack spacing={2}>
                    <Typography variant="body2">Order Date</Typography>
                    <Typography variant="body1" sx={OrderDetailsInputStyle}>
                      {orderDetailDisplay.orderDate}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h4">Contact Info</Typography>
              <Grid container columns={16}>
                <Grid item xs={8}>
                  <Stack spacing={2}>
                    <Typography variant="body2">Full Name</Typography>
                    <Typography variant="body1" sx={OrderDetailsInputStyle}>
                      {orderDetailDisplay.deliveryFullName}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={8}>
                  <Stack spacing={2}>
                    <Typography variant="body2">Mobile No.</Typography>
                    <Typography variant="body1" sx={OrderDetailsInputStyle}>
                      {orderDetailDisplay.mobile}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container columns={16}>
                <Grid item xs={8}>
                  <Stack spacing={2}>
                    <Typography variant="body2">Email</Typography>
                    <Tooltip title={orderDetailDisplay.email}>
                      <Typography variant="body1" noWrap sx={OrderDetailsInputStyle}>
                        {orderDetailDisplay.email}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={8}>
                  <Stack spacing={2}>
                    <Typography variant="body2">Phone No.</Typography>
                    <Typography variant="body1" sx={OrderDetailsInputStyle}>
                      {orderDetailDisplay.phone}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container columns={16}>
                <Grid item xs={8}>
                  <Stack spacing={2}>
                    <Typography variant="body2">Company Name</Typography>
                    <Typography variant="body1" sx={OrderDetailsInputStyle}>
                      {orderDetailDisplay.companyName}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <TsuLoading loading={isLoading}>
              <Stack spacing={2} mt={4}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cartItems.map((item, i) => {
                    const unitPrice = item.price;
                    const unitPriceText = unitPrice ? '$' + unitPrice.toFixed(2) : '-';
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <Stack direction="row" alignItems="center">
                            <Typography>{item.name ?? '-'}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell sx={{ color: 'primary.main' }}>{unitPriceText}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <OrderSummary
                numCartItems={cart?.cartInformation?.cartLines?.length ?? 0}
                itemTotal={cart?.orderCosts?.itemCost}
                pickFee={cart?.orderCosts?.fees.pickFee.fee}
                processingFee={
                  cart?.cartInformation.manualOrder
                    ? cart?.orderCosts?.fees.manualOrderFee.fee
                    : undefined
                }
                urgentPickFee={cart?.orderCosts?.fees.urgentPickFee.fee}
                shippingFee={cart?.orderCosts?.fees.shippingFee.fee}
                paysForPickFee={cart?.orderCosts?.fees.pickFee.paysFor ?? true}
                paysForShippingFee={cart?.orderCosts?.fees.shippingFee.paysFor ?? true}
                subtotalExGst={cart?.orderCosts?.subTotal}
                gst={cart?.orderCosts?.gst}
                totalAmount={cart?.orderCosts?.grandTotal}
                hideTitle
              />
              </Stack>
            </TsuLoading>
            <Grid container>
              <Grid item xs={7}>
                {/* // Empty grid item */}
              </Grid>
              <Grid item xs={5}>
                <Button onClick={handlePrint} sx={PrintOrderButtonStyle}>
                  <Typography variant="body1">Print Order Detail</Typography>
                  <PrintOutlinedIcon sx={{ color: '#E2066F', margin: '4px 8px' }} />
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
