import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';

const defaultChoices = [
  // { text: '5', value: 5 },
  // { text: '10', value: 10 },
  // { text: '15', value: 15 },
  { text: '20', value: 20 },
  { text: '30', value: 30 },
  { text: '50', value: 50 },
];

interface Props {
  choices?: { text: string; value: number }[];
  itemsPerPage?: number;
  onChange?: (ipp: number) => void;
}

export function ItemsPerPageDropdown(props: Props) {
  const { choices, itemsPerPage, onChange } = props;
  const _choices = choices ?? defaultChoices;

  const handleChange = (e: SelectChangeEvent<number>) => {
    onChange?.(parseInt(e.target.value.toString()));
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography>Show</Typography>
      <Select
        value={itemsPerPage}
        onChange={handleChange}
        sx={{ backgroundColor: 'white' }}
        size="small"
      >
        {_choices.map((c, i) => (
          <MenuItem key={i} value={c.value}>
            {c.text}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}
