import { SERVER_STATIC_FILES_URL } from '@/globals';
import { TsunamiTheme, useGetThemeQuery } from '@/graphql';
import DEFAULT_TSUNAMI_THEME from '../assets/default-tsunami-theme.json';

export function useCustomerTheme() {
  const { data } = useGetThemeQuery();
  // @ts-ignore
  const theme: Omit<TsunamiTheme, 'customerId' | 'themeId'> =
    data?.customerTheme ?? DEFAULT_TSUNAMI_THEME;
  return theme;
}

export function useCustomerLogo() {
  const theme = useCustomerTheme();
  return theme?.logo ? SERVER_STATIC_FILES_URL + '/' + theme.logo : null;
}
