import { TsuDialog, TsuDialogContent, TsuTable } from '@/common/components';
import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import { SelectedBusinessLevelContext } from '../context/selected-business-level.context';
import { useBusinessLevelsApi } from '../hooks/useBusinessLevelsApi';
import useBusinessLevelsRender from '../hooks/useBusinessLevelsRender';
import { BusinessLevel } from '../interfaces/business-level.interface';
import { EditBusinessLevelDialog } from './EditBusinessLevelDialog';

export function BusinessLevelsTable() {
  const businessLevels = useBusinessLevelsApi();
  const [openEditBusinessLevelDialog, setOpenEditBusinessLevelDialog] = useState(false);
  const [selectedBusinessLevel, setSelectedBusinessLevel] = useState<BusinessLevel | null>(null);
  const { customItemRender } = useBusinessLevelsRender({
    onEdit: useCallback(
      (bl) => {
        setSelectedBusinessLevel(bl as BusinessLevel)
        setOpenEditBusinessLevelDialog(true);
      },
      [setOpenEditBusinessLevelDialog]
    ),
  });
  return(
    <Box>
      <TsuTable
        headers={businessLevels.headers}
        items={businessLevels.data?.customerBusinessLevels ?? []}
        loading={businessLevels.loading}
        error={businessLevels.error}
        colMinWidth={2}
        page={businessLevels.opts.page}
        itemsPerPage={businessLevels.opts.itemsPerPage}
        totalCount={businessLevels.data?.customerBusinessLevels?.length}
        onPageChange={businessLevels.setPage}
        onItemsPerPageChange={businessLevels.setItemsPerPage}
        customItemRender={customItemRender}
      />
      <TsuDialog
        title="Business Level Maintenance"
        open={openEditBusinessLevelDialog}
        onClose={() => setOpenEditBusinessLevelDialog(false)}
        maxWidth="lg"
        fullWidth
        noFooter
      >
        <TsuDialogContent>
          <SelectedBusinessLevelContext.Provider value={{ selectedBusinessLevel }}>
            <EditBusinessLevelDialog/>
          </SelectedBusinessLevelContext.Provider>
        </TsuDialogContent>
      </TsuDialog>
    </Box>
  );
}