import { useApolloClient } from '@apollo/client';
import { useEffect, useMemo, useReducer, useState } from 'react';
import {
  GetProductDetailsDocument,
  useItemGroupsQuery,
  useProductStatusesQuery,
} from '../../../graphql';
import { GetProductDetailsRes, ProductDetails } from '../../../common/interfaces';
import { defaultProductDetails } from './ProductDetailsForm.constants';

function reducer(state: ProductDetails, action: { key: string; value: any }): ProductDetails {
  if (action.key === 'all')
    return {
      ...state,
      ...action.value,
    };
  return {
    ...state,
    [action.key]: action.value,
  };
}

interface Props {
  productId: number | null;
}

export function useProductDetails({ productId }: Props) {
  const client = useApolloClient();

  // Product Details Form state (reducer)
  const [state, dispatch] = useReducer(reducer, {
    ...defaultProductDetails,
  });

  // Handle the loading state (for saving) manually since we're using client.query directly
  const [isLoading, setIsLoading] = useState(false);
  const { data: itemGroupsQuery, loading: isItemGroupsLoading } = useItemGroupsQuery();
  const itemGroups = itemGroupsQuery?.itemGroups ?? [];

  // There are only three quantity type choices, so hardcode the values for now
  // Use useMemo() to avoid recomputing on every render
  const quantityTypeChoices = useMemo(
    () => [
      { name: 'Text Box', quantityTypeId: 0 },
      { name: 'Drop Down', quantityTypeId: 1 },
      { name: 'Check Box', quantityTypeId: 2 },
    ],
    []
  );

  // Wait for productId to not be null then refresh
  // product details query (which triggers the effect above)
  useEffect(() => {
    if (productId !== null) {
      setIsLoading(true);
      // Manually call query using Apollo Client
      // because useLazyQuery still gets re-triggered when state changes (productId)
      client
        .query<GetProductDetailsRes>({
          query: GetProductDetailsDocument,
          variables: { productId },
          // Make sure it doesn't cache the response so we always fetch the latest
          fetchPolicy: 'network-only',
        })
        .then((res) => dispatch({ key: 'all', value: res.data.productDetails }))
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, [client, productId]);

  return {
    // Product Details state & dispatcher
    state,
    dispatch: (key: string, value: any) => dispatch({ key, value }),
    // Dropdown choices states
    quantityTypeChoices,
    itemGroupChoices: itemGroups,
    // Flags
    isLoading,
    isItemGroupsLoading,
  };
}
