import WaivenetLogo from '@/assets/svgs/waivenet-logo.svg';
import { Copyright, LoginHeader, StyledCheckBox, TsuButton } from '@/common/components';
import { StyledTextField } from '@/common/components/StyledTextField';
import AuthService from '@/features/auth/services/auth.service';
import { SHOP_ROUTE } from '@/features/shop/shop.routes';
import { AppBackground } from '@/layouts/AppBackground';
import { useApolloClient } from '@apollo/client';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetCurrentUserDocument, GetCurrentUserQuery } from '../../../graphql';
import { PasswordField } from '../components/PasswordField';
import oldWnAuthService from '../services/old-wn-auth.service';

export default function NormalSignin() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();

  const state = location.state as { message: string | undefined };

  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
    emailError: false,
    passwordError: false,
    error: '',
  });
  const [isQuickLogin, setIsQuickLogin] = useState(
    AuthService.isRememberEnabled() && AuthService.getRememberMeUsername() !== null
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValues({ ...values, [event.target.name]: value });
  };

  const [rememberMe, setRememberMe] = React.useState(true);

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked);
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    let error = '';
    let emailError = false;
    let passwordError = false;
    let formError = false;

    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(values.email)) {
      formError = true;
      emailError = true;
      error = 'Invalid Email.';
      return;
    }

    try {
      await AuthService.login(values.email, values.password, rememberMe);

      client.query<GetCurrentUserQuery>({ query: GetCurrentUserDocument }).then(async (res) => {
        if (!res.data.currentUser) return console.error('Could not log in to OLD WN');
        await oldWnAuthService.login(res.data.currentUser.username, values.password);
        // console.log('Successfully logged in to OLD WN');
      });

      return navigate(SHOP_ROUTE);
    } catch (err) {
      console.log('error:', JSON.stringify(err));
      console.error(err);
      if (err instanceof Error) setLoginError(err.message);
      setLoading(false);
    }

    if (formError) {
      emailError = true;
      passwordError = true;
      error = 'Invalid Credentials.';
    }

    setLoading(false);
    setValues({
      ...values,
      error: error,
      emailError: emailError,
      passwordError: passwordError,
    });
  };

  function handleDeleteRememberedAccount() {
    AuthService.removeRememberedAccount();
    setIsQuickLogin(false);
  }

  return (
    <AppBackground>
      <Stack alignItems="stretch" spacing={3} sx={{ minHeight: '100vh' }}>
        <LoginHeader />
        <Box flexGrow={1} display="flex" alignItems="stretch">
          <Grid
            container
            direction="row"
            alignItems={{ xs: 'start', md: 'center' }}
            textAlign="center"
          >
            <Grid item md={6} sm={12} xs={12} sx={{ px: { xs: 8 } }}>
              <Box width="100%" height="100%">
                <img
                  src={WaivenetLogo}
                  alt="WaiveNet"
                  style={{ width: '100%', height: '100%', maxWidth: 400, objectFit: 'contain' }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Stack direction="column" alignItems="center" sx={{ px: { xs: 4 } }}>
                <Card
                  elevation={20}
                  sx={{
                    minWidth: { md: '400px' },
                    width: { xs: '100%', md: 'auto' },
                    mr: { xs: 15, md: 0 },
                    ml: { xs: 15, md: 0 },
                    p: { xs: 1, md: theme.spacing(3) },
                    boxSizing: 'border-box',
                  }}
                >
                  <CardHeader
                    sx={{ color: 'primary.main', mt: theme.spacing(1) }}
                    title="Login"
                    titleTypographyProps={{ variant: 'h2', align: 'left' }}
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <Box
                      display={!loading ? 'none' : 'flex'}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircularProgress />
                      <Typography ml={theme.spacing(1)}>Logging in...</Typography>
                    </Box>
                    {!isQuickLogin ? (
                      <Box
                        textAlign="left"
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        hidden={loading}
                      >
                        <FormGroup>
                          <Typography
                            variant="caption"
                            fontWeight="bold"
                            mb={1}
                            sx={{ color: '#e2066f' }}
                          >
                            Email
                          </Typography>
                          <StyledTextField
                            required
                            autoFocus
                            name="email"
                            type="text"
                            error={values.emailError}
                            value={values.email}
                            onChange={handleChange}
                            placeholder="Email"
                            variant="outlined"
                            sx={{ color: '#e2066f' }}
                          />
                          <PasswordField
                            name="password"
                            value={values.password}
                            showPassword={values.showPassword}
                            error={values.passwordError}
                            onChange={handleChange}
                            onShowPassword={handleClickShowPassword}
                          />
                          <FormHelperText error sx={{ mx: 0, mt: theme.spacing(1) }}>
                            {values.error}
                          </FormHelperText>
                          <Grid container justifyContent="space-between" pt={1}>
                            <Grid item>
                              <FormControlLabel
                                control={
                                  <StyledCheckBox
                                    onChange={handleChangeCheckBox}
                                    disableRipple
                                    sx={{ color: '#e2066f' }}
                                  />
                                }
                                checked={rememberMe}
                                label={
                                  <Typography variant="caption" sx={{ color: '#e2066f' }}>
                                    Remember Me?
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid item alignSelf="center">
                              <Typography align="right" variant="caption">
                                <Link
                                  href="/forgotPassword"
                                  underline="none"
                                  color="primary"
                                  paddingLeft="4px"
                                  fontWeight="bold"
                                  sx={{ color: '#e2066f' }}
                                >
                                  Forgot Password?
                                </Link>
                              </Typography>
                            </Grid>
                          </Grid>
                          {state?.message && (
                            <Alert severity="error" sx={{ alignItems: 'center' }}>
                              {state.message}
                            </Alert>
                          )}
                          {loginError && (
                            <Alert severity="error" sx={{ alignItems: 'center' }}>
                              {loginError}
                            </Alert>
                          )}
                          <TsuButton
                            type="submit"
                            sx={{ alignSelf: 'stretch', mt: 3, backgroundColor: '#e2066f' }}
                          >
                            <Typography variant="body1" fontWeight="bold">
                              Log In
                            </Typography>
                          </TsuButton>
                          <Typography
                            pt={4.5}
                            variant="caption"
                            align="center"
                            sx={{ color: '#e2066f' }}
                          >
                            Having trouble logging in?
                            <Link
                              href="/contact-us"
                              underline="none"
                              paddingLeft="4px"
                              fontWeight="bold"
                              sx={{ color: '#e2066f' }}
                            >
                              Contact Us
                            </Link>
                          </Typography>
                        </FormGroup>
                      </Box>
                    ) : (
                      <Box
                        display={!loading ? 'flex' : 'none'}
                        flexDirection="column"
                        alignItems="start"
                      >
                        {!values.email ? (
                          <Stack direction="row" sx={{ width: '100%' }}>
                            <Button
                              onClick={() => {
                                setValues({
                                  ...values,
                                  email: AuthService.getRememberMeUsername()!,
                                });
                                setTimeout(() => {
                                  (
                                    document.querySelector("input[name='password']") as
                                      | HTMLInputElement
                                      | undefined
                                  )?.focus();
                                }, 150);
                              }}
                              variant="text"
                              sx={{ py: 2, px: { md: 4 }, wordBreak: 'break-all' }}
                            >
                              <AccountCircleIcon color="primary" sx={{ mr: 1, color: '#e2066f' }} />
                              <Typography
                                textAlign="left"
                                component="form"
                                autoComplete="off"
                                onSubmit={handleSubmit}
                                hidden={loading}
                                fontWeight="bold"
                                sx={{ color: '#e2066f' }}
                              >
                                {AuthService.getRememberMeUsername() ?? ''}
                              </Typography>
                            </Button>
                            <IconButton
                              onClick={handleDeleteRememberedAccount}
                              sx={{ alignSelf: 'center', color: '#e2066f' }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                        ) : (
                          <Box
                            onSubmit={handleSubmit}
                            width="100%"
                            display="flex"
                            flexDirection="column"
                            alignItems="stretch"
                            hidden={loading}
                          >
                            <Typography textAlign="left">{values.email}</Typography>
                            <PasswordField
                              name="password"
                              value={values.password}
                              error={values.passwordError}
                              showPassword={values.showPassword}
                              onChange={handleChange}
                              onShowPassword={handleClickShowPassword}
                              onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
                            />
                            <Button
                              type="submit"
                              variant="contained"
                              onClick={handleSubmit}
                              sx={{ alignSelf: 'stretch', mt: 3 }}
                            >
                              <Typography variant="body1" fontWeight="bold">
                                Log In
                              </Typography>
                            </Button>
                          </Box>
                        )}
                        <Button
                          variant="text"
                          onClick={() => setIsQuickLogin(false)}
                          sx={{ mt: 2 }}
                        >
                          <Typography fontSize="0.85em" sx={{ color: '#e2066f' }}>
                            Use a different account
                          </Typography>
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Copyright />
      </Stack>
    </AppBackground>
  );
}
