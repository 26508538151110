import { useLazyQuery } from '@apollo/client';
import { gql } from 'apollo-boost';
import {
  GetAdvancedReportHeadersRes,
  GetAdvancedReportRes,
  ReportProps
} from '../../../common/interfaces';

export function useReportAdvanced({ skip, take }: ReportProps) {
  const [refreshData, getReport] = useLazyQuery<GetAdvancedReportRes>(
    gql`
      query ($skip: Int, $take: Int) {
        advancedReport(skip: $skip, take: $take) {
          totalCount
          items {
            uniqueId
            orderStatus
            orderType
            createdOn
            shippingNo
            despatchDate
            ordererFirstName
            ordererLastName
            billingBusinessLevel
            recipientFirstName
            recipientLastName
            companyName
            deliveryStreetAddress
            deliverySuburb
            deliveryPostcode
            deliveryNote
            orderTotal
            gstTotal
            grandTotal
          }
        }
      }
    `,
    { variables: { skip, take } }
  );

  const [refreshHeaders, getHeaders] =
    useLazyQuery<GetAdvancedReportHeadersRes>(
      gql`
        query {
          advancedReportHeaders {
            text
            value
          }
        }
      `
    );

  async function refresh() {
    await refreshData();
    await refreshHeaders();
  }

  return {
    refresh,
    data: getReport.data?.advancedReport.items ?? [],
    headers: getHeaders.data?.advancedReportHeaders ?? [],
    totalCount: getReport.data?.advancedReport.totalCount ?? 0,
    loading: getReport.loading || getHeaders.loading,
    error: getReport.error || getHeaders.error,
  };
}
