import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Card,
  Checkbox,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { PropsWithChildren, useState } from 'react';

interface CartTileProps {
  title: string;
  checked?: boolean;
  onCheckboxChange?: (checked: boolean) => void;
}

export function CartTile(props: PropsWithChildren<CartTileProps>) {
  const { title, checked, onCheckboxChange, children } = props;
  const theme = useTheme();
  const [expanded, setExpanded] = useState(true);

  return (
    <Card>
      <Stack direction="row" spacing={2} alignItems="center">
        <Checkbox
          checked={checked}
          onChange={(e) => onCheckboxChange?.(e.target.checked)}
          sx={{ color: theme.palette.primary.main }}
        />
        <Typography variant="h6">{title}</Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <IconButton onClick={() => setExpanded(!expanded)} aria-label="chevron">
          {expanded ? (
            <ExpandLessIcon sx={{ color: theme.palette.primary.main }} />
          ) : (
            <ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />
          )}
        </IconButton>
      </Stack>
      {expanded ? children : null}
    </Card>
  );
}
