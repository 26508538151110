export const SIGNIN_ROUTE = '/signin';

export enum CustomerFeatureEnum {
  DashboardBannerImages = 'dashboardBannerImages',
  DashboardGetStarted = 'dashboardGetStarted',
  DashboardOrdersForApproval = 'dashboardOrdersForApproval',
  DashboardSavedOrders = 'dashboardSavedOrders',
  DashboardTopOrderedProducts = 'dashboardTopOrderedProducts',
  CheckoutCreditsPoints = 'checkoutCreditsPoints',
  DashboardOrdersInProgress='dashboardOrderInProgress',
  CheckoutPaymentMethodEWay='checkoutPaymentMethodEWay',
  CheckoutPaymentMethodInvoice='checkoutPaymentMethodInvoice',
  CheckoutVACCAlternativePaymentMethod='checkoutVACCAlternativePaymentMethod',
  ProductShowAttributes='productShowAttributes'
}
