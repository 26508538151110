import { UnderConstructionBanner } from '@/common/components/UnderConstructionBanner';
import { useMemo } from 'react';
import { CategoriesForm } from './CategoriesForm';
import { ChildProductsTab } from './ChildProductsTab';
import { CrossUpSalesForm } from './CrossUpSalesForm';
import { MediaTab } from './MediaTab';
import { ProductDetailsForm } from './ProductDetailsForm';
import { StocktakeSettingsForm } from './StocktakeSettingsForm';
import { UomTab } from './UomTab';

interface Props {
  productId: number | null;
  onClose?: () => void;
}

export function useEditProductDialog({ productId, onClose }: Props) {
  const tabs = useMemo(
    () => [
      {
        label: 'Product Details',
        tab: 'product-details',
        component: <ProductDetailsForm productId={productId} onCancel={onClose} />,
      },
      {
        label: 'UOM',
        tab: 'uom',
        component: <UomTab productId={productId} />,
      },
      {
        label: 'Categories',
        tab: 'categories',
        component: <CategoriesForm productId={productId} onCancel={onClose} />,
      },
      {
        label: 'Cross/Up Sale',
        tab: 'cross-up-sale',
        component: <CrossUpSalesForm productId={productId} onCancel={onClose} />,
      },
      {
        label: 'Attributes/Variants',
        tab: 'attributes',
        component: <ChildProductsTab productId={productId} />,
      },
      {
        label: 'Media',
        tab: 'media',
        component: <MediaTab productId={productId} />,
      },
      {
        label: 'Price Breaks',
        tab: 'price-breaks',
        component: <UnderConstructionBanner />,
      },
      {
        label: 'Kitting',
        tab: 'kitting',
        component: <UnderConstructionBanner />,
      },
      {
        label: 'Stocktake Settings',
        tab: 'stocktake',
        component: <StocktakeSettingsForm />,
      },
      {
        label: 'Document',
        tab: 'document',
        component: <UnderConstructionBanner />,
      },
      {
        label: 'Properties',
        tab: 'properties',
        component: <UnderConstructionBanner />,
      },
    ],
    [productId, onClose]
  );

  return { tabs };
}
