import { Copyright } from '@/common/components';
import { useCustomerTheme } from '@/common/theme';
import { DASHBOARD_ROUTE } from '@/features/dashboard/dashboard.routes';
import {
  Container,
  createTheme,
  Grid,
  Link,
  LinkProps,
  Paper,
  Stack,
  ThemeProvider,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ContactUsForm } from '../features/contact-us/components/ContactUsForm';
import { useFooterItems } from './useFooterItems';

export function Footer() {
  const theme = useTheme();
  const customerTheme = useCustomerTheme();
  const matchesXxl = useMediaQuery('(min-width: 1600px)');
  const matchesLg = useMediaQuery('(min-width: 1200px)');
  const FooterItems = useFooterItems();

  const textProps: TypographyProps = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    color: customerTheme?.footer.text ?? 'text.primary',
  };

  const linkProps: LinkProps = {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '16px',
    color: customerTheme?.footer.text ?? 'text.primary',
  };

  const StyledLink = (props: LinkProps) => <Link {...props} {...linkProps} />;

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        paddingTop: 4,
        paddingBottom: 2,
        backgroundColor: customerTheme?.footer.background ?? 'background.paper',
        boxSizing: 'border-box',
      }}
    >
      <Container maxWidth={matchesXxl ? 'xl' : 'lg'} disableGutters>
        <Grid
          container
          spacing={{ xs: 3, md: 1 }}
          sx={{
            left: '0px',
            top: '64px',
            margin: '16px 0px',
          }}
          wrap={matchesLg ? 'nowrap' : 'wrap'}
        >
          <Grid item xs={12} lg={6} sx={{ mx: theme.spacing(4) }}>
            <ThemeProvider
              theme={createTheme(theme, {
                typography: {
                  allVariants: {
                    color: customerTheme?.footer.text ?? theme.palette.text.primary,
                  },
                },
                components: {
                  MuiButton: {
                    styleOverrides: {
                      contained: {
                        backgroundColor: customerTheme?.footer.button ?? theme.palette.primary.main,
                      },
                    },
                  },
                },
              })}
            >
              <ContactUsForm />
            </ThemeProvider>
          </Grid>
          <Grid item xs={12} lg={2} sx={{ mx: theme.spacing(4) }}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h4" {...textProps}>
                Main pages
              </Typography>
              {FooterItems.map((m, i) => (
                <StyledLink
                  key={i}
                  href={m.to}
                  underline="none"
                >
                  {m.text}
                </StyledLink>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            lg={2}
            sx={{ mx: theme.spacing(4), mt: { xs: theme.spacing(0), md: theme.spacing(2), lg: 0 } }}
          >
            <Stack direction="column" spacing={2}>
              <Typography variant="h4" {...textProps}>
                Setting page
              </Typography>
              <StyledLink href="/accountSettings" underline="none">
                Account setting
              </StyledLink>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Copyright />
    </Paper>
  );
}
