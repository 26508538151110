import { MenuItem, Select, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import FONTS from '../../../assets/fonts.json';

interface Props {
  label?: string;
  value?: string;
  onChange?: (v: string) => void;
  sx?: SxProps;
}

export function FontsField(props: Props) {
  const theme = useTheme();
  const [font, setFont] = useState<string>();

  function handleChange(newFont: string) {
    setFont(newFont);
    props.onChange?.(newFont);
  }

  return (
    <Stack sx={props.sx}>
      <Typography variant="h4">{props.label}</Typography>
      <Typography fontFamily={font}>This is a sample text.</Typography>
      <Select
        value={props.value}
        onChange={(e) => handleChange(e.target.value)}
        sx={{ backgroundColor: 'white' }}
      >
        {FONTS.families.map((font, i) => {
          const fontName = font.split(':').shift();
          return (
            <MenuItem key={i} value={fontName}>
              {fontName}
            </MenuItem>
          );
        })}
      </Select>
    </Stack>
  );
}
