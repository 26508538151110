import { oldWnAxiosClient } from '../../../core/old-wn';

class OldWaivenetAuthService {
  async login(username: string, password: string) {
    await oldWnAxiosClient.get('/API/Core/User/Logon', {
      params: {
        user: username,
        pass: password,
        isPunchout:true
      },
    });
  }

  async changeCustomer(customerId: number) {
    await oldWnAxiosClient.get('/API/Core/Customer/ChangeCustomer', {
      params: { customerID: customerId },
    });
  }
}

export default new OldWaivenetAuthService();
