import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Button, IconButton } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useCallback } from 'react';
import { ITsuTableItem } from '../../../../common/components/tsu-table/interfaces';
import { ISavedOrder } from '../../../../common/interfaces';

interface Args {
  onOrder?: (o: ISavedOrder) => void;
  onDelete?: (os: ISavedOrder) => void;
}

function useSavedOrdersRender({ onOrder, onDelete }: Args) {
  const customItemRender = useCallback(
    (_i: ITsuTableItem, value: string) => {
      const i = _i as ISavedOrder;
      switch (value) {
        case 'order':
          return (
            <Button
              onClick={() => onOrder?.(i)}
              variant="text"
              sx={{ color: '#42B988' }}
            >
              <ShoppingCartIcon sx={{ color: '#42B988', mr: 1 }} />
              Load to Cart
            </Button>
          );
        case 'delete':
          return (
            <Button onClick={() => onDelete?.(i)} sx={{ color: '#E35E5D' }}>
              <DeleteIcon sx={{ color: '#E35E5D', mr: 1 }} />
              Remove/Un-save
            </Button>
          );
        case 'edit':
          return (
            <Button variant="text" sx={{ color: '#DA006D' }}>
              <EditIcon sx={{ color: '#DA006D', mr: 1 }} />
              Edit Order
            </Button>
          );
        case 'created':
          let date = i[value];
          // Some dates can be null
          if (date === null || date === undefined) return <span>-</span>;
          // Some dates are returned as string (not sure why, but let's handle it)
          if (typeof date === 'string') date = parseISO(date);
          return <span>{format(date, 'dd/MM/yyyy')}</span>;
        default:
          return i[value as keyof ISavedOrder];
      }
    },
    [onOrder, onDelete]
  );

  return { customItemRender };
}

export default useSavedOrdersRender;
