import {
  useApproveMultipleOrderShippingsMutation,
  useApproveOrderShippingMutation,
  useCancelOrderShippingMutation,
  useUpdateOrderLineQuantityMutation,
  useUpdateOrderShippingFeeMutation
} from '@/graphql';

export function useOrderShippingApproval() {
  const [approveOS, approveOSMut] = useApproveOrderShippingMutation();
  const [approveMultiOS, approveMultiOSMut] = useApproveMultipleOrderShippingsMutation();
  const [cancelOS, cancelOSMut] = useCancelOrderShippingMutation();
  const [editOrderLine, editOrderLineMut] = useUpdateOrderLineQuantityMutation();
  const [updateOrderShippingFee, updateOrderShippingFeeMut] = useUpdateOrderShippingFeeMutation();

  return {
    approveOS,
    approveOSMut,
    approveMultiOS,
    approveMultiOSMut,
    cancelOS,
    cancelOSMut,
    editOrderLine,
    editOrderLineMut,
    updateOrderShippingFee,
    updateOrderShippingFeeMut
  };
}
