import { TsuButton, TsuField } from '@/common/components';
import { Box, Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { AddressAutocomplete } from '../components/AddressAutocomplete';
import { addressComponents, ChangeAddressMenu } from '../components/ChangeAddressMenu';
import { Address } from '../interfaces';
import { getDetails } from '../utils';

interface Props {
  title?: string;
  readOnly?: boolean;
  noAutocomplete?: boolean;
}

export function AddressForm(props: Props) {
  const { readOnly } = props;
  const methods = useFormContext<Address>();
  const register = methods?.register ?? (() => ({}));
  const watch = methods?.watch ?? (() => '');
  const errors = methods?.formState?.errors;

  const handleAddressSelect = (address: addressComponents) => {
    // Assuming methods is available and the correct useFormContext is used
    methods.setValue('streetAddress', address.street1);
    methods.setValue('suburb', address.suburb);
    methods.setValue('state', address.state);
    methods.setValue('postcode', address.postcode);
    methods.setValue('country', address.country);
    methods.setValue('deliveryNotes', address.deliveryNote);
  };

  async function autoFillAddress(placeId: string | null) {
    if (!placeId) return;
    try {
      const details = await getDetails(placeId);
      const addressComponents = details.address_components ?? [];
      // Street address is usually separated into multiple components,
      // so we need to build it as we loop through the components.
      let streetAddressComponents: string[] = [];
      // Reference: https://developers.google.com/maps/documentation/javascript/place-autocomplete#constrain-place-types
      for (const component of addressComponents) {
        const { long_name, types } = component;
        if (types.includes('subpremise')) streetAddressComponents.push(long_name);
        if (types.includes('street_number')) streetAddressComponents.push(long_name);
        if (types.includes('route')) streetAddressComponents.push(long_name);
        if (types.includes('locality')) methods.setValue('suburb', long_name);
        if (types.includes('administrative_area_level_1')) methods.setValue('state', long_name);
        if (types.includes('country')) methods.setValue('country', long_name);
        if (types.includes('postal_code')) methods.setValue('postcode', long_name);
      }
      if (streetAddressComponents.length > 0) {
        let streetAddress = '';
        if (
          addressComponents.some((c) => c.types.includes('subpremise')) &&
          addressComponents.some((c) => c.types.includes('street_number'))
        ) {
          streetAddress =
            streetAddressComponents.slice(0, 2).join('/') +
            ' ' +
            streetAddressComponents.slice(2).join(' ');
        } else {
          streetAddress = streetAddressComponents.join(' ');
        }
        methods.setValue('streetAddress', streetAddress);
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Stack sx={{ marginY: (theme) => readOnly ? `${theme.typography.pxToRem(1.2)} !important` : `${theme.spacing(2)} !important` , marginTop: '20px'}}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: readOnly ? "1.2" : 0 }}
      >
        <Typography sx={{fontSize: readOnly ? '1.2rem' : '1.5rem'}}><b>{props.title ?? 'Address'}</b></Typography>
        <Box hidden={readOnly}>
          {/* <TsuButton text>
            <Typography>Add New Address</Typography>
          </TsuButton> */}
          <br/>
          <ChangeAddressMenu onClick={handleAddressSelect}/>
        </Box>
      </Stack>
      <TsuField
        {...register('businessName')}
        value={watch('businessName')}
        name="businessName"
        label="Business Name"
        errorMessage={errors?.businessName?.message}
        onlyValue={readOnly}
      />
      {!props.noAutocomplete ? (
        <AddressAutocomplete onChange={autoFillAddress} readOnly={readOnly} />
      ) : null}
      <TsuField
        {...register('streetAddress')}
        value={watch('streetAddress')}
        name="streetAddress"
        required
        label="Street"
        errorMessage={errors?.streetAddress?.message}
        onlyValue={readOnly}
      />
      <Stack direction="row" spacing={2}>
        <TsuField
          {...register('suburb')}
          value={watch('suburb')}
          name="suburb"
          required
          label="Suburb"
          errorMessage={errors?.suburb?.message}
          onlyValue={readOnly}
        />
        <TsuField
          {...register('state')}
          value={watch('state')}
          name="state"
          required
          label="State"
          errorMessage={errors?.state?.message}
          onlyValue={readOnly}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <TsuField
          {...register('postcode')}
          value={watch('postcode')}
          name="postcode"
          required
          label="Postcode"
          errorMessage={errors?.postcode?.message}
          onlyValue={readOnly}
        />
        <TsuField
          {...register('country')}
          value={watch('country')}
          name="country"
          required
          label="Country"
          errorMessage={errors?.country?.message}
          onlyValue={readOnly}
        />
      </Stack>

      <TsuField
        {...register('deliveryNotes')}
        value={watch('deliveryNotes')}
        name="deliveryNotes"
        label="Delivery Notes (please indicate special delivery instructions)"
        multiline
        showLabel
        errorMessage={errors?.deliveryNotes?.message}
        onlyValue={readOnly}
        sx={{ width: '100%', mt: readOnly ? 2 : 0 }}
        rows={5}
      />
    </Stack>
  );
}
