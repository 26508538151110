import Button from '@mui/material/Button';
import { format, parseISO } from 'date-fns';
import { useCallback } from 'react';
import { ITsuTableItem } from '../../../../common/components/tsu-table/interfaces';
import { IOrdersInProgress } from '../../../../common/interfaces';

interface Args {
  onOrder?: (o: IOrdersInProgress) => void;
}

function useOrdersInProgressRender({ onOrder}: Args) {
  const customItemRender = useCallback(
    (_i: ITsuTableItem, value: string) => {
      const i = _i as IOrdersInProgress;
      switch (value) {
        case 'created':
          let date = i[value];
          // Some dates can be null
          if (date === null || date === undefined) return <span>-</span>;
          // Some dates are returned as string (not sure why, but let's handle it)
          if (typeof date === 'string') date = parseISO(date);
          return <span>{format(date, 'dd/MM/yyyy')}</span>;
        
        case 'orderId':
          return <Button
            variant="text"
            onClick={() => onOrder?.(i)}
          >
            {i.orderId}
          </Button>
        case 'status':
          return <span>{i.orderShippings.at(0)?.status.name}</span>;
        case 'createdBy':
          return <span>{i.userCreatedFirstName} {i.userCreatedLastName}</span>;
        default:
          return i[value as keyof IOrdersInProgress];
      }
    },
    [onOrder]
  );

  return { customItemRender };
}

export default useOrdersInProgressRender;
