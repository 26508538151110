import { TsuLoading } from '@/common/components';
import BulletLoader from '@/common/components/loaders/BulletLoader';
import {
  useAutoPopulateCustomerFeaturesMutation,
  useGetCustomerFeaturesQuery,
  useUpdateMultipleCustomerFeaturesMutation,
} from '@/graphql';
import { Button, Checkbox, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

export function FeaturesForm() {
  const { enqueueSnackbar } = useSnackbar();
  // Queries & mutations
  const {
    data: getFeaturesQuery,
    refetch: getFeatures,
    loading: isLoadingFeatures,
  } = useGetCustomerFeaturesQuery();
  const [updateFeatures, { loading: isUpdatingFeatures }] =
    useUpdateMultipleCustomerFeaturesMutation();
  const [autoPopulateFeatures, { loading: isAutoPopulating }] =
    useAutoPopulateCustomerFeaturesMutation();

  // Derived state
  const features = getFeaturesQuery?.customerFeatures ?? [];
  // Local state
  const [enabledFeatureIds, setEnabledFeatureIds] = useState<number[]>([]);

  useEffect(() => {
    if (!isLoadingFeatures) {
      const enabledIds = features
        .filter(f => f.access == true)
        .map((f) => f.tsuCustomerFeatureId);
      setEnabledFeatureIds(enabledIds);
    }
  }, [isLoadingFeatures]);
  // Helper functions
  const isFeatureEnabled = (id: number) => enabledFeatureIds.some((efId) => efId === id);

  function handleToggleFeature(id: number) {
    if (isFeatureEnabled(id)) {
      setEnabledFeatureIds(enabledFeatureIds.filter((efId) => efId !== id));
    } else {
      setEnabledFeatureIds([...enabledFeatureIds, id]);
    }
  }

  async function saveFeatures() {
    await updateFeatures({
      variables: {
        updates: features.map((f) => ({
          custFeatureId: f.tsuCustomerFeatureId,
          access: isFeatureEnabled(f.tsuCustomerFeatureId),
        })),
      },
    });
    window.location.reload();
    enqueueSnackbar('Updated Customer Features', { variant: 'success' });
  }

  async function setUpFeatures() {
    await autoPopulateFeatures();
    enqueueSnackbar('Populated Customer Features', { variant: 'success' });
    await getFeatures();
  }

  return (
    <TsuLoading
      loading={isLoadingFeatures || isUpdatingFeatures}
      loader={<BulletLoader />}
      justifyContent="start"
    >
      <Stack>
        {!isLoadingFeatures && features.length === 0 && (
          <Stack direction="row" alignItems="start" sx={{ mb: 1 }}>
            <TsuLoading loading={isAutoPopulating}>
              <Typography>This customer does not have any features configured</Typography>
              <Button onClick={setUpFeatures} variant="contained">
                Set up Customer Features
              </Button>
            </TsuLoading>
          </Stack>
        )}
        {features.map((f, i) => (
          <Stack direction="row" alignItems="center" key={i}>
            <Checkbox
              key={i}
              checked={isFeatureEnabled(f.tsuCustomerFeatureId)}
              onChange={() => handleToggleFeature(f.tsuCustomerFeatureId)}
            />
            <Typography>{f.tsuFeature.description}</Typography>
          </Stack>
        ))}
        <Button
          variant="contained"
          onClick={saveFeatures}
          disabled={features.length === 0}
          sx={{ alignSelf: 'end' }}
        >
          Save
        </Button>
      </Stack>
    </TsuLoading>
  );
}
