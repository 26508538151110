import { AppLayout } from '@/layouts/AppLayout';
import { Container } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const releaseNotes = `

## 1.0.1 (December 19, 2023)

- #### Deployment updates
  - Order Approval feature added
  - eWay payment method added
  - Freight methods now can be set in Roles administration
  - Clothing products now have size options
  - Theme Builder banner now can delete and change images
  - Updated Readme

## 0.1.17 (May 11, 2023)

- #### Deployment updates
  - User details pre-populated at checkout
  - Fix the bug where sometimes cart becomes null due to the cache
  - Updated Readme

## 0.1.16 (December 01, 2022)

- #### Deployment updates
  - Switch to "Trunk-based development" (modern approach). Stop "Gitflow" (older approach)
  - Support DEV, UAT, PROD WN2 websites

## 0.1.15 (November 21, 2022)

- #### Checkout process updates
  - Add better validations for email, phone, and mobile.
  - Add error messages below required fields, and prevent user from being able to continue without filling required fields.
  - Add **Google Places Autocomplete** field for Delivery Address.

- #### Bug fixes
  - Hotfix release notes not showing properly (Martin)
  - Limit Shop items per page to 50 (Vinh)
  - Fix stock add to cart bug (Vinh)
  - Hotfix bug in Theme Builder causing issues in Logo Bar section (Martin)

## 0.1.14 (November 16, 2022)

- Added Release Notes page
- Fix bugs in Navigation Bar permission checking
- Hid "Parent Products" from Shop (Martin), Product Management (Vinh)
- Added initial "WN Bulk Order Punchout" (Hammad)
- Added initial "Waivenet Report Punchout" (Vinh)
- Hotfix multiple issues in Checkout flow (Martin)
  - Make sure Delivery Address, Contact Info, Billing Address are all required
  - Make "Delivery Notes" required
- Fixed multiple issues in Shop page (Martin)
  - Fixed shop not refreshing when changing customer
`;

export function ReleaseNotes() {
  return (
    <AppLayout title="Release Notes">
      <Container sx={{ flexGrow: 1, my: 4, alignSelf: 'center' }} maxWidth="sm">
        <ReactMarkdown>{releaseNotes}</ReactMarkdown>
      </Container>
    </AppLayout>
  );
}
