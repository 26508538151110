import { useEffect, useRef, useState } from 'react';
import { ITsuTableItem } from './interfaces';

export function useTableCellWidth(items: ITsuTableItem) {
  const tbodyRef = useRef<HTMLTableSectionElement | null>(null);
  const [tdWidth, setTdWidth] = useState(0);

  useEffect(() => {
    if (items.length) {
      // For some reason even if the items are available the table cells
      // still have zero width. If we wait for a second, it seems to work
      setTimeout(() => {
        const td = tbodyRef.current?.querySelector('tr td:nth-child(1)');
        if (td) {
          // Source: https://stackoverflow.com/a/25197206
          // setTdWidth(
          //   parseInt(getComputedStyle(td, null).getPropertyValue('width'))
          // );
          setTdWidth(td.clientWidth);
        }
      }, 1000);
    }
  }, [items]);

  return { tdWidth, tbodyRef };
}
