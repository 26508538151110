import { FeatureRoute } from '@/common/interfaces/feature-route';
import { WaivenetReport } from './screens/WaivenetReport';

export const waivnetReportRoutes: FeatureRoute[] = [
  {
    path: '/wnreports',
    component: WaivenetReport,
    options: {
      useCustomerTheme: true,
    },
  },
];