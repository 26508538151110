import { IBasicFilter } from '../../common/interfaces';

export const dateTimeFilters = [
  { title: 'Do not change value', description: 'Use same value as of current filter' },
  {
    title: 'Use date of reporting',
    description: 'Use date time when the scheduled report is sent',
  },
  {
    title: 'Use first day of previous week',
    description: 'Monday of last week, from when the scheduled report is sent',
  },
  {
    title: 'Use first day of this week',
    description: 'Monday of this week, from when the scheduled report is sent',
  },
  {
    title: 'Use first day of previous month',
    description: 'First day of last month, from when the scheduled report is sent',
  },
  {
    title: 'Use first day of current month',
    description: 'First day of this month, from when the scheduled report is sent',
  },
  {
    title: 'Use first day of previous financial quarter',
    description: 'First day of last financial quarter, from when the scheduled report is sent',
  },
  {
    title: 'Use first day of last two financial quarters',
    description: 'First day of last two financial quarters, from when the scheduled report is sent',
  },
  {
    title: 'Use first day of this financial quarter',
    description: 'First day of this financial quarter, from when the scheduled report is sent',
  },
  {
    title: 'Use first day of last year',
    description: '1 January of last year, from when the scheduled report is sent',
  },
  {
    title: 'Use first day of this month',
    description: '1 January of this year, from when the scheduled report is sent',
  },
  {
    title: 'Use last day of previous week',
    description: 'Sunday of last week, from when the scheduled report is sent',
  },
  {
    title: 'Use last day of previous month',
    description: 'Last day of last month, from when the scheduled report is sent',
  },
  {
    title: 'Use last day of previous financial quarter',
    description: 'Last day of last financial quarter, from when the scheduled report is sent',
  },
  {
    title: 'Use last day of last two financial quarter',
    description: 'Last day of last two financial quarters, from when the scheduled report is sent',
  },
  { title: 'Use last day of last year', description: '31 December of last year' },
  { title: 'Do not filter', description: 'Do not filter this column' },
  {
    title: 'Use previous 24 hours',
    description: 'Last 25 hours, from when the scheduled report is sent',
  },
].map((f, i) => ({ ...f, value: i }));

export const basicFilterKeys = [
  { text: 'Order Status', value: 'orderStatus' },
  { text: 'Order Type', value: 'orderType' },
  { text: 'Suburb', value: 'suburb' },
  { text: 'State', value: 'state' },
];

export const initialBasicFilter = {
  orderStatus: null,
  orderType: null,
  suburb: null,
  state: null,
} as IBasicFilter;

export const suburbChoices = [
  { text: 'Carlton', value: 'Carlton' },
  { text: 'Docklands', value: 'Docklands' },
  { text: 'East Melbourne', value: 'East Melbourne' },
  { text: 'Kensington', value: 'Kensington' },
  { text: 'North Melbourne', value: 'North Melbourne' },
  { text: 'Parkville', value: 'Parkville' },
];

export const stateChoices = [
  { text: 'New South Wales', value: 'New South Wales' },
  { text: 'Queensland', value: 'Queensland' },
  { text: 'South Australia', value: 'South Australia' },
  { text: 'Tasmania', value: 'Tasmania' },
  { text: 'Victoria', value: 'Victoria' },
  { text: 'Western Australia', value: 'Western Australia' },
  {
    text: 'Australian Capital Territory',
    value: 'Australian Capital Territory',
  },
  { text: 'Northern Territory', value: 'Northern Territory' },
];

export const conditionChoices = [
  { text: 'Contains', value: 'contains' },
  { text: 'Is Less Than', value: 'lt' },
  { text: 'Is Equal To', value: 'eq' },
  { text: 'Is Greater Than', value: 'gt' },
];

export const allHeaders = [
  { text: 'Unique ID', value: 'orderId', type: 'number' },
  { text: 'Order Status', value: 'statusName', type: 'string' },
  { text: 'Order Type', value: 'orderTypeName', type: 'string' },
  { text: 'Created On', value: 'created', type: 'date' },
  { text: 'Shipping No.', value: 'shippingTrackLink', type: 'string' },
  { text: 'Despatch Date', value: 'despatchedDate', type: 'date' },
  {
    text: "Orderer's First Name",
    value: 'billingUserFirstName',
    type: 'string',
  },
  {
    text: "Orderer's Last Name",
    value: 'billingUserLastName',
    type: 'string',
  },
  {
    text: 'Billing Business Level',
    value: 'billingBusinessLevelNameList',
    type: 'string',
  },
  {
    text: "Recipient's First Name",
    value: 'deliveryUserFirstName',
    type: 'string',
  },
  {
    text: "Recipient's Last Name",
    value: 'deliveryUserLastName',
    type: 'string',
  },
  { text: 'Company Name', value: 'deliveryCompanyName', type: 'string' },
  { text: 'Delivery Street Address', value: 'deliveryStreet1', type: 'string' },
  { text: 'Delivery Suburb', value: 'deliverySuburb', type: 'string' },
  { text: 'Delivery State', value: 'deliveryState', type: 'string' },
  { text: 'Delivery Postcode', value: 'deliveryPostcode', type: 'string' },
  { text: 'Delivery Note', value: 'deliveryNote', type: 'string' },
  { text: 'Order Total', value: 'invoiceTotal', type: 'string' },
  { text: 'GST Total', value: 'gsttotal', type: 'string' },
  { text: 'Grand Total', value: 'grandTotal', type: 'string' },
];

export const productHeaders = [
  { text: 'Waivenet Code', value: 'productCode', type: 'string' },
  { text: 'Product Name', value: 'name', type: 'string' },
  { text: 'Main Category', value: 'reportCategory', type: 'string' },
  { text: 'Sub Category', value: 'reportCategory', type: 'string' },
  { text: 'Total Weight', value: 'weightKg', type: 'number' },
  { text: 'Quantity', value: 'multiplier', type: 'number' },
  { text: 'Unit Price', value: 'sellPrice', type: 'number' },
  { text: 'Grand Total', value: 'sellPrice', type: 'number' },
];

export const basicHeaderValues = [
  'orderId',
  'statusName',
  'created',
  'shippingTrackLink',
  'despatchedDate',
  'deliveryUserFirstName',
  'deliveryUserLastName',
  'deliveryCompanyName',
  'deliveryStreet1',
  'deliverySuburb',
  'deliveryPostcode',
  'invoiceTotal',
  'gsttotal',
  'grandTotal',
];

export const advancedHeaderValues = [
  'orderId',
  'statusName',
  'orderTypeName',
  'created',
  'shippingTrackLink',
  'despatchedDate',
  'billingUserFirstName',
  'billingUserLastName',
  'billingBusinessLevelNameList',
  'deliveryUserFirstName',
  'deliveryUserLastName',
  'deliveryMethod',
  'deliveryCompanyName',
  'deliveryStreet1',
  'deliverySuburb',
  'deliveryPostcode',
  'deliveryNote',
  'invoiceTotal',
  'gsttotal',
  'grandTotal',
];
