import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useContext, useReducer, useState } from 'react';
import { CustomOrderType } from '../components/RoleOrderTypesList';
import { SelectedRoleContext } from '../context/selected-role.context';
import { Role } from '../interfaces';
import { CustomFreightSetting } from '../interfaces/freight-setting.interfaces';
import { UpdateRoleInput } from '../interfaces/update-role.interfaces';
import { UserAction } from '../interfaces/user-action.interfaces';
import { useRoleApi } from './useRoleApi';

function reducer(state: UpdateRoleInput, action: { key: string; value: any }): UpdateRoleInput {
  if (action.key === 'all') return { ...state, ...action.value };
  return {
    ...state,
    [action.key]: action.value,
  };
}

export function useEditRoleForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedRole } = useContext(SelectedRoleContext);
  const roleApi = useRoleApi();
  const [selectedUserActions, setSelectedUserActions] = useState<UserAction[]>([]);
  const [selectedOrderTypes, setSelectedOrderTypes] = useState<CustomOrderType[]>([]);
  const [selectedFreightSettings, setSelectedFreightSettings] = useState<CustomFreightSetting[]>(
    []
  );
  const [state, dispatch] = useReducer(reducer, {
    name: selectedRole?.name,
    spendLimitPerDay: selectedRole?.baseRole.maxSpendByDays,
    spendLimitDays: selectedRole?.baseRole.maxSpendDays,
    minOrderTotal: selectedRole?.baseRole.minOrderTotal,
    maxOrderQuantity: selectedRole?.baseRole.maxOrderQuantity,
    discount: selectedRole?.discount,
    discountUom: Boolean(selectedRole?.baseRole.discountIsPercentage) ? '%' : '$',
    defaultWarehouse: selectedRole?.baseRole.defaultWarehouse,
    zeroFreightOrderAmount: selectedRole?.baseRole.zeroFreightOrderAmount,
  } as UpdateRoleInput);

  async function handleSave() {
    var role_input = state;
    role_input.spendLimitPerDay = state.spendLimitPerDay?.toString() ?? null;
    role_input.spendLimitDays = state.spendLimitDays?.toString() ?? null;
    role_input.minOrderTotal = state.minOrderTotal?.toString() ?? null;
    role_input.maxOrderQuantity = state.maxOrderQuantity?.toString() ?? null;
    role_input.discount = state.discount?.toString() ?? null;
    role_input.zeroFreightOrderAmount = state.zeroFreightOrderAmount?.toString() ?? null;
    if (!selectedRole) return;
    await roleApi.updateRole({
      roleId: selectedRole.roleId,
      role: role_input,
      selectedUserActionIds: selectedUserActions.map((ua) => ua.actionId),
      selectedOrderTypeIds: selectedOrderTypes.map((ot) => ot.orderTypeId),
      selectedFreightSettingIds: selectedFreightSettings.map((fs) => fs.freightSettingId),
    });
    window.location.reload();
    enqueueSnackbar('Updated role', { variant: 'success' });
  }

  return {
    // State
    formState: state,
    dispatch: (key: string, value: string) => dispatch({ key, value }),
    handleSave,
    handleUserActionsChange: setSelectedUserActions,
    handleOrderTypesChange: setSelectedOrderTypes,
    handleFreightSettingsChange: setSelectedFreightSettings,
    isSaving: roleApi.isUpdatingRole,
  };
}
