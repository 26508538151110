import { FeatureRoute } from '@/common/interfaces/feature-route';
import { ContactUs } from '@/features/contact-us/screens/ContactUs';
import Maintenance from '@/common/screens/Maintenance';
import PageNotFound from '@/common/screens/PageNotFound';
import { administrationRoutes } from './administration/administration.routes';
import { authRoutes } from './auth/auth.routes';
import { cartRoutes } from './cart/cart.routes';
import { checkoutRoutes } from './checkout/routes';
import { dashboardRoutes } from './dashboard/dashboard.routes';
import { etemplatesRoutes } from './e-templates/e-templates.routes';
import { faqRoutes } from './faq/faq.routes';
import { favoritesRoutes } from './favorites/favorites.routes';
import { orderManagementRoutes } from './order-management/order-management.routes';
import { productManagementRoutes } from './product-management/product-management.routes';
import { reportRoutes } from './report/report.routes';
import { shopRoutes } from './shop/shop.routes';
import { themeBuilderRoutes } from './theme/theme.routes';
import { docManagerRoutes } from './punchout/document-management/document-management.routes';
import { projectManagementRoutes } from './punchout/pms/project-management.routes';
import { waivnetReportRoutes } from './punchout/wnreport/waivenet-report.routes';
import { rgaListRoutes } from './punchout/rga-list/rga-list.routes';
import { waivenetAdminRoutes } from './punchout/waivenet-admin/waivenet-admin.routes';
import { expectedDeliveryUploadRoutes } from './punchout/expected-delivery-date-upload/expected-delivery-date-upload.routes';
import { ReleaseNotes } from './release-notes/screens/ReleaseNotes';
import { bulkOrderCsvRoutes } from './bulk-order-csv/bulkOrderCsv.routes';
import { prodManagementPunchoutRoutes } from './punchout/product-management-punchout/product-management-punchout.routes';
import { etempV2Routes } from './punchout/etemp-v2/etemp-v2.routes';

export const featureRoutes: FeatureRoute[] = [
  ...authRoutes,
  ...dashboardRoutes,
  ...bulkOrderCsvRoutes,
  ...shopRoutes,
  ...faqRoutes,
  ...orderManagementRoutes,
  ...productManagementRoutes,
  ...reportRoutes,
  ...cartRoutes,
  ...checkoutRoutes,
  ...themeBuilderRoutes,
  ...administrationRoutes,
  ...favoritesRoutes,
  ...etemplatesRoutes,
  ...docManagerRoutes,
  ...projectManagementRoutes,
  ...waivnetReportRoutes,
  ...rgaListRoutes,
  ...waivenetAdminRoutes,
  ...expectedDeliveryUploadRoutes,
  ...prodManagementPunchoutRoutes,
  ...etempV2Routes,
  {
    path: '/pageNotFound',
    component: PageNotFound,
    options: { isPublic: true },
  },
  {
    path: '/maintenance',
    component: Maintenance,
    options: { isPublic: true },
  },
  {
    path: '/contact-us',
    component: ContactUs,
    options: { isPublic: true },
  },
  {
    path: '/release-notes',
    component: ReleaseNotes,
    options: { requiresAdmin: true },
  },
];

export const publicRoutes = featureRoutes.filter((r) => r.options?.isPublic);
