import { Container } from '@mui/material';
import { PropsWithChildren } from 'react';

export function AppBackground({ children }: PropsWithChildren<{}>) {
  return (
    <Container disableGutters={true} maxWidth={false} sx={{ backgroundColor: 'background.default' }}>
      {children}
    </Container>
  );
}
