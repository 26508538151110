import { ProductFilterInput, ProductsQueryVariables, useProductsQuery } from '../../../graphql';

type UseShopProductsOptions = {
  searchValue?: string;
  statusId?: number;
  page: number;
  itemsPerPage: number;
  categoryId?: number;
};

export function useShopProducts(opts: UseShopProductsOptions) {
  const { searchValue, statusId, page, itemsPerPage, categoryId } = opts;

  let where: ProductsQueryVariables['where'] = undefined;
  if (searchValue || statusId) {
    const or: ProductFilterInput['or'] = [];
    if (searchValue) {
      const camelCaseSearchValue = searchValue.charAt(0).toUpperCase() + searchValue.slice(1).toLowerCase();
      const upperCaseCode = searchValue.toUpperCase();
      or.push({ productCode: { contains: upperCaseCode } });
      // Split search terms for name filter
      const words = camelCaseSearchValue.trim().split(' ');
      const nameFilter: ProductFilterInput = { name: { contains: words[0] } };
      const otherWordFilters: ProductFilterInput[] = [];
      for (const otherWord of words.slice(1)) {
        otherWordFilters.push({ name: { contains: otherWord } });
      }
      if (otherWordFilters.length > 0) nameFilter.and = otherWordFilters;
      or.push(nameFilter);
    }
    if (statusId) {
      or.push({ statusId: { eq: statusId } });
    }
    where = { or };
  }

  // Query for parent products only (parentId == null)
  const parentWhere = { ...where, parentId: { eq: null } };

  const {
    data: parentsQuery,
    refetch: refetchParents,
    loading: loadingParents,
  } = useProductsQuery({
    variables: {
      where: parentWhere,
      skip: page * itemsPerPage,
      take: itemsPerPage,
      categoryId: categoryId,
    },
    notifyOnNetworkStatusChange: true,
  });

  // Query all products (including children) related to those parents
  const parentIds = parentsQuery?.products?.items?.map(p => p.productId) || [];

  const allProductsWhere = { ...where, parentId: { in: parentIds } };

  const {
    data: allProductsQuery,
    refetch: refetchAll,
    loading: loadingAll,
  } = useProductsQuery({
    variables: {
      where: allProductsWhere,
      categoryId: categoryId,
    },
    notifyOnNetworkStatusChange: true,
  });

  // Combine parents and their child products
  const allProducts = allProductsQuery?.products?.items ?? [];
  const parentProducts = parentsQuery?.products?.items ?? [];

  const products = parentProducts.concat(
    allProducts.filter(p => p.parentId !== null)
  );
  const totalCount = parentsQuery?.products?.totalCount ?? 0;

  return {
    products,
    refetch: () => {
      refetchParents();
      refetchAll();
    },
    totalCount, // Only count the parent products for pagination
    loading: loadingParents || loadingAll,
  };
}
