import * as yup from 'yup';
import { Address, BillingDetailsForm, ContactInfo, DeliveryDetailsForm } from '../interfaces';
import { CHECKOUT_REQUIRED_FIELD_MESSAGE } from './initial-data';

const message = CHECKOUT_REQUIRED_FIELD_MESSAGE;
// Mobile number regex (Source: https://stackoverflow.com/a/22378975/5575610)
const mobileRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
// Phone number regex (Source: https://stackoverflow.com/a/53210158/5575610)
// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const contactInfoSchema: yup.SchemaOf<ContactInfo> = yup.object().shape(
  {
    fullName: yup.string().required(message),
    email: yup.string().email('Email is not valid').required(message),
    mobileNo: yup
      .string()
      // Use the value of the phoneNo field to determine how to validate mobileNo
      .when('phoneNo', {
        // If phoneNo is not empty, make mobileNo optional and use an empty string as the default value
        is: (phoneNo: string) => phoneNo && phoneNo.length > 0,
        then: yup.string().optional().default(''),
        // Otherwise, validate mobileNo using the specified regular expression
        // and make the field required (if it's empty, an error will be thrown)
        otherwise: yup
          .string()
          .when('mobileNo', {
            // If mobileNo is not empty, apply the regular expression for validation
            is: (mobileNo: string) => mobileNo !== '',
            then: yup.string().matches(mobileRegExp, 'Mobile Number is not valid'),
          })
          // Explain that either mobileNo or phoneNo field required if phoneNo is empty
          .required('One of phone or mobile number is required'),
      })
      // If none of the cases above occur, use an empty string as the default value for mobileNo
      .default(''),
    phoneNo: yup
      .string()
      .when('mobileNo', {
        // If mobileNo is not empty, make phoneNo optional and use an empty string as the default value
        is: (mobileNo: string) => mobileNo && mobileNo.length > 0,
        then: yup.string().optional().default(''),
        // Otherwise, explain that either mobileNo or phoneNo field required if phoneNo is empty
        otherwise: yup.string().required('One of phone or mobile number is required'),
      })
      // If none of the cases above occur, use an empty string as the default value for phoneNo
      .default(''),
    companyName: yup.string().required(message).optional().default(''),
  },
  // Source: https://stackoverflow.com/a/65804396/5575610
  // @ts-ignore
  ['mobileNo', 'phoneNo']
);

export const addressSchema: yup.SchemaOf<Address> = yup.object({
  businessName: yup.string().default(''),
  streetAddress: yup.string().required(message),
  suburb: yup.string().required(message),
  state: yup.string().required(message),
  postcode: yup.string().required(message),
  country: yup.string().required(message),
  deliveryNotes: yup.string().default(''),
});

export const deliveryDetailsSchema: yup.SchemaOf<DeliveryDetailsForm> =
  contactInfoSchema.concat(addressSchema);

export const billingDetailsSchema: yup.SchemaOf<BillingDetailsForm> =
  contactInfoSchema.concat(addressSchema);
