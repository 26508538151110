import { Theme, ThemeProvider as MuiThemeProvider } from '@mui/system';
import { SnackbarProvider } from 'notistack';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useCustomerTheme } from './hooks/use-customer-theme';
import { createFromTsunamiTheme } from './utils';

export function ThemeProvider({ children }: PropsWithChildren<{}>) {
  const customerTheme = useCustomerTheme();
  const [theme, setTheme] = useState<Theme>(createFromTsunamiTheme(customerTheme));

  useEffect(() => {
    setTheme(createFromTsunamiTheme(customerTheme));
  }, [customerTheme]);

  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider autoHideDuration={5000} maxSnack={3} classes={{ variantInfo: '#FF0000' }}>
        {children}
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}
