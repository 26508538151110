import { TsuLoading } from '@/common/components';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, CardContent, CardHeader, Grid, List, Stack, Table } from '@mui/material';
import { CrossUpSaleItem } from '../../../common/interfaces';
import {
  CategoryContextProvider,
  useCategoriesContext,
} from '../../product-category/category.context';
import { CategoryItem } from '../../product-category/components/CategoryItem';
import { useCrossUpSales } from './CrossUpSalesForm.hook';

interface Props {
  productId: number | null;
  onCancel?: () => void;
}

export function WrappedCrossUpSalesForm(props: Props) {
  return (
    <CategoryContextProvider>
      <CrossUpSalesForm {...props} />
    </CategoryContextProvider>
  );
}

export function CrossUpSalesForm({ productId, onCancel }: Props) {
  const cus = useCrossUpSales({ productId });
  const { categories, isLoading: isLoadingCategories } = useCategoriesContext();

  // const categoryContext: ICategoryContext = {
  //   category: null,
  //   showExpandOnAllCategories: true,
  //   categoryChildComponents: cus.catChildComponents,
  //   onCategoryExpand: (c) => cus.loadProductsUnderCategory(c.categoryId),
  //   onCategoryCustomEvent: (event, payload) => {
  //     if (event === 'cross-sale-click') {
  //       cus.addCrossSaleItem(payload.product, payload.productTitle);
  //     } else if (event === 'up-sale-click') {
  //       cus.addUpSaleItem(payload.product, payload.productTitle);
  //     }
  //   },
  // };

  return (
    <Stack>
      <Grid container spacing={4} sx={{ width: '100%' }}>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Categories" />
            <CardContent>
              <TsuLoading loading={isLoadingCategories}>
                <List>
                  {categories.map((c, i) => (
                    <CategoryItem key={i} {...c} />
                  ))}
                </List>
              </TsuLoading>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Card>
                <CardHeader title="Cross Sell List" />
                <CardContent>
                  <CrossUpSaleTable
                    headers={cus.headers}
                    items={cus.crossSaleItems}
                    onRemoveItem={cus.removeCrossSaleItem}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <CardHeader title="Up Sell List" />
                <CardContent>
                  <CrossUpSaleTable
                    headers={cus.headers}
                    items={cus.upSaleItems}
                    onRemoveItem={cus.removeUpSaleItem}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TsuLoading loading={cus.isSaving}>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button variant="outlined" onClick={onCancel}>
            Close
          </Button>
          <Button variant="contained" onClick={cus.updateCrossUpSales}>
            Save
          </Button>
        </Stack>
      </TsuLoading>
    </Stack>
  );
}

interface CrossSalesTableProps {
  headers: { text: string; value: string }[];
  items: CrossUpSaleItem[];
  onRemoveItem?: (productId: number) => void;
}

function CrossUpSaleTable({ headers, items, onRemoveItem }: CrossSalesTableProps) {
  return (
    <Table>
      <thead>
        <tr>
          {headers.map((h, i) => (
            <th key={i}>{h.text}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item, i) => (
          <tr key={i}>
            <td>{item.productTitle}</td>
            <td>
              <Button variant="text" onClick={() => onRemoveItem?.(item.productId)}>
                <CloseIcon />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
