import { PropsWithChildren } from 'react';
import { useHasFeature } from '../../../common/auth/hooks/use-has-feature';

type FeatureGuardProps = {
  feature: string;
  otherwise?: JSX.Element;
};

export function FeatureGuard(props: PropsWithChildren<FeatureGuardProps>) {
  const { feature, children, otherwise } = props;
  const hasFeature = useHasFeature();
  if (!hasFeature(feature)) {return otherwise ?? null;}
  else {
    return children as JSX.Element;
  }
}
