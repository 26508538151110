import { gql, useMutation } from '@apollo/client';
import { ProductDetails } from '../../../common/interfaces';

export function useUpdateProduct() {
  const [mutate, { loading, error }] = useMutation<
    { updateProductDetails: { message: string } },
    { productId: number; payload: ProductDetails }
  >(gql`
    mutation ($productId: Int!, $payload: ProductDetailsPayloadInput!) {
      updateProductDetails(productId: $productId, payload: $payload) {
        message
      }
    }
  `);

  function updateProduct(productId: number, payload: ProductDetails) {
    return mutate({ variables: { productId, payload } });
  }

  return {
    updateProduct,
    error,
    loading,
  };
}
