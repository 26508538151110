import { useCurrentUser } from '@/common/auth/hooks/use-current-user';
import { useShoppingCart } from '@/features/cart/shopping-cart.context';
import { FavoriteButton } from '@/features/favorites/components/FavoriteButton';
import { ProductContext } from '@/features/product/product.context';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useContext, useState } from 'react';
import { ProductImage } from '../../product/components/ProductImage';
import { ShopItemActions } from './ShopItemActions';

interface ShopItemProps {
  onClick?: () => void;
  onAddToCart?: (id: number, amount: number) => void;
}

function ShopItem(props: ShopItemProps) {
  const theme = useTheme();
  const { currentUser } = useCurrentUser();
  const discount = currentUser?.role?.discount;
  const { cartItems } = useShoppingCart();
  const [shadow, setShadow] = useState(false);
  const { productId, name, isNormalProduct, isContractProduct, ...product } =
    useContext(ProductContext);
  // Compute the quantity of this item in our shopping cart (if any)
  const quantityInCart = cartItems.reduce(
    (acc, i) => (i.productId === productId ? acc + i.quantity : acc),
    0
  );
  return (
    <Card
      onMouseOver={() => setShadow(true)}
      onMouseLeave={() => setShadow(false)}
      sx={{
        border: 'solid 1px transparent',
        backgroundColor: 'whiImageThumbte',
        '&:hover': {
          border: `solid 1px ${theme.palette.primary.main}`,
          cursor: 'pointer',
        },
        height: '100%',
        '&.MuiCard-root': {
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      raised={shadow}
    >
      <div style={{ flexGrow: 1 }} onClick={props.onClick}>
        <Box
          sx={{
            backgroundColor: 'white',
            height: 200,
            position: 'relative',
          }}
        >
          <ProductImage id={productId} alt={name} />
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: 2,
              top: 0,
            }}
          >
            {product.isNew ? (
              <div
                style={{
                  backgroundColor: '#26B887',
                  padding: 6,
                  textAlign: 'center',
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%', // Ensure the div takes up the full height of its container
                }}
              >
                New Item
              </div>
            ) : (
              <div></div>
            )}
            <div></div>
            <FavoriteButton productId={productId} />
          </Box>
        </Box>
        <CardContent sx={{ minHeight: { xs: 0, sm: 100, lg: 100, xl: 250 } }}>
          <Stack spacing={1}>
            <Typography variant="body1" color="black" sx={{ mb: 1 }}>
              {name}
            </Typography>
            <Stack direction="row" mb={1}>
              <Typography variant="body2" color="black" mr={0.5}>
                {product.code}
              </Typography>
              {isContractProduct ? <Chip label="Contract Order" size="small" /> : null}
            </Stack>
            {isNormalProduct && product.stockAvailable > 0 ? (
              <Typography variant="body2" sx={{ mb: 1 }}>
                {product.unitOfMeasurement} {'• ' + product.stockAvailable} available
              </Typography>
            ) : null
            // <Typography variant="body2" sx={{ mb: 1 }}>
            //     {product.unitOfMeasurement} • sizes available
            //   </Typography>
              }
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              {discount ? 
              <div>
                <span dangerouslySetInnerHTML={{ __html: product.priceSummary }} style={{color: 'gray', textDecoration: 'line-through'}}></span>
                <span dangerouslySetInnerHTML={{ __html: "  $" + product.prices[0].price.toFixed(2) }}></span>
              </div>
              :
              <span dangerouslySetInnerHTML={{ __html: product.priceSummary }}></span>
              }
              
            </Typography>
            {product.isArchiveProduct &&
              (product.isAtClient ? (
                <Typography>Item at Client</Typography>
              ) : product.isInWarehouse ? (
                <Typography>Item in Warehouse</Typography>
              ) : null)}
            {/* {discountDetail && (
              <Stack direction="row">
                <Typography sx={{ color: '##3A5069' }}>
                  <span style={{ textDecoration: 'line-through' }}>
                    {originalPrice}
                  </span>
                  &nbsp;
                  <span>({discountDetail})</span>
                </Typography>
              </Stack>
            )} */}
            {product.minOrderQuantity !== null && (
              <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1 }}>
                <InfoOutlinedIcon sx={{ color: theme.palette.primary.main }} />
                <Typography variant="body2" sx={{ color: '#222F3E' }}>
                  Minimum Order of {product.minOrderQuantity}
                </Typography>
              </Stack>
            )}
            {quantityInCart > 0 && (
              <Stack direction="row" spacing={1}>
                <CheckCircleIcon sx={{ color: '#26B887' }} />
                <Typography variant="body2">
                  <span style={{ fontWeight: '700' }}>{quantityInCart}</span>
                  &nbsp;item{quantityInCart > 1 ? 's' : ''} already in cart
                </Typography>
              </Stack>
            )}
          </Stack>
        </CardContent>
      </div>
      <CardActions sx={{ justifyContent: 'center' }}>
        <ShopItemActions onAddToCart={props.onAddToCart} onClick={props.onClick}/>
      </CardActions>
    </Card>
  );
}

export default ShopItem;
