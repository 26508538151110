import { TsuLoading, TsuSelect } from '@/common/components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Table,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { ProductContext } from '../screens/ProductManagement.hook';
import { useChildProductsTab } from './ChildProductsTab.hook';

export interface ChildProducsTabProps {
  productId: number | null;
}

export function ChildProductsTab(props: ChildProducsTabProps) {
  const cpt = useChildProductsTab(props);
  const { productId, productName } = useContext(ProductContext);
  return (
    <Stack>
      <Grid container spacing={4} sx={{ width: '100%' }}>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Create Variant / Children Products" />
            <CardContent>
              <TsuSelect
                value={cpt.selectedAttribSet?.attributeSetId.toString() ?? ''}
                items={cpt.attributeSets}
                itemText="name"
                itemValue="attributeSetId"
                label="Select Attribute Set"
                onChange={(id) => cpt.selectAttributeSet(parseInt(id))}
              />
              <TreeView
                defaultExpandIcon={<ChevronRightIcon />}
                defaultCollapseIcon={<ExpandMoreIcon />}
                onNodeSelect={cpt.selectAttributeTreeItem}
              >
                {cpt.selectedAttribSet?.attributes.map((a) => (
                  <TreeItem key={a.attributeId} nodeId={a.attributeId.toString()} label={a.name}>
                    {a.attributeData.map((ad, k) => (
                      <TreeItem
                        key={a.attributeId + '-' + ad.attribDataId}
                        nodeId={a.attributeId + '-' + ad.attribDataId}
                        label={ad.name}
                      />
                    ))}
                  </TreeItem>
                ))}
              </TreeView>
              {cpt.selectedAttribData && (
                <Typography>
                  Variant Product Code: {productName} - {cpt.selectedAttribData.abbreviation}
                </Typography>
              )}
            </CardContent>
            <CardActions>
              <TsuLoading loading={cpt.isCreatingChildProduct}>
                <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
                  <Button
                    variant="contained"
                    onClick={cpt.createChildProduct}
                    disabled={!cpt.selectedAttribData}
                  >
                    Create Variant/Child Product
                  </Button>
                </Stack>
              </TsuLoading>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Variant / Children Products" />
            <CardContent>
              <TsuLoading loading={cpt.isDeletingProduct || cpt.isUpdatingProductParent}>
                <Table>
                  <thead>
                    <tr>
                      {cpt.headers.map((h, i) => (
                        <th key={i}>{h.text}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {cpt.childProducts.map((item, i) => (
                      <tr key={i}>
                        <td>{item.code}</td>
                        <td>{item.name}</td>
                        <td>{item.stockAvailable}</td>
                        <td>
                          <Button
                            variant="text"
                            onClick={() => cpt.deleteChildProduct(item.productId)}
                          >
                            <CloseIcon />
                          </Button>
                          <Button
                            variant="text"
                            onClick={() =>
                              cpt.updateProductParent(item.productId, item.parentId !== productId)
                            }
                          >
                            {item.parentId === productId ? 'Unlink' : 'Link'}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TsuLoading>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
}
