import VaccLogo from '@/assets/images/VACC_logo.png';
import OurautoistoreLogo from '@/assets/images/ourautoistore.png';
import { VACCStyledTextField } from '@/common/components/StyledTextField';
import { AppBackground } from '@/layouts/AppBackground';
import {
  Box,
  Button,
  Divider,
  Grid,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { VACCPasswordField } from '../../auth/components/PasswordField';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { countries } from 'countries-list';
import { States, SecurityQuestions, Country } from './components/SignUpComponents';
import { VACCStyledMenuItem, VACCStyledSelectMenu } from './components/VACCStyledSelectMenu';
import vaccAuthService from './hooks/vacc.auth.service';
import { CreateUserInput } from './interfaces/CreateUserInput.interface';
import { useSnackbar } from 'notistack';

export default function VACCSignup() {
  const { enqueueSnackbar } = useSnackbar();
  const countriesData: Country[] = Object.entries(countries).map(([code, country]) => ({
    code,
    name: country.name,
  }));
  const navigate = useNavigate();
  const location = useLocation();

  const ErrorState = location.state as { message: string | undefined };
  const [values, setValues] = useState({
    customerId: 478,
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: '',
    confirmPassword: '',
    address: {
      name: '',
      street1: '',
      street2: '',
      street3: '',
      street4: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
    },
    showPassword: false,
    emailError: '',
    passwordError: '',
    phone: '000 000 000',
    mobile: '0124 456 789'
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name.startsWith("address.")) {
      const addressField = name.substring(8);
      setValues({
        ...values,
        address: {
          ...values.address,
          [addressField as string]: value
        }
      });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleChangeState = (event: SelectChangeEvent<{ value: unknown }>) => {
    const newState = event.target.value as string;
    setValues(prevValues => ({
      ...prevValues,
      address: {
        ...prevValues.address,
        state: newState
      }
    }));
  };

  const handleChangeCountry = (event: SelectChangeEvent<{ value: unknown }>) => {
    const newCountry = event.target.value as string;
    setValues(prevValues => ({
      ...prevValues,
      address: {
        ...prevValues.address,
        country: newCountry
      }
    }));
  };


  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      const addUserInput: CreateUserInput = {
        customerId: values.customerId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        address: values.address,
        phone: values.phone,
        mobile: values.mobile
      };
      console.log(values);
      await vaccAuthService.addUser(addUserInput);
      enqueueSnackbar("Your account has been created successfully.", { variant: 'success' });
      return navigate('/signin');
    } catch (err) {
      const errorObject = JSON.parse(JSON.stringify(err));
      enqueueSnackbar(errorObject.graphQLErrors[0].message, { variant: 'error' });
      console.log('error:', JSON.stringify(err));
      console.error(err);
    }
  };

  useEffect(() => {
    let emailError = '';
    let passwordError = '';
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(values.email)) {
      emailError = "Invalid Email.";
    }

    if(values.password !== values.confirmPassword) {
      passwordError = "Confirming password failed to match.";
    }

    setValues({
      ...values,
      emailError: emailError,
      passwordError: passwordError,
    });

  }, [values.email, values.password, values.confirmPassword, values.emailError, values.passwordError]);

  return (
    <AppBackground>
      <Stack alignItems="stretch" spacing={3} sx={{ minHeight: '100vh' }}>
        <Grid
          container
          direction="row"
          alignItems={{ xs: 'start', md: 'center' }}
          textAlign="center"
          sx={{ marginLeft: 'auto', marginRight: 'auto', width: '90%', display: 'flex', minWidth: '60vw'}}
        >
          <Grid item md={6} sm={12} xs={12} sx={{ flex: 1}}>
            <Box width="100%" height="100%" sx={{ textAlign: 'left'}}>
              <img
                src={OurautoistoreLogo}
                alt="VACC Logo"
                style={{ maxHeight: 93, objectFit: 'contain'}}
              />
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12} sx={{ flex: 1}}>
            <Box width="100%" height="100%" sx={{ textAlign: 'right'}}>
              <img
                src={VaccLogo}
                alt="VACC Logo"
                style={{ maxHeight: 93, objectFit: 'contain', width: 175}}
              />
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ bgcolor: '#f5821f', borderBottomWidth: 5}}/>
        <Box sx={{
          marginRight: 'auto', marginLeft: 'auto',
          width: '100%',
        }}>
          <Stack alignItems='center' spacing={4} width='100%'>
            <Typography variant='h1' color='#f5821f'>
              OurAuto iStore Registration
            </Typography>
            <Stack alignItems='flex-start' sx={{width: '50%'}}>
              <Typography variant='h6' color='#6d6e71' sx={{marginBottom: '5px'}}>
                VACC MEMBERSHIP DETAILS
              </Typography>
              <Divider sx={{ bgcolor: '#f5821f', borderBottomWidth: 2, width: '100%' }} />
              <Typography variant='h6' color='#f5821f' sx={{marginTop: '20px', marginBottom: '20px'}}>
                VACC members enjoy at least 15% off all products.
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <Typography>VACC Member Numbers:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <VACCStyledTextField/>
                </Grid>
                <Grid item xs={6} alignItems="flex-start" display='flex'>
                  <p style={{width: '237px', margin: 0}}>If you dont't know your membership number or would like to become a VACC member, call 1300 013 341.</p>
                </Grid>
              </Grid>
              <Typography variant='h6' color='#6d6e71' sx={{marginTop: '50px', marginBottom: '5px'}}>
                USER DETAILS
              </Typography>
              <Divider sx={{ bgcolor: '#f5821f', borderBottomWidth: 2, width: '100%' }} />
              <Grid container spacing={3} sx={{marginTop: '5px'}}>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      First Name *
                    </Typography>
                    <VACCStyledTextField
                      required
                      name="firstName"
                      type="text"
                      value={values.firstName}
                      onChange={handleChange}
                      placeholder="First Name"
                      variant="outlined"
                      sx={{ color: '#f5821f' }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      Last Name *
                    </Typography>
                    <VACCStyledTextField
                      required
                      name="lastName"
                      type="text"
                      value={values.lastName}
                      onChange={handleChange}
                      placeholder="Last Name"
                      variant="outlined"
                      sx={{ color: '#f5821f' }}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      Email Address *
                    </Typography>
                    <VACCStyledTextField
                      required
                      name="email"
                      type="text"
                      error={values.emailError !== '' }
                      value={values.email}
                      onChange={handleChange}
                      placeholder="Email"
                      variant="outlined"
                    />
                    {values.emailError != ''
                    ?
                    <Typography variant='h6' sx={{color: 'red'}}>{values.emailError}</Typography>
                    : null
                    }
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      Username *
                    </Typography>
                    <VACCStyledTextField
                      required
                      name="username"
                      type="text"
                      value={values.username}
                      onChange={handleChange}
                      placeholder="Username"
                      variant="outlined"
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      Password *
                    </Typography>
                    <VACCPasswordField
                      name="password"
                      value={values.password}
                      showPassword={values.showPassword}
                      error={values.passwordError !== ''}
                      onChange={handleChange}
                      onShowPassword={handleClickShowPassword}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      Confirm Password *
                    </Typography>
                    <VACCPasswordField
                      name="confirmPassword"
                      value={values.confirmPassword}
                      showPassword={values.showPassword}
                      error={values.passwordError !== ''}
                      onChange={handleChange}
                      onShowPassword={handleClickShowPassword}
                    />
                  </Stack>
                </Grid>
              </Grid>
              {values.passwordError != ''
              ?
              <Typography variant='h6' sx={{color: 'red'}}>{values.passwordError}</Typography>
              : null
              }
              <Typography variant='h6' color='#6d6e71' sx={{marginTop: '50px', marginBottom: '5px'}}>
                SECURITY QUESTION
              </Typography>
              <Divider sx={{ bgcolor: '#f5821f', borderBottomWidth: 2, width: '100%' }} />
              <Typography sx={{marginTop: '10px', marginBottom: '10px'}}>
                Select a Security Question
              </Typography>
              <VACCStyledSelectMenu
                id="security-question-select"
                defaultValue="--- Please select a security question ---"
                fullWidth
              >
                {SecurityQuestions.map((question) => (
                  <VACCStyledMenuItem key={question} value={question}>
                    {question}
                  </VACCStyledMenuItem>
                ))}
              </VACCStyledSelectMenu>
              <Typography sx={{marginTop: '10px', marginBottom: '10px'}}>
                Security Answer *
              </Typography>
              <VACCStyledTextField/>
              <Typography variant='h6' color='#6d6e71' sx={{marginTop: '50px', marginBottom: '5px'}}>
                ADDRESS
              </Typography>
              <Divider sx={{ bgcolor: '#f5821f', borderBottomWidth: 2, width: '100%' }} />
              <Typography sx={{marginTop: '10px', marginBottom: '10px'}}>
                Company Name *
              </Typography>
              <VACCStyledTextField
                required
                name="address.name"
                type="text"
                value={values.address.name}
                onChange={handleChange}
                placeholder="Company Name"
                variant="outlined"
                sx={{ color: '#f5821f' }}
              />
              <Grid container spacing={3} sx={{marginTop: '5px'}}>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      Address Line 1 *
                    </Typography>
                    <VACCStyledTextField
                       required
                       name="address.street1"
                       type="text"
                       value={values.address.street1}
                       onChange={handleChange}
                       variant="outlined"
                       sx={{ color: '#f5821f' }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      Address Line 2
                    </Typography>
                    <VACCStyledTextField
                      name="address.street2"
                      type="text"
                      value={values.address.street2}
                      onChange={handleChange}
                      variant="outlined"
                      sx={{ color: '#f5821f' }}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{marginTop: '5px'}}>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      Address Line 3
                    </Typography>
                    <VACCStyledTextField
                      name="address.street3"
                      type="text"
                      value={values.address.street3}
                      onChange={handleChange}
                      variant="outlined"
                      sx={{ color: '#f5821f' }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      Address Line 4
                    </Typography>
                    <VACCStyledTextField
                      name="address.street4"
                      type="text"
                      value={values.address.street4}
                      onChange={handleChange}
                      variant="outlined"
                      sx={{ color: '#f5821f' }}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{marginTop: '5px'}}>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      City *
                    </Typography>
                    <VACCStyledTextField
                      required
                      name="address.suburb"
                      type="text"
                      value={values.address.suburb}
                      onChange={handleChange}
                      variant="outlined"
                      sx={{ color: '#f5821f' }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      State *
                    </Typography>
                    <Select
                      required
                      name="address.state"
                      id="state-select"
                      onChange={handleChangeState}
                      sx = {{
                        "&.MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#69B0E8"
                          }
                        }
                      }}
                    >
                      {States.map((state) => (
                        <VACCStyledMenuItem key={state} value={state}>
                          {state}
                        </VACCStyledMenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{marginTop: '5px'}}>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      Postcode *
                    </Typography>
                    <VACCStyledTextField
                      required
                      name="address.postcode"
                      type="text"
                      value={values.address.postcode}
                      onChange={handleChange}
                      variant="outlined"
                      sx={{ color: '#f5821f' }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <Typography>
                      Country *
                    </Typography>
                    <Select
                      name="address.country"
                      id="country-select"
                      fullWidth
                      onChange={handleChangeCountry}
                      sx = {{
                        "&.MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "#69B0E8"
                          }
                        }
                      }}
                    >
                      {countriesData.map((country) => (
                        <VACCStyledMenuItem key={country.code} value={country.name}>
                          {country.name}
                        </VACCStyledMenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{marginTop: '25px', marginBottom: '15px'}}>
                <Grid item xs={4}>
                  <Link to="/signin" style={{ textDecoration: 'none' }}>
                    <Typography sx={{color: '#f5821f', '&:hover': {color: '#337ab7'}}}>
                      <ArrowBackIosIcon sx={{height: '14px', width: '10px'}}/>back to login page
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={8}>
                  <Button variant='contained' sx={{width: '100%', background: '#f5821f', '&:hover' : {color: 'black', backgroundColor: '#f5821f'}}}
                    onClick={handleSubmit}
                  >
                    Register
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </AppBackground>
  );
}
