import { useMutation } from '@apollo/client';
import { useRef } from 'react';
import {
  CreateReportScheduleResponse,
  CREATE_REPORT_SCHEDULE_MUTATION,
  IReportDetails
} from '../schedule-report-dialog/constants';
import { IDateFilters } from '../schedule-report-dialog/DateFilters';
import { IEmailContents } from '../schedule-report-dialog/EmailContents';
import { IFrequency } from '../schedule-report-dialog/Frequency';

const getReportScheduleInitialValue = () => ({
  filtersApplied: [] as { column: string; value: string }[],
  reportDetails: {
    reportName: '',
    fileType: '',
    emailsToSend: [] as string[],
    emailSubjectName: '',
    emailBody: '',
  },
  dateFilters: {
    createdOnFrom: '',
    createdOnTo: '',
  },
  frequencyInput: {
    frequency: '',
    startDateTime: '',
    days: [] as string[],
    endType: '',
    endValue: '',
  },
});

export function useCreateReportScheduleMutation() {
  // We use ref here so that the components that want to update
  // our ReportScheduleInput do not need to re-render.
  // They manage their own state, so they can re-render on their own,
  // we just want to also receive their state values
  const reportScheduleInput = useRef(getReportScheduleInitialValue());
  const input = reportScheduleInput.current;

  // Initialize the mutation
  const [callMutation, { loading, error }] =
    useMutation<CreateReportScheduleResponse>(CREATE_REPORT_SCHEDULE_MUTATION);

  // This function will be provided to the component using this hook,
  // all they have to do is call it without any arguments and we will
  // handle passing the variables
  const submit = async () =>
    await callMutation({
      variables: { input },
    });

  // ===========================================================================
  // Coupled function handlers for the components under ScheduleReportDialog
  // These functions are pretty specific to the components of ScheduleReportDialog,
  // so I do not consider this as "decoupled" code.
  // TODO: Figure out a better way to allow outside components to update our ref
  // ===========================================================================

  // Update reportScheduleInput ref when the user edits Report Details section
  const handleReportDetailsChange = (reportDetails: IReportDetails) => {
    input.reportDetails.reportName = reportDetails.reportName;
    input.reportDetails.fileType = reportDetails.fileType;
  };

  // Update reportScheduleInput ref when the user edits Date Filters section
  const handleDateFiltersChange = (dateFilters: IDateFilters) => {
    input.dateFilters.createdOnFrom = dateFilters.createdOnFrom.toISOString();
    input.dateFilters.createdOnTo = dateFilters.createdOnTo.toISOString();
  };

  const handleFrequencyChange = (freq: IFrequency) => {
    input.frequencyInput.frequency = freq.frequency;
    input.frequencyInput.startDateTime =
      freq.startDate?.toISOString() ?? new Date().toISOString();
    input.frequencyInput.days = freq.selectedDays;
    input.frequencyInput.endType = freq.frequencyEndType;
    input.frequencyInput.endValue =
      freq.frequencyEndType === 'after-x-occurrences'
        ? freq.numOccurences.toString()
        : '';
  };

  const handleEmailContentsChange = (emailContents: IEmailContents) => {
    input.reportDetails.emailSubjectName = emailContents.subject;
    input.reportDetails.emailBody = emailContents.body;
  };

  // These are all the variables that the component
  // can play with, for UI stuff & updating the ref
  return {
    submit,
    loading,
    error,
    handleReportDetailsChange,
    handleDateFiltersChange,
    handleFrequencyChange,
    handleEmailContentsChange,
  };
}
