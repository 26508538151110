import { TsuButton } from '@/common/components';
import { client } from '@/core/apollo-client';
import { useCheckoutStore } from '@/features/checkout/checkout-store';
import { GetUserCartDocument, useCreateUserCartMutation, useFreightSettingsQuery, useGetCurrentUserQuery, useGetUserCartQuery, useSubmitOrderMutation, useUpdateUserCartMutation } from '@/graphql';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { MouseEvent, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { setDestructionModeOn } from '../components/CartInformationBuffer';
import { useShoppingCart } from '../shopping-cart.context';

interface DestructionModeConfirmationDialogProps {
  open: boolean;
  onClose?: () => void;
  onAccept?: () => void;
}

function DestructionModeConfirmationDialog(props: DestructionModeConfirmationDialogProps) {
  const setUpdatingCart = useCheckoutStore((s) => s.setUpdatingCart);
  let userDetail = useGetCurrentUserQuery({
    fetchPolicy: 'no-cache',
  });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [createCartMutation, createCartMut] = useCreateUserCartMutation();
  const [updateCartMutation, updateCartMut] = useUpdateUserCartMutation();
  const [submitOrderMutation, { error }] = useSubmitOrderMutation();
  const [destructionNote, setDestructionNote] = useState("");
  const { cartItems } = useShoppingCart();
  const shoppingCart = useShoppingCart();
  const {data: userCartData, refetch: userCartRefetch, loading: userCartLoading} = useGetUserCartQuery({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });
  const userCart = userCartData?.userCart;
  const handleClose = () => {
    setDestructionModeOn(false);
    props.onClose?.();
  };

  // Freight Settings choices
  const {
    data,
  } = useFreightSettingsQuery({
    fetchPolicy: 'network-only',
  });

  const handleDestructionNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDestructionNote(e.target.value); // Update the state with the new value
  };

  // Derived state
  const freightSettings = data?.freightSettings;

  async function updateDestructionCart() {
    if (userCartLoading)
      return console.log('User Cart still loading, cannot continue with updateCart');
    const cartId = userCart?.id;
    const cartInput = {
      freightSettingId: freightSettings?.at(0)?.freightSettingId ?? -1,
      chargeProcessingFee: false,
      isUrgentOrder: false,
      // serviceCode: checkoutState.shippingMethod.service,
      firstName: userDetail.data?.currentUser?.firstName ?? '',
      lastName: userDetail.data?.currentUser?.lastName ?? '',
      notes: destructionNote,
      company: "Brand Services Australia",
      street1: "113-115 Northcorp Blvd",
      suburb: "Broadmeadows",
      postcode: "3047",
      state: "VIC",
      country: "Australia",
      mobile: "(03) 9487 2100",
      phone: "(03) 9487 2100",
      email: "enquiries@brandservices.com.au",
      cartLines: cartItems.map((i) => ({
        productId: i.productId,
        // TODO: possibly check if any item's productCode is undefined,
        // throw an error (but this should never happen)
        productCode: i.productCode!,
        quantity: i.quantity,
        eTemplateId: i.eTemplateId,
      })),
      destructionMode: true
    };
    setUpdatingCart(true);
    try {
      // console.log('[updateCart] cart Id:', cartInput, cartId);
      if (cartId)
        await updateCartMutation({
          variables: { cartId, ...cartInput },
          refetchQueries: [GetUserCartDocument],
        });
      else await createCartMutation({ variables: cartInput });
    } catch (err) {
      console.error(err);
    } finally {
      setUpdatingCart(false);
    }
  }


  const handleCancel = () => {
    setDestructionModeOn(false);
    props.onClose?.();
  }
  const accept = async (e: MouseEvent) => {
    try {
      await updateDestructionCart();
      // refetch userCart data
      const userCartId = (await userCartRefetch()).data.userCart?.id
      // Trigger order mutation
      const res = await submitOrderMutation({ variables: { cartId: userCartId ?? ""} });
      // Check for problems before proceeding
      if (!res && error) return;

      // Clear the cart items saved in store
      shoppingCart.removeAllItems();
      // Refresh the user's cart (should be NULL as it is cleared during submitOrder mutation)
      client.refetchQueries({ include: ['userCart'] });

      enqueueSnackbar("Destruction Order has been submitted successfully!", { variant: 'success' });
      
    } catch (err) {
      console.log('error:', err);
      enqueueSnackbar((err as any).message, { variant: 'error' });
    }
    setDestructionModeOn(false);
    props.onClose?.();
    navigate('/');
  };

  return (
    <Dialog maxWidth="md" fullWidth open={props.open} onClose={handleClose}>
      <DialogTitle sx={{ pt: 1, pb: 1 }}>
        <Stack direction="row" justifyContent="end">
          <TsuButton onClick={handleClose} text>
            <CancelOutlinedIcon sx={{ mr: 1 }} />
            <Typography>Close</Typography>
          </TsuButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography>Do you confirm to proceed with or cancel Destruction Mode?</Typography>
        <TextField
          label="Destruction Note"
          multiline
          maxRows={3}
          fullWidth
          onChange={handleDestructionNoteChange}
        />
        <Stack direction="row" spacing={2} sx={{marginTop: '20px',marginBottom: '20px', width: '90%'}}>
          <Button variant="contained" onClick={accept} sx={{ width: '30%' }}>
            Yes, proceed
          </Button>
          <Button variant='contained'
            onClick={handleCancel} 
          >
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
export default DestructionModeConfirmationDialog;
