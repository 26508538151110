import { TsuSelect } from '@/common/components';
import { ProductType } from '@/graphql';
import { Box, SelectChangeEvent } from '@mui/material';
import { useMemo } from 'react';

interface QuantityDropdownProps {
  value: string;
  quantityChoices: ProductType['prices'];
  onChange?: (q: string, e: SelectChangeEvent<string>) => void;
}

function QuantityDropdown(props: QuantityDropdownProps) {
  // Do not recalculate after the first time, this likely won't change
  const items = useMemo(
    () =>
      props.quantityChoices.map((q) => ({
        text: q.quantity,
        value: q.quantity,
      })),
    [props.quantityChoices]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'stretch',
        marginRight: 1,
      }}
    >
      <TsuSelect
        value={props.value}
        items={items}
        onChange={props.onChange}
        itemText="text"
        itemValue="value"
        dense
      />
    </Box>
  );
}

export default QuantityDropdown;
