import { useSnackbar } from 'notistack';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TsuField, TsuSelect, TsuLoading } from '@/common/components';
import { useEditBusinessLevelForm } from '../hooks/useEditBusinessLevel';
import { BusinessLevel } from '../interfaces/business-level.interface';

interface EditBusinessLevelDialogProps {
  onClose?: () => void;
  onEdit?: () => void;
}

export function EditBusinessLevelDialog(props: EditBusinessLevelDialogProps) {
  const { onClose, onEdit } = props;
  // Toggle on and off the save button when a change is made
  const [saveDisabled, setSaveDisabled] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const editBusinessLevelForm = useEditBusinessLevelForm();
  const { formState, dispatch } = editBusinessLevelForm;
  const handleClose = () => onClose?.();
  // When click Save, it runs multiple UpdateOrderLineQuantity calls
  async function handleEdit() {
    setSaveDisabled(true);
    handleClose();
    onEdit?.();
    enqueueSnackbar('Business Level has been updated', { variant: 'success' });
  }


  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>Business Level Name</Typography>
            <TsuField
              value={formState?.name ?? 'Random name'}
              onChange={(v) => dispatch(v, 'name')}
            />
            <Typography>Active</Typography>
            <TsuField
              value={formState?.active}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>External Client Business Level ID</Typography>
            <TsuField
              value={formState?.customerBlid ?? ''}
              onChange={(v) => dispatch(v, 'customerBlid')}
            />
            <Typography>Order Progress Notify Email</Typography>
            <TsuField
              value={formState?.orderNotifyEmail ?? ''}
              onChange={(v) => dispatch(v, 'orderNotifyEmail')}
            />
            <Typography>Account Code</Typography>
            <TsuField
              value={formState?.accpacAccount ?? ''}
              onChange={(v) => dispatch(v, 'accpacAccount')}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>Web Address</Typography>
            <TsuField
              value={formState?.webAddress ?? ''}
              onChange={(v) => dispatch(v, 'webAddress')}
            />
            <Typography>ABN</Typography>
            <TsuField
              value={formState?.abn ?? ''}
              onChange={(v) => dispatch(v, 'abn')}
            />
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        <TsuLoading loading={editBusinessLevelForm.isSaving}>
          <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
            <Button variant="contained" onClick={editBusinessLevelForm.handleSave}>
              Save
            </Button>
          </Stack>
        </TsuLoading>
      </CardActions>
    </Card>
  );
}
