import { format, parseISO } from 'date-fns';
import { Document, Packer, Paragraph, Table, TableCell, TableRow } from 'docx';
import { saveAs } from 'file-saver';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { useState } from 'react';
import { IOrderShipping } from '../../../common/interfaces';
import { allHeaders } from '../constants';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

export function useExportMenu(items: IOrderShipping[], reportType: string) {
  // Source: https://mui.com/components/menus/#basic-menu
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const handleMenuClose = () => setAnchorEl(null);
  const handleMenuItemClick = (v: string) => {
    switch (v) {
      case 'PDF':
        exportPdf(items, reportType);
        break;
      case 'CSV':
        exportCsv(items, reportType);
        break;
      case 'DOC':
        exportDoc(items, reportType);
        break;
    }
  };

  return {
    open,
    anchorEl,
    setAnchorEl,
    handleMenuClose,
    handleMenuItemClick,
  };
}

// ========================================
// Functions used by useExportMenu hook
// ========================================

function getReportName(ext: string, reportType: string) {
  let type = '';
  if (reportType === 'basic') type = 'Basic';
  else if (reportType === 'advanced') type = 'Advanced';
  else if (reportType === 'lineOrder') type = 'Line Order';
  const now = format(Date.now(), 'yyyyMMddHHmmss');
  return `WaiveNet ${type} Report_${now}.${ext}`;
}

function mapHeaderValue(
  o: IOrderShipping,
  h: { text: string; value: string; type: string }
): string {
  const value = o[h.value as keyof IOrderShipping];
  if (h.value === 'despatchedDate' || h.value === 'created') {
    let date = value;
    // Some dates can be null
    if (date === null) return '-';
    // Some dates are returned as string (not sure why, but let's handle it)
    if (typeof date === 'string') date = parseISO(date);
    if (date instanceof Date) return format(date, 'dd/MM/yyyy');
  }
  if (value instanceof Date) return format(value, 'dd/MM/yyyy');
  if (typeof value === 'object') {
    if (value === null) return '-';
    if (Object.prototype.hasOwnProperty.call(value, 'name')) return value.toString() || '-';
    return JSON.stringify(value);
  }
  if (typeof value === 'number') return value.toString();
  return value || '-';
}

export function exportPdf(orders: IOrderShipping[], reportType: string) {
  const headerCount = allHeaders.length;
  const ordersTableBody = orders.map((o) => allHeaders.map((h) => mapHeaderValue(o, h)));
  const doc = {
    pageOrientation: 'landscape',
    content: [
      {
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          widths: Array(headerCount).fill('*'),
          body: [allHeaders.map((h) => h.text), ...ordersTableBody],
        },
      },
    ],
  } as TDocumentDefinitions;
  // console.log(JSON.stringify(doc));

  pdfMake.createPdf(doc).download(getReportName('pdf', reportType));
}

export function exportCsv(orders: IOrderShipping[], reportType: string) {
  let csv = '';
  csv += allHeaders.map((h) => h.text).join(',') + '\n';
  orders.forEach((o) => {
    csv += allHeaders.map((h) => mapHeaderValue(o, h)) + '\n';
  });
  const elem = document.createElement('a');
  elem.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  elem.target = '_blank';
  elem.download = getReportName('csv', reportType);
  elem.click();
}

export async function exportDoc(orders: IOrderShipping[], reportType: string) {
  const headerRow = new TableRow({
    children: allHeaders.map((h) => new TableCell({ children: [new Paragraph(h.text)] })),
  });
  const orderTableRows = orders.map(
    (o) =>
      new TableRow({
        children: allHeaders.map(
          (h) =>
            new TableCell({
              children: [new Paragraph(mapHeaderValue(o, h))],
            })
        ),
      })
  );
  const doc = new Document({
    sections: [
      {
        properties: {},
        children: [
          new Table({
            rows: [headerRow, ...orderTableRows],
          }),
        ],
      },
    ],
  });
  saveAs(await Packer.toBlob(doc), getReportName('docx', reportType));
}
