import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useMemo, useState } from 'react';
import { EditRoleForm } from './EditRoleForm';

function useEditRoleTabs() {
  return useMemo(
    () => [
      {
        label: 'Edit Role',
        tab: 'edit-role',
        component: <EditRoleForm />,
      },
    ],
    []
  );
}

export function EditRoleTabContainer() {
  const tabs = useEditRoleTabs();
  const [tab, setTab] = useState('edit-role');
  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={(_, t) => setTab(t)} variant="scrollable" scrollButtons="auto">
          {tabs.map((t, i) => (
            <Tab key={i} label={t.label} value={t.tab} />
          ))}
        </TabList>
      </Box>
      {tabs.map((t, i) => (
        <TabPanel key={i} value={t.tab}>
          {t.component}
        </TabPanel>
      ))}
    </TabContext>
  );
}
