import { TsuTable, TsuTableFilter } from '@/common/components';
import { AppLayout } from '@/layouts/AppLayout';
import { Container, MenuItem, Select, Stack } from '@mui/material';
import AdvancedFilter from './components/AdvancedFilter';
import BasicFilter from './components/BasicFilter';
import FilterChips from './components/FilterChips';
import { ReportActions } from './components/ReportActions';
import { useReport } from './Report.hook';
import ScheduleReportDialog from './schedule-report-dialog/ScheduleReportDialog';
import { useScheduleReport } from './useScheduleReport';

export function Report() {
  const report = useReport();
  const scheduleReport = useScheduleReport();

  function handleExtraActionChange(action: string, headers: any[]) {
    action === 'show-hide-columns' && report.setHideHeaders(headers);
    action === 'freeze-columns' && report.setFreezeHeaders(headers);
    action === 'group-by' && report.setGroupByHeaders(headers);
  }

  return (
    <AppLayout title="Reports">
      <Container sx={{ flexGrow: 1 }} maxWidth={false}>
        <Stack spacing={2}>
          <TsuTableFilter
            headers={report.headers}
            onExtraActionChange={handleExtraActionChange}
            onAdvancedFilterTabChange={() => {}}
            onSearchEntered={report.setSearchValue}
            basicFilterComponent={<BasicFilter />}
            advancedFilterComponent={<AdvancedFilter headers={report.headers} />}
            leftActionsComponent={
              <Select
                value={report.selectedReport}
                onChange={(e) => report.selectReport(e.target.value)}
                sx={{
                  bgcolor: 'primary.main',
                  color: 'primary.contrastText',
                  borderRadius: 2,
                }}
              >
                <MenuItem value="basic">Reports - Basic</MenuItem>
                <MenuItem value="advanced">Reports - Advanced</MenuItem>
                <MenuItem value="lineOrder">Reports - Line Order</MenuItem>
              </Select>
            }
            actionsComponent={
              <ReportActions
                items={report.items}
                isExportEnabled={report.items.length > 0 && !report.loading}
                onOpenScheduleReportDialog={scheduleReport.openDialog}
              />
            }
          />
          <FilterChips label="Filters:" onFilterDelete={report.onFilterDelete} />
          <TsuTable
            headers={report.headers}
            hideHeaders={report.opts.hideHeaders}
            freezeHeaders={report.opts.freezeHeaders}
            groupByHeaders={report.opts.groupByHeaders}
            items={report.items}
            loading={report.loading}
            error={report.error}
            totalCount={report.totalCount}
            customItemRender={report.customTableItemRender}
            page={report.opts.page}
            itemsPerPage={report.opts.itemsPerPage}
            onPageChange={report.setPage}
            onItemsPerPageChange={report.setItemsPerPage}
          />
        </Stack>
      </Container>
      <ScheduleReportDialog
        open={scheduleReport.dialog}
        onClose={scheduleReport.closeDialog}
        filterTab={0}
        onReportScheduleCreate={scheduleReport.handleScheduleCreate}
      />
    </AppLayout>
  );
}