import { TsuDialog, TsuDialogContent, TsuTable } from '@/common/components';
import { ITsuTableItem } from '@/common/components/tsu-table/interfaces';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Checkbox, IconButton, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { SelectedRoleContext } from '../context/selected-role.context';
import { Role } from '../interfaces';
import { EditRoleTabContainer } from './EditRoleTabContainer';
import { useRoleAdministration } from './RoleAdministration.hook';

export function RoleAdministration() {
  const [dialogs, setDialogs] = useState({
    editRoleDialog: false,
  });
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const roleAdmin = useRoleAdministration();

  const customItemRender = useCallback((_item: ITsuTableItem, value: string) => {
    const item = _item as Role;
    const key = value as keyof Role;
    const tableColumn = key as any;
    if (key === 'defaultRole' || key === 'adminRole')
      return <Checkbox checked={Boolean(item[key])} />;
    if (key === 'active') return <span>{Boolean(item[key]) ? 'Active' : 'Inactive'}</span>;
    if (key === 'discount') {
      const discount = item[key] as number | null;
      const discountText = discount !== null ? '$' + discount.toFixed(2) : '-';
      return <span>{discountText}</span>;
    }
    if (tableColumn === 'actions')
      return (
        <Stack direction="row">
          <IconButton color="primary" onClick={() => onEditClick?.(item)}>
            <EditIcon />
          </IconButton>
          <IconButton color="primary" onClick={() => onRemoveClick?.(item)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      );
    return <span>{item[key]}</span>;
  }, []);

  function onEditClick(_item: ITsuTableItem) {
    setSelectedRole(_item as Role);
    setDialogs({ ...dialogs, editRoleDialog: true });
  }

  function onRemoveClick(item: ITsuTableItem) {}

  return (
    <Box>
      <TsuTable
        headers={roleAdmin.headers}
        items={roleAdmin.roles}
        loading={roleAdmin.isRolesLoading}
        error={roleAdmin.getRolesError}
        colMinWidth={12}
        page={roleAdmin.opts.page}
        itemsPerPage={roleAdmin.opts.itemsPerPage}
        totalCount={roleAdmin.roles.length}
        onPageChange={roleAdmin.setPage}
        onItemsPerPageChange={roleAdmin.setItemsPerPage}
        customItemRender={customItemRender}
      />

      <TsuDialog
        title="Role Maintenance"
        open={dialogs.editRoleDialog}
        onClose={() => setDialogs({ ...dialogs, editRoleDialog: false })}
        maxWidth="lg"
        fullWidth
        noFooter
      >
        <TsuDialogContent>
          <SelectedRoleContext.Provider value={{ selectedRole }}>
            <EditRoleTabContainer />
          </SelectedRoleContext.Provider>
        </TsuDialogContent>
      </TsuDialog>
    </Box>
  );
}
