export const isDevelopment = process.env.NODE_ENV === 'development';

export const SERVER_URL = isDevelopment
  ? process.env.REACT_APP_API_URL_DEV
  : process.env.REACT_APP_API_URL_PROD;

export const OLD_WN_API_URL = isDevelopment
  ? process.env.REACT_APP_OLD_WN_API_URL_DEV
  : process.env.REACT_APP_OLD_WN_API_URL_PROD;

export const SERVER_STATIC_FILES_URL = `${SERVER_URL}/staticFiles`;
export const GRAPHQL_URL = `${SERVER_URL}${process.env.REACT_APP_GRAPHQL_ROUTE}`;
export const CONTACT_US_EMAIL = 'enquiries@brandservices.com.au';
export const STORAGE_KEY_ACCESS_TOKEN = 'tsunamiAccessToken';
export const VERSION = process.env.REACT_APP_VERSION;
export const GOOGLE_PLACES_API_KEY = process.env.GOOGLE_PLACES_API_KEY;
