import { TsuDialog, TsuDialogContent, TsuField } from '@/common/components';
import { Button, DialogActions } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { IOrderShippingsForApproval } from '../../../../common/interfaces';
import { useOrderShippingApproval } from './use-order-shipping-approval';

interface ApproveDialogProps {
  open: boolean;
  orderShipping: IOrderShippingsForApproval | null;
  onClose?: () => void;
  onApprove?: () => void;
}

export function ApproveDialog(props: ApproveDialogProps) {
  const { onClose, onApprove, open, orderShipping } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [comments, setComments] = useState('');
  const { approveOS, approveOSMut } = useOrderShippingApproval();

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value);
  };

  const handleClose = () => onClose?.();
  async function handleApprove() {
    if (orderShipping === null) return;
    await approveOS({
      variables: {
        input: {
          orderShippingId: orderShipping.orderShipId,
          comments,
        },
      },
    });
    // enqueueSnackbar('Approved Order', { variant: 'success' });
    onApprove?.();
    handleClose();
  }

  return (
    <TsuDialog
      open={open}
      loading={approveOSMut.loading}
      error={approveOSMut.error}
      title="Approve Order Shipping"
      // submitText="Approve"
      onClose={onClose}
      // onSubmit={handleApprove}
    >
      <TsuDialogContent>
        <TsuField
          value={comments}
          onChange={handleCommentChange}
          label="Comments (Optional)"
          multiline
          rows={5}
        />
      </TsuDialogContent>
      <DialogActions>
        <Button
            onClick={handleApprove}
            variant="contained"
            sx={{ backgroundColor: '#E35E5D' }}
        >
          Confirm Approve
        </Button>
      </DialogActions>
    </TsuDialog>
  );
}
