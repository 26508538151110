import VaccLogo from '@/assets/images/VACC_logo.png';
import OurautoistoreLogo from '@/assets/images/ourautoistore.png';
import Banner1 from '@/assets/images/exedy-2401.jpg';
import Banner2 from '@/assets/images/denso-2108.gif';
import Banner3 from '@/assets/images/bendix-2311.gif';
import Banner4 from '@/assets/images/podium-banner-2306.gif';
import Banner5 from '@/assets/images/spirit-super-2211.png';
import Banner6 from '@/assets/images/Workshop-Software.gif';
import { UnstyledTsuCarousel } from '@/common/components';
import { VACCStyledTextField } from '@/common/components/StyledTextField';
import { AppBackground } from '@/layouts/AppBackground';
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BannerItem, useSendResetPasswordEmailMutation } from '@/graphql';

export default function VACCForgotPassword() {
  const navigate = useNavigate();
  const banners: BannerItem[] = [
    {fileName: Banner1, href: "https://www.bendix.com.au/product-range/general-ct-disc-brake-rotor"}, 
    {fileName: Banner2, href: "https://exedy.com.au/"}, 
    {fileName: Banner3, href: "https://www.podium.com.au/"}, 
    {fileName: Banner4, href: "https://www.spiritsuper.com.au/"}, 
    {fileName: Banner5, href: "https://workshopsoftware.com/free-trial/?utm_source=VACC&utm_medium=website&utm_campaign=VACC-Banner"}, 
    {fileName: Banner6, href: "https://www.youtube.com/watch?v=eCyDO7uxrOY"}];
  const [values, setValues] = useState({
    email: '',
    emailSending: false,
    emailError: false,
    error: '',
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValues({ ...values, [event.target.name]: value });
  };
  const [sendEmail] = useSendResetPasswordEmailMutation();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    let error = '';
    let emailError = false;

    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

    if (reg.test(values.email) === true) {
      const email = values.email;
      setValues({ ...values, emailSending: true });
      await sendEmail({
        variables: {
          email,
        },
      });
      navigate('/checkYourEmail');
    } else {
      emailError = true;
      error = 'Invalid Email.';
    }

    setValues({
      ...values,
      error: error,
      emailError: emailError,
      emailSending: false,
    });
  };


  return (
    <AppBackground>
      <Stack alignItems="stretch" spacing={3} sx={{ minHeight: '100vh' }}>
        <Grid
          container
          direction="row"
          alignItems={{ xs: 'start', md: 'center' }}
          textAlign="center"
          sx={{ marginLeft: 'auto', marginRight: 'auto', width: '90%', display: 'flex', minWidth: '60vw'}}
        >
          <Grid item md={6} sm={12} xs={12} sx={{ flex: 1}}>
            <Box width="100%" height="100%" sx={{ textAlign: 'left'}}>
              <img
                src={OurautoistoreLogo}
                alt="VACC Logo"
                style={{ maxHeight: 93, objectFit: 'contain'}}
              />
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12} sx={{ flex: 1}}>
            <Box width="100%" height="100%" sx={{ textAlign: 'right'}}>
              <img
                src={VaccLogo}
                alt="VACC Logo"
                style={{ maxHeight: 93, objectFit: 'contain', width: 175}}
              />
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ bgcolor: '#f5821f', borderBottomWidth: 5}}/>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box width="70%" sx={{ textAlign: 'center' }}>
            <UnstyledTsuCarousel images={(banners ?? []).map((b) => b)} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stack alignItems='center'>
            <Typography>
              Enter your username below and we'll send you a link to reset your password
            </Typography>
            <VACCStyledTextField
              required
              autoFocus
              name="email"
              type="text"
              error={values.emailError}
              value={values.email}
              onChange={handleChange}
              placeholder="Email..."
              variant="outlined"
              sx={{ color: '#f5821f', marginTop: '20px', marginBottom: '20px'}}
            />
            <Button
              sx={{ backgroundColor: '#f5821f', color: '#fff', '&:hover': {color: 'black', backgroundColor: '#f5821f'}, width: '100%', marginBottom: '30px'}}
              // onClick={handleSubmit}
            >
              SEND EMAIL
            </Button>
            <Typography>
              All Done? Please <Link href='/signin' color='#f5821f'>Click Here</Link> to Sign In.
            </Typography>
            <Typography>
              Having trouble? Contact us
            </Typography>
            <Typography>
              Telephone: 03 9487 2100
            </Typography>
            <Typography>
              Email: <Link href='mailto:bhughes@brandservices.com.au' color='#f5821f'>bhughes@brandservices.com.au</Link>
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </AppBackground>
  );
}
