import { useShoppingCart } from '@/features/cart/shopping-cart.context';
import { ProductType } from '@/graphql';
import { ProductProvider } from '../../product/product.context';
import { CartItem } from './CartItem';
import { CartTile } from './CartTile';

interface Props {
  orderType: string;
}

export function OrderTypeCartItems({ orderType }: Props) {
  const {
    cartItems,
    setItemsSelected,
    removeItems,
    incrementItem,
    decrementItem,
    setItemQuantity,
  } = useShoppingCart();

  // Compute items based on our Order Type
  const items = cartItems.filter((i) => i.product?.orderType?.name === orderType) ?? [];
  // Compute if all items in this Order Type are selected
  const areAllItemsSelected = items.every((i) => i.isSelected);

  // Select all items in this Order Type
  const selectAllItems = () =>
    setItemsSelected(
      items.filter((i) => i.productId).map((i) => i.productId!),
      true
    );
  const deselectAllItems = () =>
    setItemsSelected(
      items.filter((i) => i.productId).map((i) => i.productId!),
      false
    );
  const handleCheckboxChange = (id: number, c: boolean) =>
    c ? setItemsSelected([id], true) : setItemsSelected([id], false);

  return (
    <CartTile
      checked={areAllItemsSelected}
      onCheckboxChange={(c) => (c ? selectAllItems() : deselectAllItems())}
      title={`${orderType} (${items.length} Items)`}
    >
      {items
        .filter((item) => item.product !== undefined)
        .map((item, i) => (
          <ProductProvider
            key={i}
            product={item.product!}
            eTemplateId={item.eTemplateId ?? undefined}
          >
            <CartItem
              checked={item.isSelected}
              quantity={item.quantity}
              onRemove={() => item.productId && removeItems([item.productId])}
              onQuantityChange={(q) => item.productId && setItemQuantity(item.productId, q)}
              onIncrementClick={() => {
                item.productId &&
                item.productCode &&
                incrementItem(item.productId, item.productCode, item.quantity + 1);
              }}
              onDecrementClick={() =>
                item.product?.productId !== undefined && decrementItem(item.product.productId)
              }
            />
          </ProductProvider>
        ))}
    </CartTile>
  );
}
