import { SERVER_STATIC_FILES_URL } from '@/globals';
import { useState, useEffect } from 'react';

type Props = {
  file?: File | string;
};

export function useImageFile(props: Props) {
  const [imageDataUrl, setImageDataUrl] = useState<string>('');

  useEffect(() => {
    if (props.file && props.file instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (!e.target?.result) return;
        setImageDataUrl(e.target.result as string);
      };
      reader.readAsDataURL(props.file);
      return () => {
        reader.abort();
        reader.onload = null;
      };
    }
    // If it's not a File it's most likely a filename (string)
    else if (props.file && typeof props.file === 'string') {
      if (props.file.startsWith('https://')) setImageDataUrl(props.file);
      else setImageDataUrl(SERVER_STATIC_FILES_URL + '/' + props.file);
    }
  }, [props.file]);

  return { imageDataUrl };
}
