import AuthService from '@/features/auth/services/auth.service';
import {
  ChangeCustomerMutationVariables,
  GetCurrentUserDocument,
  GetShoppingCartDocument,
  GetThemeDocument,
  useChangeCustomerMutation,
} from '@/graphql';
import { useApolloClient } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useEffect } from 'react';
import oldWnAuthService from '../../auth/services/old-wn-auth.service';
import changeCustomerEventEmitter from '../utils/change-customer-event-emitter';

// Add queries to refetch whenever the user changes customer
const QUERIES_TO_REFETCH = [
  GetCurrentUserDocument,
  GetShoppingCartDocument,
  // GetFaqsDocument
  GetThemeDocument,
  // GetCategoriesDocument,
  // GetProductsDocument
]
  // Transform each query document to it's name (that's all we need)
  .map((d) => getOperationName(d))
  // Then these last two operations remove any nulls
  .filter((s) => s !== null)
  .map((s) => s!);

export enum ChangeCustomerEvent {
  CustomerChange = 'CUSTOMER_CHANGE',
}

export function useChangeCustomer() {
  const client = useApolloClient();

  const [changeCustomer, changeCustomerMutation] = useChangeCustomerMutation({
    async onCompleted(res) {
      await client.clearStore();
      await AuthService.changeCustomer(
        res.changeCustomer.token,
        res.changeCustomer.expires,
        res.changeCustomer.refreshToken
      );
      client.refetchQueries({ include: QUERIES_TO_REFETCH });
      changeCustomerEventEmitter.emit(ChangeCustomerEvent.CustomerChange);
    },
  });

  return {
    changeCustomer(variables: ChangeCustomerMutationVariables) {
      changeCustomer({ variables });
      oldWnAuthService.changeCustomer(variables.customerId);
    },
    error: changeCustomerMutation.error,
    loading: changeCustomerMutation.loading,
  };
}

export function useChangeCustomerEffect(onChange?: () => void) {
  useEffect(() => {
    const listener = changeCustomerEventEmitter.addListener(
      ChangeCustomerEvent.CustomerChange,
      () => onChange?.()
    );
    return () => listener.remove();
  }, []);
}
