import { useGetCurrentUserQuery } from '@/graphql';
import { AppLayout } from '@/layouts/AppLayout';
import { TsuLoading } from '@/common/components';
import {
  Card,
  CardContent,
  Container,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const routes = [
  {
    title: 'Customer Administration',
    path: '/administration/customer',
    // The user must have these sitemaps in their current user object
    // to be able to access this route
    sitemaps: ['Customer Administration'],
  },
  {
    title: 'Business Levels Administration',
    path: '/administration/businessLevels',
    // The user must have these sitemaps in their current user object
    // to be able to access this route
    sitemaps: ['Business Levels Administration'],
  },
  {
    title: 'Roles Administration',
    path: '/administration/roles',
    // The user must have these sitemaps in their current user object
    // to be able to access this route
    sitemaps: ['Roles Administration'],
  },
  {
    title: 'Site map Administration',
    path: '/administration/sitemap',
    // The user must have these sitemaps in their current user object
    // to be able to access this route
    sitemaps: ['SiteMap Administration'],
  },
  {
    title: 'User Administration',
    path: '/administration/user',
    // The user must have these sitemaps in their current user object
    // to be able to access this route
    sitemaps: ['User Administration'],
  },
  {
    title: 'RGA List',
    path: '/administration/rgalist',
    // The user must have these sitemaps in their current user object
    // to be able to access this route
    sitemaps: ['RGA List'],
  },
  {
    title: 'Expected Delivery Date Bulk Upload',
    path: '/administration/expectedDeliveryUpload',
    // The user must have these sitemaps in their current user object
    // to be able to access this route
    sitemaps: ['Expected Delivery upload'],
  },
];

export function Administration() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    data: currentUserQuery,
    loading: isUserLoading,
    error: getUserError,
  } = useGetCurrentUserQuery();
  const currentUser = currentUserQuery?.currentUser;
  // Source: https://bobbyhadz.com/blog/javascript-check-if-array-contains-all-elements-another-array#check-if-array-has-all-elements-of-another-array---arrayincludes
  const visibleRoutes = currentUser?.sitemaps
    ? routes.filter((r) =>
        // Every sitemap of this route must exist in User's sitemaps
        r.sitemaps.every((s1) => currentUser.sitemaps?.some((s2) => s2.name === s1))
      )
    : [];

  return (
    <AppLayout>
      <Container disableGutters sx={{margin: 5, minWidth: '96%'}}>
        <Grid container spacing={2} sx={{ px: 2 }}>
          <Grid item xs={12} lg={3}>
            <Card sx={{ pl: 1, pr: 2 }}>
              <CardContent>
                <Typography variant="h4">Administration</Typography>
                <TsuLoading loading={isUserLoading} error={getUserError}>
                  <List>
                    {visibleRoutes.map((r, i) => (
                      <ListItemButton key={i} onClick={() => navigate(r.path)}>
                        <ListItemText
                          primary={r.title}
                          primaryTypographyProps={{
                            color: location.pathname === r.path ? 'primary.main' : undefined,
                          }}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </TsuLoading>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </AppLayout>
  );
}
