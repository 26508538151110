import { useLazyQuery } from '@apollo/client';
import { gql } from 'apollo-boost';
import {
  GetBasicReportHeadersRes,
  GetBasicReportRes,
  ReportProps,
} from '../../../common/interfaces';

export function useReportBasic({ skip, take, sort }: ReportProps) {
  const [refreshData, getReport] = useLazyQuery<GetBasicReportRes>(
    gql`
      query (
        $skip: Int
        $take: Int
        $sort: [OrderBasicReportSortInput!]
      ) {
        basicReport(skip: $skip, take: $take, sort: $sort) {
          totalCount
          items {
            uniqueId
            orderStatus
            shippingNo
            despatchDate
            recipientFirstName
            recipientLastName
            companyName
            deliveryStreetAddress
            deliverySuburb
            deliveryPostcode
            orderTotal
            gstTotal
            grandTotal
          }
        }
      }
    `,
    { variables: { skip, take, sort } }
  );

  const [refreshHeaders, getHeaders] = useLazyQuery<GetBasicReportHeadersRes>(
    gql`
      query {
        basicReportHeaders {
          text
          value
        }
      }
    `
  );

  async function refresh() {
    await refreshData();
    await refreshHeaders();
  }

  return {
    refresh,
    data: getReport.data?.basicReport.items ?? [],
    headers: getHeaders.data?.basicReportHeaders ?? [],
    totalCount: getReport.data?.basicReport.totalCount ?? 0,
    loading: getReport.loading || getHeaders.loading,
    error: getReport.error || getHeaders.error,
  };
}
