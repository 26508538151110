import MaintenanceImage from '@/assets/images/Maintenance.png';
import { DASHBOARD_ROUTE } from '@/features/dashboard/dashboard.routes';
import { AppLayout } from '@/layouts/AppLayout';
import { Box, Button, Container, Stack, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import AnnouncementTwoToneIcon from '@mui/icons-material/AnnouncementTwoTone'; 
import { useCustomerTheme } from '@/common/theme';

export default function NoPermission() {
  const theme = useTheme();
  const customerTheme = useCustomerTheme();

  return (
    <AppLayout>
      <Box display="flex" justifyContent="center">
        <Container sx={{ flexGrow: 1 }} maxWidth="lg">
          <Stack alignItems="center" spacing={3}>
            <AnnouncementTwoToneIcon sx={{width: '100%', height: '200px', color: customerTheme?.primary.main ?? 'text.primary'}}/>
            <Stack flex={1} alignItems="stretch" spacing={4}>
              <Stack sx={{ textAlign: 'center' }} spacing={2}>
                <Typography variant="h1" fontWeight="bold">
                  Access Denied
                </Typography>
                <Typography variant="h6">
                  Sorry, you are not authorized to access this page.{' '}
                  <span style={{ color: theme.palette.primary.main }}>Contact Us</span>
                </Typography>
              </Stack>
              <Button
                component={Link}
                to={DASHBOARD_ROUTE}
                variant="contained"
                sx={{ alignSelf: 'stretch', py: 2 }}
              >
                <Typography variant="h6">Go back to Home</Typography>
              </Button>
            </Stack>
            {/* <img
              src={MaintenanceImage}
              alt="Page Not Found"
              style={{ flex: 1, height: 500, objectFit: 'contain' }}
            /> */}
          </Stack>
        </Container>
      </Box>
    </AppLayout>
  );
}
