import { ApolloProvider } from '@apollo/react-hooks';
import { render } from 'react-dom';
import App from './App';
import { client } from './core/apollo-client';
import reportWebVitals from './reportWebVitals';
import * as ReactDOM from 'react-dom/client';

// CSS imports
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

reportWebVitals();
