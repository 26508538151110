import { OrderShippingFilterInput, useGetOrderShippingsForApprovalQuery } from '@/graphql';
import { useMemo, useState } from 'react';

export function useOrderShippingsforApproval() {
  const [opts, setOpts] = useState({ page: 0, itemsPerPage: 20, searchValue: '' });
  const search = opts.searchValue;
  const { loading, error, data, refetch } = useGetOrderShippingsForApprovalQuery({
    variables: {
      skip: opts.page * opts.itemsPerPage,
      take: opts.itemsPerPage,
      where: search
        ? {
            or: [
              { orderId: { eq: parseInt(search) } },
              { deliveryUserFirstName: { contains: search } },
              { deliveryUserLastName: { contains: search } },
              { billingUserFirstName: { contains: search } },
              { billingUserLastName: { contains: search } },
            ],
          }
        : undefined,
    },
  });

  const headers = useMemo(
    () => [
      { text: '', value: 'select' },
      { text: 'Order No.', value: 'orderId' },
      { text: 'Created On', value: 'created' },
      { text: 'Ordered By', value: 'orderedByUserFullName' },
      { text: 'Company Name', value: 'companyName' },
      { text: 'Deliver To', value: 'deliveryUserFullName' },
      { text: '', value: 'approve' },
      { text: '', value: 'cancel' },
      { text: '', value: 'edit' },
    ],
    []
  );

  return {
    opts,
    setPage: (page: number) => setOpts({ ...opts, page }),
    setItemsPerPage: (ipp: number) => setOpts({ ...opts, itemsPerPage: ipp }),
    setSearchValue: (v: string) => setOpts({ ...opts, searchValue: v }),
    headers,
    data,
    loading,
    error,
    refetch,
  };
}
