import { TsuLoading } from '@/common/components';
import { useShoppingCart } from '@/features/cart/shopping-cart.context';
import { AppLayout } from '@/layouts/AppLayout';
import { gql, useMutation } from '@apollo/client';
import { Box, Card, CardMedia, Container, Grid, Typography } from '@mui/material';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import useSWR from 'swr';
import { oldWnAxiosClient } from '../../../core/old-wn';
import { useCurrentUser } from '../../../common/auth/hooks/use-current-user';
import { ETemplateSubmitPayload } from '../interfaces/e-template-submit-payload';
import { IPunchoutWaivenet, IPunchoutWaivenetVars } from '../interfaces/punchout';
import { ETempProduct } from './CustomArtworks.types';

function CustomArtworkCard({ children }: PropsWithChildren<{}>) {
  const [shadow, setShadow] = useState(false);
  return (
    <Card
      onMouseOver={() => setShadow(true)}
      onMouseLeave={() => setShadow(false)}
      sx={(theme) => ({
        height: '100%',
        textAlign: 'center',
        backgroundColor: 'whiImageThumbte',
        border: 'solid 1px transparent',
        '&:hover': {
          border: `solid 1px ${theme.palette.primary.main}`,
        },
      })}
      raised={shadow}
    >
      {children}
    </Card>
  );
}

export function CustomArtworks() {
  const navigate = useNavigate();
  const [hideAllImages, setHideAllImages] = useState(false);
  const abortController = useMemo(() => new AbortController(), []);
  let { data: eTempProductsData } = useSWR<ETempProduct[]>(
    'etempProducts',
    () =>
      oldWnAxiosClient
        .get('/API/Core/Etemplate/GetEtempProducts', {
          params: { productCode: '', mode: '' },
          signal: abortController.signal,
        })
        .then((res) => (Array.isArray(res.data) ? res.data : [])),
    { revalidateOnFocus: false }
  );
  const eTempProducts = eTempProductsData ?? [];

  useEffect(() => {
    return () => abortController.abort();
  }, []);

  function handleEtemplateClick(p: ETempProduct) {
    setHideAllImages(true);
    setTimeout(() => navigate(`/customArtworks/${p.ProductCode}`), 250);
  }

  return (
    <AppLayout title="Custom Artworks">
      <Container maxWidth="xl">
        <Grid container spacing={4} sx={{ width: '100%' }}>
          {eTempProducts.map((p, i) => (
            <Grid key={i} item xs={6} md={4} lg={3} xl={2}>
              <CustomArtworkCard>
                <CardMedia
                  component="img"
                  alt={p.Name}
                  height="150"
                  sx={{ objectFit: 'contain', cursor: 'pointer' }}
                  onClick={() => handleEtemplateClick(p)}
                  image={!hideAllImages ? oldWnAxiosClient.defaults.baseURL + p.ImageSrc : ''}
                />
                <Box py={1}>
                  <Typography variant="h6" mb={2}>
                    {p.Name}
                  </Typography>
                  {p.PriceBreaks.map((pb, j) => (
                    <Typography key={j} variant="body2">
                      {pb.Quantity} for {pb.PriceText} ({pb.UOM})
                    </Typography>
                  ))}
                </Box>
              </CustomArtworkCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </AppLayout>
  );
}

const WAIVENET_PUNCHOUT_MUTATION = gql`
  mutation ($destination: String!, $returnURL: String!) {
    punchoutWaivenet(input: { destination: $destination, returnURL: $returnURL }) {
      success
      link
    }
  }
`;

export function OldCustomArtworks() {
  const { incrementItem } = useShoppingCart();
  const [punchoutWaivenetMutation, { data, loading }] = useMutation<
    IPunchoutWaivenet,
    IPunchoutWaivenetVars
  >(WAIVENET_PUNCHOUT_MUTATION, {
    variables: { destination: 'customArtwork', returnURL: 'https://test.com.au' },
  });

  useEffect(() => {
    punchoutWaivenetMutation();
  }, []);

  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      console.log('[CustomArtworks] Got MessageEvent:', event);
      if (event.origin.includes('waivenet.com.au')) {
        try {
          console.log('[CustomArtworks] Event data:', event.data);
          const payload = JSON.parse(event.data) as ETemplateSubmitPayload;
          incrementItem(
            payload.productId,
            payload.productCode,
            payload.quantity,
            payload.etemplateId
          );
        } catch (err) {
          console.error(`[CustomArtworks] Error parsing message: ${event.data.toString()}`);
        }
      }
    };
    window.addEventListener('message', handleMessage, false);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <AppLayout title="Custom Artworks">
      <TsuLoading loading={loading}>
        <iframe
          id="punchout"
          src={data?.punchoutWaivenet.link}
          style={{ width: '100%' }}
          height={1080}
        ></iframe>
      </TsuLoading>
    </AppLayout>
  );
}
