import {
  FavoriteProductEntryInput,
  useGetFavoritesLazyQuery,
  useGetFavoritesQuery,
  useUpdateFavoritesMutation,
} from '@/graphql';
import { useEffect, useState } from 'react';
import { useCurrentUser } from '../../../common/auth/hooks/use-current-user';

export function useFavoritesApi() {
  const {
    data: getFavoritesQuery,
    loading: isGettingFavorites,
    error: getFavoritesError,
    refetch,
  } = useGetFavoritesQuery();
  // Derive favorites from Get Favorites Query
  const favorites = getFavoritesQuery?.favorites?.products ?? [];

  const [mutate, { error: updateFavoritesError, loading: isUpdatingFavorites }] =
    useUpdateFavoritesMutation();

  const [localFavorites, setLocalFavorites] = useState<typeof favorites>([]);

  useEffect(() => {
    favorites && setLocalFavorites(favorites);
  }, [getFavoritesQuery]);

  function isFavorite(productId: number) {
    return favorites.some((e) => e.productId === productId);
  }

  async function updateFavorites(input: FavoriteProductEntryInput[]) {
    setLocalFavorites(input);
    await mutate({ variables: { input } });
    refetch();
  }

  async function addToFavorites(productId: number) {
    updateFavorites([...favorites.map((f) => ({ productId: f.productId })), { productId }]);
  }

  async function removeFromFavorites(productId: number) {
    updateFavorites(
      favorites.filter((f) => f.productId !== productId).map((f) => ({ productId: f.productId }))
    );
  }

  return {
    // State
    favorites: localFavorites,
    // Functions
    isFavorite,
    addToFavorites,
    removeFromFavorites,
    // API Status
    error: [getFavoritesError, updateFavoritesError].find((e) => e !== undefined),
    isLoading: isGettingFavorites || isUpdatingFavorites,
  };
}
