import { useSnackbar } from 'notistack';
import { ProductDetails } from '../../../common/interfaces';
import { useDeleteProduct } from './useDeleteProduct';
import { useRestoreProduct } from './useRestoreProduct';
import { useUpdateProduct } from './useUpdateProduct';

interface Props {
  productId: number | null;
}

export function useEditProduct({ productId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const dp = useDeleteProduct();
  const rp = useRestoreProduct();
  const up = useUpdateProduct();

  async function deleteProduct() {
    if (productId === null) return;
    try {
      const res = await dp.deleteProduct(productId);
      enqueueSnackbar(res.data?.deleteProduct.message ?? 'Deleted Product', {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar((err as any).toString(), { variant: 'error' });
    }
  }

  async function restoreProduct() {
    if (productId === null) return;
    try {
      const res = await rp.restoreProduct(productId);
      enqueueSnackbar(res.data?.restoreProduct.message ?? 'Restored Product', {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar((err as any).toString(), { variant: 'error' });
    }
  }

  async function updateProduct(payload: ProductDetails) {
    if (productId === null) return;
    try {
      // @ts-ignore
      if (payload.__typename) delete payload.__typename;
      const res = await up.updateProduct(productId, payload);
      enqueueSnackbar(
        res.data?.updateProductDetails.message ?? 'Updated Product Details',
        { variant: 'success' }
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar((err as any).toString(), { variant: 'error' });
    }
  }

  return {
    restoreProduct,
    deleteProduct,
    updateProduct,
    isDeleting: dp.loading,
    isRestoring: rp.loading,
    isUpdating: up.loading,
  };
}
