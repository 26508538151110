import { TsuSelect } from '@/common/components';
import { useOrderStatusesQuery, useOrderTypesQuery } from '@/graphql';
import { RootState } from '@/store';
import { setBasicFilter } from '@/store/reportSlice';
import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IBasicFilter } from '../../../common/interfaces';
import { initialBasicFilter, stateChoices, suburbChoices } from '../constants';

interface BasicFilterProps {
  onApplyFilters?: (draftBasicFilter: IBasicFilter) => void;
}

function BasicFilter({ onApplyFilters }: BasicFilterProps) {
  const dispatch = useDispatch();
  const basicFilter = useSelector((store: RootState) => store.report.basicFilter);
  const [draftBasicFilter, setDraftBasicFilter] = useState(basicFilter);

  const { data: orderStatusesQuery, loading: isOrderStatusesLoading } = useOrderStatusesQuery();
  const { data: orderTypesQuery, loading: isOrderTypesLoading } = useOrderTypesQuery();

  const orderStatuses = orderStatusesQuery?.orderStatuses ?? [];
  const orderTypes = orderTypesQuery?.orderTypes ?? [];

  const handleApplyFilters = () => {
    dispatch(setBasicFilter(draftBasicFilter));
    onApplyFilters?.(draftBasicFilter);
  };

  const handleClearAll = () => {
    setDraftBasicFilter(initialBasicFilter);
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <TsuSelect
          value={draftBasicFilter.orderStatus || ''}
          items={orderStatuses}
          loading={isOrderStatusesLoading}
          onChange={(v) => setDraftBasicFilter({ ...draftBasicFilter, orderStatus: v })}
          itemText="name"
          itemValue="statusId"
          label="Order Status"
        />
        <TsuSelect
          value={draftBasicFilter.orderType || ''}
          items={orderTypes}
          loading={isOrderTypesLoading}
          onChange={(v) => setDraftBasicFilter({ ...draftBasicFilter, orderType: v })}
          itemText="name"
          itemValue="orderTypeId"
          label="Order Type"
        />
        <TsuSelect
          value={draftBasicFilter.suburb || ''}
          items={suburbChoices}
          onChange={(v) => setDraftBasicFilter({ ...draftBasicFilter, suburb: v })}
          itemText="text"
          itemValue="value"
          label="Suburb"
        />
        <TsuSelect
          value={draftBasicFilter.state || ''}
          items={stateChoices}
          onChange={(v) => setDraftBasicFilter({ ...draftBasicFilter, state: v })}
          itemText="text"
          itemValue="value"
          label="State"
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <Button onClick={handleApplyFilters} variant="contained">
          Apply Filters
        </Button>
        <Button onClick={handleClearAll} variant="text">
          Clear All
        </Button>
      </Stack>
    </Stack>
  );
}

export default BasicFilter;
