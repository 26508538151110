import { TsuButton, TsuField } from '@/common/components';
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import {
  EMAIL_ADDRESS_NOTIFICATION_DESC,
  FIRST_RECOUNT_THRESHOLD_DESC,
  SECOND_RECOUNT_THRESHOLD_DESC,
  STOCKTAKE_REOCCURRENCE_DESC,
} from './StocktakeSettingsForm.constants';

export function StocktakeSettingsForm() {
  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Grid container spacing={2} sx={{ width: '100%' }} margin={0}>
            <Grid item xs={2}>
              <Typography>Stocktake Re-Occurrence in Days:</Typography>
            </Grid>
            <Grid item xs={3}>
              <TsuField />
            </Grid>
            <Grid item xs={7}>
              <Typography>{STOCKTAKE_REOCCURRENCE_DESC}</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ width: '100%' }} margin={0}>
            <Grid item xs={2}>
              <Typography>First Recount Threshold:</Typography>
            </Grid>
            <Grid item xs={2}>
              <TsuField />
            </Grid>
            <Grid item xs={1}>
              %
            </Grid>
            <Grid item xs={7}>
              <Typography>{FIRST_RECOUNT_THRESHOLD_DESC}</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ width: '100%' }} margin={0}>
            <Grid item xs={2}>
              <Typography>Second Recount Threshold:</Typography>
            </Grid>
            <Grid item xs={2}>
              <TsuField />
            </Grid>
            <Grid item xs={1}>
              %
            </Grid>
            <Grid item xs={7}>
              <Typography>{SECOND_RECOUNT_THRESHOLD_DESC}</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ width: '100%' }} margin={0}>
            <Grid item xs={2}>
              <Typography>Email Address Notification:</Typography>
            </Grid>
            <Grid item xs={3}>
              <TsuField />
            </Grid>
            <Grid item xs={7}>
              <Typography>{EMAIL_ADDRESS_NOTIFICATION_DESC}</Typography>
            </Grid>
          </Grid>
        </Stack>
        <TsuButton>Update</TsuButton>
      </CardContent>
    </Card>
  );
}
