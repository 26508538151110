import { useCurrentUser } from '@/common/auth/hooks/use-current-user';
import { OrderSummary, TsuLoading } from '@/common/components';
import { useUserActionCategories } from '@/features/administration/role/components/RoleActionPermissionsList';
import { useShoppingCart } from '@/features/cart/shopping-cart.context';
import { useProductsTotalPriceQuery } from '@/graphql';
import { AppLayout } from '@/layouts/AppLayout';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Stack,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHasFeature } from '../../../common/auth/hooks/use-has-feature';
import { CustomerFeatureEnum } from '../../auth/auth.constants';
import { getDestructionModeOn, setDestructionModeOn } from '../components/CartInformationBuffer';
import { CartItemLoader } from '../components/CartItemLoader';
import { OrderTypeCartItems } from '../components/OrderTypeCartItems';
import DestructionModeConfirmationDialog from './DestructionModeConfirmationDialog';

export function Cart() {
  const navigate = useNavigate();
  const [canSeeDestructionMode, setCanSeeDestructionMode] = useState(false);
  const [destructionModeConfirmationDialogOpen, setDestructionModeConfirmationDialogOpen] = useState(false);
  const [destructionModeSelected, setDestructionModeSelected] = useState(getDestructionModeOn);
  const {
    cartItems,
    isCartLoading,
    selectedCartItems,
    setAllItemsSelected,
    removeItems,
    isAllSelected,
  } = useShoppingCart();
  const hasFeature = useHasFeature();
  const usesCredits = hasFeature(CustomerFeatureEnum.CheckoutCreditsPoints);
  const { currentUser } = useCurrentUser();
  const {
    userActionCategories,
  } = useUserActionCategories(currentUser?.role?.roleId);
  const { data: totalPriceQuery, loading: isTotalPriceLoading } = useProductsTotalPriceQuery({
    variables: {
      products: selectedCartItems
        .filter((i) => i.productId)
        .map((i) => ({
          productId: i.productId!,
          quantity: i.quantity,
        })),
    },
  });
  const totalPrice = totalPriceQuery?.productsTotalPrice.total;

  // Computed state
  const orderTypes = [
    ...new Set(
      cartItems
        .filter(({ product }) => product?.orderType?.name)
        .map(({ product }) => product!.orderType!.name as string)
    ),
  ];

  function handleRemoveSelectedItems() {
    removeItems(selectedCartItems.filter((i) => i.productId).map((i) => i.productId!));
  }

  function handleCheckBoxClick() {
    if(!destructionModeSelected){
      setDestructionModeConfirmationDialogOpen(true);
    }
    else {
      setDestructionModeOn(false);
    }
  }

  function handleCancelOrClose() {
    setDestructionModeOn(false);
    setDestructionModeSelected(false);
    setDestructionModeConfirmationDialogOpen(false);
    window.location.reload();
  }

  useEffect(() => {
    userActionCategories.map((cat, i) => {
      if(cat.name === "Order Screen" && cat.userActions.some((action) => action.actionId === 270 && action.isActive)) {
        setCanSeeDestructionMode(true);
      }
    });
  }, [userActionCategories, currentUser]);

  return (
    <AppLayout title="Cart">
      <Box display="flex" justifyContent="center">
        <Container sx={{ flexGrow: 1 }} maxWidth="xl">
          <Stack direction={{ xs: 'column', lg: 'row' }} spacing={4} sx={{ width: '100%' }}>
            <Stack spacing={2} sx={{ flex: 3 }}>
              {isCartLoading ? (
                Array(3)
                  .fill(0)
                  .map((_, i) => <CartItemLoader key={i} />)
              ) : (
                <>
                  <Card>
                    <Stack direction="row" alignItems="center">
                      <Checkbox
                        checked={isAllSelected}
                        onChange={(_, c) => setAllItemsSelected(c)}
                        sx={{ color: 'primary.main' }}
                      />
                      <Typography variant="h6">Select All ({cartItems.length} Items)</Typography>
                      <Box sx={{ flexGrow: 1 }}></Box>
                      <Button
                        onClick={handleRemoveSelectedItems}
                        variant="text"
                        sx={{ textTransform: 'none' }}
                      >
                        <DeleteOutlineIcon />
                        <Typography variant="h6">Remove</Typography>
                      </Button>
                    </Stack>
                  </Card>
                  {canSeeDestructionMode ?
                    <Card>
                      <Stack direction="row" alignItems="center">
                        <Checkbox checked={destructionModeSelected} onClick={handleCheckBoxClick}/>
                        <Typography variant="h6">Destruction Mode</Typography>
                      </Stack>
                    </Card>
                  : null}
                  {orderTypes.map((orderType, i) => (
                    <OrderTypeCartItems key={i} orderType={orderType} />
                  ))}
                </>
              )}
            </Stack>
            <Stack spacing={2} sx={{ flex: 1 }}>
              {/* <FeatureGuard feature={CustomerFeatureEnum.CheckoutCreditsPoints}>
                <AvailablePointsCard />
              </FeatureGuard> */}
              <Card>
                <CardContent>
                  <TsuLoading loading={isTotalPriceLoading}>
                    <OrderSummary
                      numCartItems={selectedCartItems.length}
                      itemTotal={totalPrice}
                      credits={usesCredits ? 0 : null}
                    />
                  </TsuLoading>
                  <Box hidden={!isTotalPriceLoading} mb={2} />
                  <PlaceOrderButton
                    onClick={() => {
                      navigate('/checkout');
                    }}
                    disabled={selectedCartItems.length === 0}
                  />
                </CardContent>
              </Card>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <DestructionModeConfirmationDialog
        open={destructionModeConfirmationDialogOpen}
        onClose={handleCancelOrClose}
        onAccept={() => {setDestructionModeSelected(true)}}/>
    </AppLayout>
  );
}

interface PlaceOrderButtonProps {
  onClick?: () => void;
  disabled: boolean;
}

function PlaceOrderButton({ onClick, disabled }: PlaceOrderButtonProps) {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      sx={{
        textTransform: 'none',
        width: '100%',
        borderRadius: 2,
        py: 1.5,
      }}
      disableElevation
      disabled={disabled}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Place Order
      </Typography>
      <ArrowDropDownIcon fontSize="large" />
    </Button>
  );
}
