import { Button, Card, CardActions, CardContent, CardHeader, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Unstable_DateField } from '@mui/x-date-pickers/DateField';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import useSWR from 'swr';
import { TsuLoading } from '../../../common/components';
import { oldWnAxiosClient } from '../../../core/old-wn';
import { AppLayout } from '../../../layouts/AppLayout';
import { useCurrentUser } from '../../../common/auth/hooks/use-current-user';
import { useShoppingCart } from '../../cart/shopping-cart.context';
import { customerImageOverrides, possibleWnOptions } from '../constants/customer-texts';
import { StoreEntry } from '../interfaces/store';

const wnRoot = process.env.REACT_APP_OLD_WN_API_URL_PROD;

interface TextEditorEntry {
  id: string;
  wnOptionName?: string;
  resolver?: (store: any) => string | undefined;
  text: string;
  label?: string;
}

export function Artwork() {
  const { incrementItem } = useShoppingCart();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const params = useParams();
  const productCode = params.productCode;
  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { customer } = useCurrentUser();
  const [stylesLink, setStylesLink] = useState('');
  const [selectedStore, setSelectedStore] = useState<StoreEntry>();
  // This value will only be set by doing save or generate proof
  const [eTemplateId, setETemplateId] = useState<number>();
  const [quantity, setQuantity] = useState<number>();
  const [inputValues, setInputValues] = useState<TextEditorEntry[]>([]);
  const [showEditor, setShowEditor] = useState(true);

  const { data: product } = useSWR(
    () => (productCode ? `getEtemplateData` : null),
    () =>
      oldWnAxiosClient
        .post(`${wnRoot}/API/Core/Etemplate/GetEtemplateData`, { productCode })
        .then((res) => res.data.product)
  );

  const { data: wnElem, isValidating } = useSWR(
    () => (customer?.name && productCode ? `getArtworkAspx-${productCode}` : null),
    () => {
      const wnProductCodeRoot = `${wnRoot}/etempV2/Views/${productCode}`;
      return oldWnAxiosClient
        .get(`${wnProductCodeRoot}/${productCode}.aspx`)
        .then((res) => res.data)
        .then((htmlString) => {
          const domParser = new DOMParser();
          const doc = domParser.parseFromString(htmlString, 'text/html');

          let stylesLink = doc.querySelector('link[href^="Assets"]')?.getAttribute('href');
          stylesLink = stylesLink ? `${wnProductCodeRoot}/${stylesLink}` : '';
          if (stylesLink) setStylesLink(stylesLink);
          // console.log('styles link:', stylesLink);

          // const ul = doc.querySelector('#container ul.pages');
          // Not all E-Template aspx's have <ul> elements
          const ul = doc.querySelector('#container');
          const page = doc.querySelector('#container .page');
          if (page) {
            (page as HTMLElement).style.backgroundColor = theme.palette.background.paper;
          }

          // Go through each E-Template background <img> and fix their src attributes,
          // by prepending old waivenet's URL before the image path.
          const imgs = ul?.querySelectorAll('img');
          ul?.querySelectorAll('[wn-etemp-text-options]').forEach((elem) => {
            const htmlElem = elem as HTMLElement;
            htmlElem.style.fontFamily = 'Verdana';
            htmlElem.style.padding = '2px';
            htmlElem.style.backgroundColor = 'rgba(255, 255, 255, 0)';
            htmlElem.style.color = '#000';
          });
          if (imgs && imgs?.length > 0) {
            imgs.forEach((img, i) => {
              // Check if this product code has an image override in our customerImageOverrides,
              // if it does then use that instead of <img>'s src.
              if (
                Object.prototype.hasOwnProperty.call(customerImageOverrides, productCode!) &&
                customerImageOverrides[productCode!].length > i
              ) {
                img.src = wnProductCodeRoot + '/' + customerImageOverrides[productCode!][i];
              } else {
                img.src = wnProductCodeRoot + '/' + img.getAttribute('src');
              }
            });
          }

          const storeDropdowns = ul?.querySelectorAll(
            'input[wn-etemp-dropdown-options="storeOption"][wn-etemp-dropdown-list]'
          );
          let selectDropdowns: HTMLSelectElement[] = [];
          if (storeDropdowns && storeDropdowns.length > 0) {
            // If etemplate is a store dropdown select, hide Editor
            setShowEditor(false);
            storeDropdowns.forEach((storeDropdown) => {
              const select = document.createElement('select');
              select.id = 'store';
              select.style.backgroundColor = 'rgba(255, 255, 255, 0.95)';
              select.style.color = '#000';
              storeDropdown.replaceWith(select);
              selectDropdowns.push(select);
              oldWnAxiosClient
                .get(`${wnRoot}/API/Customisation/${customer?.name}/UserDetails`)
                .then((res) => res.data.Stores)
                .then((stores) => {
                  stores
                    .filter((store: any) => !!store.CompanyName)
                    .forEach((store: any) => {
                      const option = document.createElement('option');
                      option.value = store.ID;
                      option.innerText = store.CompanyName;
                      select.appendChild(option);
                    });
                  select.addEventListener('change', (e) => {
                    const selectedValue = (e.target as typeof select).value;
                    const store = stores.find((s: any) => s.ID === selectedValue);
                    setSelectedStore(store);
                    // Go through all form elements with "wn-etemp-text-options"
                    // This is how old WaiveNet seems to standardize their form inputs
                    ul?.querySelectorAll('[wn-etemp-text-options][wn-etemp-text-input]').forEach(
                      (elem) => {
                        const wnOptionName = elem.getAttribute('wn-etemp-text-options');
                        if (!wnOptionName) return;
                        // Find the wn-option from our list of known textEntry's
                        const textEntry = possibleWnOptions.find(
                          (t) => t.wnOptionName === wnOptionName
                        );
                        if (!textEntry) return;
                        // Get value for input from our textEntry's resolver, or default value
                        const htmlElem = elem as HTMLTextAreaElement | HTMLInputElement;
                        const textEntryValue = textEntry.resolver?.(store) ?? textEntry.text;
                        if (textEntryValue) htmlElem.value = textEntryValue;
                        // If text entry's resolver or default value has nothing,
                        // try falling back to input's placeholder
                        else if (htmlElem.placeholder) htmlElem.value = htmlElem.placeholder;
                      }
                    );
                    // Some E-Templates have multiple / duplicate store dropdowns (multiple pages)
                    selectDropdowns.forEach((otherStoreDropdown) => {
                      (otherStoreDropdown as HTMLSelectElement).value = selectedValue;
                    });
                  });
                });
            });
          }

          return ul;
        });
    },
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    if (stylesLink) {
      const link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = stylesLink;
      document.head.appendChild(link);
      return () => link.remove();
    }
  }, [stylesLink]);

  useEffect(() => {
    if (wnElem && containerRef.current) {
      containerRef.current.appendChild(wnElem);
    }
  }, [wnElem]);

  async function save() {
    if (!productCode || !selectedStore || !product) {
      console.log(
        `One of the following values is missing: productCode: ${productCode}, selected store: ${selectedStore}, product: ${product}`
      );
      return;
    }
    enqueueSnackbar('Saving...', { variant: 'info' });
    const input = {
      texts: possibleWnOptions.map((t) => ({
        id: t.id,
        text: t.resolver?.(selectedStore) ?? t.text,
      })),
      // https://waivenetts.visualstudio.com/_git/WaiveNET%20Git?path=/Waivenet/etempV2/Views/PSVETTMP009/Controller/pscontroller.js&version=GBdevelop Line 35
      servicesOptions: { selectedItems: [] },
      productCode,
      stopUsingJSON: true,
      product,
      proofGenerated: false,
      artworkOnly: false,
      useNamedTags: true,
      // etemplateID
      images: [],
    };
    const data = {
      ...input,
      rawJsonData: JSON.stringify(input),
    };
    try {
      // This API's response provides just the E-Template ID (number)
      const res = await oldWnAxiosClient.post(`${wnRoot}/API/CORE/Etemplate/SaveEtemplate`, data);
      setETemplateId(res.data);
      enqueueSnackbar('Saved', { variant: 'success' });
    } catch (err) {
      console.error(err);
      enqueueSnackbar((err as any).toString(), { variant: 'error' });
    }
  }

  async function proof() {
    if (!productCode) return;
    enqueueSnackbar('Generating proof...', { variant: 'info' });
    try {
      const input = {
        texts: possibleWnOptions.map((t) => {
          const value: Record<string, any> = { id: t.id, value: '' };
          // If there is a selected store, compute the field value from that store
          if (selectedStore) value.text = t.resolver?.(selectedStore) || t.text || t.wnOptionName;
          // Otherwise, this means the value is to be computed from the user's input
          else if (t.wnOptionName) {
            const inputElement = document.querySelector(
              `[wn-etemp-text-options='${t.wnOptionName}']`
            ) as HTMLInputElement;
            if (inputElement) value.text = inputElement.value;
          }
          return value;
        }),
        productCode,
        stopUsingJSON: true,
        product,
        proofGenerated: false,
        artworkOnly: false,
        useNamedTags: true,
        etemplateID: eTemplateId,
        images: [],
      };
      const data = {
        ...input,
        rawJsonData: JSON.stringify(input),
      };
      const res = await oldWnAxiosClient.post(`${wnRoot}/API/CORE/Etemplate/GenerateProof`, data);
      window.open(res.data.FileResults[0].URL);
      setETemplateId(res.data.EtemplateId);
      enqueueSnackbar('Generated Proof', { variant: 'success' });
    } catch (err) {
      console.error(err);
      enqueueSnackbar((err as any).toString(), { variant: 'error' });
    }
  }

  function addToCart() {
    if (!eTemplateId)
      return enqueueSnackbar('Save / generate proof before adding to cart', { variant: 'info' });
    if (!product || !productCode) {
      console.error('[addToCart] missing product or productCode', product, productCode);
      return;
    }
    if (!quantity)
      return enqueueSnackbar('Select quantity before adding to cart', { variant: 'warning' });

    incrementItem(product.ProductID, productCode, quantity, eTemplateId);
    enqueueSnackbar('Added Artwork to Cart', { variant: 'success' });
  }

  useEffect(() => {
    if (!wnElem) return;

    const textEntries: TextEditorEntry[] = [];
    wnElem?.querySelectorAll('[wn-etemp-text-options][wn-etemp-text-input]').forEach((elem) => {
      const wnOptionName = elem.getAttribute('wn-etemp-text-options');
      if (!wnOptionName) return;

      const textEntry: TextEditorEntry = possibleWnOptions.find(t => t.wnOptionName === wnOptionName) || {
        id: wnOptionName,
        text: '',
      };

      textEntries.push(textEntry);
    });

    setInputValues(textEntries.map(entry => ({ id: entry.id, text: entry.text, label: entry.label })));
  }, [wnElem, possibleWnOptions]);

  const handleInputChange = (index: number, id: string, newValue: string) => {
    const newInputValues = [...inputValues];
    newInputValues[index].text = newValue;
    setInputValues(newInputValues);
    const wnOption = wnElem?.querySelector(`[wn-etemp-text-options="${id}Option"]`);
    if(wnOption) {
      wnOption.setAttribute('value', newValue);
    }
  };
  const handleDateInputChange = (index: number, id: string, newValue: string) => {
    // Parse the date using dayjs, assuming newValue is in ISO format
    const parsedDate = dayjs(newValue);
    // Format the parsed date to dd/mm/yyyy
    const formattedDate = parsedDate.format('DD/MM/YYYY');
    // Update inputValues with the formatted date
    const newInputValues = [...inputValues];
    newInputValues[index].text = formattedDate;
    setInputValues(newInputValues);
    // Update the value attribute of the corresponding element
    const wnOption = wnElem?.querySelector(`[wn-etemp-text-options="${id}Option"]`);
    if(wnOption) {
      wnOption.setAttribute('value', formattedDate);
    }
  };
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    // Select all input elements within the container
    const inputElements = container.querySelectorAll('[wn-etemp-text-options][wn-etemp-text-input]');
    inputElements.forEach((element) => {
      element.setAttribute('disabled', 'true');
    })
  }, [wnElem, possibleWnOptions]);

  const resetInputValues = () => {
    const container = containerRef.current;
    if (!container) return;

    const resetValues = inputValues.map(input => ({ id: input.id, text: '', label: input.label }));
    setInputValues(resetValues);

    const inputElements = container.querySelectorAll('[wn-etemp-text-options][wn-etemp-text-input]');
    inputElements.forEach((element) => {
      element.setAttribute('value', '');
    })
  };

  return (
    <AppLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
            <Stack direction="row" spacing={1} sx={{ width: '100%', justifyContent: 'center' }}>
              {/* <Button variant="contained" onClick={save}>
                Save
              </Button> */}
              <Button variant="contained" onClick={proof}>
                Proof
              </Button>
              <Stack direction="row" spacing={1}>
                <FormControl>
                  <InputLabel>Select Quantity</InputLabel>
                  <Select
                    value={quantity?.toString() ?? ''}
                    onChange={(e) => setQuantity(parseInt(e.target.value as string))}
                    label="Select Quantity"
                    sx={{ width: 200 }}
                  >
                    {(product?.PriceBreaks ?? []).map((pb: any, i: number) => (
                      <MenuItem key={i} value={pb.Quantity?.toString()}>
                        {pb.Quantity} for {pb.PriceText} ({pb.UOM})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button variant="contained" onClick={addToCart}>
                  Add to Cart
                </Button>
              </Stack>
            </Stack>
          </Container>
          <TsuLoading loading={isValidating}>
            <div ref={containerRef} id="container"></div>
          </TsuLoading>
          </Grid>
        <Grid item xs={12} md={4}>
          {showEditor ?
            <Card sx={{ position: 'sticky', top: 0, alignSelf: 'flex-end', marginTop: 2 }}>
              <CardHeader
                title="Text Editor"
              />
              <CardContent>
                <Stack>
                  {inputValues.map((input, index) => (
                    <Stack spacing={1} direction='row'>
                      {(input.id.includes("valid") || input.id.includes("date")) ?
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Unstable_DateField
                          label={input.label}
                          value={input.text}
                          onChange={(newValue) => handleDateInputChange(index, input.id, newValue ?? dayjs('2022-04-17').toString())}
                        />
                      </LocalizationProvider>
                      :
                      <TextField
                        key={input.id}
                        value={input.text}
                        label={input.label}
                        onChange={(e) => handleInputChange(index, input.id, e.target.value)}
                      />
                      }
                      <Button size='small' onClick={(e) => handleInputChange(index, input.id, '')}>Reset</Button>
                    </Stack>
                  ))}
                </Stack>
                <CardActions>
                  <Button variant='contained' onClick={resetInputValues}>Reset</Button>
                </CardActions>
              </CardContent>
            </Card>
          :
          <></>}
        </Grid>
      </Grid>
    </AppLayout>
  );
}
