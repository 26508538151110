import { FeatureRoute } from '@/common/interfaces/feature-route';
import { ETempV2 } from './screens/ETempV2';

export const etempV2Routes: FeatureRoute[] = [
  {
    path: '/etempV2',
    component: ETempV2,
    options: {
      useCustomerTheme: true,
    },
  },
];