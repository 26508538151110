import { VERSION } from '@/globals';
import CircleIcon from '@mui/icons-material/Circle';
import { Link, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useCustomerTheme } from '@/common/theme';

export function Copyright(props: TypographyProps) {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const customerTheme = useCustomerTheme();
  return (
    <footer>
      <Box textAlign="center" pb={1}>
        <Container maxWidth="lg" disableGutters>
          <Typography align="center" variant="caption" {...props} sx={{ color: customerTheme?.footer.text ?? 'text.primary' }} data-cy="copyright">
            Copyright © {new Date().getFullYear()} WaveNET Pty Ltd{' '}
            <CircleIcon
              sx={{
                fontSize: 7.5,
                marginLeft: '1em',
                marginRight: '1em',
                color: customerTheme?.footer.text ?? 'text.primary',
              }}
            />
            <br style={{ display: matchesMd ? 'none' : 'initial', color: customerTheme?.footer.text ?? 'text.primary' }} />
            ABN: 79 610 896 083
            <CircleIcon
              sx={{
                fontSize: 7.5,
                marginLeft: '1em',
                marginRight: '1em',
                color: customerTheme?.footer.text ?? 'text.primary',
              }}
            />
            <Link href="/release-notes"
              sx = {{
                color: customerTheme?.footer.text ?? 'text.primary',
              }}
            >{VERSION}
            </Link>
          </Typography>
        </Container>
      </Box>
    </footer>
  );
}
