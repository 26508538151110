export const SecurityQuestions = [
  '--- Please select a security question ---',
  'What was your dream job as a child?',
  'What school did you attend for sixth grade?',
  'What is the middle name of your youngest child?',
  'What was the make of your first car?',
  'In what city did you meet your spouse/significant other?',
  'What was your childhood nickname?',
  'What is the name of your favourite childhood friend?',
  'What street did you live on in third grade?',
  'In what city or town did your mother and father meet?',
  'In what city was your first job?',
  'What was the name of your first stuffed animal?',
  'What is your oldest sibling\'s birthday month and year?',
  'What is your mother\'s maiden name?',
  'What was the name of your first pet?',
  'Which is your favourite musical group?',
  'Which celebrity poster you placed on your wall as a kid?',
];

export const States = [
  'VIC',
  'TAS',
  'QLD',
  'NSW',
  'SA',
  'WA',
  'NT',
  'ACT',
];

export interface Country {
  code: string;
  name: string;
}