import { TsuButton, TsuDialog, TsuDialogContent } from '@/common/components';
import { Stack } from '@mui/material';
import { useCreateReportScheduleMutation } from '../hooks/create-report-schedule';
import DateFilters from './DateFilters';
import EmailContents from './EmailContents';
import FiltersApplied from './FiltersApplied';
import Frequency from './Frequency';
import ReportDetails from './ReportDetails';

interface ScheduleReportDialogProps {
  open: boolean;
  onClose?: () => void;
  filterTab: number;
  onFilterDelete?: (key: string) => void;
  onReportScheduleCreate?: (reportScheduleId: number) => void;
}

function ScheduleReportDialog(props: ScheduleReportDialogProps) {
  const { onClose, open, filterTab, onReportScheduleCreate } = props;

  // CRS is my shorthand for Create Report Schedule
  const crs = useCreateReportScheduleMutation();

  const handleClose = () => onClose?.();
  const handleSave = async () => {
    const res = await crs.submit();
    onReportScheduleCreate?.(
      res.data?.createReportSchedule?.reportScheduleId ?? -1
    );
    handleClose();
  };
  const handleFilterDelete = (key: string) => props.onFilterDelete?.(key);

  return (
    <TsuDialog
      title="Schedule Report"
      submitText="Save Schedule"
      open={open}
      onClose={handleClose}
      onSubmit={handleSave}
      loading={crs.loading}
      error={crs.error}
      footerActions={
        <TsuButton outlined>
          Preview Report
        </TsuButton>
      }
    >
      <TsuDialogContent>
        <Stack
          flex={1}
          spacing={2}
          maxWidth={{ xs: 'none', lg: '30vw' }}
          alignItems="stretch"
        >
          <FiltersApplied
            filterTab={filterTab}
            onFilterDelete={handleFilterDelete}
          />
          <ReportDetails onChange={crs.handleReportDetailsChange} />
          <DateFilters onChange={crs.handleDateFiltersChange} />
          <Frequency onChange={crs.handleFrequencyChange} />
          <EmailContents onChange={crs.handleEmailContentsChange} />
        </Stack>
      </TsuDialogContent>
    </TsuDialog>
  );
}

export default ScheduleReportDialog;
