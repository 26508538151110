import { useCategoriesContext } from '@/features/product-category/category.context';
import { Box, ListItemButton, ListItemText, ListItemIcon, Stack } from '@mui/material';
import { padding, useTheme } from '@mui/system';
import { Fragment } from 'react';
import { Maybe, useGetCategoriesQuery } from '../../../graphql';
import { useCategoryItemsContext } from './CategoryItemContainer';
import CircleIcon from '@mui/icons-material/Circle';
// import { CategoriesItemImage } from './CategoriesItemImage';

interface Props {
  categoryId: number;
  name?: Maybe<string>;
  childCategoriesCount: number;
  selected?: boolean;
}

export function CategoryItem(props: Props) {
  const { categoryId, name, childCategoriesCount, selected } = props;
  const theme = useTheme();

  // Contexts & categories query
  const categoryItemsContext = useCategoryItemsContext();
  const { selectedCategoryId } = useCategoriesContext();
  // const { data: categoriesQuery } = useGetCategoriesQuery({
  //   variables: { catId: categoryId },
  // });
  // const childCategories = categoriesQuery?.childCategories ?? [];

  // Computed state
  const textColor = selected ? theme.palette.primary.main : undefined;

  /*
  const catChildComponents =
    // Check if "category child components" was defined by parent's context
    context.categoryChildComponents !== undefined &&
    // Check if this category exists in the "category child components" hash map
    Object.prototype.hasOwnProperty.call(context.categoryChildComponents, categoryId.toString())
      ? // Therefore, get the child components assigned to this category
        context.categoryChildComponents[categoryId.toString()]
      : // Otherwise, return null
        null;
  */

  const handleClick = async () => {
    categoryItemsContext?.onCategoryItemClick?.(categoryId)
    window.location.reload();
  };

  const handleExpandClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    // context.onCategoryExpand?.(category);
    // setExpanded(!expanded);
  };

  return (
    <Fragment>
      <Stack direction="row" alignItems="center">
        {/* {context.hasCheckbox && (
          <Checkbox
            checked={(context.checkedCategories ?? []).some((cc) => cc.categoryId === categoryId)}
            onChange={() => context.onCategoryCheck?.(category)}
          />
        )} */}
        <ListItemButton
          onClick={handleClick}
          sx={{ backgroundColor: selected ? 'whitesmoke' : undefined }}
        >
          {/* <Box mr={1}>
              <CategoriesItemImage id={categoryId} alt="icon image for category"/>
          </Box> */}
          <CircleIcon sx={{color: theme.palette.primary.main, fontSize: 14, marginRight: 1}}/>
          <ListItemText
            primary={name}
            primaryTypographyProps={{ fontWeight: selected ? 'bold' : undefined, lineHeight: 1.25 }}
          />
        </ListItemButton>
      </Stack>
      {/* {childCategories.map((c, i) => (
        <Box key={i} pl={2}>
          <CategoryItem key={i} {...c} />
        </Box>
      ))} */}
      {/* <Box hidden={catChildComponents === null} pl={2}>
          {catChildComponents}
        </Box> */}
    </Fragment>
  );
}
