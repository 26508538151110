import { Address, ContactInfo } from '../interfaces';

export const CHECKOUT_REQUIRED_FIELD_MESSAGE = 'This field is required.';

// =================================================
//                Initial Data
//
// These "initial" variables are used
// in Checkout page & components for initializing
// page level and local state
// =================================================

// Step 1 of the Checkout Form involves these two state objects:
// Contact Info & Delivery Address
export const SAMPLE_CONTACT_INFO: ContactInfo = {
  fullName: 'John Doe',
  email: 'jdoe@brandservices.com.au',
  mobileNo: '0123456789',
  phoneNo: '0123456789',
  companyName: 'Brand Services',
};

export const EMPTY_CONTACT_INFO: ContactInfo = {
  fullName: '',
  email: '',
  mobileNo: '',
  phoneNo: '',
  companyName: '',
};

// Delivery Address Form is directly under Contact Info form
export const SAMPLE_ADDRESS: Address = {
  businessName: 'Brand Services',
  streetAddress: '113-115 Northcorp Blvd',
  suburb: 'Broadmeadows',
  state: 'VIC',
  postcode: '3047',
  country: 'Australia',
  deliveryNotes: 'Leave at doorstep near dog house.',
};

export const EMPTY_ADDRESS: Address = {
  businessName: '',
  streetAddress: '',
  suburb: '',
  state: '',
  postcode: '',
  country: '',
  deliveryNotes: '',
};
