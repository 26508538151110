import { client } from '@/core/apollo-client';
import {
  LoginDocument,
  LoginMutation,
  LoginMutationResult,
  LoginMutationVariables,
  RefreshAccessTokenDocument,
} from '../../../graphql';

const ACCESS_TOKEN_STORAGE_KEY = 'tsunamiAccessToken';
const REFRESH_TOKEN_STORAGE_KEY = 'tsunamiRefreshToken';
const REMEMBER_ME_STORAGE_KEY = 'tsunamiRememberMe';
const REMEMBER_ME_USERNAME = 'tsunamiRememberMeUsername';
const REDUX_TOOLKIT_PERSIST_STORAGE_KEY = 'persist:root';

class AuthService {
  private accessToken: string | null = null;
  private accessTokenExpireUnix: number | null = null;

  constructor() {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    if (accessToken !== null) this.accessToken = accessToken;
  }

  getAccessToken(): string | null {
    return this.accessToken;
  }

  hasRefreshToken(): boolean {
    return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY) !== null;
  }

  isAccessTokenExpired(): boolean {
    if (this.accessTokenExpireUnix === null) return false;
    return this.accessTokenExpireUnix - Date.now() < 0;
  }

  isRememberEnabled(): boolean {
    const rememberMeString = localStorage.getItem(REMEMBER_ME_STORAGE_KEY);
    if (rememberMeString === null) return false;
    return rememberMeString === 'true';
  }

  getRememberMeUsername(): string | null {
    return localStorage.getItem(REMEMBER_ME_USERNAME);
  }

  // Temporary functions to update access token / refresh token,
  // later on move entire apollo mutation here
  async changeCustomer(accessToken: string, expiresUnix: number, refreshToken: string) {
    this.accessToken = accessToken;
    this.accessTokenExpireUnix = expiresUnix;
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, this.accessToken);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
    // console.log('[AuthService changeCustomer] updating refresh token:', refreshToken);
  }

  async removeRememberedAccount() {
    localStorage.removeItem(REMEMBER_ME_USERNAME);
  }

  async login(email: string, password: string, rememberMe?: boolean) {
    const res = await client.mutate<LoginMutation, LoginMutationVariables>({
      mutation: LoginDocument,
      variables: { email, password },
    });

    if (!res.data) {
      console.error('[AuthService] Empty login data');
      throw new Error('Login failed');
    }

    this.accessToken = res.data.userLogin.token;
    this.accessTokenExpireUnix = res.data.userLogin.expires;
    // TODO: Encrypt all localStorage values into one JSON object
    localStorage.setItem(REMEMBER_ME_STORAGE_KEY, rememberMe ? 'true' : 'false');
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, this.accessToken);
    if (rememberMe) localStorage.setItem(REMEMBER_ME_USERNAME, email);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, res.data.userLogin.refreshToken);
    // console.log('[AuthService login] saving refresh token:', res.data.userLogin.refreshToken);
  }

  logout() {
    // Clear our access token (in-memory)
    this.accessToken = null;
    // Clear redux-toolkit persisted state
    localStorage.removeItem(REDUX_TOOLKIT_PERSIST_STORAGE_KEY);
    // Clear our tokens from localStorage
    // localStorage.removeItem(REMEMBER_ME_STORAGE_KEY);
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
  }

  async refreshAccessToken() {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
    if (!refreshToken) throw new Error('No refresh token');

    const res = await client.mutate({
      mutation: RefreshAccessTokenDocument,
      variables: { refreshToken },
    });

    this.accessToken = res.data.refreshAccessToken.token;
    this.accessTokenExpireUnix = res.data.refreshAccessToken.expires;
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, res.data.refreshAccessToken.refreshToken);
    // console.log(
    //   '[AuthService refreshAccessToken] got new refresh token:',
    //   res.data.refreshAccessToken.refreshToken
    // );
  }
}

export default new AuthService();
