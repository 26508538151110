import { FeatureRoute } from '@/common/interfaces/feature-route';
import { Favorites } from './screens/Favorites';

export const favoritesRoutes: FeatureRoute[] = [
  {
    path: '/favourites',
    component: Favorites,
    options: {
      useCustomerTheme: true,
    },
  },
];
