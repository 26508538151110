import { FeatureRoute } from '@/common/interfaces/feature-route';
import { ProjectManagement } from './screens/ProjectManagement';

export const projectManagementRoutes: FeatureRoute[] = [
  {
    path: '/pms',
    component: ProjectManagement,
    options: {
      useCustomerTheme: true,
    },
  },
];