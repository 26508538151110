import { Chip, Stack, Typography, useTheme } from '@mui/material';

interface ITsuPillItem {
  text: string;
  value: any;
}

interface TsuPillsProps {
  label?: string;
  value: any;
  items: ITsuPillItem[];
  onChange?: (value: any) => void;
  stretchItems?: boolean;
  readOnly?: boolean;
  [x: string]: any;
}

export function TsuPills({
  label,
  value,
  items,
  onChange,
  stretchItems,
  readOnly,
  ...otherProps
}: TsuPillsProps) {
  const theme = useTheme();

  const isSelected = (v: any) => {
    if (Array.isArray(value)) {
      return value.includes(v);
    } else {
      return v === value;
    }
  };

  const handleClick = (v: any) => !readOnly && onChange?.(v);

  return (
    <Stack sx={{ mt: 0 }} spacing={1} {...otherProps}>
      {label && <Typography sx={{ fontSize: '1.2rem' }}>{label}</Typography>}
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          '& .MuiChip-root': {
            flexGrow: stretchItems ? 1 : 0,
          },
        }}
      >
        {items.map(({ text, value: v }, i) => (
          <Chip
            label={text}
            onClick={() => handleClick(v)}
            key={i}
            sx={{
              fontSize: '1.2rem',
              color: isSelected(v) ? 'white' : undefined,
              backgroundColor: isSelected(v)
                ? theme.palette.primary.main
                : undefined,
              '&:hover': readOnly
                ? undefined
                : {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                  },
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
}
