import { useGetOrdersInProgressQuery } from '@/graphql';
import { TsuTable } from '@/common/components';
import { Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import useOrdersInProgressRender from './useOrdersInProgressRender';
import { OrderInProgressDialog } from './OrderInProgressDialog';
import { IOrdersInProgress } from '@/common/interfaces';

function OrdersInProgressTable() {
  const [openOrderInProgressDialog, setOpenOrderInProgressDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<IOrdersInProgress | null>(null);
  const [opts, setOpts] = useState({
    page: 0,
    itemsPerPage: 20,
  });

  const { data, loading, error } = useGetOrdersInProgressQuery({
    variables: {
      skip: opts.page * opts.itemsPerPage,
      take: opts.itemsPerPage,
    },
  });

  const headers = useMemo(
    () => [
      { text: 'Order No.', value: 'orderId' },
      { text: 'Order Date', value: 'created' },
      { text: 'Order Status', value: 'status' },
      { text: 'Created By', value: 'createdBy' },
    ],
    []
  );

  const { customItemRender } = useOrdersInProgressRender({
    onOrder: useCallback(
      (order) => {
        setSelectedOrder(order)
        setOpenOrderInProgressDialog(true);
      },
      [setOpenOrderInProgressDialog]
    ),
  });
  const orderInProgressItems = data?.ordersInProgress?.items ?? [];
  const shownItems = orderInProgressItems.filter(item => item.orderShippings.at(0)?.status.name != "Despatched");
  
  return (
    <Stack spacing={2} alignItems="stretch">
      <Typography variant="h2" fontWeight="bold" sx={{ alignSelf: 'center' }}>
        Order In Progress
      </Typography>
      <TsuTable
        headers={headers}
        items={shownItems}
        totalCount={shownItems.length}
        customItemRender={customItemRender}
        loading={loading}
        error={error}
        stretchColumns={true}
        page={opts.page}
        itemsPerPage={opts.itemsPerPage}
        onPageChange={(page) => setOpts({ ...opts, page })}
        onItemsPerPageChange={(ipp) => setOpts({ ...opts, itemsPerPage: ipp })}
      />
      <OrderInProgressDialog
        open={openOrderInProgressDialog}
        onClose={() => setOpenOrderInProgressDialog(false)}
        order = {selectedOrder}
      />
    </Stack>
  );
}

export default OrdersInProgressTable;
