import { useEffect, useState } from 'react';
import { ProductUom } from '../../../graphql';
import { CreateProductUomPayload } from '../../../common/interfaces';
import { UomFormProps } from './UomForm';
import { useGetUomDescriptors } from '../hooks/useGetUomDescriptors';

function computeUomState(iu?: ProductUom) {
  return {
    descriptorId: iu?.descriptorId ?? null,
    stockingUom: iu?.sellUom ?? false,
    description: iu?.name ?? '',
    multiplier: iu?.multiplier ?? 0,
    pricePerUnit: iu?.sellPrice ?? null,
    costPerUnit: iu?.costPrice ?? null,
    weightKg: iu?.weightKg ?? null,
    dimensionX: iu?.dimensionXcm ?? null,
    dimensionY: iu?.dimensionYcm ?? null,
    dimensionZ: iu?.dimensionZcm ?? null,
  } as CreateProductUomPayload;
}

export function useUomForm({ initialUom: iu, onCancel, onSave }: UomFormProps) {
  const [uom, setUom] = useState(() => computeUomState(iu));

  const {
    uomDescriptors,
    error: getUomDescriptorsError,
    loading: isUomDescriptorsLoading,
  } = useGetUomDescriptors();

  useEffect(() => {
    setUom(computeUomState(iu));
  }, [iu]);

  function cancel() {
    onCancel?.();
  }

  function save() {
    onSave?.(uom);
  }

  return {
    // Form state
    uom,
    setUom,
    // UOM Descriptors state
    uomDescriptors,
    getUomDescriptorsError,
    // Functions
    cancel,
    save,
    // Flags
    isUomDescriptorsLoading,
  };
}
