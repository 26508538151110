import { TsuLoading } from '@/common/components';
import { Box, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { Fragment, PropsWithChildren, useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { useCheckoutStore } from '@/features/checkout/checkout-store';

const makeOrderSummaryItems = (props: OrderSummaryProps) => {
  const items: { title: string; value: string; paysFor: boolean }[] = [];

  if (props.itemTotal !== undefined) {
    items.push({
      title: `Items (${props.numCartItems ?? 0})`,
      value: '$' + props.itemTotal.toFixed(2),
      paysFor: true,
    });
  }

  if (props.shippingFee !== null && props.shippingFee !== undefined)
    items.push({
      title: 'Shipping Fee',
      value: '$' + (props.paysForShippingFee ? (props.shippingFee! + props.fuelLevyFee!) : 0).toFixed(2),
      paysFor: props.paysForShippingFee ?? true,
    });

  if (props.pickFee !== null && props.pickFee !== undefined)
    items.push({
      title: 'Pick Fee',
      value: '$' + (props.paysForPickFee ? props.pickFee! : 0).toFixed(2),
      paysFor: props.paysForPickFee ?? true,
    });

  // if (props.fuelLevyFee !== null && props.fuelLevyFee !== undefined)
  // items.push({
  //   title: 'Fuel Levy Fee',
  //   value: '$' + props.fuelLevyFee!.toFixed(2),
  //   paysFor: props.paysForFuelLevyFee ?? true,
  // });

  if (props.processingFee !== null && props.processingFee !== undefined)
    items.push({
      title: 'Processing Fee',
      value: '$' + props.processingFee!.toFixed(2),
      paysFor: true,
    });

  if (props.urgentPickFee !== null && props.urgentPickFee !== undefined)
    items.push({
      title: 'Urgent Order Fee',
      value: '$' + props.urgentPickFee!.toFixed(2),
      paysFor: true,
    });

  if (props.credits !== null && props.credits !== undefined)
    items.push({
      title: 'Credits / Points',
      value: '$' + props.credits.toFixed(2),
      paysFor: true,
    });
  return items;
};

const TotalLabel = ({ children }: PropsWithChildren<{}>) => (
  <Typography variant="h6" fontWeight="700" sx={{ color: 'primary.main' }}>
    {children}
  </Typography>
);

export interface OrderSummaryProps {
  numCartItems?: number;
  itemTotal?: number;
  pickFee?: number | null;
  processingFee?: number | null;
  urgentPickFee?: number | null;
  // Temporary prop to control UI to display
  // if pick fee should be paid for or not
  paysForPickFee?: boolean;
  shippingFee?: number | null;
  // Temporary prop to control UI to display
  // if shipping fee should be paid for or not
  paysForShippingFee?: boolean;
  fuelLevyFee?: number | null;
  paysForFuelLevyFee?: boolean;
  credits?: number | null;
  subtotalExGst?: number | null;
  totalAmount?: number | null;
  gst?: number | null;
  hideTitle?: boolean;
}

export function OrderSummary(props: OrderSummaryProps) {
  const isCartLoading = useCheckoutStore((s) => s.isUpdatingCart);
  // Data for Order Summary rows are abstracted away here
  const orderSummaryItems = makeOrderSummaryItems(props);
  let totalAmount = props.totalAmount;
  if (!totalAmount)
    totalAmount = (props.itemTotal ?? 0) + (props.subtotalExGst ?? 0) + (props.credits ?? 0);

  return (
    <Stack>
      <Box hidden={props.hideTitle}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Order Summary
        </Typography>
      </Box>
      <TsuLoading loading={isCartLoading} loader={<OrderSummaryLoading />}>
        <Grid container spacing={2}>
          {orderSummaryItems.filter(o => o.value !== "$0.00" ).map((item, i) => (
            <Fragment key={i}>
              <Grid item xs={6}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>{item.title}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Typography textAlign="right" fontWeight="bold" style={{textDecoration: item.paysFor ? 'none' : 'line-through'}}>
                  {item.value}
                </Typography>
              </Grid>
            </Fragment>
          ))}
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <Typography variant="h6">Subtotal Ex GST</Typography>
          <Typography fontWeight="700">
            {/* display item cost as subtotal when there is no misc fee */}
            {(props.subtotalExGst) ?
              (props.subtotalExGst ?? 0).toFixed(2)
              : (props.itemTotal ?? 0).toFixed(2)
            }
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">GST</Typography>
          <Typography fontWeight="700">
            {/* display 10% of item cost when there is not misc fee participated */}
            {(props.subtotalExGst) ?
              ((props.subtotalExGst ?? 0)* 0.1).toFixed(2)
              : ((props.itemTotal ?? 0)* 0.1).toFixed(2)
            }
          </Typography>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
          <TotalLabel>Total Amount</TotalLabel>
          <TotalLabel>
            {/* // show item total + gst when there is no misc fee included */}
            {/* {((props.itemTotal ?? 0) + ((props.itemTotal ?? 0)* 0.1)).toFixed(2)} */}
            {(props.subtotalExGst) ?
              totalAmount.toFixed(2)
              : ((props.itemTotal ?? 0) + ((props.itemTotal ?? 0)* 0.1)).toFixed(2)
            }
          </TotalLabel>
        </Stack>
      </TsuLoading>
    </Stack>
  );
}

export function OrderSummaryLoading() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={132}
      viewBox="0 0 500 132"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      preserveAspectRatio="none"
    >
      <rect x="11" y="5" rx="0" ry="0" width="127" height="10" />
      <rect x="12" y="23" rx="0" ry="0" width="87" height="10" />
      <rect x="13" y="43" rx="0" ry="0" width="107" height="10" />
      <rect x="13" y="63" rx="0" ry="0" width="93" height="10" />
      <rect x="14" y="82" rx="0" ry="0" width="68" height="10" />
      <rect x="15" y="100" rx="0" ry="0" width="50" height="10" />
      <rect x="14" y="119" rx="0" ry="0" width="70" height="10" />
      <rect x="460" y="83" rx="0" ry="0" width="30" height="10" />
      <rect x="441" y="64" rx="0" ry="0" width="50" height="10" />
      <rect x="450" y="44" rx="0" ry="0" width="40" height="10" />
      <rect x="441" y="26" rx="0" ry="0" width="50" height="10" />
      <rect x="450" y="8" rx="0" ry="0" width="40" height="10" />
      <rect x="447" y="101" rx="0" ry="0" width="45" height="10" />
      <rect x="443" y="118" rx="0" ry="0" width="50" height="10" />
    </ContentLoader>
  );
}
