import { StyledCheckBox } from '@/common/components';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

interface FieldProps {
  label?: string;
  value?: string;
  onChange?: (v: string) => void;
  // If this flag is enabled then we will always show the label even though
  // onlyValue is true
  showLabel?: boolean;
  // Source: https://stackoverflow.com/a/58201122
  [x: string]: any;
}

export function TsuPasswordField({
  label,
  value,
  onChange,
  showLabel,
  ...otherProps
}: FieldProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [cursor, setCursor] = useState<number | null>(null);
  const [showPassword, setShowPassword] = useState(false);

  // Password Strength Check state
  const [validate, setValidate] = useState({
    hasSpecialChar: false,
    hasUpperChar: false,
    hasNumber: false,
    has8CharsMin: false,
  });
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);

  const strength = Object.values(validate).reduce(
    (a, item) => a + (item ? 1 : 0),
    0
  );
  const feedback = {
    1: 'Weak!',
    2: 'Still weak!',
    3: "You're almost there!",
    4: 'Strong',
  }[strength];

  // Source: https://stackoverflow.com/a/68928267
  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      input.setSelectionRange(cursor, cursor);
    }
  }, [cursor, inputRef, showPassword]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCursor(event.target.selectionStart);
    const newValue = event.target.value;
    onChange?.(newValue);
    validatePassword(newValue);
  };

  const validatePassword = (password: string) => {
    if (password.match(/\d+/g)) {
      setValidate((o) => ({ ...o, hasNumber: true }));
      setIsChecked3(true);
    } else {
      setValidate((o) => ({ ...o, hasNumber: false }));
      setIsChecked3(false);
    }

    if (password.match(/[A-Z]+/g)) {
      setValidate((o) => ({ ...o, hasUpperChar: true }));
      setIsChecked2(true);
    } else {
      setValidate((o) => ({ ...o, hasUpperChar: false }));
      setIsChecked2(false);
    }

    if (password.match(/[!@#$%^&*)(+=._-]+/g)) {
      setValidate((o) => ({ ...o, hasSpecialChar: true }));
      setIsChecked4(true);
    } else {
      setValidate((o) => ({ ...o, hasSpecialChar: false }));
      setIsChecked4(false);
    }

    if (password.length > 7) {
      setValidate((o) => ({ ...o, has8CharsMin: true }));
      setIsChecked1(true);
    } else {
      setValidate((o) => ({ ...o, has8CharsMin: false }));
      setIsChecked1(false);
    }
  };

  return (
    <Stack spacing={1} {...otherProps}>
      <Box>
        <Typography sx={{ color: '#3D5069' }}>{label || ''}</Typography>
      </Box>
      <FormControl
        sx={{
          '& .MuiInputBase-formControl': {
            borderRadius: 2.5,
          },
          backgroundColor: '#F5FAFF',
        }}
        variant="outlined"
      >
        <OutlinedInput
          inputRef={inputRef}
          type={showPassword ? 'text' : 'password'}
          value={value}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <progress
        className={`password strength-${strength}`}
        value={strength}
        max="4"
      />
      <Typography>
        Password strength:&nbsp;<strong>{feedback}</strong>
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={<StyledCheckBox id="8CharsMin" checked={isChecked1} />}
          label="8 Characters Minimum"
        />
        <FormControlLabel
          control={<StyledCheckBox id="UpperChar" checked={isChecked2} />}
          label="Uppercase Letter"
        />
        <FormControlLabel
          control={<StyledCheckBox id="Number" checked={isChecked3} />}
          label="Number"
        />
        <FormControlLabel
          control={<StyledCheckBox id="SpecialChar" checked={isChecked4} />}
          label="Special Character"
        />
      </FormGroup>
    </Stack>
  );
}
