import { BillingDetails } from '../components/BillingDetails';
import { DeliveryDetails } from '../components/DeliveryDetails';
import { OrderedItems } from '../components/OrderedItems';
import { PaymentMethod } from '../components/PaymentMethod';
import { ShippingMethod } from '../components/ShippingMethod';

export const CHECKOUT_STEPS = [
  {
    title: 'Delivery Details',
    submitTitle: 'Continue to Billing',
    component: () => <DeliveryDetails />,
  },
  {
    title: 'Billing Details',
    submitTitle: 'Continue to Shipping',
    component: () => <BillingDetails />,
  },
  {
    title: 'Shipping Method',
    submitTitle: 'Continue to Order Summary',
    component: () => <ShippingMethod />,
  },
  {
    title: 'Order Summary',
    submitTitle: 'Continue to Payment Methods',
    component: () => <OrderedItems />,
  },
  {
    title: 'Payment Method',
    submitTitle: 'Complete Checkout',
    component: () => <PaymentMethod />,
  },
];
