import { Box, Checkbox, IconButton, Stack } from '@mui/material';
import { BusinessLevelsTable } from './BusinessLevelsTable';
export function BusinessLevelAdministration() {
  
  return (
    <Box>
      <BusinessLevelsTable/>
    </Box>
  );
}
