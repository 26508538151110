import NoPermission from '@/features/auth/screens/NoPermission';
import { RootState } from '@/store';
import { useCurrentUser } from '@/common/auth/hooks/use-current-user';

interface Props {
  children: JSX.Element;
}

// Reference: https://ui.dev/react-router-protected-routes-authentication
function RequireAdmin({ children }: Props) {
  const {isAdmin} = useCurrentUser();
  return isAdmin ? children : <NoPermission />;
}

export default RequireAdmin;
