import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

interface GetVisibleTabsRes {
  visibleTabs: string[];
}

interface Props {
  productId: number | null;
}

export function useVisibleTabs({ productId }: Props) {
  const [query, { data, loading, error }] = useLazyQuery<GetVisibleTabsRes>(
    gql`
      query ($productId: Int!) {
        visibleTabs(productId: $productId)
      }
    `
  );

  // Product Details will always be visible
  let visibleTabs = ['product-details'];
  visibleTabs = visibleTabs.concat(data?.visibleTabs ?? ([] as string[]));

  useEffect(() => {
    productId !== null && query({ variables: { productId } });
  }, [query, productId]);

  return {
    visibleTabs,
    loading,
    error,
  };
}
