export interface AddProductDetails {
  productName: string;
  productDescription: string;
  statusId: number | null;
  itemGroupId: number | null;
  productCode: string;
  externalProductId: string | null;
  productTypeId: number | null;
  reOrderLevel: number | null;
  maxStockLevel: number;
  gst: number;
  processDays: number;
  minQuantityPerOrder: number | null;
  maxOrderQuantityLimit: number | null;
  maxOrderQuantityLimitDays: number | null;
  unitOfMeasure: string;
  zeroFreightOrderQuantity: number;
  isSpecialDelivery: boolean;
  quantityTypeId: number | null;
  maxQuantityPerOrder: number | null;
}

export const defaultProduct: AddProductDetails = {
  // Basic Details - Column 1
  productName: '',
  productDescription: '',
  statusId: null,
  itemGroupId: null,
  // Basic Details - Column 2
  productCode: '',
  externalProductId: '',
  productTypeId: null,
  // Replenishment Details - Column 1
  reOrderLevel: 0,
  maxStockLevel: 0,
  // Order Details - Column 1
  gst: 0,
  processDays: 0,
  minQuantityPerOrder: 0,
  maxOrderQuantityLimit: 0,
  maxOrderQuantityLimitDays: 0,
  unitOfMeasure: '',
  zeroFreightOrderQuantity: 0,
  isSpecialDelivery: false,
  // Order Details - Column 2
  quantityTypeId: null,
  maxQuantityPerOrder: 0,
};
