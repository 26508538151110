import { useCustomerTheme } from '@/common/theme';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  to: string;
  children: React.ReactNode;
}

export function NavMenuItem({ to, children }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const customerTheme = useCustomerTheme();
  const matchesBelowXXL = useMediaQuery(theme.breakpoints.down(1700));
  const matchesBelowXL = useMediaQuery(theme.breakpoints.down('xl'));
  // If the current history's location "pathname" is this item's "to",
  // then this means the current page corresponds to this navigation menu item
  const isActive = location.pathname === to;

  // Compute button styles using theme & isActive status
  const buttonStyle = useMemo(
    () => ({
      color: isActive
        ? customerTheme?.navigationBar.textActive
        : customerTheme?.navigationBar.text ?? '#FFFFFF',
      background: isActive ? customerTheme?.navigationBar.buttonActive : 'none',
      borderRadius: 12,
    }),
    [isActive, customerTheme]
  );

  // Derived state
  const fontWeight = isActive ? 700 : 300;
  let fontSize: string | undefined = undefined;
  if (matchesBelowXXL) fontSize = '0.75em';
  if (matchesBelowXL) fontSize = '0.65em';

  const handleClick = () => navigate(to);

  return (
    <Button variant="text" onClick={handleClick} style={buttonStyle}>
      <Typography variant="body1" sx={{ fontWeight, fontSize }}>
        {children}
      </Typography>
    </Button>
  );
}
