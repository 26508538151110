import { FeatureRoute } from '@/common/interfaces/feature-route';
import OrderManagement from './screens/OrderManagement';

export const orderManagementRoutes: FeatureRoute[] = [
  {
    path: '/orderManagement',
    component: OrderManagement,
    options: {
      useCustomerTheme: true,
    },
  },
];
