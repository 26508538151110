import { useLazyQuery } from '@apollo/client';
import { gql } from 'apollo-boost';
import { useEffect } from 'react';
import { GetProductUomsRes } from '../../../common/interfaces';

interface Props {
  productId: number | null;
}

export function useGetUoms({ productId }: Props) {
  const [query, { data, error, loading, refetch }] = useLazyQuery<
    GetProductUomsRes,
    { productId: number }
  >(gql`
    query ($productId: Int!) {
      productUoms(productId: $productId) {
        uomid
        productId
        descriptorId
        sellUom
        name
        sellPrice
        costPrice
        weightKg
        dimensionXcm
        dimensionYcm
        dimensionZcm
        multiplier
        descriptor {
          descriptorId
          name
        }
      }
    }
  `);

  useEffect(() => {
    productId !== null && query({ variables: { productId } });
  }, [query, productId]);

  // Compute type from descriptor.name to make it easier for
  // components to get the UOM's descriptor type
  const uoms = (data?.productUoms ?? []).map((u) => ({
    ...u,
    type: u.descriptor.name,
  }));

  return { uoms, error, loading, refetch };
}