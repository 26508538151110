import AuthService from '@/features/auth/services/auth.service';
import { ApolloClient, from, FieldMergeFunction, InMemoryCache, TypePolicies } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { GRAPHQL_URL } from '../globals';
import introspection from '../graphql';

// Support upload files in Apollo Client (Source: https://stackoverflow.com/a/65465279)
// createUploadLink is a drop-in replacement for createHttpLink (Source: https://stackoverflow.com/a/62087864)
const httpLink = createUploadLink({
  uri: GRAPHQL_URL,
  credentials: 'include',
  headers: {
    'X-Api-Key': 'xbs1Z52wKsHfdnroX+5h2UlUvSO7zHHmig1CwJqRDTg=',
  },
});

// This middleware attaches the Tsunami API Key into every request
const authLink = setContext((_, { headers }) => {
  const context = {
    headers: { ...headers, 'X-Api-Key': 'xbs1Z52wKsHfdnroX+5h2UlUvSO7zHHmig1CwJqRDTg=' },
  };
  const accessToken = AuthService.getAccessToken();
  if (accessToken) {
    context.headers.Authorization = `Bearer ${accessToken}`;
  }
  return context;
});

type CustomTypePolicies = TypePolicies & {
  ShoppingCartType: {
    cartItems?: {
      merge: FieldMergeFunction<any, any, any>;
    };
  };
};

const mergeShoppingCartItems: FieldMergeFunction<any, any, any> = (
  existing,
  incoming
) => {
  return incoming;
};


export const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache({
    // __typename is now necessary for fragments to work properly
    // (Source: https://stackoverflow.com/a/63363664/5575610)
    addTypename: true,
    possibleTypes: introspection.possibleTypes,
    typePolicies: {
      ...({
        ShoppingCartType: {
          fields: {
            cartItems: {
              merge:mergeShoppingCartItems
            },
          },
        },
      } as CustomTypePolicies), // Use custom type
    },
  }),
});
