import { FeatureRoute } from '@/common/interfaces/feature-route';
import Faq from './screens/Faq';

export const faqRoutes: FeatureRoute[] = [
  {
    path: '/faqs',
    component: Faq,
    options: {
      useCustomerTheme: true,
    },
  },
];
