import { useSearchParams } from 'react-router-dom';

type ShopParamsOptions = {
  onSearchChange: (s: string) => void;
};

export enum SearchParamsKey {
  ParentCategoryId = 'pcId',
  SelectedCategoryId = 'scId',
  SearchValue = 's',
}

/*
  This custom hook retrieves the following from route query:
    - Parent Category ID
    - Seleted Category ID
    - Search Value
*/
export function useShopParams(options?: ShopParamsOptions) {
  const [searchParams, _setSearchParams] = useSearchParams();

  const searchParamsParentCatId = searchParams.get(SearchParamsKey.ParentCategoryId);
  const parentCategoryId = searchParamsParentCatId ? parseInt(searchParamsParentCatId) : undefined;

  const searchParamsCatId = searchParams.get(SearchParamsKey.SelectedCategoryId);
  const selectedCategoryId = searchParamsCatId ? parseInt(searchParamsCatId) : undefined;

  const searchParamsSearchValue = searchParams.get(SearchParamsKey.SearchValue);
  const searchValue = searchParamsSearchValue ? decodeURIComponent(searchParamsSearchValue) : '';

  // This is a helper function so the proceeding functions can easily modify the Search Params
  function setSearchParams(updates: Record<string, string>) {
    // If no updates were passed then clear all search params
    if (Object.keys(updates).length === 0) _setSearchParams({});
    else {
      // Reference: https://stackoverflow.com/a/56780570/5575610
      _setSearchParams({ ...Object.fromEntries(searchParams), ...updates });
    }
  }

  // This is a helper function so the proceeding functions can easily remove a Search Param
  function clearSearchParam(key: string) {
    const updates = { ...Object.fromEntries(searchParams) };
    delete updates[key];
    _setSearchParams(updates);
  }

  function setSearchValue(s: string) {
    const newSearch = encodeURIComponent(s);
    setSearchParams({ [SearchParamsKey.SearchValue]: newSearch });
    options?.onSearchChange(newSearch);
  }

  function setParentCategoryId(catId: number) {
    setSearchParams({ [SearchParamsKey.ParentCategoryId]: catId.toString() });
  }

  function setSelectedCategoryId(catId: number) {
    setSearchParams({ [SearchParamsKey.SelectedCategoryId]: catId.toString() });
  }

  return {
    searchValue,
    setSearchValue,
    parentCategoryId,
    setParentCategoryId(catId?: number) {
      if (catId) setParentCategoryId(catId);
      else clearSearchParam(SearchParamsKey.ParentCategoryId);
    },
    selectedCategoryId,
    selectCategoryId(catId?: number) {
      if (catId) setSelectedCategoryId(catId);
      else clearSearchParam(SearchParamsKey.SelectedCategoryId);
    },
    setCategoryIds({ parentCatId, catId }: { parentCatId?: number; catId?: number }) {
      const params: Record<string, string> = {};
      if (parentCatId) params[SearchParamsKey.ParentCategoryId] = parentCatId.toString();
      if (catId) params[SearchParamsKey.SelectedCategoryId] = catId.toString();
      setSearchParams(params);
    },
    // May be needed for multiple param updates
    setSearchParams,
    clearSearchParams() {
      setSearchParams({});
    },
  };
}
