import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { Button, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useFavorites } from '../favorites.context';

interface Props {
  productId: number;
  text?: boolean;
}

export function FavoriteButton(props: Props) {
  const { isFavorite, addToFavorites, removeFromFavorites, isLoading } = useFavorites();
  const { enqueueSnackbar } = useSnackbar();

  async function handleFavoriteClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    if (isFavorite(props.productId)) {
      await removeFromFavorites(props.productId);
      enqueueSnackbar('Removed from favourites', { variant: 'success' });
    } else {
      await addToFavorites(props.productId);
      enqueueSnackbar('Added to favourites', { variant: 'success' });
    }
  }

  if (props.text)
    return (
      <Button onClick={handleFavoriteClick} variant="outlined" sx={{ width: '100%' }}>
        {isFavorite(props.productId) ? 'Remove from Favourites' : 'Add to Favourites'}
      </Button>
    );

  return (
    <IconButton onClick={handleFavoriteClick}>
      {isFavorite(props.productId) ? (
        <FavoriteIcon sx={{ color: 'primary.main' }} />
      ) : (
        <FavoriteBorderOutlinedIcon sx={{ color: 'primary.main' }} />
      )}
    </IconButton>
  );
}
