import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { MouseEvent } from 'react';
import { useExportMenu } from '../hooks/export-menu';

interface ReportActionsProps {
  items?: any[];
  isExportEnabled?: boolean;
  onOpenScheduleReportDialog?: () => void;
  onExportClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function ReportActions({
  items,
  onOpenScheduleReportDialog,
  onExportClick,
  ...props
}: ReportActionsProps) {
  // const exportMenu = useExportMenu(items, reportType);
  const exportMenu = useExportMenu(items || [], 'basic');
  const isExportEnabled = props.isExportEnabled !== undefined ? props.isExportEnabled : true;

  const handleExportClick = (e: MouseEvent<HTMLButtonElement>) => {
    exportMenu.setAnchorEl(e.currentTarget);
  };

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" spacing={2}>
      <Button onClick={onOpenScheduleReportDialog} variant="outlined" size="small">
        <EventAvailableIcon />
        <Typography variant="h6">Schedule Report</Typography>
        <ArrowDropDownIcon fontSize="large" />
      </Button>
      <Button
        variant="outlined"
        disabled={!isExportEnabled}
        onClick={handleExportClick}
        size="small"
      >
        <Typography>Export to XLSX</Typography>
        <ArrowDropDownIcon fontSize="large" />
      </Button>
      <Menu
        id="export-menu"
        anchorEl={exportMenu.anchorEl}
        open={exportMenu.open}
        onClose={exportMenu.handleMenuClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem onClick={() => exportMenu.handleMenuItemClick('PDF')}>Export to PDF</MenuItem>
        <MenuItem onClick={() => exportMenu.handleMenuItemClick('CSV')}>Export to CSV</MenuItem>
        <MenuItem onClick={() => exportMenu.handleMenuItemClick('DOC')}>Export to DOC</MenuItem>
      </Menu>
    </Stack>
  );
}
