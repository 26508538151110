import { ProductPrice } from '@/common/components';
import {
  Box,
  Card,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { OLD_WN_API_URL } from '@/globals';
import { ProductImage } from '../../../../product/components/ProductImage';
import { useProductContext } from '../../../../product/product.context';

interface OrderItemProps {
  quantity: number;
}

export function OrderItem(props: OrderItemProps) {
  const theme = useTheme();
  const product = useProductContext();

  const randNumDigits = (n: number) =>
    Math.floor(Math.random() * (9 * Math.pow(10, n))) + Math.pow(10, n);

  const getETemplateUrl = () =>
    `${OLD_WN_API_URL}/etemp/default.aspx?pdf=${randNumDigits(5)}${
      product.eTemplateId
    }w.pdf&&hl=true`;

  return (
    <Card sx={{ minHeight: 250 }}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="start">
        <Box flexGrow={1} p={2} width="100%" boxSizing="border-box">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} sx={{ height: 200 }}>
              <ProductImage id={product.productId} alt={product.name} />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'start',
              }}
            >
              <Container disableGutters>
                <Stack flex={3}>
                  <Typography variant="body1">{product.name}</Typography>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    <ProductPrice
                      priceType={product.priceType}
                      prices={product.prices}
                      quantity={props.quantity}
                    />
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#3A5069' }}>
                    {product.code}
                  </Typography>
                  {product.isETemplate && product.eTemplateId && (
                    <Link href={getETemplateUrl()} target="_blank" rel="noopener">
                      View PDF
                    </Link>
                  )}
                </Stack>
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Card>
  );
}
