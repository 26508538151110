import { getDestructionModeOn } from '@/features/cart/components/CartInformationBuffer';
import { useShoppingCart } from '@/features/cart/shopping-cart.context';
import {
  useGetUserCartQuery,
  useCreateUserCartMutation,
  useUpdateUserCartMutation,
  GetUserCartDocument,
} from '@/graphql';
import { useCheckoutState, useCheckoutStore } from '../checkout-store';

export function useUpdateCart() {
  const [createCartMutation, createCartMut] = useCreateUserCartMutation();
  const [updateCartMutation, updateCartMut] = useUpdateUserCartMutation();
  const getCartQuery = useGetUserCartQuery({ fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });
  const checkoutState = useCheckoutState();
  const { cartItems } = useShoppingCart();
  const setUpdatingCart = useCheckoutStore((s) => s.setUpdatingCart);

  const userCart = getCartQuery.data?.userCart;

  async function updateCart() {
    if (getCartQuery.loading)
      return console.log('User Cart still loading, cannot continue with updateCart');
    const cartId = userCart?.id;
    let destructionModeStatus = getDestructionModeOn() ?? false;
    const cartInput = {
      freightSettingId: checkoutState.freightSettingId ?? -1,
      chargeProcessingFee: checkoutState.chargeProcessingFee,
      isUrgentOrder: checkoutState.isUrgentOrder,
      // serviceCode: checkoutState.shippingMethod.service,
      firstName: checkoutState.contactInfo.fullName.split(' ').shift() ?? '',
      lastName: checkoutState.contactInfo.fullName.split(' ').pop() ?? '',
      notes: checkoutState.deliveryAddress.deliveryNotes,
      company: checkoutState.contactInfo.companyName,
      street1: checkoutState.deliveryAddress.streetAddress,
      suburb: checkoutState.deliveryAddress.suburb,
      postcode: checkoutState.deliveryAddress.postcode,
      state: checkoutState.deliveryAddress.state,
      country: checkoutState.deliveryAddress.country,
      mobile: checkoutState.contactInfo.mobileNo,
      phone: checkoutState.contactInfo.phoneNo,
      email: checkoutState.contactInfo.email,
      cartLines: cartItems.map((i) => ({
        productId: i.productId,
        // TODO: possibly check if any item's productCode is undefined,
        // throw an error (but this should never happen)
        productCode: i.productCode!,
        quantity: i.quantity,
        eTemplateId: i.eTemplateId,
      })),
      destructionMode: destructionModeStatus
    };
    setUpdatingCart(true);
    try {
      // console.log('[updateCart] cart Id:', cartInput, cartId);
      if (cartId)
        await updateCartMutation({
          variables: { cartId, ...cartInput },
          refetchQueries: [GetUserCartDocument],
        });
      else await createCartMutation({ variables: cartInput });
    } catch (err) {
      console.error(err);
    } finally {
      setUpdatingCart(false);
    }
  }

  return updateCart;
}
