import { useQuery } from '@apollo/client';
import { Checkbox, List, ListItemButton, ListItemText, Stack } from '@mui/material';
import { gql } from 'apollo-boost';
import { useContext, useEffect, useState } from 'react';
import { SelectedRoleContext } from '../context/selected-role.context';

export interface CustomOrderType {
  orderTypeId: number;
  name: string;
  isActive: boolean;
}

function useRoleOrderTypes(roleId?: number) {
  const [selectedOrderTypes, setSelectedOrderTypes] = useState<CustomOrderType[]>([]);

  // rot = Role Order Types
  const { data: rotQuery } = useQuery<{ adminOrderTypes: CustomOrderType[] }>(
    gql`
      query getOrderTypes($roleId: Int!) {
        adminOrderTypes(roleId: $roleId) {
          orderTypeId
          name
          isActive
        }
      }
    `,
    {
      variables: { roleId },
    }
  );

  useEffect(() => {
    if (rotQuery) {
      setSelectedOrderTypes(rotQuery?.adminOrderTypes.filter((ot) => ot.isActive));
    }
  }, [rotQuery]);

  return {
    orderTypes: rotQuery?.adminOrderTypes ?? [],
    selectedOrderTypes,
    setSelectedOrderTypes,
  };
}

interface Props {
  onChange?: (orderTypes: CustomOrderType[]) => void;
}

export function RoleOrderTypesList(props: Props) {
  const { selectedRole } = useContext(SelectedRoleContext);
  const { orderTypes, selectedOrderTypes, setSelectedOrderTypes } = useRoleOrderTypes(
    selectedRole?.roleId
  );

  useEffect(() => {
    props.onChange?.(selectedOrderTypes);
  }, [selectedOrderTypes, props.onChange]);

  function handleRoleOrderTypeClick(idx: number) {
    const orderType = orderTypes[idx];
    const isSelected = selectedOrderTypes.some((rot) => rot.orderTypeId === orderType.orderTypeId);
    if (!isSelected) {
      setSelectedOrderTypes([...selectedOrderTypes, orderType]);
    } else {
      setSelectedOrderTypes(
        selectedOrderTypes.filter((rot) => rot.orderTypeId !== orderType.orderTypeId)
      );
    }
  }

  return (
    <Stack>
      <List>
        {orderTypes.map((ot, i) => (
          <ListItemButton key={i} sx={{ pl: 4 }} onClick={() => handleRoleOrderTypeClick(i)}>
            <Checkbox
              sx={{ mr: 1 }}
              checked={selectedOrderTypes.some((srot) => srot.orderTypeId === ot.orderTypeId)}
            />
            <ListItemText primaryTypographyProps={{ fontSize: '12px' }} primary={ot.name} />
          </ListItemButton>
        ))}
      </List>
    </Stack>
  );
}
