import { FeatureRoute } from '@/common/interfaces/feature-route';
import { ExpectedDeliveryUpload } from './screens/ExpectedDeliveryDateUpload';

export const expectedDeliveryUploadRoutes: FeatureRoute[] = [
  {
    path: '/expectedDeliveryUpload',
    component: ExpectedDeliveryUpload,
    options: {
      useCustomerTheme: true,
    },
  },
];