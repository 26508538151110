import { FeatureRoute } from '@/common/interfaces/feature-route';
import Shop from './screens/Shop';

export const SHOP_ROUTE = '/';

export const shopRoutes: FeatureRoute[] = [
  {
    path: SHOP_ROUTE,
    component: Shop,
    options: {
      useCustomerTheme: true,
      requiresAdmin: false,
    },
  },
];
