import { Checkbox, IconButton, Paper } from '@mui/material';
import { StyledTable } from '../../../common/components/tsu-table/styled';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ProductUom } from '../../../graphql';

interface Props {
  uoms: ProductUom[];
  headers: { text: string; value: string }[];
  onEditClick?: (uom: ProductUom) => void;
  onRemoveClick?: (uom: ProductUom) => void;
}

export function UomTable({ uoms, headers, onEditClick, onRemoveClick }: Props) {
  const Table = StyledTable();
  return (
    <Paper elevation={0} sx={{ overflowX: 'auto', width: '100%', mb: 3 }}>
      <Table>
        <thead>
          <tr>
            {headers.map((h, i) => (
              <th key={i}>{h.text}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {uoms.map((item, i) => (
            <tr key={i}>
              {headers.map((h, j) => {
                if (h.value === 'actions')
                  return (
                    <td key={j}>
                      <IconButton
                        color="primary"
                        onClick={() => onEditClick?.(item)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => onRemoveClick?.(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  );
                let value = item[h.value as keyof ProductUom];
                if (h.value === 'sellUom') {
                  value = value as boolean;
                  return (
                    <td key={j}>
                      <Checkbox checked={value} readOnly disabled />
                    </td>
                  );
                }
                return <td key={j}>{value ?? '-'}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </Paper>
  );
}
