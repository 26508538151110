import { useCurrentUser } from '@/common/auth/hooks/use-current-user';
import { DASHBOARD_ROUTE } from '@/features/dashboard/dashboard.routes';
import { useEffect, useMemo, useState } from 'react';

export function useNavMenuItems() {
  const { isAdmin, isRouteVisible, sitemaps, customer, refresh } = useCurrentUser();
  const [eTempRoute, setETempRoute] = useState("/etempV2");
  const [adminRoute, setAdminRoute] = useState("/administration");

  useEffect(() =>{
    refresh();
    var currentCusId = customer?.customerId;
    // admin punchout logic for VACC, Ansell and AFCAL plus EuropCar
    if(currentCusId == 478 || currentCusId == 435 || currentCusId == 483 || currentCusId == 426) {
      setAdminRoute("/waivenetAdmin");
    }
  },[customer]);
  return useMemo(() => {
    const items = [
      {
        text: 'Dashboard',
        to: DASHBOARD_ROUTE,
        legacyPagePaths: ['/userWelcomeScreen.aspx'],
      },
      {
        text: 'Shop',
        to: '/',
        legacyPagePaths: ['/orderV2.aspx', '/order.aspx'],
      },
      // {
      //   text: 'Product Management',
      //   to: '/productManagement',
      //   legacyPagePaths: ['/product.aspx'],
      // },
      {
        text: 'Product Management',
        to: '/productmanagementpunchout',
        legacyPagePaths: ['/product.aspx'],
      },
      {
        text: 'Order Management',
        to: '/orderManagement',
        legacyPagePaths: ['/order/orderswaitingapproval.aspx'],
      },
      {
        text: 'Bulk Order Csv',
        to: '/bulkOrderCsv',
        legacyPagePaths: ['/customisation/GoodFridayAppeal/UploadCSV.aspx', '/customisation/BCNA/BulkOrderBCNA.aspx'],
      },
      // {
      //   text: 'Report',
      //   to: '/report',
      //   legacyPagePaths: ['/reportViewer.aspx'],
      // },
      {
        text: 'FAQs',
        to: '/faqs',
        legacyPagePaths: ['/FAQ.aspx'],
      },
      {
        text: 'Theme Builder',
        to: '/themeBuilder',
        legacyPagePaths: ['/adminSiteMap.aspx'],
      },
      {
        text: 'Administration',
        to: adminRoute,
        legacyPagePaths: ['/admin.aspx'],
      },
      // {
      //   text: 'Custom Artworks',
      //   to: '/customArtworks',
      //   legacyPagePaths: ['/etempV2/default.aspx'],
      // },
      {
        text: 'Custom Artworks',
        to: eTempRoute,
        legacyPagePaths: ['/etempV2/default.aspx?orderflow=2'],
      },
      {
        text: 'Document Manager',
        to: '/documentManager',
        legacyPagePaths: ['/Core/Document/Default.aspx'],
      },
      {
        text: 'Project Management',
        to: '/pms',
        legacyPagePaths: ['/Plan/ProjectList.aspx'],
      },
      {
        text: 'Reports',
        to: '/wnreports',
        legacyPagePaths: ['/reportViewer.aspx'],
      },
      // {
      //   text: 'WN Csv Upload',
      //   to: '/wncsvupload',
      //   legacyPagePaths: ['/customisation/GoodFridayAppeal/UploadCSV.aspx'],
      // },
    ];
    return (
      items
        .filter((i) => i.legacyPagePaths.some((p) => isRouteVisible(p)))
        // .sort((a, b) => {
        //   const orderA =
        //     sitemaps.find((s) => s.pagePath && a.legacyPagePaths.includes(s.pagePath))?.seqNo ?? 0;
        //   const orderB =
        //     sitemaps.find((s) => s.pagePath && b.legacyPagePaths.includes(s.pagePath))?.seqNo ?? 0;
        //   return orderA - orderB;
        // })
        // Remove isVisible from items (we don't need it anymore)
        .map((i) => ({ text: i.text, to: i.to }))
    );
  }, [isAdmin, isRouteVisible, eTempRoute, adminRoute]);
}
