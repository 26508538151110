import { OrderSummary, TsuLoading } from '@/common/components';
import { useShoppingCart } from '@/features/cart/shopping-cart.context';
import { ProductImage } from '@/features/product/components/ProductImage';
import { CartLineInput, GetUserCartDocument, useCreateUserCartMutation, useGetCurrentUserQuery, useGetUserCartQuery, useUpdateUserCartMutation } from '@/graphql';
import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useCheckoutStep, useCheckoutStore } from '../checkout-store';
import { CancelOrSaveOrder } from './CancelOrSaveOrder';
import { useStepCardContext } from './StepCard';

export function OrderedItems() {
  const [createCartMutation, createCartMut] = useCreateUserCartMutation();
  const [updateCartMutation, updateCartMut] = useUpdateUserCartMutation();
  const {
    data,
  } = useGetCurrentUserQuery({ notifyOnNetworkStatusChange: true});

  const setUpdatingCart = useCheckoutStore((s) => s.setUpdatingCart);

  const currentUser = data?.currentUser ?? null;
  const discount = currentUser?.role?.discount;
  // These values are provided by our parent Checkout Step Card (React Context).
  const isUpdatingCart = useCheckoutStore((s) => s.isUpdatingCart);
  // These values are provided by our parent Checkout Step Card (React Context).
  const { stepIndex, submitTitle, nextStep } = useStepCardContext();
  const { currentStep } = useCheckoutStep();
  // These values are provided by the Checkout Store (Zustand).
  const shoppingCartItems = data?.currentUser?.shoppingCart?.cartItems;
  const [shoppingCart, setShoppingCart] = useState(shoppingCartItems);
  const { isCartLoading: isShoppingCartLoading } = useShoppingCart();
  const { data: userCartQuery, loading: isUserCartLoading, refetch: userCartRefetch } = useGetUserCartQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  });
  const refreshPage = () => {
    window.location.reload();
  };
  // Derived state
  const cart = userCartQuery?.userCart;
  useEffect(() => {
    if(!cart && !isUpdatingCart) {
      userCartRefetch();
    }
  }, [isUpdatingCart]);
  // Helper to apply discount to product prices
  const getProductPriceWithDiscount = useCallback(
    (product) => {
      if (!product || discount === 0) return product;
      return {
        ...product,
        prices: product.prices.map((p) => ({
          ...p,
          price: p.price - p.price * (discount?? 0)/100,
        })),
      };
    },
    [discount]
  );
  // const lines = cart?.cartInformation.cartLines
  const isLoading = isUserCartLoading || isShoppingCartLoading;
  const isReadOnly = currentStep != stepIndex;

  async function updateDiscountToCart() {
    if (isUserCartLoading)
      return console.log('User Cart still loading, cannot continue with updateCart');
    const cartId = cart?.id;
    const cartLinesInput: CartLineInput[] = cart?.cartInformation.cartLines?.map((i) => ({
      productId: i.productId,
      // TODO: possibly check if any item's productCode is undefined,
      // throw an error (but this should never happen)
      productCode: i.productCode ?? "",
      quantity: i.quantity ?? 0,
      eTemplateId: i.eTemplateId ?? 0,
    })) ?? [];
    const updateCartInput = {
      freightSettingId: cart?.cartInformation.deliveryMethodId ?? -1,
      chargeProcessingFee: cart?.cartInformation.manualOrder ?? false,
      isUrgentOrder: cart?.cartInformation.urgentPick ?? false,
      // serviceCode: checkoutState.shippingMethod.service,
      firstName: cart?.cartInformation.firstName ?? '',
      lastName: cart?.cartInformation.lastName ?? '',
      notes: cart?.cartInformation.deliveryInstructions ?? "",
      company: cart?.cartInformation.deliveryAddress?.company ?? "",
      street1: cart?.cartInformation.deliveryAddress?.street1 ?? "",
      suburb: cart?.cartInformation.deliveryAddress?.suburb ?? "",
      postcode: cart?.cartInformation.deliveryAddress?.postcode ?? "",
      state: cart?.cartInformation.deliveryAddress?.state ?? "",
      country: cart?.cartInformation.deliveryAddress?.country ?? "",
      mobile: cart?.cartInformation.deliveryAddress?.mobile ?? "",
      phone: cart?.cartInformation.deliveryAddress?.phone ?? "",
      email: cart?.cartInformation.deliveryAddress?.email ?? "",
      cartLines: cartLinesInput,
      destructionMode: false,
      orderCost: cart?.orderCosts,
    };
    console.log("Update Cart Input: ", updateCartInput)
    setUpdatingCart(true);
    try {
      if (cartId)
        await updateCartMutation({
          variables: { cartId, ...updateCartInput },
          refetchQueries: [GetUserCartDocument],
        });
      else await createCartMutation({ variables: updateCartInput });
    } catch (err) {
      console.error(err);
    } finally {
      setUpdatingCart(false);
    }
  }

  // Update shopping cart with discounted prices when discount or cart items change
  useEffect(() => {
    const discountedCart = shoppingCartItems?.map((cartItem) => ({
      ...cartItem,
      product: getProductPriceWithDiscount(cartItem.product),
    }));
    setShoppingCart(discountedCart);
  }, [discount, shoppingCartItems, getProductPriceWithDiscount]);

  const goToPaymentMethod = async () => {
    try {
      await updateDiscountToCart();
      nextStep();
    } catch (err) {
      console.log('error:', err);
    }
  };

  return (
    <TsuLoading loading={isLoading}>
      <Stack spacing={2} mt={4}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shoppingCart?.map((item, i) => {
              const unitPrice = item.product?.prices.find((p) => p.quantity !== 0)?.price;
              const unitPriceText = unitPrice ? '$' + unitPrice.toFixed(2) : '$0.00';
              return (
                <TableRow key={i}>
                  <TableCell>
                    <Stack direction="row" alignItems="center">
                      {item.productId ? (
                        <Box width={50} height={50}>
                          <ProductImage
                            id={item.productId}
                            alt={item.product?.name}
                            thumbnail
                          />
                        </Box>
                      ) : null}
                      <Typography>{item.product?.name ?? '-'}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ color: 'primary.main' }}>{unitPriceText}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <OrderSummary
          numCartItems={cart?.cartInformation?.cartLines?.length ?? 0}
          itemTotal={cart?.orderCosts?.itemCost ?? 0}
          pickFee={cart?.orderCosts?.fees.pickFee.fee ?? 0}
          processingFee={
            cart?.cartInformation.manualOrder
              ? cart?.orderCosts?.fees.manualOrderFee.fee
              : 0
          }
          urgentPickFee={cart?.orderCosts?.fees.urgentPickFee.fee ?? 0}
          shippingFee={cart?.orderCosts?.fees.shippingFee.fee ?? 0}
          paysForPickFee={cart?.orderCosts?.fees.pickFee.paysFor ?? true}
          fuelLevyFee={cart?.orderCosts?.fees.fuelLevyFee.fee ?? 0}
          paysForFuelLevyFee={cart?.orderCosts?.fees.fuelLevyFee.paysFor ?? true}
          paysForShippingFee={cart?.orderCosts?.fees.shippingFee.paysFor ?? true}
          subtotalExGst={cart?.orderCosts?.subTotal ?? 0}
          gst={cart?.orderCosts?.gst ?? 0}
          totalAmount={cart?.orderCosts?.grandTotal ?? 0}
          hideTitle
        />

        <Grid container>
          <Grid xs={12} item>
            <Box hidden={isReadOnly}>
              {!cart?.orderCosts?.fees.shippingFee ?
              <Button
                onClick={refreshPage}
                variant="contained"
                sx={{ alignSelf: 'start', width: '50%' }}
              >
                Please click the button to verify your details again !
              </Button>
              :
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  onClick={goToPaymentMethod}
                  variant="contained"
                  sx={{ width: '50%', height: '44px' }}
                >
                  {submitTitle}
                </Button>
                <CancelOrSaveOrder saveOrderDisabled={false} />
              </Stack>
              }
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </TsuLoading>
  );
}