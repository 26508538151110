import { useGetCurrentUserQuery, useSavedAddressQuery } from '@/graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Checkbox, Divider, Grid, Stack, Typography } from '@mui/material';
import { FormEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useCheckoutStep, useCheckoutStore } from '../checkout-store';
import { billingDetailsSchema } from '../constants/checkout-validation';
import { AddressForm } from '../forms/AddressForm';
import { ContactInfoForm } from '../forms/ContactInfoForm';
import { BillingDetailsForm } from '../interfaces';
import { addressToFormValues, getInitialAddress, getInitialContactInfo } from '../utils';
import { useStepCardContext } from './StepCard';

export function BillingDetails() {
  // These values are provided by our parent Checkout Step Card (React Context).
  const { stepIndex, submitTitle, nextStep } = useStepCardContext();
  // These values are provided by the Checkout Store (Zustand).
  const { currentStep } = useCheckoutStep();
  const { contactInfo, deliveryAddress } = useCheckoutStore((s) => ({
    contactInfo: s.contactInfo,
    deliveryAddress: s.deliveryAddress,
  }));
  // Setter function for saving Billing Details in our Checkout Store (Zustand).
  const submitBillingDetails = useCheckoutStore((s) => s.submitBillingDetails);
  // We manage our own state for "Same as Delivery Details",
  // to allow the user to freely toggle true / false without losing their custom Billing Details values.
  const [isSameAsDelivery, setSameAsDelivery] = useState(true);
  // Define our form state using react-hook-form & yup.
  const methods = useForm<BillingDetailsForm>({
    defaultValues: { ...getInitialContactInfo(), ...getInitialAddress() },
    resolver: yupResolver(billingDetailsSchema),
  });
  const [loading, setLoading] = useState(true); // Add loading state
  useGetCurrentUserQuery({
    fetchPolicy: 'no-cache',
    onCompleted(query) {
      const data = query.currentUser;
      if (!data) return;
      methods.reset({
        ...methods.getValues(),
        fullName: (data.firstName ?? '') + (data.lastName ? ' ' + data.lastName : ''),
        email: data.email ?? '',
        mobileNo: data.mobile ?? '',
        phoneNo: data.phone ?? '',
      });
      setLoading(false);
    },
  });
  useSavedAddressQuery({
    fetchPolicy: 'no-cache',
    onCompleted(query) {
      const data = query.savedAddress?.billingAddress;
      if (!data) return;
      methods.reset({ ...methods.getValues(), ...addressToFormValues(data) });
    },
  });
  const isReadOnly = currentStep != stepIndex;
  const isCurrentStep = currentStep === stepIndex;

  // If Billing Details became the current step, then auto-fill
  // our form values with the data from Contact Info & Delivery Address.
  // Only do this IF "same as delivery" is true (may be unchecked in the future, then user comes back to this step).
  useEffect(() => {
    if (!isCurrentStep || !isSameAsDelivery) return;
    methods.reset({ ...contactInfo, ...deliveryAddress }, { keepErrors: true });
  }, [isCurrentStep]);

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    // If user ticks "same as delivery", then DO NOT do any validation, just continue.
    if (isSameAsDelivery) {
      e.preventDefault();
      return submit(methods.getValues());
    }
    // Otherwise, go through the standard validation process (react-hook-form), before continuing.
    return methods.handleSubmit(submit)(e);
  }

  function submit(data: BillingDetailsForm) {
    submitBillingDetails(data, isSameAsDelivery);
    nextStep();
  }
  if(loading) {
    return(
      <div>Loading...</div> // Render loading state
    );
  }
  else {
    return (
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Checkbox
                checked={isSameAsDelivery}
                onChange={!isReadOnly ? (e) => setSameAsDelivery(e.target.checked) : undefined}
                readOnly={isReadOnly}
              />
              <Typography variant="h6">Same as Delivery Details</Typography>
            </Stack>
            <Box hidden={isSameAsDelivery}>
              <ContactInfoForm readOnly={isReadOnly} />
              <Divider
                sx={{
                  '&.MuiDivider-root': { my: 4 },
                  display: !isReadOnly ? 'block' : 'none',
                }}
              />
              <Box mt={2}>
                <AddressForm title="Billing Details" readOnly={isReadOnly} />
              </Box>
            </Box>
            <Box hidden={isReadOnly}>
              <Grid container>
                <Grid xs={6} item>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ alignSelf: 'start', width: '100%' }}
                  >
                    <Typography>{submitTitle}</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </form>
      </FormProvider>
    );
  }
}
