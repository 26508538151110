import { TsuLoading } from '@/common/components';
import { useCurrentUser } from '@/common/auth/hooks/use-current-user';
import { BusinessLevel, useGetCustomerBusinessLevelsLazyQuery } from '@/graphql';
import { Button, Container, Menu, Stack, Typography } from '@mui/material';
import { useState } from 'react';

export interface addressComponents {
  street1: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
  deliveryNote: string;
  businessName: string;
}

interface changeAddressMenuProps {
  onClick?: (a: addressComponents) => void;
}

export function ChangeAddressMenu(props: changeAddressMenuProps) {
  // This is needed for MUI's Menu to know what element to render above of
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  // This is used to show the dropdown of customers
  const [getCustomerBusinessLevels, { data, loading: isLoadingCustomerBusinessLevels }] = useGetCustomerBusinessLevelsLazyQuery();
  const [selectedAddress, setSelectedAddress] = useState<addressComponents>({
    street1: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
    deliveryNote: '',
    businessName: '',
  });
  const businessLevels = (data?.customerBusinessLevels ?? [])
    .slice()
    .sort((c1, c2) => ((c1?.name ?? '') < (c2?.name ?? '') ? -1 : 1));

  // This is for displaying the current customer's initials
  const { isAdmin } = useCurrentUser();

  function handleOpenMenuClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (!isAdmin) return;
    getCustomerBusinessLevels();
    openMenu(e.currentTarget);
  }
  function handleAddressClick(billingAddress: Partial<BusinessLevel["billingAddress"]>) {
    const newSelectedAddress: addressComponents = {
      street1: billingAddress?.street1 ?? '',
      suburb: billingAddress?.suburb ?? '',
      state: billingAddress?.state ?? '',
      postcode: billingAddress?.postcode ?? '',
      country: billingAddress?.country ?? '',
      deliveryNote: billingAddress?.notes ?? '',
      businessName: billingAddress?.companyName ?? '',
    };
    setSelectedAddress(newSelectedAddress);
    closeMenu();
    if (props.onClick) {
      props.onClick(newSelectedAddress); // Pass the updated newSelectedAddress to the onClick function if it exists
    }
  }

  const openMenu = (t: EventTarget & HTMLElement) => setAnchorEl(t);
  const closeMenu = () => setAnchorEl(null);
  if(isAdmin) {
    return(
      <>
        <Button
          onClick={handleOpenMenuClick}
        >
          <Typography>
            Select saved addresses
          </Typography>
        </Button>
        <Menu
          id="switch-customer-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        >
          <Container>
            <TsuLoading loading={isLoadingCustomerBusinessLevels} title="Loading customer business levels">
              <Stack alignItems="start" sx={{ width: '100%' }}>
                {businessLevels.map((c, i) => (
                  <Button
                    key={i}
                    variant="text"
                    sx={{ width: '100%', justifyContent: 'start' }}
                    onClick = {() => handleAddressClick(c.billingAddress)}
                  >
                    <Typography textAlign="left" whiteSpace="nowrap" sx={{color: 'black'}}>
                     {c.name} - {c.billingAddress?.street1}
                    </Typography>
                  </Button>
                ))}
              </Stack>
            </TsuLoading>
          </Container>
        </Menu>
      </>
    );
  }
  else {
    return(
      <>
      </>
    );
  }
}
