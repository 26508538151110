import { Circle } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';

interface TsuStepNumberProps {
  step: number;
  [x: string]: any;
}

export function TsuStepNumber({ step, ...otherProps }: TsuStepNumberProps) {
  const theme = useTheme();
  return (
    <Box position="relative" width={32} height={32} {...otherProps}>
      <Box position="absolute">
        <Circle sx={{ color: theme.palette.primary.main }} fontSize="large" />
      </Box>
      <Typography position="absolute" left={12} color="white">
        <span
          style={{
            fontSize: 20,
            fontFamily: theme.typography.body1.fontFamily,
          }}
        >
          {step}
        </span>
      </Typography>
    </Box>
  );
}
