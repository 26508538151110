import { NavMenu, SearchBar } from '@/common/components';
import { AppBackground } from '@/layouts/AppBackground';
import { Box, Stack } from '@mui/material';
import { Footer } from './Footer';

interface Props {
  title?: string;
  // children?: JSX.Element | JSX.Element[] | false | null;
  // This covers everything rather than JSX.Element
  // (Source: https://stackoverflow.com/a/57253387)
  children?: React.ReactNode;
  withSearchBar?: boolean;
  noContentSpacing?: boolean;
}

export function AppLayout(props: Props) {
  const { title, children, withSearchBar, noContentSpacing } = props;
  return (
    <AppBackground>
      <Stack
        direction="column"
        alignItems="stretch"
        justifyContent="start"
        sx={{ height: '100%', minHeight: '100vh', overflowX: 'hidden' }}
      >
        <NavMenu />
        <SearchBar centerTitle={title} withSearchBar={withSearchBar} />
        <Box my={!noContentSpacing ? 4 : 0}>{children}</Box>
        <Box flexGrow={1} flexBasis={0} boxSizing="border-box" mt={4}>
          <Footer />
        </Box>
      </Stack>
    </AppBackground>
  );
}
