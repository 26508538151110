import create from 'zustand';
import { EMPTY_ADDRESS, EMPTY_CONTACT_INFO } from './constants/initial-data';
import {
  Address,
  BillingDetailsForm,
  ContactInfo,
  DeliveryDetailsForm,
  PaymentMethodForm,
  PaymentMethods,
  ShippingMethodForm,
} from './interfaces';

type CheckoutState = {
  // Represents the user's current position in the checkout flow (zero-indexed)
  // e.g. Step 0 means the user is currently in "Delivery Address" step.
  step: number;
  // Stores step indexes that the user has encountered.
  encounteredSteps: number[];
  // Represents the user's contact info in their UserCart.
  // This is extremely important for populating Order & OrderShipping records.
  contactInfo: ContactInfo;
  // Represents the OrderShipping's destination address.
  // This is absolutely required for Order, OrderShipping, and OneFlo API.
  deliveryAddress: Address;
  // Represents the OrderShipping's billing address.
  // Not sure if this is absolutely required, but it definitely is important.
  // This is used to fill up the billing address columns of OrderShipping.
  billingAddress: Address;
  // Helper flag to quickly determine if Billing Address is the same as Delivery Address.
  // Setting this to true will cause Delivery Address to be copied into Billing Address.
  isBillingSameAsDelivery: boolean;
  // Represents the chosen Freight Setting (ID).
  // This will be NULL at first as no freight settings are loaded or chosen.
  // e.g. Express (6280), Standard (6281)
  freightSettingId: number | null;
  // Represents whether the order will be marked as "urgent".
  // This incurs a fee during the computation of Order Fees.
  isUrgentOrder: boolean;
  // Represents whether the order should "charge a processing fee" for making us order on their behalf.
  // This is typically used when our admins manually order an item for a customer.
  chargeProcessingFee: boolean;
  // (Optional) Any delivery notes the customer wants to pass along with the Order.
  deliveryNotes: string;
  // As of now, not much development has been made for payments.
  // We only have the hardcoded payment methods defined in PaymentMethods.
  // Most customers will use "invoice" payment which is automated by WN1 at EOM.
  // This will be NULL at first as no payment method is automatically chosen.
  paymentMethod: PaymentMethods | null;
  isUpdatingCart: boolean;
  // ------------------------------------------------------------------------------------------
  // Mutations
  // ------------------------------------------------------------------------------------------
  // Move forward to next step in state.
  // Checkout components will be notified of this step update.
  nextStep: () => void;
  // Allows us to set step to an arbitrary number, for backtracking purposes.
  // This will be primarily used when the user clicks on "Edit Details" of a previous step.
  navigateStep: (step: number) => void;
  // Update loading flag
  setUpdatingCart: (isUpdatingCart: boolean) => void;
  // ------------------------------------------------------------------------------------------
  // Checkout mutations
  // ------------------------------------------------------------------------------------------
  submitDeliveryDetails: (v: DeliveryDetailsForm) => void;
  submitBillingDetails: (v: BillingDetailsForm, isSameAsDelivery: boolean) => void;
  submitShippingMethod: (v: ShippingMethodForm) => void;
  submitPaymentMethod: (v: PaymentMethodForm) => void;
};

export const useCheckoutStore = create<CheckoutState>((set) => ({
  // User's current step (4 total steps)
  step: 0,
  // Step indexes that the user has encountered.
  encounteredSteps: [],
  // 1. Delivery Details
  contactInfo: { ...EMPTY_CONTACT_INFO },
  deliveryAddress: { ...EMPTY_ADDRESS },
  // 2. Billing Details
  billingAddress: { ...EMPTY_ADDRESS },
  isBillingSameAsDelivery: true,
  // 3. Shipping Method
  freightSettingId: null,
  isUrgentOrder: false,
  chargeProcessingFee: false,
  deliveryNotes: '',
  // 4. Payment Method
  paymentMethod: PaymentMethods.INVOICE,
  isUpdatingCart: false,
  // Mutations
  nextStep: () =>
    set((state) => {
      const encounteredSteps = state.encounteredSteps.slice();
      const nextStep = state.step + 1;
      if (!encounteredSteps.includes(nextStep)) encounteredSteps.push(nextStep);
      return { step: nextStep, encounteredSteps };
    }),
  navigateStep: (step) =>
    set((state) => {
      const encounteredSteps = state.encounteredSteps.slice();
      if (!encounteredSteps.includes(step)) encounteredSteps.push(step);
      return { step, encounteredSteps };
    }),
  setUpdatingCart: (isUpdatingCart) => set({ isUpdatingCart }),
  // Checkout mutations
  // 1. Submitting Delivery Details
  submitDeliveryDetails: (v) => {
    const address = {
      businessName: v.businessName,
      streetAddress: v.streetAddress,
      suburb: v.suburb,
      state: v.state,
      postcode: v.postcode,
      country: v.country,
      deliveryNotes: v.deliveryNotes,
    };
    return set({
      contactInfo: {
        fullName: v.fullName,
        email: v.email,
        mobileNo: v.mobileNo,
        phoneNo: v.phoneNo,
        companyName: v.companyName,
      },
      deliveryAddress: address,
      billingAddress: address,
    });
  },
  // 2. Submitting Billing Details
  submitBillingDetails: (v, isSameAsDelivery) =>
    set((state) => ({
      isBillingSameAsDelivery: isSameAsDelivery,
      // If we pass true, then Delivery Address will be copied into Billing Address.
      // Otherwise (false), set billing address to itself (which essentially does nothing).
      billingAddress: isSameAsDelivery ? { ...state.deliveryAddress } : { ...v },
    })),
  // 3. Submitting Shipping Method
  submitShippingMethod: async (v) => {
    // Perform any asynchronous operations to fetch data
    try {
      // const data = await fetchData();
      set({ 
        ...v, 
      });
    } catch (error) {
      // Handle any errors that occur during data fetching
      console.error('Error fetching data from Oneflo:', error);
      // Optionally, you can set an error state or display an error message
    }
  },
  // 4. Submitting Payment Method
  submitPaymentMethod: (v) => set({ ...v }),
}));

export function useCheckoutStep() {
  return useCheckoutStore((store) => ({
    currentStep: store.step,
    nextStep: store.nextStep,
    navigateStep: store.navigateStep,
    isStepEncountered: (s: number) => store.encounteredSteps.includes(s),
  }));
}

export function useCheckoutState() {
  return useCheckoutStore((store) => ({
    contactInfo: store.contactInfo,
    deliveryAddress: store.deliveryAddress,
    billingAddress: store.billingAddress,
    isBillingSameAsDelivery: store.isBillingSameAsDelivery,
    freightSettingId: store.freightSettingId,
    isUrgentOrder: store.isUrgentOrder,
    chargeProcessingFee: store.chargeProcessingFee,
    deliveryNotes: store.deliveryNotes,
    paymentMethod: store.paymentMethod,
  }));
}
