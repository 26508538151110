import ContentLoader from 'react-content-loader';

export const CustomThemeBuilderLoader = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={120}
    viewBox="0 0 400 120"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="10" y="7" rx="2" ry="2" width="145" height="21" />
    <rect x="296" y="40" rx="0" ry="0" width="60" height="59" />
    <rect x="10" y="39" rx="0" ry="0" width="275" height="60" />
  </ContentLoader>
);
