import {
  ProductPartsFragment,
  ShoppingCartItemInput,
  ShoppingCartItemInputType,
  // useGetShoppingCartQuery,
  useUpdateShoppingCartMutation,
} from '@/graphql';
import { createContext, PropsWithChildren, useContext, useRef, useState } from 'react';
import { useCurrentUser } from '../../common/auth/hooks/use-current-user';
import { useChangeCustomerEffect } from '../customer/hooks/use-change-customer';

type ShoppingCartItem = ShoppingCartItemInput & {
  product?: ProductPartsFragment;
};

export type ShoppingCartContext = {
  cartItems: ShoppingCartItem[];
  selectedCartItems: ShoppingCartItem[];
  cartQuantity: number;
  isAllSelected: boolean;
  isCartUpdating: boolean;
  isCartLoading: boolean;
  incrementItem: (
    productId: number,
    productCode: string,
    amount: number,
    eTemplateId?: number
  ) => void;
  decrementItem: (id: number) => void;
  setItemQuantity: (id: number, quantity: number) => void;
  getItemQuantity: (id: number) => number;
  removeItems: (ids: number[]) => void;
  removeAllItems: () => void;
  setItemsSelected: (ids: number[], isSelected: boolean) => void;
  setAllItemsSelected: (isSelected: boolean) => void;
};

const ShoppingCartContext = createContext({} as ShoppingCartContext);

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}

export function withShoppingCart(component: React.ReactNode) {
  return <ShoppingCartProvider>{component}</ShoppingCartProvider>;
}

export function ShoppingCartProvider({ children }: PropsWithChildren<{}>) {
  const { shoppingCart, isUserLoading } = useCurrentUser();
  const [cartItems, setCartItems] = useState<ShoppingCartContext['cartItems']>([]);

  // Update our local cartItems after shoppingCart loads, without useEffect
  // Source: https://beta.reactjs.org/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  const [prevShoppingCart, setPrevShoppingCart] = useState(shoppingCart);
  if (shoppingCart !== prevShoppingCart && shoppingCart) {
    setPrevShoppingCart(shoppingCart);
    setCartItems(
      shoppingCart.map((i) => ({
        productId: i.productId,
        productCode: i.productCode,
        quantity: i.quantity,
        product: i.product ?? undefined,
        isSelected: i.isSelected,
        eTemplateId: i.eTemplateId,
      }))
    );
  }

  const timeout = useRef<NodeJS.Timeout | null>(null);
  // const query = useGetShoppingCartQuery();
  const [updateShoppingCart, { loading: isCartUpdating }] = useUpdateShoppingCartMutation();

  // Computed state
  const selectedCartItems = cartItems.filter((i) => i.isSelected);
  const cartQuantity = cartItems.reduce((quantity, i) => i.quantity + quantity, 0);
  const isAllSelected = cartItems.length > 0 && cartItems.every((i) => i.isSelected);

  useChangeCustomerEffect(() => setCartItems([]));

  function getItemQuantity(id: number) {
    return cartItems.find((i) => i.productId === id)?.quantity ?? 0;
  }

  function updateShoppingCartDebounced(updates: ShoppingCartItemInputType[]) {
    if (timeout.current !== null) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      updateShoppingCart({
        variables: {
          // Avoid passing our internal product property to ShoppingCartItemInput
          cartItems: updates.map((i) => ({
            productId: i.productId,
            productCode: i.productCode,
            quantity: i.quantity,
            isSelected: i.isSelected,
            eTemplateId: i.eTemplateId,
          })),
        },
        onCompleted(data) {
          if (!data.updateShoppingCart?.cartItems) return;
          setCartItems(
            data.updateShoppingCart.cartItems.map((i) => ({
              productId: i.productId,
              productCode: i.productCode,
              quantity: i.quantity,
              product: i.product ?? undefined,
              isSelected: i.isSelected,
              eTemplateId: i.eTemplateId,
            }))
          );
        },
      });
    }, 300);
  }

  // This function calls both setCartItems and _updateShoppingCart
  function update(updated: ShoppingCartItemInputType[]) {
    setCartItems(updated);
    updateShoppingCartDebounced(updated);
  }

  function incrementItem(
    productId: number,
    productCode: string,
    amount: number,
    eTemplateId?: number
  ) {
    let updatedCartItems: ShoppingCartItemInputType[] = [];
    // Only add item if it doesn't already exist in cartItems
    if (cartItems.every((i) => i.productId !== productId)) {
      if (!productId) throw new Error('[ShoppingCart::incrementItem] Product ID is required');
      updatedCartItems = [
        ...cartItems,
        {
          productId,
          productCode,
          quantity: amount,
          isSelected: true,
          // If the product payload contains eTemplateId, pass it here.
          // Otherwise this will just be undefined (which is fine)
          eTemplateId,
        },
      ];
    }
    // Otherwise, we should increment existing item
    else {
      updatedCartItems = cartItems.map((c) =>
        c.productId === productId ? { ...c, quantity: amount } : c
      );
    }
    update(updatedCartItems);
  }

  function decrementItem(productId: number) {
    update(
      cartItems.map((c) => {
        if (productId !== c.productId) return c;
        let quantity = c.quantity - 1;
        quantity = Math.max(quantity, 0);
        if (c.product?.minOrderQuantity)
        quantity = Math.max(quantity, c.product.minOrderQuantity, 0);
        return { ...c, quantity };
      })
    );
  }

  function setItemQuantity(productId: number, quantity: number) {
    update(
      cartItems.map((c) => {
        if (productId !== c.productId) return c;
        if (c.product?.minOrderQuantity)
        quantity = Math.max(quantity, c.product.minOrderQuantity, 0);
        return { ...c, quantity };
      })
    );
  }

  function removeItems(productIds: number[]) {
    if(productIds.length == cartItems.length) {
      let emptyArray = new Array<ShoppingCartItem>();
      update(emptyArray);
    }
    else {
      update(cartItems.filter((c) => c.productId && !productIds.includes(c.productId)));
    }
  }

  function removeAllItems() {
    update([]);
  }

  function setItemsSelected(ids: number[], isSelected: boolean) {
    update(
      cartItems
        .filter((i) => i.productId)
        .map((i) => ({
          ...i,
          isSelected: ids.includes(i.productId!) ? isSelected : i.isSelected,
        }))
    );
  }

  function setAllItemsSelected(isSelected: boolean) {
    setItemsSelected(
      cartItems.filter((i) => i.productId).map((i) => i.productId!),
      isSelected
    );
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        // State
        cartItems,
        selectedCartItems,
        cartQuantity,
        isAllSelected,
        isCartUpdating,
        isCartLoading: isUserLoading,
        // Functions
        incrementItem,
        decrementItem,
        setItemQuantity,
        getItemQuantity,
        removeItems,
        removeAllItems,
        setItemsSelected,
        setAllItemsSelected,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}
