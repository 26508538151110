import { Box, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, forwardRef } from 'react';

interface Props {
  label?: string;
  value?: string;
  // Needed for passing synthetic event to react-hook-form
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  // Instead, we can optionally listen to change ourselves.
  onChangeText?: (v: string) => void;
  onBlur?: () => void;
  name?: string;
  multiline?: boolean;
  rows?: number;
  // If this flag is enabled then we will only show the value as a label
  onlyValue?: boolean;
  type?: string;
  // If this flag is enabled then we will always show the label even though
  // onlyValue is true
  showLabel?: boolean;
  // If this flag is enabled then the label will contain indicators
  // (red asterisk) that it is required
  required?: boolean;
  errorMessage?: React.ReactNode;
  // Source: https://stackoverflow.com/a/58201122
  [x: string]: any;
}

// "forwardRef" is needed here so that parent components can access our <input>'s ref
export const TsuField = forwardRef<any, Props>((props: Props, ref) => {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const newValue = event.target.value;
    props.onChange?.(event);
    props.onChangeText?.(newValue);
  }

  return (
    <Stack spacing={1} {...props.sx}>
      <Box hidden={props.onlyValue && !props.showLabel}>
        <Typography sx={{ color: '#3D5069' }}>
          {props.label || ''}
          {props.required ? <span style={{ color: 'red' }}> * </span> : null}
        </Typography>
      </Box>
      <Box hidden={props.onlyValue} sx={{ width: '100%' }}>
        <TextField
          inputProps={{
            'data-testid': props.name,
          }}
          inputRef={ref}
          name={props.name}
          variant="outlined"
          value={props.value}
          onChange={handleChange}
          onBlur={props.onBlur}
          type={props.type ?? 'text'}
          error={Boolean(props.errorMessage)}
          helperText={<span data-validation-for={props.name}>{props.errorMessage}</span>}
          sx={{
            borderColor: '#E1E1E1',
            '& .MuiInputBase-formControl': {
              backgroundColor: 'background.paper',
              borderRadius: 2.5,
            },
            width: '100%',
          }}
          multiline={props.multiline}
          rows={props.rows}
        />
      </Box>
      <Box hidden={!props.onlyValue}>
        <Typography>{props.value}</Typography>
      </Box>
    </Stack>
  );
});
