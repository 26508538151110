import { TsuField } from '@/common/components';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

export interface Args {
  label: string | React.ReactNode;
  color: string;
  onChange?: (color: string) => void;
}

function ColorField({ label, color, onChange }: Args) {
  const theme = useTheme();
  const [showColorPicker, setShowColorPicker] = useState(false);

  const [prevColor, setPrevColor] = useState(color);
  // Update the color picker render every time the color changes (esp. when sliding the values)
  // However, the final value will only be propagated (onChange) when you mouse up
  const [currentColor, setCurrentColor] = useState(color);
  if (color !== prevColor) {
    setCurrentColor(color);
    setPrevColor(color);
  }

  // We need refs to the color box (wrapper ) and color picker component
  // for the click event listeners
  const wrapperRef = useRef<Element>(null);
  const colorPickerRef = useRef<Element>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      // Cast to Node because .contains() will be used twice
      const targetNode = event.target as Node | null;
      // Toggle color picker when clicking on the color box (a.k.a "wrapper")
      if (wrapperRef.current === event.target) {
        setShowColorPicker(!showColorPicker);
      }
      // Hide color picker when clicking on anything besides the wrapper or the color picker
      else if (
        wrapperRef.current &&
        colorPickerRef.current &&
        !wrapperRef.current.contains(targetNode) &&
        !colorPickerRef.current.contains(targetNode)
      ) {
        setShowColorPicker(false);
      }
    };
    // We need to register the listener on document so that we can listen to outside clicks
    document.addEventListener('mousedown', handleClick);
    // Clean up event listener
    return () => document.removeEventListener('mousedown', handleClick);
  }, [showColorPicker, wrapperRef, colorPickerRef]);

  const handleChangeComplete = (color: ColorResult) => {
    setCurrentColor(color.hex);
    onChange?.(color.hex);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box
        sx={{
          backgroundColor: color,
          border: `solid 1px ${theme.palette.text.primary}`,
          borderRadius: 2.5,
        }}
        width={56}
        height={56}
        position="relative"
        ref={wrapperRef}
      >
        {showColorPicker && (
          <Box position="absolute" left="calc(100% + 5px)" zIndex={2} ref={colorPickerRef}>
            <SketchPicker
              color={currentColor}
              onChange={(c) => setCurrentColor(c.hex)}
              onChangeComplete={handleChangeComplete}
            />
          </Box>
        )}
      </Box>
      <Stack>
        {label}
        <Typography fontSize="0.85em">{color ? color.toUpperCase() : 'None'}</Typography>
      </Stack>
    </Stack>
  );
}

export default ColorField;
