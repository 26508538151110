import { FeatureRoute } from '@/common/interfaces/feature-route';
import { ThemeBuilder } from './screens/ThemeBuilder';

export const themeBuilderRoutes: FeatureRoute[] = [
  {
    path: '/themeBuilder',
    component: ThemeBuilder,
    options: {
      useCustomerTheme: true,
    },
  },
];
