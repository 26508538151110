import { TsuField, TsuLoading, TsuSelect } from '@/common/components';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useProductStatusesQuery } from '../../../graphql';
import { useEditProduct } from '../hooks/useEditProduct';
import { useProductDetails } from './ProductDetailsForm.hook';

interface Props {
  productId: number | null;
  onCancel?: () => void;
}

export function ProductDetailsForm({ productId, onCancel }: Props) {
  const productDetails = useProductDetails({ productId });
  const { state: pd, dispatch, quantityTypeChoices } = productDetails;
  const editProduct = useEditProduct({ productId });
  const { data: productStatusesQuery, loading: isProductStatusesLoading } =
    useProductStatusesQuery();
  const productStatusChoices = productStatusesQuery?.productStatuses ?? [];

  const checkboxes = [
    {
      checked: pd.isHidden,
      title: 'Hidden',
      key: 'isHidden',
    },
    {
      checked: pd.isWBSOwnedStock,
      title: 'WBS Owned Stock',
      key: 'isWBSOwnedStock',
    },
    {
      checked: pd.isScreenPrintItem,
      title: 'Screen Print Item',
      key: 'isScreenPrintItem',
    },
    {
      checked: pd.isOverPrintItem,
      title: 'OverPrint Item',
      key: 'isOverPrintItem',
    },
    {
      checked: pd.isWBSJobItem,
      title: 'WBS Job Item',
      key: 'isWBSJobItem',
    },
  ];

  // We need a function here to display confimation dialog before proceeding
  async function handleDeleteClick() {
    if (pd.isRemoved) {
      await editProduct.restoreProduct();
      onCancel?.();
    } else if (window.confirm('Are you sure you want to delete this product?')) {
      await editProduct.deleteProduct();
      onCancel?.();
    }
  }

  function handleUpdateClick() {
    editProduct.updateProduct(pd);
  }

  return (
    <Stack spacing={2}>
      <TsuLoading loading={productDetails.isLoading}>
        <Card>
          <CardHeader title="Basic Details" />
          <CardContent>
            <Grid container spacing={4} sx={{ width: '100%' }}>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <TsuField
                    label="Product Name"
                    value={pd.productName}
                    onChange={(v) => dispatch('productName', v)}
                  />
                  <TsuField
                    label="Product Description"
                    value={pd.productDescription}
                    onChange={(v) => dispatch('productDescription', v)}
                    multiline
                    rows={5}
                  />
                  <TsuSelect
                    value={pd.statusId?.toString() ?? ''}
                    items={productStatusChoices}
                    loading={isProductStatusesLoading}
                    onChange={(s) => dispatch('statusId', s)}
                    itemText="name"
                    itemValue="statusId"
                    label="- Select -"
                    title="Product Status"
                  />
                  <TsuSelect
                    value={pd.itemGroupId?.toString() ?? ''}
                    items={productDetails.itemGroupChoices}
                    loading={productDetails.isItemGroupsLoading}
                    onChange={(s) => dispatch('itemGroupId', s)}
                    itemText="name"
                    itemValue="productItemGroupId"
                    label="- Select -"
                    title="Item Group"
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <TsuField
                    label="Product ID"
                    value={pd.productCode}
                    onChange={(v) => dispatch('productCode', v)}
                  />
                  <TsuField
                    label="External Product ID"
                    value={pd.externalProductId ?? ''}
                    onChange={(v) => dispatch('externalProductId', v)}
                    multiline
                  />
                  <Stack>
                    {checkboxes.map((c, i) => (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            checked={c.checked}
                            onChange={(e) => dispatch(c.key, e.target.checked)}
                          />
                        }
                        label={c.title}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <TsuField
                    label="CRM Supplier"
                    value={pd.crmSupplier}
                    onChange={(v) => dispatch('crmSupplier', v)}
                  />
                  <TsuField
                    label="Supplier Lead Time (days)"
                    value={pd.supplierLeadTime?.toString() ?? ''}
                    onChange={(v) => dispatch('supplierLeadTime', parseInt(v))}
                  />
                  <TsuField
                    label="CRM Specification"
                    value={pd.crmSpecification}
                    onChange={(v) => dispatch('crmSpecification', v)}
                    multiline
                    rows={5}
                  />
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Replenishment Details" />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Typography>Stock on Hand: 0</Typography>
                <TsuField
                  label="Re-Order Level"
                  value={pd.reOrderLevel?.toString() ?? ''}
                  onChange={(v) => dispatch('reOrderLevel', parseInt(v))}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>Stock Committed: 0</Typography>
                <TsuField
                  label="Maximum Stock Level"
                  value={pd.maxStockLevel.toString()}
                  onChange={(v) => dispatch('maxStockLevel', parseInt(v))}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pd.enableSerialNumberTracking}
                      onChange={(e) => dispatch('enableSerialNumberTracking', e.target.checked)}
                    />
                  }
                  label="Enable Serial Number Tracking"
                />
                <TsuField
                  label="Serial Number Book Size"
                  value={pd.serialNumberBookSize?.toString() ?? ''}
                  onChange={(v) => dispatch('serialNumberBookSize', parseInt(v))}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Order Details" />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Stack spacing={2}>
                  <TsuField
                    label="GST (e.g. for 10% enter 0.10)"
                    value={pd.gst.toString()}
                    onChange={(v) => dispatch('gst', parseFloat(v))}
                  />
                  <TsuField
                    label="Process Days"
                    value={pd.processDays.toString()}
                    onChange={(v) => dispatch('processDays', parseInt(v))}
                  />
                  <TsuField
                    label="Minimum Quantity Per Order"
                    value={pd.minQuantityPerOrder?.toString() ?? ''}
                    onChange={(v) => dispatch('minQuantityPerOrder', parseInt(v))}
                  />
                  <Box>
                    Maximum Order Quantity Limit is{' '}
                    <input
                      value={pd.maxOrderQuantityLimit?.toString() ?? ''}
                      onChange={(e) => dispatch('maxOrderQuantityLimit', parseInt(e.target.value))}
                    />
                    &nbsp; per &nbsp;
                    <input
                      value={pd.maxOrderQuantityLimitDays?.toString() ?? ''}
                      onChange={(e) =>
                        dispatch('maxOrderQuantityLimitDays', parseInt(e.target.value))
                      }
                    />
                    &nbsp; day(s)
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pd.isSpecialDelivery}
                        onChange={(e) => dispatch('isSpecialDelivery', e.target.checked)}
                      />
                    }
                    label="Is this product a special delivery product?"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={2}>
                  <TsuSelect
                    value={pd.quantityTypeId?.toString() ?? ''}
                    items={quantityTypeChoices}
                    onChange={(v) => dispatch('quantityTypeId', v)}
                    itemText="name"
                    itemValue="quantityTypeId"
                    label="- Select -"
                    title="Quantity Type"
                  />
                  <TsuField
                    label="Maximum Quantity Per Order"
                    value={pd.maxQuantityPerOrder?.toString() ?? ''}
                    onChange={(v) => dispatch('maxQuantityPerOrder', parseInt(v))}
                  />
                  <TsuField
                    label="Free freight when the quantity ordered is at least"
                    value={pd.minQuantityPerOrder?.toString() ?? ''}
                    onChange={(v) => dispatch('minQuantityPerOrder', parseInt(v))}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pd.forceRGA}
                        onChange={(e) => dispatch('forceRGA', e.target.checked)}
                      />
                    }
                    label="Force RGA process?"
                  />
                  <TsuField
                    label="Sort Order"
                    value={pd.sortOrder?.toString() ?? ''}
                    onChange={(v) => dispatch('sortOrder', parseInt(v))}
                  />
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <TsuLoading
          loading={editProduct.isUpdating || editProduct.isDeleting || editProduct.isRestoring}
        >
          <Stack direction="row" justifyContent="end" spacing={1}>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="outlined" onClick={handleDeleteClick}>
              {pd.isRemoved ? 'Restore' : 'Delete'}
            </Button>
            <Button variant="contained" onClick={handleUpdateClick}>
              Update
            </Button>
          </Stack>
        </TsuLoading>
      </TsuLoading>
    </Stack>
  );
}
