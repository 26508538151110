import { FeatureRoute } from '@/common/interfaces/feature-route';
import { WaivenetAdmin } from './screens/WaivenetAdmin';

export const waivenetAdminRoutes: FeatureRoute[] = [
  {
    path: '/waivenetAdmin',
    component: WaivenetAdmin,
    options: {
      useCustomerTheme: true,
    },
  },
];