import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useContext, useReducer, useState } from 'react';
import { SelectedBusinessLevelContext } from '../context/selected-business-level.context';
import { UpdateBusinessLevelInput } from '../interfaces/update-business-level.interface';;

function reducer(state: UpdateBusinessLevelInput, action: { key: string; value: any }): UpdateBusinessLevelInput {
  if (action.key === 'all') return { ...state, ...action.value };
  return {
    ...state,
    [action.key]: action.value,
  };
}

export function useEditBusinessLevelForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { selectedBusinessLevel } = useContext(SelectedBusinessLevelContext);
  const [state, dispatch] = useReducer(reducer, {
    name: selectedBusinessLevel?.name,
    active: selectedBusinessLevel?.active,
    customerBlid: selectedBusinessLevel?.customerBlid,
    orderNotifyEmail: selectedBusinessLevel?.orderNotifyEmail,
    accpacAccount: selectedBusinessLevel?.accpacAccount,
    webAddress: selectedBusinessLevel?.webAddress,
    abn: selectedBusinessLevel?.abn,
    primaryApproverId: selectedBusinessLevel?.primaryApproverId,
    secondaryApproverId: selectedBusinessLevel?.secondaryApproverId
  } as UpdateBusinessLevelInput);

  async function handleSave() {
    var business_level_input = state;
    if (!selectedBusinessLevel) return;
    // await roleApi.updateRole({
    //   roleId: selectedRole.roleId,
    //   role: role_input,
    //   selectedUserActionIds: selectedUserActions.map((ua) => ua.actionId),
    //   selectedOrderTypeIds: selectedOrderTypes.map((ot) => ot.orderTypeId),
    //   selectedFreightSettingIds: selectedFreightSettings.map((fs) => fs.freightSettingId),
    // });
    window.location.reload();
    enqueueSnackbar('Updated business level', { variant: 'success' });
  }

  return {
    // State
    formState: state,
    dispatch: (key: string, value: string) => dispatch({ key, value }),
    handleSave,
    isSaving: false,
  };
}
