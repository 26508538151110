import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdvancedFilterEntry, IBasicFilter } from '../common/interfaces';
import { initialBasicFilter } from '../features/report/constants';

// =======================================
// Interfaces
// =======================================

export interface ReportState {
  // Basic Filter is just a simple object because the filters
  // involved are fixed (pre-determined)
  basicFilter: IBasicFilter;
  // Advanced Filters is an array because it contains one or more
  // filters which contain custom key & value pairs
  advancedFilters: AdvancedFilterEntry[];
}

export interface SetBasicFilterPayload {
  filter: keyof IBasicFilter;
  // The value passed to the payload should be any type that belongs
  // to IBasicFilter (Source: https://stackoverflow.com/a/55852096)
  value: IBasicFilter[keyof IBasicFilter];
}

// =======================================
// Store Report Slice logic
// =======================================

const initialState: ReportState = {
  basicFilter: initialBasicFilter,
  advancedFilters: [],
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    // This action allows the caller to set a Basic Filter by
    // providing a key & value in the payload
    // setBasicFilter: (state, action: PayloadAction<SetBasicFilterPayload>) => {
    //   state.basicFilter[action.payload.filter] = action.payload.value;
    // },
    // Alternative: let caller set the entire basic filter because there's an apply button
    setBasicFilter: (state, action: PayloadAction<IBasicFilter>) => {
      Object.assign(state.basicFilter, action.payload);
    },
    // ==========================================================
    // These actions won't be used yet because
    // the advanced filter component can just set the entire
    // advanced filters array via setAdvancedFilter below.
    // But we will keep this for future uses.
    // ===========================================================
    // This action allows the caller to add an Advanced Filter Entry
    addAdvancedFilter: (state, action: PayloadAction<{ filter: AdvancedFilterEntry }>) => {
      state.advancedFilters.push(action.payload.filter);
    },
    // This action allows the caller to remove an Advanced Filter Entry
    removeAdvancedFilter: (state, action: PayloadAction<{ index: number }>) => {
      state.advancedFilters.splice(action.payload.index, 1);
    },
    // This will be used by AdvancedFilter component to quickly set the
    // entire Advanced Filters array (because there's an apply button)
    setAdvancedFilters: (state, action: PayloadAction<AdvancedFilterEntry[]>) => {
      state.advancedFilters = action.payload;
    },
  },
});

// Intentionally exclude add/remove advanced filters because we're not gonna use it yet
export const { setBasicFilter, setAdvancedFilters, removeAdvancedFilter } = reportSlice.actions;

export default reportSlice.reducer;
