import { FeatureRoute } from '@/common/interfaces/feature-route';
import { RgaListWaivenet } from './screens/RgaList';

export const rgaListRoutes: FeatureRoute[] = [
  {
    path: '/rgalist',
    component: RgaListWaivenet,
    options: {
      useCustomerTheme: true,
    },
  },
];