import { TsuField } from '@/common/components';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';

export interface IEmailContents {
  subject: string;
  body: string;
}

interface EmailContentsProps {
  onChange?: (emailContents: IEmailContents) => void;
}

function EmailContents({ onChange }: EmailContentsProps) {
  const [emailContents, setEmailContents] = useState<IEmailContents>(() => ({
    subject: 'Auto Generated Subject Name for Report',
    body: 'Lorem Ipsum dolor sit amet consectetur adisciping elit.',
  }));

  useEffect(() => onChange?.(emailContents), [onChange, emailContents]);

  return (
    <Stack spacing={2}>
      <TsuField
        value={emailContents.subject}
        onChange={(v) => setEmailContents({ ...emailContents, subject: v })}
      />
      <TsuField
        value={emailContents.body}
        onChange={(v) => setEmailContents({ ...emailContents, body: v })}
        multiline
        rows={5}
      />
    </Stack>
  );
}

export default EmailContents;
