import { FeatureRoute } from '@/common/interfaces/feature-route';
import CheckYourEmail from './screens/CheckYourEmail';
import ForgotPassword from './screens/ForgotPassword';
import MyAccount from './screens/MyAccount';
import Registration from './screens/Registration';
import SetNewPassword from './screens/SetNewPassword';
import Signin from './screens/Signin';
import Signup from './screens/Signup';
import VACCSignin from '../customization/VACC/VACCSignin';
import VACCForgotPassword from '../customization/VACC/VACCForgotPassword';
import VACCSignup from '../customization/VACC/VACCSignup';

export const authRoutes: FeatureRoute[] = [
  {
    path: '/signup',
    component: Signup,
    options: { isPublic: true },
  },
  {
    path: '/customization/VACC/signup',
    component: VACCSignup,
    options: { isPublic: true },
  },
  {
    path: '/signin',
    component: Signin,
    options: { isPublic: true },
  },
  {
    path: '/customization/VACC/signin',
    component: VACCSignin,
    options: { isPublic: true },
  },
  {
    path: '/checkYourEmail',
    component: CheckYourEmail,
    options: { isPublic: true },
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword,
    options: { isPublic: true },
  },
  {
    path: '/customization/VACC/forgotPassword',
    component: VACCForgotPassword,
    options: { isPublic: true },
  },
  {
    path: '/registration',
    component: Registration,
    options: { isPublic: true },
  },
  {
    path: '/setNewPassword',
    component: SetNewPassword,
    options: { isPublic: true },
  },
  {
    path: '/accountSettings',
    component: MyAccount,
    options: {
      useCustomerTheme: true,
    },
  },
];
