import { TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';


export const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    background: theme.palette.background.paper,
    color: 'black',
    borderStyle: 'none',
    borderRadius: 10,
    borderWidth: '2px',
    borderColor: theme.palette.primary.dark,
    '&:hover fieldset': {
      borderColor: theme.palette.primary.dark,
      color: theme.palette.primary.main
    },
    '&.Mui-focused fieldset': {
      // background: 'white',
      borderColor: theme.palette.primary.dark,
    },
    paddingTop: 2,
    paddingBottom: 2,
  },
  '& .MuiInputAdornment-root': {
    padding: theme.spacing(1),
    borderColor: theme.palette.primary.dark,
  },
  color: theme.palette.primary.main
}));

export const VACCStyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    background: theme.palette.background.paper,
    color: 'black',
    borderStyle: 'none',
    borderRadius: 10,
    borderWidth: '2px',
    borderColor: '#ccc',
    '&:hover fieldset': {
      borderColor: '#69B0E8',
      color: '#f5821f'
    },
    '&.Mui-focused fieldset': {
      // background: 'white',
      borderColor: '#69B0E8',
    },
    paddingTop: 2,
    paddingBottom: 2,
  },
  '& .MuiInputAdornment-root': {
    padding: theme.spacing(1),
    borderColor: '#f5821f',
  },
  color: '#f5821f',
}));
