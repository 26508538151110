// import ForgotPasswordLogo from '@/assets/images/ForgotPasswordLogo.png';
import { Copyright, LoginHeader, StyledTextField } from '@/common/components';
import { useSendResetPasswordEmailMutation } from '@/graphql';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  FormGroup,
  FormHelperText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useCustomerTheme } from '@/common/theme';

export default function NormalForgotPassword() {
  const customerTheme = useCustomerTheme();
  const theme = useTheme();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: '',
    emailSending: false,
    emailError: false,
    error: '',
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValues({ ...values, [event.target.name]: value });
  };
  const [sendEmail] = useSendResetPasswordEmailMutation();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    let error = '';
    let emailError = false;

    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;

    if (reg.test(values.email) === true) {
      const email = values.email;
      setValues({ ...values, emailSending: true });
      await sendEmail({
        variables: {
          email,
        },
      });
      navigate('/checkYourEmail');
    } else {
      emailError = true;
      error = 'Invalid Email.';
    }

    setValues({
      ...values,
      error: error,
      emailError: emailError,
      emailSending: false,
    });
  };
  return (
    <Container maxWidth={false} sx={{ minHeight: '100vh' }}>
      <Stack direction="column" alignItems="center" justifyContent="space-between" spacing={3}>
        <LoginHeader />
        <Card elevation={20} sx={{ padding: theme.spacing(3), minWidth: '300px' }}>
          <Stack direction="column" alignItems="center" justifyContent="space-between" spacing={1}>
            <Box m={10} maxWidth="60%" margin="10px">
              <LockOpenTwoToneIcon sx ={{height: '90%', width: '90%', color: customerTheme?.primary.main ?? 'text.primary'}}/>
            </Box>
            <CardHeader
              sx={{ marginTop: theme.spacing(1) }}
              title="Forgot your Password"
              titleTypographyProps={{ variant: 'h2', align: 'left' }}
            />
            <CardContent sx={{ paddingTop: theme.spacing(0) }}>
              <Box textAlign="left" component="form" autoComplete="off" onSubmit={handleSubmit}>
                <Box display={!values.emailSending ? 'none' : 'flex'} justifyContent="center">
                  <CircularProgress />
                </Box>
                <Box hidden={values.emailSending}>
                  <FormGroup>
                    <Typography variant="caption" fontWeight="normal" mb={1}>
                      We'll be sending a reset password link to your email.
                    </Typography>
                    <StyledTextField
                      required
                      autoFocus
                      name="email"
                      type="text"
                      error={values.emailError}
                      value={values.email}
                      onChange={handleChange}
                      placeholder="Email"
                      variant="outlined"
                    />
                    <FormHelperText error sx={{ mx: 0, mt: theme.spacing(1) }}>
                      {values.error}
                    </FormHelperText>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      size="large"
                      sx={{ mt: theme.spacing(4.5), textTransform: 'none' }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        Send Password Reset Email
                      </Typography>
                    </Button>
                  </FormGroup>
                </Box>
              </Box>
            </CardContent>
          </Stack>
        </Card>
        <Copyright />
      </Stack>
    </Container>
  );
}
