import { ApolloError } from '@apollo/client';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { TsuLoading } from './TsuLoading';

interface TsuSelectProps {
  value: string;
  items: any[];
  itemValue: string;
  itemText: string;
  label?: string;
  title?: string;
  loading?: boolean;
  error?: ApolloError;
  dense?: boolean;
  disabled?: boolean;
  onChange?: (v: any, e: SelectChangeEvent<string>) => void;
  backgroundColor?: 'white' | 'blue';
  renderItem?: (item: any) => JSX.Element;
}

interface TsuSelectForProdVariantsProps {
  value: string;
  items: any[];
  itemValue: string;
  itemText: string;
  itemStock: string;
  label?: string;
  title?: string;
  loading?: boolean;
  error?: ApolloError;
  dense?: boolean;
  disabled?: boolean;
  onChange?: (v: any, e: SelectChangeEvent<string>) => void;
  backgroundColor?: 'white' | 'blue';
  renderItem?: (item: any) => JSX.Element;
}

export function TsuSelect({
  value,
  items,
  itemValue,
  itemText,
  label,
  title,
  loading,
  error,
  dense,
  disabled,
  onChange,
  backgroundColor = 'blue',
  renderItem,
}: TsuSelectProps) {
  const handleChange = (e: SelectChangeEvent<string>) => {
    const newValue = e.target.value;
    onChange?.(newValue, e);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TsuLoading loading={loading} error={error}>
        {title && (
          <Box mb={1}>
            <Typography>{title}</Typography>
          </Box>
        )}
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            value={value}
            onChange={handleChange}
            readOnly={disabled}
            sx={{
              backgroundColor:
                backgroundColor === 'white' ? 'white' : '#F5FAFF',
              borderRadius: 2,
              '& .MuiSelect-select': Boolean(dense)
                ? {
                    py: 1,
                  }
                : undefined,
            }}
          >
            {items.map((item, i) => (
              <MenuItem
                value={item[itemValue]}
                onClick={(e) => e.stopPropagation()}
                key={i}
              >
                {renderItem ? renderItem(item) : item[itemText].toString()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TsuLoading>
    </Box>
  );
}

export function TsuSelectForProdVariants({
  value,
  items,
  itemValue,
  itemText,
  itemStock,
  label,
  title,
  loading,
  error,
  dense,
  disabled,
  onChange,
  backgroundColor = 'blue',
  renderItem,
}: TsuSelectForProdVariantsProps) {
  const handleChange = (e: SelectChangeEvent<string>) => {
    const newValue = e.target.value;
    onChange?.(newValue, e);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TsuLoading loading={loading} error={error}>
        {title && (
          <Box mb={1}>
            <Typography>{title}</Typography>
          </Box>
        )}
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            value={value}
            onChange={handleChange}
            readOnly={disabled}
            sx={{
              backgroundColor:
                backgroundColor === 'white' ? 'white' : '#F5FAFF',
              borderRadius: 2,
              '& .MuiSelect-select': Boolean(dense)
                ? {
                    py: 1,
                  }
                : undefined,
            }}
          >
            {items.map((item, i) => (
              <MenuItem
                value={item[itemValue]}
                onClick={(e) => e.stopPropagation()}
                key={i}
              >
                {renderItem ? renderItem(item) : `${item[itemText]} - ${item[itemStock]} available`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TsuLoading>
    </Box>
  );
}