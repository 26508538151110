import { useCurrentUser } from '@/common/auth/hooks/use-current-user';
import { useCustomerTheme } from '@/common/theme';
import { ChangeCustomerMenu } from '@/features/customer/components/ChangeCustomerMenu';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import {
  AppBar,
  Box,
  Button,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { TsuLoading } from '../TsuLoading';
import { NavMenuItem } from './NavMenuItem';
import { NavMenuMobile } from './NavMenuMobile';
import { useAccountMenuItems } from './useAccountMenuItems';
import { useNavMenuItems } from './useNavMenuItems';

// This custom hook handles the state management of "references to elements / anchors".
// It makes the NavMenu overall code shorter and cleaner
function useMenuAnchors() {
  // If these references to elements are "not-null", then the menu is considered "open"
  const [accountAnchorEl, setAccountAnchorEl] = useState<Element | null>(null);

  // Rename the state variables to shorter / easier to read names
  return {
    account: accountAnchorEl,
    setAccount: setAccountAnchorEl,
  };
}

export function NavMenu() {
  const theme = useTheme();
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));
  const { fullName, isUserLoading } = useCurrentUser();
  const menuAnchors = useMenuAnchors();
  const customerTheme = useCustomerTheme();

  // The computation for visibility of menu items are handled by these custom hooks
  const accountMenuItems = useAccountMenuItems();
  const navMenuItems = useNavMenuItems();

  return (
    <AppBar elevation={0} position="static">
      <Toolbar sx={{ backgroundColor: customerTheme?.navigationBar.background }}>
        <Box display={{ xs: 'block', lg: 'none' }}>
          <NavMenuMobile />
        </Box>

        <Stack
          direction="row"
          spacing={matchesXL ? 2 : 0}
          sx={{
            display: {
              xs: 'none',
              lg: 'flex',
            },
          }}
        >
          {navMenuItems.map((m, i) => (
            <NavMenuItem key={i} to={m.to}>
              {m.text}
            </NavMenuItem>
          ))}
        </Stack>
        <Box flexGrow={1} />

        <TsuLoading loading={isUserLoading} defaultLoaderProps={{ size: 25 }}>
          <Button variant="text" onClick={(e) => menuAnchors.setAccount(e.currentTarget)}>
            <Typography variant="h6" color="primary.contrastText">
              {fullName}
            </Typography>
            <ArrowDropDownCircleIcon sx={{ ml: 1, color: 'primary.contrastText' }} />
          </Button>
        </TsuLoading>

        {!isUserLoading ? <ChangeCustomerMenu /> : null}

        <Menu
          id="account-menu"
          anchorEl={menuAnchors.account}
          open={Boolean(menuAnchors.account)}
          onClose={() => menuAnchors.setAccount(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {accountMenuItems.map((i, j) => (
            <MenuItem key={j} onClick={i.onClick}>
              <Stack direction="row" spacing={1}>
                {i.icon}
                <Typography>{i.name}</Typography>
              </Stack>
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
