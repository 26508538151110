import MenuIcon from '@/assets/images/MenuIcon.png';
import { Box, Button, Link, Menu, MenuItem, useTheme } from '@mui/material';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useLocation, useNavigate } from 'react-router';
import { useNavMenuItems } from './useNavMenuItems';

// TODO: Use "usePopupState" custom hook instead of higher order component
export function NavMenuMobile() {
  const navigate = useNavigate();
  const location = useLocation();
  const navMenuItems = useNavMenuItems();

  return (
    <Box data-testid="web-component-dropdownMenu">
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <>
            <Button {...bindTrigger(popupState)} sx={{ '&:hover': { color: '#3A5069' } }}>
              <img src={MenuIcon} alt="Mobile Navigation Menu" width="20" />
            </Button>
            <Menu
              {...bindMenu(popupState)}
              sx={{
                '& .MuiButton-root': {
                  width: '208px',
                  height: '280px',
                  position: 'static',
                  paddingRight: '15px',
                },
              }}
            >
              {navMenuItems.map((m, i) => (
                <MenuItem
                  sx={{
                    '&:hover': {
                      color: '#3A5069',
                    },
                  }}
                  key={i}
                  onClick={() => navigate(m.to)}
                >
                  {m.text}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </PopupState>
    </Box>
  );
}
