import { TsuButton, TsuLoading, TsuTable, TsuTableFilter } from '@/common/components';
import { ITsuTableHeader } from '@/common/components/tsu-table/interfaces';
import { Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { IOrderShippingsForApproval } from '../../../../common/interfaces';
// import { ApproveMultiDialog } from './ApproveMultiDialog';
import { EditOrderDialog } from './EditOrderDialog';
import { useOrderShippingsforApproval } from './use-get-order-shippings-for-approval';
import useOrdersForApprovalRender from './useOrdersForApprovalRender';

// Alias the type because it's way too long while keeping the integrity of the name
type IOSFA = IOrderShippingsForApproval;

function OrdersForApprovalTable() {
  // This state variable allows this component to know which Order Shipping entry
  // the user is trying to approve / cancel / edit.
  const [selectedOS, setSelectedOS] = useState<IOSFA | null>(null);
  // This state variable allows the user to select multiple Order Shipping entries
  // so that they can perform the "Approve Selected" action on them
  const [selectedMultiOS, setSelectedMultiOS] = useState<IOSFA[]>([]);
  // We will have two state variables for two separate dialogs
  // TODO: It might be better to have just one dialog but for both types of approval (single / multi)
  const [openEditOrderDialog, setOpenEditOrderDialog] = useState(false);
  // After doing actions, we need to refresh the data and show a loading indicator
  const [refetching, setRefetching] = useState(false);

  const [opts, setOpts] = useState({ freezeHeaders: [] as ITsuTableHeader[] });
  const os = useOrderShippingsforApproval();
  const { customItemRender } = useOrdersForApprovalRender({
    selectedOrderShippings: selectedMultiOS,
    // Allow the user to select (tick the checkbox beside) an Order Shipping entry.
    // So that they may perform bulk actions like "Approve Selected".
    onCheck: useCallback(
      (o, checked) => {
        if (checked) {
          setSelectedMultiOS([...selectedMultiOS, o]);
        } else {
          setSelectedMultiOS(selectedMultiOS.filter((os) => os.orderShipId !== o.orderShipId));
        }
      },
      [setSelectedMultiOS, selectedMultiOS]
    ),
    // Allow the user to approve a single Order Shipping entry (each has an Approve button)
    // onApprove: useCallback(
    //   (os) => {
    //     setSelectedOS(os);
    //     setOpenApproveDialog(true);
    //   },
    //   [setSelectedOS, setOpenApproveDialog]
    // ),
    // Allow the user to cancel a single Order Shipping entry (each has a Cancel button)
    // onCancel: useCallback(
    //   (os) => {
    //     setSelectedOS(os);
    //     setOpenCancelDialog(true);
    //   },
    //   [setSelectedOS, setOpenCancelDialog]
    // ),
    // Allow the user to edit a single Order Shipping entry (each has an Edit button)
    onEdit: useCallback(
      (os) => {
        setSelectedOS(os);
        setOpenEditOrderDialog(true);
      },
      [setSelectedOS, setOpenEditOrderDialog]
    ),
  });

  const refreshData = useCallback(async () => {
    setRefetching(true);
    await os.refetch();
    setRefetching(false);
  }, [os]);

  // Handle action updates from the TsuTableFilter component
  const handleExtraActionChange = (action: string, headers: ITsuTableHeader[]) => {
    if (action === 'freeze-columns') setOpts({ ...opts, freezeHeaders: headers });
  };

  return (
    <Stack spacing={2} alignItems="stretch">
      <Typography variant="h2" fontWeight="bold" sx={{ alignSelf: 'center' }}>
        Orders for Approval
      </Typography>

      <TsuLoading error={os.error}>
        <TsuTableFilter
          headers={os.headers}
          onExtraActionChange={handleExtraActionChange}
          onSearchEntered={os.setSearchValue}
          actionsComponent={
            <Stack direction="row">
              <TsuButton
                // onClick={() => setOpenApproveMultiDialog(true)}
                sx={{ backgroundColor: '#42B988' }}
                disabled={selectedMultiOS.length === 0}
              >
                <Typography>Approve Selected</Typography>
              </TsuButton>
            </Stack>
          }
        />
      </TsuLoading>

      <TsuTable
        headers={os.headers}
        items={os.data?.orderShippingsForApproval?.items ?? []}
        customItemRender={customItemRender}
        loading={os.loading || refetching}
        error={os.error}
        colMinWidth={12}
        page={os.opts.page}
        itemsPerPage={os.opts.itemsPerPage}
        totalCount={os.data?.orderShippingsForApproval?.totalCount}
        onPageChange={os.setPage}
        onItemsPerPageChange={os.setItemsPerPage}
        freezeHeaders={opts.freezeHeaders}
      />

      {/* <ApproveDialog
        open={openApproveDialog}
        onClose={() => setOpenApproveDialog(false)}
        onApprove={refreshData}
        orderShipping={selectedOS}
      />

      <ApproveMultiDialog
        open={openApproveMultiDialog}
        onClose={() => setOpenApproveMultiDialog(false)}
        onApprove={refreshData}
        orderShippings={selectedMultiOS}
      /> */}

      {/* <CancelDialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onCancel={refreshData}
        orderShipping={selectedOS}
      /> */}

      <EditOrderDialog
        open={openEditOrderDialog}
        onClose={() => setOpenEditOrderDialog(false)}
        onCancel={refreshData}
        onApprove={refreshData}
        onEdit={refreshData}
        orderShipping={selectedOS}
      />
    </Stack>
  );
}

export default OrdersForApprovalTable;
