import { useChangeCustomerEffect } from '@/features/customer/hooks/use-change-customer';
import {
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  useGetCurrentUserQuery,
} from '@/graphql';
import { QueryHookOptions } from '@apollo/client';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ProductContext } from '@/features/product/product.context';

type Options = QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>;

export function useCurrentUser(options?: Options) {
  const {
    data,
    loading: isUserLoading,
    refetch,
  } = useGetCurrentUserQuery({ notifyOnNetworkStatusChange: true, ...options });

  useChangeCustomerEffect(() => refetch());

  const currentUser = data?.currentUser ?? null;
  const discount = currentUser?.role?.discount;
  const fullName =
    currentUser?.firstName && currentUser?.lastName
      ? `${currentUser.firstName} ${currentUser.lastName}`
      : '';
  const isAdmin = currentUser?.role?.adminRole ?? false;

  const shoppingCart = data?.currentUser?.shoppingCart?.cartItems;
  // Get product context data at the top level
  const { productId, name, isNormalProduct, isContractProduct, ...productContext } =
    useContext(ProductContext);
  // const favorites = data?.currentUser?.favorites?.products ?? [];
  const customer = data?.currentUser?.customer ?? null;
  const customerTheme = data?.currentUser?.customerTheme ?? null;
  const customerFeatures = data?.currentUser?.customerFeatures ?? null;
  const sitemaps = data?.currentUser?.sitemaps ?? [];

   

  const isRouteVisible = useCallback(
    (routePath: string) =>
      sitemaps.some((s) => s.pagePath?.toLowerCase() === routePath.toLowerCase()),
    [sitemaps]
  );

  return {
    // getCurrentUser,
    currentUser,
    fullName,
    isAdmin,
    shoppingCart,
    sitemaps,
    // favorites,
    customer,
    customerTheme,
    customerFeatures,
    isUserLoading,
    isRouteVisible,
    refresh: refetch,
  };
}
