import { TsuLoading } from '@/common/components';
import { useCurrentUser } from '@/common/auth/hooks/use-current-user';
import { Customer, useGetCustomersLazyQuery } from '@/graphql';
import { Button, Container, Menu, Stack, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useChangeCustomer } from '../hooks/use-change-customer';

export function ChangeCustomerMenu() {
  const { enqueueSnackbar } = useSnackbar();
  // This is needed for MUI's Menu to know what element to render above of
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { changeCustomer, loading: isChangingCustomer } = useChangeCustomer();
  // This is used to show the dropdown of customers
  const [getCustomers, { data, loading: isLoadingCustomers }] = useGetCustomersLazyQuery();
  const customers = (data?.customers ?? [])
    .slice()
    .sort((c1, c2) => ((c1?.name ?? '') < (c2?.name ?? '') ? -1 : 1));

  // This is for displaying the current customer's initials
  const { customer, isAdmin } = useCurrentUser();
  const customerName = customer?.name ?? '';
  const customerInitials =
    customer?.name
      ?.split(' ')
      .map((n) => n.slice(0, 1))
      .join('') ?? '';

  function handleCurrentCustomerClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (!isAdmin) return;
    getCustomers();
    openMenu(e.currentTarget);
  }

  async function handleCustomerClick({ name, customerId }: Partial<Customer>) {
    if (!customerId) return;
    // Close menu before changing customer since the loading wrapper will temporarily remove the button element
    // which this menu is anchored to
    closeMenu();
    await changeCustomer({ customerId });
    enqueueSnackbar('Changed customer' + (name ? ` to ${name}` : ''), {
      variant: 'success',
    });
  }

  const openMenu = (t: EventTarget & HTMLElement) => setAnchorEl(t);
  const closeMenu = () => setAnchorEl(null);

  return (
    <>
      <TsuLoading loading={isChangingCustomer}>
        <Tooltip title={customerName} placement="bottom-start">
          <Button
            variant="contained"
            sx={{
              borderRadius: 100,
              px: 0.5,
              py: 0,
              minWidth: 0,
              bgcolor: 'background.paper',
              color: 'text.primary',
            }}
            onClick={handleCurrentCustomerClick}
          >
            <Typography variant="body2" fontWeight="bold">
              {customerInitials}
            </Typography>
          </Button>
        </Tooltip>
      </TsuLoading>

      <Menu
        id="switch-customer-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <Container>
          <TsuLoading loading={isLoadingCustomers} title="Loading customers">
            <Stack alignItems="start" sx={{ width: '100%' }}>
              {customers.map((c, i) => (
                <Button
                  key={i}
                  onClick={() => handleCustomerClick(c)}
                  variant="text"
                  sx={{ width: '100%', justifyContent: 'start' }}
                >
                  <Typography textAlign="left" whiteSpace="nowrap">
                    {c.name}
                  </Typography>
                </Button>
              ))}
            </Stack>
          </TsuLoading>
        </Container>
      </Menu>
    </>
  );
}
