import { useSecurityQuestionsQuery } from '@/graphql';
import { AppLayout } from '@/layouts/AppLayout';
import { TsuField, TsuPasswordField, TsuSelect } from '@/common/components';
import RedeemIcon from '@mui/icons-material/Redeem';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IRegistrationInput {
  vaccMemberNumber: string;
  personalInfo: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  };
  securityQuestion: {
    questionId: number | null;
    answer: string;
  };
  address: {
    companyName: string;
    streetAddress: string;
    suburb: string;
    state: string;
    postcode: string;
    country: string;
  };
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const getInitialState = () =>
  ({
    vaccMemberNumber: '',
    personalInfo: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    securityQuestion: {
      questionId: null,
      answer: '',
    },
    address: {
      companyName: '',
      streetAddress: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
    },
  } as IRegistrationInput);

function Registration() {
  const navigate = useNavigate();
  const [input, setInput] = useState(getInitialState);
  const { data: securityQuestionsQuery, loading, error } = useSecurityQuestionsQuery();
  const securityQuestions = securityQuestionsQuery?.securityQuestions ?? [];

  const handlePersonalInfoChange = (property: string, value: string) => {
    setInput({
      ...input,
      personalInfo: {
        ...input.personalInfo,
        [property]: value,
      },
    });
  };
  const handleSecurityQuestionChange = (property: string, value: string) => {
    setInput({
      ...input,
      securityQuestion: {
        ...input.securityQuestion,
        [property]: value,
      },
    });
  };
  const handleAddressChange = (property: string, value: string) => {
    setInput({
      ...input,
      address: {
        ...input.address,
        [property]: value,
      },
    });
  };
  const handleCreateAccountClick = async () => {
    // console.log('input:', input);
  };

  return (
    <AppLayout>
      <Grid container sx={{ mb: 4 }}>
        <Grid item xs={4}>
          <div></div>
        </Grid>
        <Grid item xs={4}>
          <Box my={4}>
            <Typography variant="h2" textAlign="center">
              Registration
            </Typography>
          </Box>
          <Card raised>
            <CardContent>
              <Box hidden={true} display={false ? 'flex' : 'none'} justifyContent="center">
                <CircularProgress />
              </Box>
              <Box hidden={false}>
                <Stack alignItems="stretch" spacing={2} sx={{ p: 2 }}>
                  <Typography variant="h3">VACC Membership</Typography>
                  <Stack direction="row">
                    <RedeemIcon sx={{ color: 'primary.main' }} />
                    <Typography>VACC Members Enjoy at least 15% OFF All Products</Typography>
                  </Stack>
                  <TsuField
                    label="VACC Member Number"
                    onChange={(v) => setInput({ ...input, vaccMemberNumber: v })}
                  />
                  <Typography variant="body1">
                    If you don’t know your memberhsip number or would like to become a VACC member,
                    call <span style={{ fontWeight: 700 }}>1300 013 341</span>
                  </Typography>
                  <StyledDivider />
                  <Typography variant="h3">Personal Info</Typography>
                  <Stack direction="row" alignItems="stretch" spacing={2} sx={{ width: '100%' }}>
                    <TsuField
                      label="First Name"
                      onChange={(v) => handlePersonalInfoChange('firstName', v)}
                      sx={{ flex: 1 }}
                    />
                    <TsuField
                      label="Last Name"
                      onChange={(v) => handlePersonalInfoChange('lastName', v)}
                      sx={{ flex: 1 }}
                    />
                  </Stack>
                  <TsuField label="Email" onChange={(v) => handlePersonalInfoChange('email', v)} />
                  <TsuPasswordField
                    label="Password"
                    onChange={(v) => handlePersonalInfoChange('password', v)}
                  />
                  <StyledDivider />
                  <TsuSelect
                    label="Security Question"
                    value={input.securityQuestion.questionId?.toString() ?? ''}
                    loading={loading}
                    error={error}
                    items={securityQuestions}
                    itemText="question"
                    itemValue="questionId"
                    onChange={(v) => handleSecurityQuestionChange('questionId', v)}
                  />
                  <TsuField
                    label="Answer"
                    onChange={(v) => handleSecurityQuestionChange('answer', v)}
                  />
                  <StyledDivider />
                  <Typography variant="h3">Address</Typography>
                  <TsuField
                    label="Company Name"
                    onChange={(v) => handleAddressChange('companyName', v)}
                  />
                  <TsuField
                    label="Street Address"
                    onChange={(v) => handleAddressChange('street', v)}
                  />
                  <Stack direction="row" spacing={2}>
                    <TsuField
                      label="Suburb"
                      onChange={(v) => handleAddressChange('suburb', v)}
                      sx={{ flex: 1 }}
                    />
                    <TsuField
                      label="State"
                      onChange={(v) => handleAddressChange('state', v)}
                      sx={{ flex: 1 }}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <TsuField
                      label="Postcode"
                      onChange={(v) => handleAddressChange('postcode', v)}
                      sx={{ flex: 1 }}
                    />
                    <TsuField
                      label="Country"
                      onChange={(v) => handleAddressChange('country', v)}
                      sx={{ flex: 1 }}
                    />
                  </Stack>
                  <Stack direction="row" spacing={4} sx={{ width: '100%' }}>
                    <Button onClick={() => navigate(-1)} variant="text" sx={{ flex: 1 }}>
                      Cancel
                    </Button>
                    <Button onClick={handleCreateAccountClick} variant="contained" sx={{ flex: 1 }}>
                      Create Account
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <div></div>
        </Grid>
      </Grid>
    </AppLayout>
  );
}

export default Registration;
