import ContentLoader from 'react-content-loader';

export function CategoriesContentLoader() {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={400}
      viewBox="0 0 250 400"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle cx="20" cy="28" r="12" />
      <rect x="40" y="18" rx="5" ry="5" width="205" height="19" />
      <circle cx="20" cy="73" r="12" />
      <rect x="40" y="63" rx="5" ry="5" width="205" height="19" />
      <circle cx="23" cy="118" r="12" />
      <rect x="43" y="108" rx="5" ry="5" width="205" height="19" />
      <circle cx="23" cy="163" r="12" />
      <rect x="43" y="153" rx="5" ry="5" width="205" height="19" />
      <circle cx="24" cy="206" r="12" />
      <rect x="44" y="196" rx="5" ry="5" width="205" height="19" />
      <circle cx="24" cy="251" r="12" />
      <rect x="44" y="241" rx="5" ry="5" width="205" height="19" />
      <circle cx="27" cy="296" r="12" />
      <rect x="47" y="286" rx="5" ry="5" width="205" height="19" />
      <circle cx="27" cy="341" r="12" />
      <rect x="47" y="331" rx="5" ry="5" width="205" height="19" />
    </ContentLoader>
  );
}
