import { FeatureRoute } from '@/common/interfaces/feature-route';
import BulkOrderCsv from './screens/BulkOrderCsv';

export const bulkOrderCsvRoutes: FeatureRoute[] = [
  {
    path: '/bulkOrderCsv',
    component: BulkOrderCsv,
    options: {
      useCustomerTheme: true,
    },
  },
];