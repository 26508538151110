import { TsuDialog, TsuDialogContent } from '@/common/components';
import { OrderItem } from './components/OrderItem';
import { ProductProvider } from '@/features/product/product.context';
import { IOrdersInProgress } from '../../../../common/interfaces';
import { Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';

interface OrderInProgressDialogProps {
  open: boolean;
  order: IOrdersInProgress | null;
  onClose?: () => void;
  onOpen?: () => void;
}

export function OrderInProgressDialog(props: OrderInProgressDialogProps) {
  const { onClose, onOpen, open, order } = props;
  const handleClose = () => onClose?.();
  const items = order?.orderShippings[0].orderLines ?? [];
  return (
    <TsuDialog
      open={open}
      title="Order Details"
      onClose={onClose}
    >
      <TsuDialogContent>
        <Typography variant="h4">
          Order details
        </Typography>
        <Stack direction={'row'} spacing={25}>
          <Stack>
            <Typography>
              Order No.<br/><b>{order?.orderId}</b>
            </Typography>
            <Typography>
              Order date<br/><b>{(moment(order?.created)).format('DD/MMM/YYYY')}</b>
            </Typography>
          </Stack>
          <Stack>
            <Typography>
              Order Placed By<br/><b>{order?.userCreatedFirstName} {order?.userCreatedLastName}</b>
            </Typography>
            <Typography>
              Order status<br/><b>{order?.orderShippings[0].status.name}</b>
            </Typography>
            {/* <Typography>
              Order Reference<br/><b>{orderShipping?.billingCompanyName}</b>
            </Typography> */}
          </Stack>
        </Stack>
        <Divider />
        <Typography variant="h4">
          Delivery details
        </Typography>
        <Stack direction={'row'} spacing={20}>
          <Stack>
            <Typography>
              Address<br/><b>{order?.orderShippings[0].deliveryStreet1},<br/>{order?.orderShippings[0].deliverySuburb}, {order?.orderShippings[0].deliveryState} {order?.orderShippings[0].deliveryPostcode}</b>
            </Typography>
            <Typography>
              Delivery Type<br/><b>{order?.orderShippings[0].deliveryMethod}</b>
            </Typography>
            <Typography>
              Shipping number<br/><b>{order?.orderShippings[0].orderShipId}</b>
            </Typography>
          </Stack>
          <Stack>
            <Typography>
              Delivery To<br/><b>{order?.orderShippings[0].deliveryCompanyName}</b>
            </Typography>
            <Typography>
              Email<br/><b>{order?.orderShippings[0].deliveryEmail}</b>
            </Typography>
            <Typography>
              Phone: {order?.orderShippings[0].deliveryPhone}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Typography variant="h4">
          Product details
        </Typography>
        {items
          .filter((item) => item.feeLine == false)
          .map((item, i) => (
            <ProductProvider
              key={i}
              product={item.product!}
              eTemplateId={item.etemplateId ?? undefined}
            >
              <OrderItem
                quantity={item.quantity}
              />
            </ProductProvider>
          ))}
      </TsuDialogContent>
    </TsuDialog>
  );
}
