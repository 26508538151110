import MaintenanceImage from '@/assets/images/Maintenance.png';
import { DASHBOARD_ROUTE } from '@/features/dashboard/dashboard.routes';
import { AppLayout } from '@/layouts/AppLayout';
import { Box, Button, Container, Stack, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

export default function PageNotFound() {
  const theme = useTheme();

  return (
    <AppLayout>
      <Box display="flex" justifyContent="center">
        <Container sx={{ flexGrow: 1 }} maxWidth="lg">
          <Stack direction="row" alignItems="center" spacing={3}>
            <Stack flex={1} alignItems="stretch" spacing={4}>
              <Stack sx={{ textAlign: 'center' }} spacing={2}>
                <Typography variant="h1" fontWeight="bold">
                  We'll Be Back Soon
                </Typography>
                <Typography variant="h6">
                  We sincerely apologize for the inconvenience. We will be back as soon as possible.
                  Please try again later or{' '}
                  <span style={{ color: theme.palette.primary.main }}>Contact Us</span>
                </Typography>
              </Stack>
              <Button
                component={Link}
                to={DASHBOARD_ROUTE}
                variant="contained"
                sx={{ alignSelf: 'stretch', py: 2 }}
              >
                <Typography variant="h6">Go back to Home</Typography>
              </Button>
            </Stack>
            <img
              src={MaintenanceImage}
              alt="Page Not Found"
              style={{ flex: 1, height: 500, objectFit: 'contain' }}
            />
          </Stack>
        </Container>
      </Box>
    </AppLayout>
  );
}
