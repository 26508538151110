export const allHeaders = [
  { text: 'Product Code', value: 'code', type: 'number' },
  { text: 'Product Name', value: 'name', type: 'string' },
  { text: 'Image', value: 'image', type: 'string' },
  { text: 'Price', value: 'priceSummary', type: 'string' },
  { text: 'Categories', value: 'categories', type: 'string' },
  { text: 'UOM', value: 'unitOfMeasurement', type: 'string' },
  { text: 'Committed', value: 'committed', type: 'date' },
  {
    text: 'SOH',
    value: 'stockOnHand',
    type: 'string',
  },
  {
    text: 'Stock Available',
    value: 'stockAvailable',
    type: 'string',
  },
  {
    text: 'RGA',
    value: 'rga',
    type: 'string',
  },
  {
    text: 'Status',
    value: 'status',
    type: 'string',
  },
  {
    text: 'Copy',
    value: 'copy',
    type: 'string',
  },
];
