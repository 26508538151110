import { TsuLoading } from '@/common/components';
import { AppLayout } from '@/layouts/AppLayout';
import { Box, Button, Container, Stack, styled, Typography } from '@mui/material';
import { useCustomerTheme } from '@/common/theme';
import { useBulkOrderCsvUploadMutation } from '@/graphql';

export default function BulkOrderCsv() {
  const customerTheme = useCustomerTheme();
  const [mutate, {error: UploadCsvError}] = useBulkOrderCsvUploadMutation();
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const csv_file = event.target.files?.[0];
    if (csv_file) {
      try {
        const response = await mutate({ variables: {
          input: {
            file: csv_file
          } 
        }});
        window.alert(response.data?.bulkOrderCsvUpload);
      } catch (error) {
        // Handle errors
        console.error(error);
        window.alert(error);
      }
    }
  }

  return (
    <AppLayout title="Bulk Order Csv Upload">
      <Container sx={{ flexGrow: 1, my: 4, alignSelf: 'center' }} maxWidth="sm">
        <Stack alignItems="stretch" spacing={2}>
          <Typography variant="h3" fontWeight="bold" sx={{ alignSelf: 'center' }}>
            Upload your csv to submit multiple orders.
          </Typography>
          <Container
            maxWidth="lg"
            sx={{
              alignSelf: 'left',
              '&.MuiContainer-root': {
                my: 8,
              },
            }}
          >
            <Stack spacing={1} alignItems="center">
              <div style={{position: 'relative'}}>
                <Button component="label" variant="contained" sx ={{backgroundColor: customerTheme.pageHeader.cart, margin: '5px'}}>
                  Select Csv file
                  <VisuallyHiddenInput type="file" accept=".csv" onChange={handleFileUpload}/>
                </Button>
              </div>
            </Stack>
          </Container>
        </Stack>
      </Container>
    </AppLayout>
  );
}
