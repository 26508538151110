import { useSnackbar } from 'notistack';
import { useState } from 'react';

export function useScheduleReport() {
  const { enqueueSnackbar } = useSnackbar();
  const [dialog, setDialog] = useState(false);

  const openDialog = () => setDialog(true);
  const closeDialog = () => setDialog(false);
  const handleScheduleCreate = (reportScheduleId: number) => {
    enqueueSnackbar(`Report Schedule created (ID: ${reportScheduleId})`);
  };

  return {
    dialog,
    openDialog,
    closeDialog,
    handleScheduleCreate,
  };
}
