import DividerCircle from '@/assets/images/DividerCircle.png';
import LoginBackground from '@/assets/images/LoginBackground.png';
import { CardMedia, Grid, Paper, Typography } from '@mui/material';

export default function NormalSignup() {
  return (
    <div>
      <Paper>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h6">Interested in Waivenet?</Typography>
            <CardMedia
              component="img"
              image={LoginBackground}
              title="Waivenet Login background image"
              alt="Waivenet Login background image"
            />
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Typography variant="h6">
          Copyright © 2010 WaiveNET Pty Ltd
          <CardMedia
            component="img"
            image={DividerCircle}
            title="Divider Circle"
            alt="Divider Circle"
          />
          ABN: 79 610 896 083
        </Typography>
      </Paper>
    </div>
  );
}
