import { createContext, PropsWithChildren, useContext } from 'react';
import { useFavoritesApi } from './hooks/use-favorites-api';

type IFavoritesContext = ReturnType<typeof useFavoritesApi>;

export const FavoritesContext = createContext({} as IFavoritesContext);

export function useFavorites() {
  return useContext(FavoritesContext);
}

export function FavoritesProvider({ children }: PropsWithChildren<{}>) {
  const favoritesApi = useFavoritesApi();
  return <FavoritesContext.Provider value={favoritesApi}>{children}</FavoritesContext.Provider>;
}
