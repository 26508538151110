/*

This component provides descendants with state & functions that access & manipulate Categories.
It encapsulates multiple code / business logic about Categories that may be duplicated / redundant otherwise.

Some of the most important features of this Context Provider is the following:
  1. Selected Category ID - this state tracks which category ID was selected among the CategoryItem descendants 
      (if it was being used in the component hierarchy)
  2. Categories - this state is a result of Get Categories Query, 
      which will be accessible to all our descendants (to avoid redundant code)

The rest of the variables / functions are mostly helper functions that are derived / computed from the above state.

*/

import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import {
  CategoryType,
  useGetCategoriesQuery,
  useGetCategoryLazyQuery,
  useGetCategoryQuery,
} from '../../graphql';
import { useShopParams } from '../shop/hooks/use-shop-params';

type CategoryContext = {
  selectedCategoryId?: number;
  categories: CategoryType[];
  // selectedCategory: CategoryType | null;
  // Helper functions for descendants
  // to retrieve a full Category Entity from the query cache via ID
  getCategoryById: (catId: number) => CategoryType | undefined;
  // getSelectedCategory: () => CategoryType | undefined;
  // Stateful function for descendants to trigger & track a selected Category Entity by ID
  // selectCategoryId: (catId?: number) => void;
  clearSelectedCategory: () => void;
  isLoading: boolean;
};

// Initialize React Context, with an empty object
// (the values will be initialized in CategoryContextProvider)
export const CategoryContext = createContext({} as CategoryContext);

export function useCategoriesContext() {
  return useContext(CategoryContext);
}

export function CategoryContextProvider(props: PropsWithChildren<{}>) {
  const shopParams = useShopParams();
  const [initialCatId] = useState(shopParams.parentCategoryId);
  const [selectedCategoryId, setSelectedCategoryId] =
    useState<CategoryContext['selectedCategoryId']>(initialCatId);

  // Use query from graphql generated code
  const { data: categoriesQuery, loading: isCategoriesLoading } = useGetCategoriesQuery({
    variables: { catId: selectedCategoryId },
  });
  // const [getCategory, { data: categoryQuery, loading: isCategoryLoading, client }] =
  //   useGetCategoryLazyQuery();

  // useEffect(() => {
  //   async function loadCategory() {
  //     const res = await getCategory({ variables: { catId: initialCatId! } });
  //     // if (res.data && res.data.category && res.data.category.childCategoriesCount > 0) {

  //     // }
  //   }
  //   if (initialCatId) loadCategory();
  // }, [initialCatId]);

  // The query data will be null-coalesced to empty array [] to make later code easier to write
  const categories = categoriesQuery?.childCategories ?? [];
  // const selectedCategory = selectedCategoryId ? categoryQuery?.category ?? null : null;

  // Construct the CategoryContext value, which will be provided down to descendants
  const context: CategoryContext = {
    // selectedCategoryId,
    categories,
    // selectedCategory,
    getCategoryById: (catId) => categories.find((c) => c.categoryId === catId),
    // As mentioned in the overview, these functions are just derived from the above state
    // getSelectedCategory: () =>
    //   selectedCategoryId ? getCategoryById(selectedCategoryId) : undefined,
    // selectCategoryId(catId) {
    //   if (catId) getCategory({ variables: { catId } });
    //   setSelectedCategoryId(catId);
    // },
    clearSelectedCategory: () => setSelectedCategoryId(undefined),
    // Flag to help descendants know if Categories query is loading
    isLoading: isCategoriesLoading,
  };

  // React Context Provider boilerplate code
  // Here we pass the context value and then wrap the child
  // so that all descendants can do "useContext" and access our context value
  return <CategoryContext.Provider value={context}>{props.children}</CategoryContext.Provider>;
}
