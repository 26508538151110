import {
  CategoryType,
  useGetProductCategoriesQuery,
  useUpdateProductCategoriesMutation
} from '@/graphql';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

interface Props {
  productId: number | null;
}

export function useCategoriesForm({ productId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [checkedCategoryIds, setCheckedCategoryIds] = useState<number[]>([]);

  // Get Product Categories
  const {
    data: productCategoriesQuery,
    loading: isLoadingProductCategories,
    refetch: refetchProductCategories,
  } = useGetProductCategoriesQuery({
    variables: { productId: productId! },
  });
  const productCategories = productCategoriesQuery?.productCategories ?? [];

  // Update Product Categories
  const [updateProductCategories, { loading: isUpdatingProductCategories }] =
    useUpdateProductCategoriesMutation();

  // Wait for Product Categories to load, then update checkedCategories
  useEffect(() => {
    productCategories && setCheckedCategoryIds(productCategories.map((pc) => pc.categoryId));
  }, [productCategories]);

  function checkCategory(c: CategoryType) {
    if (checkedCategoryIds.some((catId) => catId === c.categoryId))
      setCheckedCategoryIds(checkedCategoryIds.filter((catId) => catId !== c.categoryId));
    else setCheckedCategoryIds([...checkedCategoryIds, c.categoryId]);
  }

  async function save() {
    if (productId === null) return;
    try {
      const res = await updateProductCategories({
        variables: { productId, categoryIds: checkedCategoryIds },
      });
      enqueueSnackbar(
        res.data?.updateProductCategories?.message ?? "Updated Product's Categories",
        { variant: 'success' }
      );
      refetchProductCategories();
    } catch (err) {
      console.error(err);
      enqueueSnackbar((err as any).toString(), { variant: 'error' });
    }
  }

  return {
    // Product's categories (existing checked categories)
    productCategories,
    // Category Checkbox state & functions
    checkCategory,
    checkedCategoryIds,
    // Functions
    save,
    // Flags
    isLoadingProductCategories,
    isSaving: isUpdatingProductCategories,
  };
}
