import { ITsuTableItem } from '@/common/components/tsu-table/interfaces';
import { ProductMgtRecord } from '@/common/interfaces';
import { Box, Button } from '@mui/material';
import { createContext, useState } from 'react';
import { useGetProductManagementQuery } from '../../../graphql';
import { ProductImage } from '../../product/components/ProductImage';
import { Filter, makeBasicFilter } from '../components/BasicFilter';

export interface ProductContextValues {
  productId: number | null;
  productName: string;
}

// ==========================================
// React Context to pass down product object
// to any descendant components
// ==========================================
export const ProductContext = createContext<ProductContextValues>({
  productId: null,
  productName: '',
});

export function useProductManagement() {
  // ==========================================
  // Pagination options
  // ==========================================

  const [opts, setOpts] = useState({
    page: 0,
    itemsPerPage: 20,
  });

  // Other state
  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState<Filter>(makeBasicFilter);
  const [selectedProduct, setSelectedProduct] = useState<ProductMgtRecord | null>(null);
  const [editProductDialog, setEditProductDialogOpen] = useState(false);
  const [addProductDialog, setAddProductDialogOpen] = useState(false);

  // ==========================================
  // Apollo Query
  // ==========================================

  const variables = {
    skip: opts.page * opts.itemsPerPage,
    take: opts.itemsPerPage,
    orderTypeId: filter.orderTypeId,
    catId: filter.categoryId,
    search: searchValue,
  };
  const { data, error, loading, refetch } = useGetProductManagementQuery({
    variables,
    notifyOnNetworkStatusChange: true,
  });

  // ==========================================
  // Functions to control state variables
  // ==========================================

  const setPage = (page: number) => setOpts({ ...opts, page });
  const setItemsPerPage = (ipp: number) => setOpts({ ...opts, itemsPerPage: ipp });

  // Other functions

  function applyBasicFilters(filter: Filter) {
    setFilter(filter);
  }

  const openEditProductDialog = () => setEditProductDialogOpen(true);
  const closeEditProductDialog = () => {
    setSelectedProduct(null);
    setEditProductDialogOpen(false);
  };

  const openAddProductDialog = () => setAddProductDialogOpen(true);
  const closeAddProductDialog = () => {
    setSelectedProduct(null);
    setAddProductDialogOpen(false);
  };

  const handleProductCodeClick = (p: ProductMgtRecord) => {
    setSelectedProduct(p);
    openEditProductDialog();
  };

  // ==========================================
  // Custom Table Item Render function
  // we need to define it here because
  // it relies on some functions
  // ==========================================
  const customItemRender = (_i: ITsuTableItem, value: string) => {
    const i = _i as ProductMgtRecord;
    switch (value) {
      case 'code':
        return (
          <Button variant="text" onClick={() => handleProductCodeClick(i)}>
            {i.code}
          </Button>
        );
      case 'image':
        return (
          <Box width={50} height={50}>
            <ProductImage id={i.productId} alt={i.name} thumbnail />
          </Box>
        );
      default:
        return i[value as keyof ProductMgtRecord];
    }
  };

  return {
    // Product Management Data
    data: data?.productManagement?.items && !loading ? data.productManagement.items : [],
    totalCount: data?.productManagement?.totalCount ?? 0,
    error,
    loading,
    refresh: refetch,
    searchValue,
    setSearchValue,

    // Pagination variables
    opts,
    setPage,
    setItemsPerPage,
    customItemRender,

    // Select Product for the dialogs below
    selectedProduct,

    // Edit Product Dialog
    editProductDialog,
    openEditProductDialog,
    closeEditProductDialog,

    // Add Product Dialog
    addProductDialog,
    openAddProductDialog,
    closeAddProductDialog,

    applyBasicFilters,
  };
}
