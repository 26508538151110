import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Checkbox, Button } from '@mui/material';
import { parseISO, format } from 'date-fns';
import { useCallback } from 'react';
import { ITsuTableItem } from '../../../../common/components/tsu-table/interfaces';
import { IOrderShippingsForApproval } from '../../../../common/interfaces';

// Alias the type because it's way too long while keeping the integrity of the name
type IOSFA = IOrderShippingsForApproval;

interface Args {
  selectedOrderShippings: IOSFA[];
  onCheck?: (o: IOSFA, checked: boolean) => void;
  onApprove?: (os: IOSFA) => void;
  onCancel?: (os: IOSFA) => void;
  onEdit?: (os: IOSFA) => void;
}

function useOrdersForApprovalRender({
  selectedOrderShippings,
  onCheck,
  onApprove,
  onCancel,
  onEdit,
}: Args) {
  const customItemRender = useCallback(
    (_i: ITsuTableItem, value: string) => {
      const i = _i as IOSFA;
      switch (value) {
        // case 'select':
        //   return (
        //     <Checkbox
        //       checked={selectedOrderShippings.some(
        //         (so) => so.orderShipId === i.orderShipId
        //       )}
        //       onChange={(_, checked) => onCheck?.(i, checked)}
        //     />
        //   );
        // case 'approve':
        //   return (
        //     <Button
        //       onClick={() => onApprove?.(i)}
        //       variant="text"
        //       sx={{ color: '#42B988' }}
        //     >
        //       <CheckIcon sx={{ color: '#42B988', mr: 1 }} />
        //       Approve
        //     </Button>
        //   );
        // case 'cancel':
        //   return (
        //     <Button
        //       onClick={() => onCancel?.(i)}
        //       variant="text"
        //       sx={{ color: '#E35E5D' }}
        //     >
        //       <CloseIcon sx={{ color: '#E35E5D', mr: 1 }} />
        //       Cancel
        //     </Button>
        //   );
        case 'edit':
          return (
            <Button
              onClick={() => onEdit?.(i)} 
              variant="contained" 
              color='success'
            >
              View / Edit / Cancel / Approve Order
            </Button>
          );
        case 'created':
          let date = i[value];
          // Some dates can be null
          if (date === null || date === undefined) return <span>-</span>;
          // Some dates are returned as string (not sure why, but let's handle it)
          if (typeof date === 'string') date = parseISO(date);
          return <span>{format(date, 'dd/MM/yyyy')}</span>;
        case 'orderId':
          return (
            <Button
              onClick={() => onEdit?.(i)} 
              variant="text" 
            >
              {i.order.orderId}
            </Button>
          );
        case 'deliveryUserFullName':
          return (
            <span>
              {i.deliveryUserFirstName + ' ' + i.deliveryUserLastName}
            </span>
          );
        case 'companyName':
          return (
            <span>
              {i.billingCompanyName}
            </span>
          );
        case 'orderedByUserFullName':
          if (i.orderedByUser === null) return <span>-</span>;
          return (
            <span>
              {i.orderedByUser.firstName + ' ' + i.orderedByUser.lastName}
            </span>
          );
        default:
          return i[value as keyof IOSFA];
      }
    },
    [selectedOrderShippings, onApprove, onCancel, onCheck, onEdit]
  );

  return { customItemRender };
}

export default useOrdersForApprovalRender;
