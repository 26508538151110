import { isDevelopment } from '@/globals';
import { Address } from '@/graphql';
import { getDetails as getDetailsPlacesAutocomplete } from 'use-places-autocomplete';
import {
  EMPTY_ADDRESS,
  EMPTY_CONTACT_INFO,
  SAMPLE_ADDRESS,
  SAMPLE_CONTACT_INFO,
} from './constants/initial-data';
import { ContactInfo, Address as FormAddress } from './interfaces';

export const getInitialContactInfo = () =>
  isDevelopment ? SAMPLE_CONTACT_INFO : EMPTY_CONTACT_INFO;

export const getInitialAddress = () => (isDevelopment ? SAMPLE_ADDRESS : EMPTY_ADDRESS);

export function getDetails(placeId: string) {
  return getDetailsPlacesAutocomplete({ placeId, fields: ['address_components'] });
}

export function addressToFormValues(data: Partial<Address>): FormAddress & Partial<ContactInfo> {
  const values: FormAddress & Partial<ContactInfo> = {
    // companyName: data.companyName ?? '',
    businessName: data.companyName ?? '',
    streetAddress: data.street1 ?? '',
    suburb: data.suburb ?? '',
    state: data.state ?? '',
    postcode: data.postcode ?? '',
    country: data.country ?? '',
    deliveryNotes: data.notes ?? '',
  };
  // Uf the values are not available, then the form's state will not be overwritten.
  // Instead, any existing values still remain.
  if (data.email) values.email = data.email;
  if (data.mobile) values.mobileNo = data.mobile;
  if (data.phone) values.phoneNo = data.phone;
  return values;
}
