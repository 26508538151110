import { BannerItem } from '@/graphql';
import { styled, useTheme } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';

const StyledCarousel = styled(Carousel)({
  marginBottom: 75,
  '& .carousel.carousel-slider': {
    overflow: 'visible',
  },
});

const Indicator = styled('li')(({ theme }) => ({
  background: 'white',
  border: `solid 1px ${theme.palette.primary.main}`,
  borderRadius: 16,
  width: 32,
  height: 8,
  display: 'inline-block',
  margin: '0 8px',
  cursor: 'pointer',
  position: 'relative',
  top: 50,
}));

interface TsuCarouselProps {
  images: BannerItem[];
}

export function TsuCarousel({ images }: TsuCarouselProps) {
  const theme = useTheme();
  return (
    <StyledCarousel
      infiniteLoop={true}
      interval={3000}
      autoPlay={true}
      showThumbs={false}
      showStatus={false}
      stopOnHover={false}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <Indicator
              style={{ background: theme.palette.primary.main, width: 64 }}
              // aria-label={`Selected: ${label} ${index + 1}`}
              // title={`Selected: ${label} ${index + 1}`}
            />
          );
        }
        return (
          <Indicator
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            // title={`${label} ${index + 1}`}
            // aria-label={`${label} ${index + 1}`}
          />
        );
      }}
    >
      {images.map((image, i) => (
        <div key={i}>
          <a href={image.href ?? ""} style={{display: "inline-block"}} target="_blank">
            <img src={image.fileName ?? ""} alt={`Customer Banner ${i + 1}`} />
          </a>
        </div>
      ))}
    </StyledCarousel>
  );
}

const UnstyledIndicator = styled('li')(({ theme }) => ({
  background: 'white',
  border: `solid 1px black`,
  borderRadius: 16,
  width: 32,
  height: 8,
  display: 'inline-block',
  margin: '0 8px',
  cursor: 'pointer',
  position: 'relative',
  top: 50,
}));

export function UnstyledTsuCarousel({ images }: TsuCarouselProps) {
  const theme = useTheme();
  return (
    <StyledCarousel
      infiniteLoop={true}
      interval={3000}
      autoPlay={true}
      showThumbs={false}
      showStatus={false}
      stopOnHover={false}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <UnstyledIndicator
              style={{ background: 'black', width: 64 }}
              // aria-label={`Selected: ${label} ${index + 1}`}
              // title={`Selected: ${label} ${index + 1}`}
            />
          );
        }
        return (
          <UnstyledIndicator
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            // title={`${label} ${index + 1}`}
            // aria-label={`${label} ${index + 1}`}
          />
        );
      }}
    >
      {images.map((image, i) => (
        <div key={i}>
          <a href={image.href ?? ""} style={{display: "inline-block"}} target="_blank">
            <img src={image.fileName ?? ""} alt={`Customer Banner ${i + 1}`} />
          </a>
        </div>
      ))}
    </StyledCarousel>
  );
}
