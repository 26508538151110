import { TsuSelect, TsuStepNumber } from '@/common/components';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { dateTimeFilters } from '../constants';

export interface IDateFilters {
  createdOnFrom: Date;
  createdOnTo: Date;
}

interface DateFiltersProps {
  onChange?: (dateFilters: IDateFilters) => void;
}

function DateFilters({ onChange }: DateFiltersProps) {
  const [dateFilters] = useState<IDateFilters>(() => ({
    createdOnFrom: new Date(),
    createdOnTo: new Date(),
  }));

  useEffect(() => onChange?.(dateFilters), [onChange, dateFilters]);

  return (
    <Stack spacing={2}>
      <Typography variant="h5" display="flex" alignItems="center">
        <TsuStepNumber step={2} sx={{ mr: 1 }} />
        Date Filters
      </Typography>
      <Card variant="outlined" raised={false}>
        <CardContent sx={{ backgroundColor: '#F1F2F6' }}>
          <Typography variant="body1">Product Created On (From)</Typography>
          <Box mb={2}>
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              {format(dateFilters.createdOnFrom, 'MM/dd/yyyy')}
            </Typography>
          </Box>
          <TsuSelect
            value={'0'}
            items={dateTimeFilters}
            itemText="title"
            itemValue="value"
            label="Replace with"
          />
        </CardContent>
      </Card>
      <Card variant="outlined" raised={false}>
        <CardContent sx={{ backgroundColor: '#F1F2F6' }}>
          <Typography variant="body1">Product Created On (To)</Typography>
          <Box mb={2}>
            <Typography variant="body1" style={{ fontWeight: 700 }}>
              {format(dateFilters.createdOnTo, 'MM/dd/yyyy')}
            </Typography>
          </Box>
          <TsuSelect
            value={'0'}
            items={dateTimeFilters}
            itemText="title"
            itemValue="value"
            label="Replace with"
          />
        </CardContent>
      </Card>
    </Stack>
  );
}

export default DateFilters;
