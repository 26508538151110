import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TsuButton } from '../TsuButton';

interface IExtraAction {
  text: string;
  value: string;
}

interface TsuTableFilterProps {
  basicFilterComponent?: React.ReactNode;
  advancedFilterComponent?: React.ReactNode;
  leftActionsComponent?: React.ReactNode;
  actionsComponent?: React.ReactNode;
  headers: any[];
  onSearchEntered?: (v: string) => void;
  onAdvancedFilterTabChange?: (tab: number) => void;
  onExtraActionChange?: (action: string, headers: any[]) => void;
}

export function TsuTableFilter({
  basicFilterComponent,
  advancedFilterComponent,
  leftActionsComponent,
  actionsComponent,
  headers,
  onSearchEntered,
  onAdvancedFilterTabChange,
  onExtraActionChange,
}: TsuTableFilterProps) {
  const theme = useTheme();
  // Source: https://mui.com/components/menus/#basic-menu
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const [searchValue, setSearchValue] = useState('');
  const [hideSearchLabel, setHideSearchLabel] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [advancedFilterTab, setAdvancedFilterTab] = useState(0);

  const [selectedExtraAction, setSelectedExtraAction] =
    useState<IExtraAction | null>(null);

  // const [selectedHeaders, setSelectedHeaders] = useState<any[]>([]);
  const [hideHeaders, setHideHeaders] = useState<any[]>([]);
  const [freezeHeaders, setFreezeHeaders] = useState<any[]>([]);
  const [groupByHeaders, setGroupByHeaders] = useState<any[]>([]);

  const getSelectedHeaders = useCallback(() => {
    const action = selectedExtraAction?.value;
    if (action === 'show-hide-columns') return hideHeaders;
    else if (action === 'freeze-columns') return freezeHeaders;
    else if (action === 'group-by') return groupByHeaders;
    else return [];
  }, [selectedExtraAction, hideHeaders, freezeHeaders, groupByHeaders]);

  const setSelectedHeaders = useCallback(
    (h: any[]) => {
      const action = selectedExtraAction?.value;
      if (action === 'show-hide-columns') setHideHeaders(h);
      else if (action === 'freeze-columns') setFreezeHeaders(h);
      else if (action === 'group-by') setGroupByHeaders(h);
    },
    [selectedExtraAction, setHideHeaders, setFreezeHeaders, setGroupByHeaders]
  );

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearchEntered?.(searchValue);
    }
  };

  // Open the extra actions pop-up
  const handleExtraActionsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  // Select an extra action (e.g. Show / hide, freeze, etc.)
  const handleExtraActionClick = (action: IExtraAction) => {
    setSelectedExtraAction(action);
  };

  // Select one or more headers after selecting an extra action
  const handleExtraActionHeaderClick = (header: any) => {
    const selectedHeaders = getSelectedHeaders();
    // Remove from selected headers if it was already in
    if (selectedHeaders.some((h) => h.value === header.value)) {
      setSelectedHeaders(
        selectedHeaders.filter((h) => h.value !== header.value)
      );
    }
    // Add to selected headers array
    else {
      setSelectedHeaders([...selectedHeaders, header]);
    }
  };

  useEffect(() => {
    onAdvancedFilterTabChange?.(advancedFilterTab);
  }, [onAdvancedFilterTabChange, advancedFilterTab]);

  // Every time a change happens in extra actions or selected headers,
  // notify listeners
  useEffect(() => {
    if (selectedExtraAction) {
      onExtraActionChange?.(selectedExtraAction.value, getSelectedHeaders());
    }
  }, [onExtraActionChange, selectedExtraAction, getSelectedHeaders]);

  const extraActionChoices = useMemo(() => {
    const props = { sx: { color: theme.palette.primary.main, mr: 1 } };
    return [
      {
        text: 'Show / Hide Columns',
        value: 'show-hide-columns',
        icon: <VisibilityOutlinedIcon {...props} />,
        iconSelected: <VisibilityOffIcon {...props} />,
      },
      {
        text: 'Freeze Column',
        value: 'freeze-columns',
        icon: <AcUnitOutlinedIcon {...props} />,
        iconSelected: (
          <AcUnitOutlinedIcon sx={{ ...props.sx, color: '#447CDC' }} />
        ),
      },
      {
        text: 'Group By',
        value: 'group-by',
        icon: <DashboardCustomizeOutlinedIcon {...props} />,
        iconSelected: <DashboardCustomizeOutlinedIcon {...props} />,
      },
    ];
  }, [theme.palette.primary.main]);

  // Headers in Extra Actions should only show actually visible (non-action) headers
  const visibleHeaders = headers.filter((h) => h.text !== '');

  return (
    <Box>
      <Card>
        <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            {leftActionsComponent}
            <FormControl
              sx={{
                m: 1,
                flex: 1,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 3,
                  backgroundColor: '#F5FAFF',
                  borderColor: '#E1E1E1',
                },
              }}
              variant="outlined"
            >
              <OutlinedInput
                id="searchValue"
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onFocus={(e) => setHideSearchLabel(true)}
                onBlur={(e) => setHideSearchLabel(searchValue.length > 0)}
                onKeyPress={handleSearchKeyPress}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                    {!hideSearchLabel && <Typography>Search</Typography>}
                  </InputAdornment>
                }
              />
            </FormControl>
            <Stack
              direction="row"
              justifyContent={{ xs: 'start', md: 'end' }}
              alignItems="center"
              sx={{ flexGrow: 1 }}
            >
              <TsuButton text onClick={() => setShowFilter(!showFilter)}>
                <Typography
                  variant="h6"
                  sx={{ color: theme.palette.primary.main, mr: 1 }}
                  fontWeight="bold"
                >
                  {showFilter ? 'Hide Filter' : 'Show Filter'}
                </Typography>
                <FilterAltOutlinedIcon
                  sx={{ color: theme.palette.primary.main }}
                  fontSize="medium"
                />
              </TsuButton>
              <IconButton onClick={handleExtraActionsClick}>
                <TuneIcon
                  sx={{ color: theme.palette.primary.main }}
                  fontSize="medium"
                />
              </IconButton>
              <Menu
                id="extra-actions-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {!selectedExtraAction &&
                  extraActionChoices.map((action, i) => (
                    <MenuItem
                      key={i}
                      onClick={() => handleExtraActionClick(action)}
                    >
                      {action.icon}
                      <Typography sx={{ flexGrow: 1 }}>
                        {action.text}
                      </Typography>
                      <KeyboardArrowRightOutlinedIcon
                        sx={{ color: theme.palette.primary.main }}
                      />
                    </MenuItem>
                  ))}
                {selectedExtraAction && (
                  <Stack spacing={1}>
                    <Stack direction="row" alignItems="center">
                      <IconButton onClick={() => setSelectedExtraAction(null)}>
                        <KeyboardArrowLeftOutlinedIcon
                          sx={{ color: theme.palette.primary.main }}
                        />
                      </IconButton>
                      <Typography variant="body1">
                        {selectedExtraAction.text}
                      </Typography>
                    </Stack>
                    {visibleHeaders.map((h, i) => {
                      const extraAction = extraActionChoices.find(
                        (c) => c.value === selectedExtraAction.value
                      )!;
                      const isSelected = getSelectedHeaders().some(
                        (h2) => h2.value === h.value
                      );
                      return (
                        <MenuItem
                          key={i}
                          onClick={() => handleExtraActionHeaderClick(h)}
                          sx={{
                            backgroundColor: isSelected ? '#F5FAFF' : 'none',
                          }}
                        >
                          {isSelected
                            ? extraAction.iconSelected
                            : extraAction.icon}
                          <Typography sx={{ flexGrow: 1 }}>{h.text}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Stack>
                )}
              </Menu>
            </Stack>
            {actionsComponent}
          </Stack>
        </CardContent>
      </Card>

      <Card
        sx={{
          visibility: showFilter ? 'visible' : 'hidden',
          width: showFilter ? 'auto' : 0,
          height: showFilter ? 'auto' : 0,
        }}
      >
        <CardContent>
          <Stack direction="row" sx={{ mb: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={advancedFilterTab}
                onChange={(_, v) => setAdvancedFilterTab(v)}
                aria-label="Advanced Filter Tabs"
              >
                {basicFilterComponent && (
                  <Tab
                    label="Basic Filter"
                    id="advanced-filter-tab-1"
                    sx={{ textTransform: 'none', fontFamily: 'Open Sans' }}
                  />
                )}
                {advancedFilterComponent && (
                  <Tab
                    label="Advance Filter"
                    id="advanced-filter-tab-2"
                    sx={{ textTransform: 'none', fontFamily: 'Open Sans' }}
                  />
                )}
              </Tabs>
            </Box>
            <Box flexGrow={1} />
            <TsuButton text onClick={() => setShowFilter(false)}>
              <CancelOutlinedIcon
                sx={{ color: theme.palette.primary.main, mr: 1 }}
                fontSize="medium"
              />
              <Typography
                variant="h6"
                sx={{ color: theme.palette.primary.main }}
                fontWeight="bold"
              >
                Hide Filter
              </Typography>
            </TsuButton>
          </Stack>
          <div hidden={advancedFilterTab !== 0}>{basicFilterComponent}</div>
          <div hidden={advancedFilterTab !== 1}>{advancedFilterComponent}</div>
        </CardContent>
      </Card>
    </Box>
  );
}
