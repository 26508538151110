import {
  Box,
  InputBaseComponentProps,
  Paper,
  styled,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import { ChangeEvent, MouseEvent } from 'react';

const AdjustmentButton = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 24,
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  backgroundColor: 'white',
  width: 50, 
  cursor: 'pointer',
  transition: 'background-color 0.25s ease',
  userSelect: 'none',
  boxShadow: 'none',
}));

const QuantityTextField= styled(TextField)(({theme}) => ({
  height: '100%',
  p: 0,
  '& .MuiInputBase-root, & .MuiInputBase-input': {
    height: '100%',
    border: 'none'
  },
  '& .MuiInputBase-input': {
    padding: '0 0.5rem',
    textAlign: 'center',
    border: 'none'
  },
  border: 'none',
  "& fieldset": { border: 'none' },
}));
const QuantityAdjustmentBox = styled(Box)(({theme}) => ({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'stretch',
  marginRight: 8,
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: 6,
  height: '38px',
  // '&:hover': {
  //   backgroundColor: theme.palette.primary.dark,
  // },
}));

interface QuantityAdjustmentProps {
  quantity: number;
  max?: number;
  min?: number;
  onChange?: (q: number) => void;
  onDecrementClick?: (e: MouseEvent<HTMLElement>) => void;
  onIncrementClick?: (e: MouseEvent<HTMLElement>) => void;
}

function QuantityAdjustment(props: QuantityAdjustmentProps) {
  const min = props.min ?? 1;
  const max = props.max;
  const inputProps: InputBaseComponentProps = {
    inputMode: 'numeric',
    pattern: '[0-9]*',
    min,
    max,
    borderBottom: 'none',
    classes: {
      underline: 'underline-none'
    }
  };


  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value === '') return props.onChange?.(min);
    // Below is a bunch of validators
    let newQuantity = parseInt(e.target.value);
    // Ensure that value is a valid number
    if (Number.isNaN(newQuantity)) return;
    // Ensure that value is above minimum order quantity
    if (min !== undefined) newQuantity = Math.max(newQuantity, min);
    // Ensure that value is under stock available
    if (max !== undefined) newQuantity = Math.min(newQuantity, max);
    props.onChange?.(newQuantity);
  }

  function handleDecrement(e: MouseEvent<HTMLElement>) {
    if (props.quantity - 1 < min) return;
    props.onDecrementClick?.(e);
  }

  function handleIncrement(e: MouseEvent<HTMLElement>) {
    if (max !== undefined && props.quantity + 1 > max) return;
    props.onIncrementClick?.(e);
  }

  return (
    <QuantityAdjustmentBox>
      <AdjustmentButton
        onClick={handleDecrement}
      >
        -
      </AdjustmentButton>
      <Paper
        sx={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1
        }}
        onClick={(e) => e.stopPropagation()}
        elevation={0}
      >
        <QuantityTextField
          value={props.quantity}
          onChange={handleChange}
          inputProps={inputProps}
          variant="outlined"
        />
      </Paper>
      <AdjustmentButton
        onClick={handleIncrement}
      >
        +
      </AdjustmentButton>
    </QuantityAdjustmentBox>
  );
}

export default QuantityAdjustment;
