import WebFont from 'webfontloader';
import FONTS from '../assets/fonts.json';

import '../assets/fonts/Verdana.ttf';
import '../assets/fonts/Verdana-bold.ttf';
import '../assets/fonts/Verdana.ttf';

export function addFonts() {
  WebFont.load({
    google: {
      families: FONTS.families,
    },
  });
}
