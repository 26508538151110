import { MenuItem, Select, styled } from '@mui/material';

export const VACCStyledSelectMenu = styled(Select)({
  "&.MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#69B0E8"
    }
  }
});

export const VACCStyledMenuItem = styled(MenuItem)({
  '&&.Mui-selected' : {
    backgroundColor: 'grey',
    color: 'white'
  },
  '&:hover' : {
    backgroundColor: '#69B0E8',
    color: 'white'
  }
});