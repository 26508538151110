import NormalSignin from './NormalSignin';
import VACCSignin from '@/features/customization/VACC/VACCSignin';

export default function Signin() {
  // Check if the URL contains "ourautoistore"
  if (window.location.hostname.includes('ourautoistore')) {
    return <VACCSignin/>;
  }

  // Render the default SignIn component
  return (
    <NormalSignin/>
  );
}
