import RequireAdmin from '@/common/components/RequireAdmin';
import { FeatureRoute } from '@/common/interfaces/feature-route';
import { RoleAdministration } from './role/components/RoleAdministration';
import { Administration } from './screens/Administration';
import { RgaListWaivenet } from '../punchout/rga-list/screens/RgaList';
import { ExpectedDeliveryUpload } from '../punchout/expected-delivery-date-upload/screens/ExpectedDeliveryDateUpload';
import { BusinessLevelAdministration } from './business-level/components/BusinessLevelAdministration';

export const administrationRoutes: FeatureRoute[] = [
  {
    path: '/administration',
    component: () => (
      <RequireAdmin>
        <Administration />
      </RequireAdmin>
    ),
    options: { useCustomerTheme: true },
    children: [
      {
        path: 'roles',
        component: RoleAdministration,
        options: { useCustomerTheme: true },
      },
      {
        path: 'businessLevels',
        component: BusinessLevelAdministration,
        options: { useCustomerTheme: true },
      },
      {
        path: 'rgaList',
        component: RgaListWaivenet,
        options: { useCustomerTheme: true },
      },
      {
        path: 'expectedDeliveryUpload',
        component: ExpectedDeliveryUpload,
        options: { useCustomerTheme: true },
      },
    ],
  },
];
