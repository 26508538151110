import { FeatureRoute } from '@/common/interfaces/feature-route';
import ProductManagement from './screens/ProductManagement';

export const productManagementRoutes: FeatureRoute[] = [
  {
    path: '/productManagement',
    component: ProductManagement,
    options: {
      useCustomerTheme: true,
    },
  },
];
