import { TsuField, TsuSelect, TsuLoading } from '@/common/components';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { ChangeEvent, useContext } from 'react';
import { SelectedRoleContext } from '../context/selected-role.context';
import { useEditRoleForm } from '../hooks/useEditRoleForm';
import { RoleActionPermissionsList } from './RoleActionPermissionsList';
import { RoleFreightSettingsList } from './RoleFreightSettingsList';
import { RoleOrderTypesList } from './RoleOrderTypesList';
import { useCurrentUser } from '@/common/auth/hooks/use-current-user';

export function EditRoleForm() {
  const { selectedRole } = useContext(SelectedRoleContext);
  const editRoleForm = useEditRoleForm();
  const { customer} = useCurrentUser();
  const customerName = customer?.name ?? '';
  const { formState, dispatch } = editRoleForm;

  const handleNumberInputChange = (event: any, property: string) => {
    const inputValue = event.target.value;
    // Check if the input is a valid integer
    const isValidInteger = /^(?:\d+)?$/.test(inputValue);

    if (isValidInteger) {
      // Set the integer value in the state
      dispatch(property, event.target.value);
    }
    // If the input is not a valid integer, show an error message TODO.
  };

  const handleDecimalInputChange = (event: any, property: string) => {
    const inputValue = event.target.value;
    // Check if the input is a valid decimal
    const isValidDecimal = /^(?:|(?!0*[.]0*$|[.]0*$)\d*(?:[.]\d{0,2})?)$/.test(inputValue);

    if (isValidDecimal) {
      // Set the decimal value in the state
      dispatch(property, event.target.value);
    }
    // If the input is not a valid decimal, show an error message TODO.
  };
  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Typography>
            <span>Customer Name: </span>
            <span style={{ fontWeight: 'bold' }}>{customerName}</span>
          </Typography>
          <TsuField
            label="Role Name"
            value={formState.name}
            onChange={(v) => dispatch('name', v.target.value)}
            required
          />
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>Spend Limit is </Typography>
            <TsuField
              value={formState.spendLimitPerDay?.toString() ?? ''}
              onChange={(v) => handleDecimalInputChange(v, 'spendLimitPerDay')}
            />
            <Typography> per </Typography>
            <TsuField
              value={formState.spendLimitDays?.toString() ?? ''}
              onChange={(v) => handleNumberInputChange(v, 'spendLimitDays')}
            />
            <Typography> day(s).</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>Apply a minimum total of </Typography>
            <TsuField
              value={formState.minOrderTotal?.toString() ?? ''}
              onChange={(v) => handleDecimalInputChange(v, 'minOrderTotal')}
            />
            <Typography> per order.</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>Maximum quantity</Typography>
            <TsuField
              value={formState.maxOrderQuantity?.toString() ?? ''}
              onChange={(v) => handleNumberInputChange(v, 'maxOrderQuantity')}
            />
            <Typography> per order.</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>Discount: </Typography>
            <TsuField
              value={formState.discount?.toString() ?? ''}
              onChange={(v) => handleDecimalInputChange(v, 'discount')}
            />
            <TsuSelect
              value={formState.discountUom}
              items={[
                { label: '%', value: 'percent' },
                { label: '$', value: 'dollar' },
              ]}
              itemText="label"
              itemValue="value"
              onChange={(v) => dispatch('discountUom', v)}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>Default Warehouse: </Typography>
            <TsuField
              value={formState.defaultWarehouse ?? ''}
              onChange={(v) => dispatch('defaultWarehouse', v.target.value)}
            />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>Free Freight threshold (0 for none): </Typography>
            <TsuField
              value={formState.zeroFreightOrderAmount?.toString() ?? ''}
              onChange={(v) => handleDecimalInputChange(v, 'zeroFreightOrderAmount')}
            />
          </Stack>
          {/* <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>Local Currency (if different from AUD): </Typography>
            <TsuField value={formState.name} onChange={(v) => dispatch('name', v)} onlyValue />
          </Stack> */}
          <Grid container>
            <Grid item xs={4}>
              <Card>
                <CardHeader title="Action Permissions" />
                <CardContent sx={{ maxHeight: '300px', overflowY: 'scroll' }}>
                  <RoleActionPermissionsList onChange={editRoleForm.handleUserActionsChange} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card>
                <CardHeader title="Role Order Types" />
                <CardContent sx={{ maxHeight: '300px', overflowY: 'scroll' }}>
                  <RoleOrderTypesList onChange={editRoleForm.handleOrderTypesChange} />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card>
                <CardHeader title="Freight Settings" />
                <CardContent sx={{ maxHeight: '300px', overflowY: 'scroll' }}>
                  <RoleFreightSettingsList onChange={editRoleForm.handleFreightSettingsChange} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
      <CardActions>
        <TsuLoading loading={editRoleForm.isSaving}>
          <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
            <Button variant="contained" onClick={editRoleForm.handleSave}>
              Save
            </Button>
          </Stack>
        </TsuLoading>
      </CardActions>
    </Card>
  );
}
