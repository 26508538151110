import { useQuery } from '@apollo/client';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Checkbox, Collapse, List, ListItemButton, ListItemText, Stack } from '@mui/material';
import { gql } from 'apollo-boost';
import { Fragment, useContext, useEffect, useState } from 'react';
import { SelectedRoleContext } from '../context/selected-role.context';
import { UserAction, UserActionCategory } from '../interfaces/user-action.interfaces';

export function useUserActionCategories(roleId?: number) {
  const [selectedUserActions, setSelectedUserActions] = useState<UserAction[]>([]);
  const [userActionCategories, setUserActionCategories] = useState<
    (UserActionCategory & { open: boolean })[]
  >([]);

  // UAC = User Action Categories
  const { data: getUacQuery } = useQuery<{ userActions: UserActionCategory[] }>(
    gql`
      query ($roleId: Int!) {
        userActions(roleId: $roleId) {
          name
          userActions {
            actionId
            name
            description
            isActive
            rolePermissions {
              userRoleId
              actionId
              roleId
            }
          }
        }
      }
    `,
    {
      variables: { roleId },
    }
  );

  useEffect(() => {
    if (getUacQuery) {
      const mappedCats = getUacQuery.userActions.map((cat) => ({
        ...cat,
        open: false,
      }));

      const selectedUserActions: UserAction[] = [];
      mappedCats.forEach((cat) =>
        cat.userActions.forEach((ua) => ua.isActive && selectedUserActions.push(ua))
      );

      setSelectedUserActions(selectedUserActions);
      setUserActionCategories(mappedCats);
    }
  }, [getUacQuery]);

  return {
    userActionCategories,
    setUserActionCategories,
    selectedUserActions,
    setSelectedUserActions,
  };
}

interface Props {
  onChange?: (userActions: UserAction[]) => void;
}

export function RoleActionPermissionsList(props: Props) {
  const { selectedRole } = useContext(SelectedRoleContext);

  const {
    userActionCategories,
    setUserActionCategories,
    selectedUserActions,
    setSelectedUserActions,
  } = useUserActionCategories(selectedRole?.roleId);

  useEffect(() => {
    props.onChange?.(selectedUserActions);
  }, [selectedUserActions, props.onChange]);

  // This is meant for expanding / collapsing the nested lists
  function handleUserActionCategoryClick(idx: number) {
    setUserActionCategories(
      userActionCategories.map((cat, i) => (i === idx ? { ...cat, open: !cat.open } : cat))
    );
  }

  function handleUserActionClick(userAction: UserAction) {
    const isSelected = selectedUserActions.some((sua) => sua.actionId === userAction.actionId);
    if (isSelected) {
      setSelectedUserActions(
        selectedUserActions.filter((sua) => sua.actionId !== userAction.actionId)
      );
    } else {
      setSelectedUserActions([...selectedUserActions, userAction]);
    }
  }

  return (
    <Stack>
      <List>
        {userActionCategories.map((cat, i) => (
          <Fragment key={i}>
            <ListItemButton onClick={() => handleUserActionCategoryClick(i)}>
              <ListItemText primaryTypographyProps={{ fontSize: '12px' }} primary={cat.name} />
              {cat.open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={cat.open} unmountOnExit>
              {cat.userActions.map((act, j) => (
                <List key={j} component="div" disablePadding>
                  <ListItemButton sx={{ pl: 4 }} onClick={() => handleUserActionClick(act)}>
                    <Checkbox
                      sx={{ mr: 1 }}
                      checked={selectedUserActions.some((ua) => ua.actionId === act.actionId)}
                    />
                    <ListItemText
                      primaryTypographyProps={{ fontSize: '12px' }}
                      primary={act.description}
                    />
                  </ListItemButton>
                </List>
              ))}
            </Collapse>
          </Fragment>
        ))}
      </List>
    </Stack>
  );
}
