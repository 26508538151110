import { useMutation } from '@apollo/client';
import { gql } from 'apollo-boost';
import { CreateProductUomPayload } from '../../../common/interfaces';

export function useUpdateUom() {
  const [mutate, { error, loading }] = useMutation<
    { message: string },
    { uomId: number; input: CreateProductUomPayload }
  >(gql`
    mutation ($uomId: Int!, $input: CreateProductUomPayloadInput!) {
      updateUom(uomId: $uomId, input: $input) {
        message
      }
    }
  `);

  const updateUom = (uomId: number, input: CreateProductUomPayload) =>
    mutate({ variables: { uomId, input } });

  return { updateUom, error, loading };
}
