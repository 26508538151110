// Reference: https://www.bezkoder.com/handle-jwt-token-expiration-react/

import { publicRoutes } from '@/features/routes';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { SIGNIN_ROUTE } from '../auth.constants';
import AuthService from '../services/auth.service';

interface TsunamiJwtPayload {
  CustomerId: number;
  // i.e. "not before" - token cannot be used before x seconds of creation
  nbf: number;
  // Token expiration date in NumericDate format
  exp: number;
  // Not sure what these are for but we don't need them anyway
  iss: string;
  aud: string;
}

const parseJwt = (token: string) => {
  try {
    return JSON.parse(window.atob(token.split('.')[1])) as TsunamiJwtPayload;
  } catch (e) {
    return null;
  }
};

export function AuthExpirationListener() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAccessToken = async () => {
      // Don't proceed with the logic below if the route is public
      if (publicRoutes.some((r) => r.path === location.pathname)) return;
      // Start checking validity of access token in certain ways
      const accessToken = AuthService.getAccessToken();
      if (!accessToken) return logout('Authentication required');
      // Case: User has an access token, but it may be VALID or INVALID
      // (e.g. manually / maliciously added to local storage)

      // Attempt to decode the access token (to get expiration date)
      const decoded = parseJwt(accessToken);
      if (!decoded) return logout('Invalid session');
      // Case: User has a VALID access token, but it may be expired (check exp)

      // Check if token has expired (Source: https://www.bezkoder.com/handle-jwt-token-expiration-react/)
      if (decoded.exp * 1000 < Date.now()) {
        // Since token has expired, check if User has clicked remember me.
        // And if they did, they should also have a Refresh Token.
        if (AuthService.hasRefreshToken() && AuthService.isRememberEnabled()) {
          try {
            await AuthService.refreshAccessToken();
            return;
          } catch (err) {
            // Invalid refresh token, don't do anything but proceed to below code (kick-out)
          }
        }
        // User did not enable remember me, therefore we kick them out and say "session has expired"
        return logout('Session has expired');
      }
    };
    checkAccessToken();
  }, [location]);

  function logout(message?: string) {
    AuthService.logout();
    navigate({ pathname: SIGNIN_ROUTE }, { state: { message } });
  }

  return <div></div>;
}
