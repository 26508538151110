import productPlaceholderImage from '@/assets/images/ProductPlaceholder.png';
import ngvPlaceholderImage from '@/assets/images/NGVPlaceholder.png'
import ngvPubsPlaceholderImage from '@/assets/images/NGVPublicationsPlaceholder.png'
import { SERVER_URL } from '@/globals';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import useSWR from 'swr';
import { useGetCurrentCustomerQuery } from '@/graphql';
import { useEffect, useState } from 'react';
interface Props {
  id: number;
  alt?: string;
  thumbnail?: boolean;
}

async function getProductImage(imageUrl: string) {
  const res = await fetch(imageUrl);
  const blob = await res.blob();
  if (!res.ok) throw new Error('Image was not found');
  if (blob.type === 'application/problem+json') throw new Error('Image was not found');
  // Source: https://stackoverflow.com/a/44069294/5575610
  return {
    objectUrl: URL.createObjectURL(blob),
    isPlaceholderImage: res.headers.get('Is-Placeholder-Image') === 'true',
  };
}

export function ProductImage(props: Props) {
  const { id, alt, thumbnail } = props;
  const [image, setImage] = useState<string>(productPlaceholderImage);
  let imageUrl = `${SERVER_URL}/api/productimage/${id}`;
  if (thumbnail) imageUrl += '?thumb=1';
  const { data, error } = useSWR(`product-image_${id}`, () => getProductImage(imageUrl), {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  const {
    data: customerData,
    loading: isCustomerLoading,
    refetch,
  } = useGetCurrentCustomerQuery();

  useEffect(() => {
    if(error) {
      if (customerData?.currentCustomer?.customerId != null) {  
        if (customerData.currentCustomer.customerId === 546) {
          setImage(ngvPlaceholderImage);
        } else if (customerData.currentCustomer.customerId === 549) {
          setImage(ngvPubsPlaceholderImage);
        } else {
          setImage(productPlaceholderImage);
        }
      }
    }
    else {
      setImage(imageUrl);
    }
  }, [customerData, error]);

  return (
    <img
      src={image}
      alt={alt}
      style={{
        width: '100%',
        height: '100%',
        objectFit: !data?.isPlaceholderImage && !error ? 'contain' : 'cover',
        objectPosition: 'center',
      }}
    />
  );
}
