import { useCurrentUser } from './use-current-user';

export function useHasFeature() {
  const { customerFeatures } = useCurrentUser();

  function hasFeature(name: string) {
    // If the customer doesn't have tsufeatures setup then disable by default
    if (!customerFeatures || (customerFeatures && customerFeatures.length === 0)) return false;
    // Otherwise, perform the typical check for existence & access of the feature
    return customerFeatures.some((f) => f.tsuFeature.name === name && f.access);
  }

  return hasFeature;
}
