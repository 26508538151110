import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useMemo, useReducer, useState } from 'react';
import { useItemGroupsQuery, useOrderTypesQuery } from '../../../graphql';
import { AddProductRes, AddProductVars } from '../../../common/interfaces';
import { defaultProduct } from './AddProductDialog.constants';

interface Validator {
  messages: string[];
  enabled: boolean;
}

function reducer(state: typeof defaultProduct, action: { key: string; value: any }) {
  return {
    ...state,
    [action.key]: action.value,
  };
}

export function useAddProduct() {
  const { enqueueSnackbar } = useSnackbar();
  const [validator, setValidator] = useState({
    // Store one or more messages from validation logic
    messages: [],
    // If this is true then we will show the validator's messages
    // on <Alert /> component(s) above the save button
    enabled: false,
  } as Validator);
  const [state, dispatch] = useReducer(reducer, { ...defaultProduct });
  const { data: itemGroupsQuery, loading: isItemGroupsLoading } = useItemGroupsQuery();
  const { data: orderTypesQuery, loading: isOrderTypesLoading } = useOrderTypesQuery();

  const itemGroups = itemGroupsQuery?.itemGroups ?? [];
  const orderTypes = orderTypesQuery?.orderTypes ?? [];

  // There are only three quantity type choices, so hardcode the values for now
  // Use useMemo() to avoid recomputing on every render
  const quantityTypeChoices = useMemo(
    () => [
      { name: 'Text Box', quantityTypeId: 0 },
      { name: 'Drop Down', quantityTypeId: 1 },
      { name: 'Check Box', quantityTypeId: 2 },
    ],
    []
  );

  const [mutate, { loading: isCreating, error: createError }] = useMutation<
    AddProductRes,
    AddProductVars
  >(gql`
    mutation ($payload: CreateProductPayloadInput!) {
      createProduct(payload: $payload) {
        message
      }
    }
  `);

  async function createProduct() {
    // Ensure the following values are not null (in order to create a product properly):
    // 1. Product Name
    // 2. Product Type ID
    // 3. Quantity Type ID
    // 4. Status ID
    const validations: any = [];
    if (state.productName === '') validations.push('Product Name is required');
    if (state.productTypeId === null) validations.push('Product Type is required');
    if (state.quantityTypeId === null) validations.push('Quantity Type is required');
    if (state.statusId === null) validations.push('Product Status is required');
    if (validations.length > 0) return validate(validations);

    resetValidator();

    try {
      const res = await mutate({ variables: { payload: state } });
      enqueueSnackbar(res.data?.message ?? 'Created Product', {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar((err as any).toString(), { variant: 'error' });
    }
  }

  const resetValidator = () => setValidator({ messages: [], enabled: false });
  const validate = (messages: string[]) =>
    setValidator({
      ...validator,
      messages,
      enabled: true,
    });

  return {
    // Add Product Form state & dispatcher
    state,
    dispatch: (key: string, value: any) => dispatch({ key, value }),
    // Functions
    createProduct,
    // Dropdown choices state
    itemGroupChoices: itemGroups,
    productTypeChoices: orderTypes,
    quantityTypeChoices,
    // Validation
    validator,
    resetValidator,
    // Flags
    isCreating,
    isItemGroupsLoading,
    isProductTypesLoading: isOrderTypesLoading,
    // Errors
    createError,
  };
}
