import { TsuDialog, TsuDialogContent, TsuLoading } from '@/common/components';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { useState } from 'react';
import { useVisibleTabs } from '../hooks/useVisibleTabs';
import { useEditProductDialog } from './EditProductTabs.hook';

interface Props {
  productId: number | null;
  open: boolean;
  onClose?: () => void;
}

export function EditProductDialog({ productId, open, onClose }: Props) {
  // Retrieve list of visible tabs (strings) from backend
  const {
    visibleTabs,
    loading: isVisibleTabsLoading,
    error: visibleTabsError,
  } = useVisibleTabs({ productId });
  // Get memoized list of all tabs
  const { tabs } = useEditProductDialog({ productId, onClose });
  // Filter tabs to only the visible tabs
  const filteredTabs = tabs.filter((t) => visibleTabs.includes(t.tab));
  // Let the default tab be the first tab from filteredTabs
  // If there is no first tab, then "product-details" (but this should never happen)
  const [tab, setTab] = useState(filteredTabs.slice().shift()?.tab ?? 'product-details');

  return (
    <TsuDialog title="Edit Product" open={open} onClose={onClose} noFooter>
      <TsuDialogContent>
        <TsuLoading loading={isVisibleTabsLoading} error={visibleTabsError}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={(_, t) => setTab(t)} variant="scrollable" scrollButtons="auto">
                {filteredTabs.map((t, i) => (
                  <Tab key={i} label={t.label} value={t.tab} />
                ))}
              </TabList>
            </Box>
            {filteredTabs.map((t, i) => (
              <TabPanel key={i} value={t.tab}>
                {t.component}
              </TabPanel>
            ))}
          </TabContext>
        </TsuLoading>
      </TsuDialogContent>
    </TsuDialog>
  );
}
