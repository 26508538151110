import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserType } from '../graphql';

export interface UserState {
  fullName: string;
  email: string;
  tokenExpireUnix: number;
  isAdmin: boolean;
}

interface UserIdentityPayload {
  firstName: string;
  lastName: string;
  email: string;
  isAdmin: boolean;
}

const initialState: UserState = {
  fullName: '',
  email: '',
  tokenExpireUnix: 0,
  isAdmin: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserIdentity: (state, action: PayloadAction<UserIdentityPayload>) => {
      state.fullName = action.payload.firstName + ' ' + action.payload.lastName;
      state.email = action.payload.email;
      // state.tokenExpireUnix = action.payload.;
      state.isAdmin = action.payload.isAdmin;
    },
    setTokenExpiry: (state, action: PayloadAction<number>) => {
      state.tokenExpireUnix = action.payload;
    },
  },
});

export const { setUserIdentity, setTokenExpiry } = userSlice.actions;

export default userSlice.reducer;
